import React from 'react';
import Close from "../../../../assets/images/close.png";

const PopupSkill = (props) => {
    return (
        <div className={"popup__container popupSkill " + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          <div className="popup__head">
            <h3>{props.popupHead}</h3>
            <span onClick={props.close} className="close__popup">
              <img src={Close} alt="close" />
            </span>
            <div className="clear"></div>
          </div>
          <div className="popup__content">
            {props.children}
            <div className="clear"></div>
            <div className="d-flex align-center justify-content-center">
            <button className="button button--primary" onClick={props.close}>
              {props.buttonText}
            </button>
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
    );
};

export default PopupSkill;