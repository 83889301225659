import React from 'react';
import AudioThubDefulat from '../../../../assets/images/audioSmall.png'
import AudioThumb from '../../../../assets/images/audioIcon.svg'
import Arrow from '../../../../assets/images/rightArrow.svg'

const AudioCard = (props) => {
    return (
        <div onClick={props.onClick} className={"audioCard " + props?.className}> 
            <div className="audioCard__thumb"> 
                <img src={props.img ? props.img : AudioThubDefulat } alt={props.name} />
                <img className="audioThumb" src={AudioThumb} alt="Audio" />
            </div>
            <div className={"audioCard__text " + (props?.carousel ? "audioCard__text--carousel":"")}> 
                <h3 className="audioName">{props.name} <span><img src={Arrow} alt="arrow" /></span></h3>
                <p className="url"><span>URL: </span> {props.url}</p>
                <p className="creator"><span>Creator: </span> {props.creator}</p>
                <p className="work"><span>Show/Work: </span> {props.work}</p>
            </div>
            <div className="clear"></div>
        </div>
    );
};

export default AudioCard;