import {
    APP_CREATE_COMMISSIONEDWORK_START,
    APP_CREATE_COMMISSIONEDWORK,
    APP_CREATE_COMMISSIONEDWORK_FAIL,
    APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_START,
    APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO,
    APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_FAIL,
    APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_START,
    APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS,
    APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'

const initialState = {
    isLoading: false,
    isLoadingAdditionalInfo: false,
    isLoadingCommissionedWorkToProfessionCards: false,
    error: false,
    message: '',
    isSuccess: false,
    commissionedWork: {},
    statusCode: 201
}


export const commissionedWork = (state = initialState, action) => {
    switch (action.type) {
        case APP_CREATE_COMMISSIONEDWORK_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_COMMISSIONEDWORK:
            return Object.assign({}, state, {
                commissionedWork: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_COMMISSIONEDWORK_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_START:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                commissionedWork: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_FAIL:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingCommissionedWorkToProfessionCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                isLoadingCommissionedWorkToProfessionCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingCommissionedWorkToProfessionCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.commissionedWork.error
export const getMsg = state => state.commissionedWork.message
export const getisLoading = state => state.commissionedWork.isLoading
export const getIsSuccess = state => state.commissionedWork.isSuccess
export const getstatusCode = state => state.commissionedWork.statusCode
export const getCommissionedWork = state => state.commissionedWork.commissionedWork
export const getisLoadingAdditionalInfo = state => state.commissionedWork.isLoadingAdditionalInfo
export const getisLoadingCommissionedWorkToProfessionCards = state => state.commissionedWork.isLoadingCommissionedWorkToProfessionCards

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
