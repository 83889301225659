/*
# Application wide actions.

Handle in all sub-reducers if necessary
*/

//introScreen
export const APP_INTRO_SEEN = 'APP_INTRO_SEEN'
export const APP_FLEXIBLE_EVENTS_SEEN = 'APP_FLEXIBLE_EVENTS_SEEN'
export const APP_FIRST_TIME_SEARCH = 'APP_FIRST_TIME_SEARCH'

//location
export const APP_MAP_DATA = 'APP_MAP_DATA'
export const APP_MAP_DATA_FAIL = 'APP_MAP_DATA_FAIL'
export const APP_START_MAP_DATA = 'APP_START_MAP_DATA'

//login
export const APP_LOGIN_START = 'APP_LOGIN_START'
export const APP_LOGIN = 'APP_LOGIN'
export const APP_LOGIN_FAIL = 'APP_LOGIN_FAIL'
export const APP_LOGOUT = 'APP_LOGOUT'
export const APP_AUTH_FAILED = 'APP_AUTH_FAILED'

//register
export const APP_REGISTER = 'APP_REGISTER'
export const APP_REGISTER_FAIL = 'APP_REGISTER_FAIL'
export const APP_REGISTER_VERIFY = 'APP_REGISTER_VERIFY'
export const APP_REGISTER_VERIFY_FAIL = 'APP_REGISTER_VERIFY_FAIL'
export const APP_RESENT_OTP = 'APP_RESENT_OTP'
export const APP_RESENT_OTP_FAIL = 'APP_RESENT_OTP_FAIL'
export const APP_RESET_PASSWORD_OTP = 'APP_RESET_PASSWORD_OTP'
export const APP_RESET_PASSWORD_OTP_FAIL = 'APP_RESET_PASSWORD_OTP_FAIL'
export const APP_RESET_PASSWORD = 'APP_RESET_PASSWORD'
export const APP_UPDATE_PROFILE_CREATED = 'APP_UPDATE_PROFILE_CREATED'

//activity
export const APP_ACTIVITY_UPDATE = 'APP_ACTIVITY_UPDATE'
export const APP_ACTIVITY_UPDATE_FAIL = 'APP_ACTIVITY_UPDATE_FAIL'

//user profile
export const APP_USER_PROFILE_START = 'APP_USER_PROFILE_START'
export const APP_USER_PROFILE_ADD = 'APP_USER_PROFILE_ADD'
export const APP_USER_PROFILE_FAIL = 'APP_USER_PROFILE_FAIL'
export const APP_USER_PROFILE_LOCATION_ADD = 'APP_USER_PROFILE_LOCATION_ADD'
export const APP_GET_FEATURED_USER_PROFILE_DATA_START = 'APP_GET_FEATURED_USER_PROFILE_DATA_START'
export const APP_GET_FEATURED_USER_PROFILE_DATA = 'APP_GET_FEATURED_USER_PROFILE_DATA'
export const APP_GET_FEATURED_USER_PROFILE_DATA_NO_CARD = 'APP_GET_FEATURED_USER_PROFILE_DATA_NO_CARD'
export const APP_GET_FEATURED_USER_PROFILE_DATA_FAIL = 'APP_GET_FEATURED_USER_PROFILE_DATA_FAIL'
export const APP_DESCRIPTION_USER_PROFESSION_CARDS_START = 'APP_DESCRIPTION_USER_PROFESSION_CARDS_START'
export const APP_DESCRIPTION_USER_PROFESSION_CARDS = 'APP_DESCRIPTION_USER_PROFESSION_CARDS'
export const APP_DESCRIPTION_USER_PROFESSION_CARDS_FAIL = 'APP_DESCRIPTION_USER_PROFESSION_CARDS_FAIL'
export const APP_CHANGE_PASSWORD_START = 'APP_CHANGE_PASSWORD_START'
export const APP_CHANGE_PASSWORD = 'APP_CHANGE_PASSWORD'
export const APP_CHANGE_PASSWORD_FAIL = 'APP_CHANGE_PASSWORD_FAIL'
export const APP_PUBLIC_USER_PROFILE_ADD = 'APP_PUBLIC_USER_PROFILE_ADD'
export const APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA = 'APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA'
export const APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA_NO_CARD = 'APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA_NO_CARD'
export const APP_GET_SPOTLOGHT_USERS_START = 'APP_GET_SPOTLOGHT_USERS_START'
export const APP_GET_SPOTLOGHT_USERS = 'APP_GET_SPOTLOGHT_USERS'
export const APP_GET_SPOTLOGHT_USERS_FAIL = 'APP_GET_SPOTLOGHT_USERS_FAIL'

//fileUpload
export const APP_FILES_UPLOAD_START = 'APP_FILES_UPLOAD_START'
export const APP_FILES_UPLOAD = 'APP_FILES_UPLOAD'
export const APP_FILES_UPLOAD_FAIL = 'APP_FILES_UPLOAD_FAIL'

//profile cards
export const APP_DELETE_PROFILE_CARD_START = 'APP_DELETE_PROFILE_CARD_START'
export const APP_DELETE_PROFILE_CARD = 'APP_DELETE_PROFILE_CARD'
export const APP_DELETE_PROFILE_CARD_FAILED = 'APP_DELETE_PROFILE_CARD_FAILED'

//discipline
export const APP_GET_DISCIPLINE_START = 'APP_GET_DISCIPLINE_START'
export const APP_GET_DISCIPLINE = 'APP_GET_DISCIPLINE'
export const APP_GET_DISCIPLINE_FAIL = 'APP_GET_DISCIPLINE_FAIL'
export const SAVE_DISCIPLINE = 'SAVE_DISCIPLINE'

//get profession by discipline
export const APP_GET_PROFESSION_CATEGORY_START = 'APP_GET_PROFESSION_CATEGORY_START'
export const APP_GET_PROFESSION_CATEGORY = 'APP_GET_PROFESSION_CATEGORY'
export const APP_GET_PROFESSION_CATEGORY_FAIL = 'APP_GET_PROFESSION_CATEGORY_FAIL'

//profession categories
export const SAVE_SELECTED_PROFESSIONS = 'SAVE_SELECTED_PROFESSIONS'
export const CLEAR_SELECTED_PROFESSIONS = 'CLEAR_SELECTED_PROFESSIONS'
export const APP_POST_USER_PROFESSION_START = 'APP_POST_USER_PROFESSION_START'
export const APP_POST_USER_PROFESSION = 'APP_POST_USER_PROFESSION'
export const APP_POST_USER_PROFESSION_FAIL = 'APP_POST_USER_PROFESSION_FAIL'
export const APP_GET_USER_PROFESSION_START = 'APP_GET_USER_PROFESSION_START'
export const APP_GET_USER_PROFESSION = 'APP_GET_USER_PROFESSION'
export const APP_GET_USER_PROFESSION_404 = 'APP_GET_USER_PROFESSION_404'
export const APP_GET_USER_PROFESSION_FAIL = 'APP_GET_USER_PROFESSION_FAIL'
export const APP_GET_USER_PROFESSION_CARDS_START = 'APP_GET_USER_PROFESSION_CARDS_START'
export const APP_GET_USER_PROFESSION_CARDS = 'APP_GET_USER_PROFESSION_CARDS'
export const APP_GET_USER_PROFESSION_CARDS_FAIL = 'APP_GET_USER_PROFESSION_CARDS_FAIL'
export const APP_GET_COMBINATION_USER_PROFESSION_START = 'APP_GET_COMBINATION_USER_PROFESSION_START'
export const APP_GET_COMBINATION_USER_PROFESSION = 'APP_GET_COMBINATION_USER_PROFESSION'
export const APP_GET_COMBINATION_USER_PROFESSION_FAIL = 'APP_GET_COMBINATION_USER_PROFESSION_FAIL'

//skills
export const APP_GET_ALL_SKILLS_START = 'APP_GET_ALL_SKILLS_START'
export const APP_GET_ALL_SKILLS = 'APP_GET_ALL_SKILLS'
export const APP_GET_ALL_SKILLS_FAIL = 'APP_GET_ALL_SKILLS_FAIL'
export const APP_GET_ALL_USER_SKILLS_START = 'APP_GET_ALL_USER_SKILLS_START'
export const APP_GET_ALL_USER_SKILLS = 'APP_GET_ALL_USER_SKILLS'
export const APP_GET_ALL_USER_SKILLS_FAIL = 'APP_GET_ALL_USER_SKILLS_FAIL'
export const SAVE_SELECTED_SKILLS = 'SAVE_SELECTED_SKILLS'
export const APP_PUT_USER_SKILLS_START = 'APP_PUT_USER_SKILLS_START'
export const APP_PUT_USER_SKILLS = 'APP_PUT_USER_SKILLS'
export const APP_PUT_USER_SKILLS_FAIL = 'APP_PUT_USER_SKILLS_FAIL'

//user cards
export const APP_POST_USER_CARDS_START = 'APP_POST_USER_CARDS_START'
export const APP_POST_USER_CARDS = 'APP_POST_USER_CARDS'
export const APP_POST_USER_CARDS_FAIL = 'APP_POST_USER_CARDS_FAIL'
export const APP_DELETE_USER_PROFESSION_CARDS_START = 'APP_DELETE_USER_PROFESSION_CARDS_START'
export const APP_DELETE_USER_PROFESSION_CARDS = 'APP_DELETE_USER_PROFESSION_CARDS'
export const APP_DELETE_USER_PROFESSION_CARDS_FAIL = 'APP_DELETE_USER_PROFESSION_CARDS_FAIL'
export const APP_GET_FAV_USER_PROFESSION_CARDS_START = 'APP_GET_FAV_USER_PROFESSION_CARDS_START'
export const APP_GET_FAV_USER_PROFESSION_CARDS = 'APP_GET_FAV_USER_PROFESSION_CARDS'
export const APP_GET_FAV_USER_PROFESSION_CARDS_FAIL = 'APP_GET_FAV_USER_PROFESSION_CARDS_FAIL'
export const APP_POST_FAV_USER_PROFESSION_CARDS_START = 'APP_POST_FAV_USER_PROFESSION_CARDS_START'
export const APP_POST_FAV_USER_PROFESSION_CARDS = 'APP_POST_FAV_USER_PROFESSION_CARDS'
export const APP_POST_FAV_USER_PROFESSION_CARDS_FAIL = 'APP_POST_FAV_USER_PROFESSION_CARDS_FAIL'
export const APP_GET_RANDOM_USER_PROFESSION_CARDS_START = 'APP_GET_RANDOM_USER_PROFESSION_CARDS_START'
export const APP_GET_RANDOM_USER_PROFESSION_CARDS = 'APP_GET_RANDOM_USER_PROFESSION_CARDS'
export const APP_GET_RANDOM_USER_PROFESSION_CARDS_FAIL = 'APP_GET_RANDOM_USER_PROFESSION_CARDS_FAIL'

//resume training program UI
export const SAVE_TRAINING_PROGRAM_NAME = 'SAVE_TRAINING_PROGRAM_NAME'
export const SAVE_TRAINING_PROGRAM_TYPE = 'SAVE_TRAINING_PROGRAM_TYPE'
export const SAVE_TRAINING_PROGRAM_LOCATION = 'SAVE_TRAINING_PROGRAM_LOCATION'
export const SAVE_TRAINING_PROGRAM_INSTITUTION_INFO = 'SAVE_TRAINING_PROGRAM_INSTITUTION_INFO'
export const SAVE_TRAINING_PROGRAM_COLLEAGUES = 'SAVE_TRAINING_PROGRAM_COLLEAGUES'
export const CLEAR_TRAINING_PROGRAM = 'CLEAR_TRAINING_PROGRAM'
export const CLEAR_TRAINING_PROGRAM_COLLEAGUES = 'CLEAR_TRAINING_PROGRAM_COLLEAGUES'

//accolades awards UI
export const SAVE_ACCOLADES_AWARD_TITLE = 'SAVE_ACCOLADES_AWARD_TITLE'
export const SAVE_ACCOLADES_AWARD_DATE = 'SAVE_ACCOLADES_AWARD_DATE'
export const SAVE_ACCOLADES_AWARD_INSTITUTION_ID = 'SAVE_ACCOLADES_AWARD_INSTITUTION_ID'
export const SAVE_ACCOLADES_AWARD_INSTITUTION_LOCATION = 'SAVE_ACCOLADES_AWARD_INSTITUTION_LOCATION'
export const SAVE_ACCOLADES_AWARD_AFFILIATE_INSTITUTION_ID = 'SAVE_ACCOLADES_AWARD_AFFILIATE_INSTITUTION_ID'
export const CLEAR_ACCOLADES_AWARD = 'CLEAR_ACCOLADES_AWARD'

//accolades competition UI
export const SAVE_ACCOLADES_COMPETITION_TITLE = 'SAVE_ACCOLADES_COMPETITION_TITLE'
export const SAVE_ACCOLADES_COMPETITION_DATE = 'SAVE_ACCOLADES_COMPETITION_DATE'
export const SAVE_ACCOLADES_COMPETITION_INSTITUTION_ID = 'SAVE_ACCOLADES_COMPETITION_INSTITUTION_ID'
export const SAVE_ACCOLADES_COMPETITION_INSTITUTION_LOCATION = 'SAVE_ACCOLADES_COMPETITION_INSTITUTION_LOCATION'
export const SAVE_ACCOLADES_COMPETITION_AFFILIATE_INSTITUTION_ID = 'SAVE_ACCOLADES_COMPETITION_AFFILIATE_INSTITUTION_ID'
export const CLEAR_ACCOLADES_COMPETITION = 'CLEAR_ACCOLADES_COMPETITION'

//accolades scholarship UI
export const SAVE_ACCOLADES_SCHOLARSHIP_TITLE = 'SAVE_ACCOLADES_SCHOLARSHIP_TITLE'
export const SAVE_ACCOLADES_SCHOLARSHIP_DATE = 'SAVE_ACCOLADES_SCHOLARSHIP_DATE'
export const SAVE_ACCOLADES_SCHOLARSHIP_INSTITUTION_ID = 'SAVE_ACCOLADES_SCHOLARSHIP_INSTITUTION_ID'
export const SAVE_ACCOLADES_SCHOLARSHIP_INSTITUTION_LOCATION = 'SAVE_ACCOLADES_SCHOLARSHIP_INSTITUTION_LOCATION'
export const SAVE_ACCOLADES_SCHOLARSHIP_AFFILIATE_INSTITUTION_ID = 'SAVE_ACCOLADES_SCHOLARSHIP_AFFILIATE_INSTITUTION_ID'
export const CLEAR_ACCOLADES_SCHOLARSHIP = 'CLEAR_ACCOLADES_SCHOLARSHIP'

//education UI
export const SAVE_EDUCATION_LOCATION = 'SAVE_EDUCATION_LOCATION'
export const SAVE_EDUCATION_INSTITUTION_ID = 'SAVE_EDUCATION_INSTITUTION_ID'
export const CLEAR_EDUCATION = 'CLEAR_EDUCATION'
export const SAVE_EDUCATION_ASSOCIATED_LOCATION = 'SAVE_EDUCATION_ASSOCIATED_LOCATION'
export const SAVE_EDUCATION_ASSOCIATED_INSTITUTION_ID = 'SAVE_EDUCATION_ASSOCIATED_INSTITUTION_ID'
export const SAVE_EDUCATION_INSTITUTION_TYPE = 'SAVE_EDUCATION_INSTITUTION_TYPE'
export const SAVE_EDUCATION_ASSOCIATED_INSTITUTION_TYPE = 'SAVE_EDUCATION_ASSOCIATED_INSTITUTION_TYPE'

//job title UI
export const SAVE_JOBTITLE_ASSOCIATION_LOCATION = 'SAVE_JOBTITLE_ASSOCIATION_LOCATION'
export const SAVE_JOBTITLE_ASSOCIATION_INSTITUTION_ID = 'SAVE_JOBTITLE_ASSOCIATION_INSTITUTION_ID'
export const SAVE_JOBTITLE_TITLE = 'SAVE_JOBTITLE_TITLE'
export const SAVE_JOBTITLE_TYPE = 'SAVE_JOBTITLE_TYPE'
export const SAVE_JOBTITLE_DATE = 'SAVE_JOBTITLE_DATE'
export const CLEAR_JOBTITLE = 'CLEAR_JOBTITLE'

//master class UI
export const SAVE_MASTERCLASS_POSITION = 'SAVE_MASTERCLASS_POSITION'
export const SAVE_MASTERCLASS_POSITION_NAME = 'SAVE_MASTERCLASS_POSITION_NAME'
export const SAVE_MASTERCLASS_INSTITUTION_ID = 'SAVE_MASTERCLASS_INSTITUTION_ID'
export const CLEAR_MASTERCLASS = 'CLEAR_MASTERCLASS'

//commissioned work UI
export const SAVE_COMMISSIONEDWORK_CREATORS = 'SAVE_COMMISSIONEDWORK_CREATORS'
export const SAVE_COMMISSIONEDWORK_OTHER_CREATORS = 'SAVE_COMMISSIONEDWORK_OTHER_CREATORS'
export const SAVE_COMMISSIONEDWORK_TITLE = 'SAVE_COMMISSIONEDWORK_TITLE'
export const SAVE_COMMISSIONEDWORK_TYPE = 'SAVE_COMMISSIONEDWORK_TYPE'
export const SAVE_COMMISSIONEDWORK_DATE = 'SAVE_COMMISSIONEDWORK_DATE'
export const SAVE_COMMISSIONEDWORK_ASSOCIATION_USER = 'SAVE_COMMISSIONEDWORK_ASSOCIATION_USER'
export const CLEAR_COMMISSIONEDWORK_ASSOCIATION_USER = 'CLEAR_COMMISSIONEDWORK_ASSOCIATION_USER'
export const CLEAR_COMMISSIONEDWORK = 'CLEAR_COMMISSIONEDWORK'

//resume training
export const SAVE_TRAINING_INDIVIDUAL_TRAINER = 'SAVE_TRAINING_INDIVIDUAL_TRAINER'
export const SAVE_TRAINING_INDIVIDUAL_INSTITUTION = 'SAVE_TRAINING_INDIVIDUAL_INSTITUTION'
export const CLEAR_TRAINING_INDIVIDUAL = 'CLEAR_TRAINING_INDIVIDUAL'
export const CLEAR_TRAINING_INDIVIDUAL_TRAINER = 'CLEAR_TRAINING_INDIVIDUAL_TRAINER'

//institution
export const APP_POST_INSTITUTIONS_START = 'APP_POST_INSTITUTIONS_START'
export const APP_POST_INSTITUTIONS = 'APP_POST_INSTITUTIONS'
export const APP_POST_INSTITUTIONS_FAIL = 'APP_POST_INSTITUTIONS_FAIL'
export const APP_GET_INSTITUTIONS_START = 'APP_GET_INSTITUTIONS_START'
export const APP_GET_INSTITUTIONS = 'APP_GET_INSTITUTIONS'
export const APP_GET_INSTITUTIONS_FAIL = 'APP_GET_INSTITUTIONS_FAIL'
export const APP_GET_INSTITUTION_TYPES_START = 'APP_GET_INSTITUTION_TYPES_START'
export const APP_GET_INSTITUTION_TYPES = 'APP_GET_INSTITUTION_TYPES'
export const APP_GET_INSTITUTION_TYPES_FAIL = 'APP_GET_INSTITUTION_TYPES_FAIL'
export const APP_GET_INSTITUTION_SUBTYPES_START = 'APP_GET_INSTITUTION_SUBTYPES_START'
export const APP_GET_INSTITUTION_SUBTYPES = 'APP_GET_INSTITUTION_SUBTYPES'
export const APP_GET_INSTITUTION_SUBTYPES_FAIL = 'APP_GET_INSTITUTION_SUBTYPES_FAIL'
export const APP_GET_INSTITUTIONS_BY_TYPE_ID_START = 'APP_GET_INSTITUTIONS_BY_TYPE_ID_START'
export const APP_GET_INSTITUTIONS_BY_TYPE_ID = 'APP_GET_INSTITUTIONS_BY_TYPE_ID'
export const APP_GET_INSTITUTIONS_BY_TYPE_ID_FAIL = 'APP_GET_INSTITUTIONS_BY_TYPE_ID_FAIL'
export const APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID_START = 'APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID_START'
export const APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID = 'APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID'
export const APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID_FAIL = 'APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID_FAIL'

//training program
export const APP_CREATE_TRAINING_PROGRAM_START = 'APP_CREATE_TRAINING_PROGRAM_START'
export const APP_CREATE_TRAINING_PROGRAM = 'APP_CREATE_TRAINING_PROGRAM'
export const APP_CREATE_TRAINING_PROGRAM_FAIL = 'APP_CREATE_TRAINING_PROGRAM_FAIL'
export const APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_START = 'APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_START'
export const APP_CREATE_TRAINING_PROGRAM_COLLEAGUES = 'APP_CREATE_TRAINING_PROGRAM_COLLEAGUES'
export const APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_FAIL = 'APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_FAIL'
export const APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_START = 'APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_START'
export const APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO = 'APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO'
export const APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_FAIL = 'APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_START = 'APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_START'
export const APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS = 'APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS'
export const APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_FAIL'

//training individual
export const APP_CREATE_TRAINING_INDIVIDUAL_START = 'APP_CREATE_TRAINING_INDIVIDUAL_START'
export const APP_CREATE_TRAINING_INDIVIDUAL = 'APP_CREATE_TRAINING_INDIVIDUAL'
export const APP_CREATE_TRAINING_INDIVIDUAL_FAIL = 'APP_CREATE_TRAINING_INDIVIDUAL_FAIL'
export const APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_START = 'APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_START'
export const APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO = 'APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO'
export const APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_FAIL = 'APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_START = 'APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_START'
export const APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS = 'APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS'
export const APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_FAIL'

//user search by text
export const APP_GET_ALL_USER_BY_TEXT_SEARCH = 'APP_GET_ALL_USER_BY_TEXT_SEARCH'
export const APP_GET_ALL_USER_BY_TEXT_SEARCH_FAIL = 'APP_GET_ALL_USER_BY_TEXT_SEARCH_FAIL'
export const APP_CLEAR_ALL_USER_BY_TEXT_SEARCH = 'APP_CLEAR_ALL_USER_BY_TEXT_SEARCH'

//accolades awards
export const APP_CREATE_ACCOLADES_AWARD_START = 'APP_CREATE_ACCOLADES_AWARD_START'
export const APP_CREATE_ACCOLADES_AWARD = 'APP_CREATE_ACCOLADES_AWARD'
export const APP_CREATE_ACCOLADES_AWARD_FAIL = 'APP_CREATE_ACCOLADES_AWARD_FAIL'
export const APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_START = 'APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_START'
export const APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO = 'APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO'
export const APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_FAIL = 'APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_START = 'APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_START'
export const APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS = 'APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS'
export const APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_FAIL'

//accolades scholarship
export const APP_CREATE_ACCOLADES_SCHOLARSHIP_START = 'APP_CREATE_ACCOLADES_SCHOLARSHIP_START'
export const APP_CREATE_ACCOLADES_SCHOLARSHIP = 'APP_CREATE_ACCOLADES_SCHOLARSHIP'
export const APP_CREATE_ACCOLADES_SCHOLARSHIP_FAIL = 'APP_CREATE_ACCOLADES_SCHOLARSHIP_FAIL'
export const APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO_START = 'APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO_START'
export const APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO = 'APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO'
export const APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO_FAIL = 'APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS_START = 'APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS_START'
export const APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS = 'APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS'
export const APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS_FAIL'

//accolades competition
export const APP_CREATE_ACCOLADES_COMPETITION_START = 'APP_CREATE_ACCOLADES_COMPETITION_START'
export const APP_CREATE_ACCOLADES_COMPETITION = 'APP_CREATE_ACCOLADES_COMPETITION'
export const APP_CREATE_ACCOLADES_COMPETITION_FAIL = 'APP_CREATE_ACCOLADES_COMPETITION_FAIL'
export const APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_START = 'APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_START'
export const APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO = 'APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO'
export const APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_FAIL = 'APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_START = 'APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_START'
export const APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS = 'APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS'
export const APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_FAIL'

//resume education
export const APP_CREATE_EDUCATION_START = 'APP_CREATE_EDUCATION_START'
export const APP_CREATE_EDUCATION = 'APP_CREATE_EDUCATION'
export const APP_CREATE_EDUCATION_FAIL = 'APP_CREATE_EDUCATION_FAIL'
export const APP_CREATE_EDUCATION_ADDITIONAL_INFO_START = 'APP_CREATE_EDUCATION_ADDITIONAL_INFO_START'
export const APP_CREATE_EDUCATION_ADDITIONAL_INFO = 'APP_CREATE_EDUCATION_ADDITIONAL_INFO'
export const APP_CREATE_EDUCATION_ADDITIONAL_INFO_FAIL = 'APP_CREATE_EDUCATION_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_START = 'APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_START'
export const APP_CREATE_EDUCATION_TO_PROFESSION_CARDS = 'APP_CREATE_EDUCATION_TO_PROFESSION_CARDS'
export const APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_FAIL'

//resume job
export const APP_CREATE_JOB_START = 'APP_CREATE_JOB_START'
export const APP_CREATE_JOB = 'APP_CREATE_JOB'
export const APP_CREATE_JOB_FAIL = 'APP_CREATE_JOB_FAIL'
export const APP_CREATE_JOB_ADDITIONAL_INFO_START = 'APP_CREATE_JOB_ADDITIONAL_INFO_START'
export const APP_CREATE_JOB_ADDITIONAL_INFO = 'APP_CREATE_JOB_ADDITIONAL_INFO'
export const APP_CREATE_JOB_ADDITIONAL_INFO_FAIL = 'APP_CREATE_JOB_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_JOB_TO_PROFESSION_CARDS_START = 'APP_CREATE_JOB_TO_PROFESSION_CARDS_START'
export const APP_CREATE_JOB_TO_PROFESSION_CARDS = 'APP_CREATE_JOB_TO_PROFESSION_CARDS'
export const APP_CREATE_JOB_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_JOB_TO_PROFESSION_CARDS_FAIL'

//resume masterclass
export const APP_CREATE_MASTERCLASS_START = 'APP_CREATE_MASTERCLASS_START'
export const APP_CREATE_MASTERCLASS = 'APP_CREATE_MASTERCLASS'
export const APP_CREATE_MASTERCLASS_FAIL = 'APP_CREATE_MASTERCLASS_FAIL'
export const APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_START = 'APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_START'
export const APP_CREATE_MASTERCLASS_ADDITIONAL_INFO = 'APP_CREATE_MASTERCLASS_ADDITIONAL_INFO'
export const APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_FAIL = 'APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_START = 'APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_START'
export const APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS = 'APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS'
export const APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_FAIL'

//resume masterclass
export const APP_CREATE_COMMISSIONEDWORK_START = 'APP_CREATE_COMMISSIONEDWORK_START'
export const APP_CREATE_COMMISSIONEDWORK = 'APP_CREATE_COMMISSIONEDWORK'
export const APP_CREATE_COMMISSIONEDWORK_FAIL = 'APP_CREATE_COMMISSIONEDWORK_FAIL'
export const APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_START = 'APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_START'
export const APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO = 'APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO'
export const APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_FAIL = 'APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_FAIL'
export const APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_START = 'APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_START'
export const APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS = 'APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS'
export const APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_FAIL = 'APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_FAIL'

//media audio ui
export const SAVE_MEDIA_AUDIO_PROFESSION_CARDS = 'SAVE_MEDIA_AUDIO_PROFESSION_CARDS'
export const SAVE_MEDIA_AUDIO_MANDATE_FIELDS = 'SAVE_MEDIA_AUDIO_MANDATE_FIELDS'
export const SAVE_MEDIA_AUDIO_URL = 'SAVE_MEDIA_AUDIO_URL'
export const CLEAR_MEDIA_AUDIO = 'CLEAR_MEDIA_AUDIO'

//media video ui
export const SAVE_MEDIA_VIDEO_PROFESSION_CARDS = 'SAVE_MEDIA_VIDEO_PROFESSION_CARDS'
export const SAVE_MEDIA_VIDEO_TYPE = 'SAVE_MEDIA_VIDEO_TYPE'
export const SAVE_MEDIA_VIDEO_URL = 'SAVE_MEDIA_VIDEO_URL'
export const CLEAR_MEDIA_VIDEO = 'CLEAR_MEDIA_VIDEO'

//media audio
export const APP_POST_MEDIA_AUDIO_START = 'APP_POST_MEDIA_AUDIO_START'
export const APP_POST_MEDIA_AUDIO = 'APP_POST_MEDIA_AUDIO'
export const APP_POST_MEDIA_AUDIO_FAIL = 'APP_POST_MEDIA_AUDIO_FAIL'
export const APP_DELETE_MEDIA_AUDIO_START = 'APP_DELETE_MEDIA_AUDIO_START'
export const APP_DELETE_MEDIA_AUDIO = 'APP_DELETE_MEDIA_AUDIO'
export const APP_DELETE_MEDIA_AUDIO_FAIL = 'APP_DELETE_MEDIA_AUDIO_FAIL'
export const APP_GET_FEATURED_MEDIA_AUDIO_START = 'APP_GET_FEATURED_MEDIA_AUDIO_START'
export const APP_GET_FEATURED_MEDIA_AUDIO = 'APP_GET_FEATURED_MEDIA_AUDIO'
export const APP_GET_FEATURED_MEDIA_AUDIO_FAIL = 'APP_GET_FEATURED_MEDIA_AUDIO_FAIL'
export const APP_GET_CARD_MEDIA_AUDIO_START = 'APP_GET_CARD_MEDIA_AUDIO_START'
export const APP_GET_CARD_MEDIA_AUDIO = 'APP_GET_CARD_MEDIA_AUDIO'
export const APP_GET_CARD_MEDIA_AUDIO_FAIL = 'APP_GET_CARD_MEDIA_AUDIO_FAIL'
export const APP_GET_MEDIA_AUDIO_START = 'APP_GET_MEDIA_AUDIO_START'
export const APP_GET_MEDIA_AUDIO = 'APP_GET_MEDIA_AUDIO'
export const APP_GET_MEDIA_AUDIO_FAIL = 'APP_GET_MEDIA_AUDIO_FAIL'
export const APP_PUT_FEATURED_MEDIA_AUDIO_START = 'APP_PUT_FEATURED_MEDIA_AUDIO_START'
export const APP_PUT_FEATURED_MEDIA_AUDIO = 'APP_PUT_FEATURED_MEDIA_AUDIO'
export const APP_PUT_FEATURED_MEDIA_AUDIO_FAIL = 'APP_PUT_FEATURED_MEDIA_AUDIO_FAIL'
export const APP_UNASSIGN_MEDIA_AUDIO_START = 'APP_UNASSIGN_MEDIA_AUDIO_START'
export const APP_UNASSIGN_MEDIA_AUDIO = 'APP_UNASSIGN_MEDIA_AUDIO'
export const APP_UNASSIGN_MEDIA_AUDIO_FAIL = 'APP_UNASSIGN_MEDIA_AUDIO_FAIL'
export const APP_PUT_MEDIA_AUDIO_START = 'APP_PUT_MEDIA_AUDIO_START'
export const APP_PUT_MEDIA_AUDIO = 'APP_PUT_MEDIA_AUDIO'
export const APP_PUT_MEDIA_AUDIO_FAIL = 'APP_PUT_MEDIA_AUDIO_FAIL'
export const APP_ASSIGN_MEDIA_AUDIO_START = 'APP_ASSIGN_MEDIA_AUDIO_START'
export const APP_ASSIGN_MEDIA_AUDIO = 'APP_ASSIGN_MEDIA_AUDIO'
export const APP_ASSIGN_MEDIA_AUDIO_FAIL = 'APP_ASSIGN_MEDIA_AUDIO_FAIL'
export const APP_DELETE_MEDIA_AUDIOS_START = 'APP_DELETE_MEDIA_AUDIOS_START'
export const APP_DELETE_MEDIA_AUDIOS = 'APP_DELETE_MEDIA_AUDIOS'
export const APP_DELETE_MEDIA_AUDIOS_FAIL = 'APP_DELETE_MEDIA_AUDIOS_FAIL'

//media video
export const APP_POST_MEDIA_VIDEO_START = 'APP_POST_MEDIA_VIDEO_START'
export const APP_POST_MEDIA_VIDEO = 'APP_POST_MEDIA_VIDEO'
export const APP_POST_MEDIA_VIDEO_FAIL = 'APP_POST_MEDIA_VIDEO_FAIL'
export const APP_DELETE_MEDIA_VIDEO_START = 'APP_DELETE_MEDIA_VIDEO_START'
export const APP_DELETE_MEDIA_VIDEO = 'APP_DELETE_MEDIA_VIDEO'
export const APP_DELETE_MEDIA_VIDEO_FAIL = 'APP_DELETE_MEDIA_VIDEO_FAIL'
export const APP_GET_FEATURED_MEDIA_VIDEO_START = 'APP_GET_FEATURED_MEDIA_VIDEO_START'
export const APP_GET_FEATURED_MEDIA_VIDEO = 'APP_GET_FEATURED_MEDIA_VIDEO'
export const APP_GET_FEATURED_MEDIA_VIDEO_FAIL = 'APP_GET_FEATURED_MEDIA_VIDEO_FAIL'
export const APP_GET_CARD_MEDIA_VIDEO_START = 'APP_GET_CARD_MEDIA_VIDEO_START'
export const APP_GET_CARD_MEDIA_VIDEO = 'APP_GET_CARD_MEDIA_VIDEO'
export const APP_GET_CARD_MEDIA_VIDEO_FAIL = 'APP_GET_CARD_MEDIA_VIDEO_FAIL'
export const APP_GET_MEDIA_VIDEO_START = 'APP_GET_MEDIA_VIDEO_START'
export const APP_GET_MEDIA_VIDEO = 'APP_GET_MEDIA_VIDEO'
export const APP_GET_MEDIA_VIDEO_FAIL = 'APP_GET_MEDIA_VIDEO_FAIL'
export const APP_PUT_FEATURED_MEDIA_VIDEO_START = 'APP_PUT_FEATURED_MEDIA_VIDEO_START'
export const APP_PUT_FEATURED_MEDIA_VIDEO = 'APP_PUT_FEATURED_MEDIA_VIDEO'
export const APP_PUT_FEATURED_MEDIA_VIDEO_FAIL = 'APP_PUT_FEATURED_MEDIA_VIDEO_FAIL'
export const APP_UNASSIGN_MEDIA_VIDEO_START = 'APP_UNASSIGN_MEDIA_VIDEO_START'
export const APP_UNASSIGN_MEDIA_VIDEO = 'APP_UNASSIGN_MEDIA_VIDEO'
export const APP_UNASSIGN_MEDIA_VIDEO_FAIL = 'APP_UNASSIGN_MEDIA_VIDEO_FAIL'
export const APP_PUT_MEDIA_VIDEO_START = 'APP_PUT_MEDIA_VIDEO_START'
export const APP_PUT_MEDIA_VIDEO = 'APP_PUT_MEDIA_VIDEO'
export const APP_PUT_MEDIA_VIDEO_FAIL = 'APP_PUT_MEDIA_VIDEO_FAIL'
export const APP_ASSIGN_MEDIA_VIDEO_START = 'APP_ASSIGN_MEDIA_VIDEO_START'
export const APP_ASSIGN_MEDIA_VIDEO = 'APP_ASSIGN_MEDIA_VIDEO'
export const APP_ASSIGN_MEDIA_VIDEO_FAIL = 'APP_ASSIGN_MEDIA_VIDEO_FAIL'
export const APP_DELETE_MEDIA_VIDEOS_START = 'APP_DELETE_MEDIA_VIDEOS_START'
export const APP_DELETE_MEDIA_VIDEOS = 'APP_DELETE_MEDIA_VIDEOS'
export const APP_DELETE_MEDIA_VIDEOS_FAIL = 'APP_DELETE_MEDIA_VIDEOS_FAIL'

//youtube
export const APP_GET_YOUTUBE_VIDEO_START = 'APP_GET_YOUTUBE_VIDEO_START'
export const APP_GET_YOUTUBE_VIDEO = 'APP_GET_YOUTUBE_VIDEO'
export const APP_GET_YOUTUBE_VIDEO_FAIL = 'APP_GET_YOUTUBE_VIDEO_FAIL'

//media photos ui
export const SAVE_MEDIA_PHOTO_PROFESSION_CARDS = 'SAVE_MEDIA_PHOTO_PROFESSION_CARDS'
export const SAVE_MEDIA_PHOTOs = 'SAVE_MEDIA_PHOTOs'
export const CLEAR_MEDIA_PHOTO = 'CLEAR_MEDIA_PHOTO'

//media photo
export const APP_POST_MEDIA_PHOTO_START = 'APP_POST_MEDIA_PHOTO_START'
export const APP_POST_MEDIA_PHOTO = 'APP_POST_MEDIA_PHOTO'
export const APP_POST_MEDIA_PHOTO_FAIL = 'APP_POST_MEDIA_PHOTO_FAIL'
export const APP_DELETE_MEDIA_PHOTO_START = 'APP_DELETE_MEDIA_PHOTO_START'
export const APP_DELETE_MEDIA_PHOTO = 'APP_DELETE_MEDIA_PHOTO'
export const APP_DELETE_MEDIA_PHOTO_FAIL = 'APP_DELETE_MEDIA_PHOTO_FAIL'
export const APP_GET_FEATURED_MEDIA_PHOTO_START = 'APP_GET_FEATURED_MEDIA_PHOTO_START'
export const APP_GET_FEATURED_MEDIA_PHOTO = 'APP_GET_FEATURED_MEDIA_PHOTO'
export const APP_GET_FEATURED_MEDIA_PHOTO_FAIL = 'APP_GET_FEATURED_MEDIA_PHOTO_FAIL'
export const APP_GET_CARD_MEDIA_PHOTO_START = 'APP_GET_CARD_MEDIA_PHOTO_START'
export const APP_GET_CARD_MEDIA_PHOTO = 'APP_GET_CARD_MEDIA_PHOTO'
export const APP_GET_CARD_MEDIA_PHOTO_FAIL = 'APP_GET_CARD_MEDIA_PHOTO_FAIL'
export const APP_GET_MEDIA_PHOTO_START = 'APP_GET_MEDIA_PHOTO_START'
export const APP_GET_MEDIA_PHOTO = 'APP_GET_MEDIA_PHOTO'
export const APP_GET_MEDIA_PHOTO_FAIL = 'APP_GET_MEDIA_PHOTO_FAIL'
export const APP_PUT_FEATURED_MEDIA_PHOTO_START = 'APP_PUT_FEATURED_MEDIA_PHOTO_START'
export const APP_PUT_FEATURED_MEDIA_PHOTO = 'APP_PUT_FEATURED_MEDIA_PHOTO'
export const APP_PUT_FEATURED_MEDIA_PHOTO_FAIL = 'APP_PUT_FEATURED_MEDIA_PHOTO_FAIL'
export const APP_UNASSIGN_MEDIA_PHOTO_START = 'APP_UNASSIGN_MEDIA_PHOTO_START'
export const APP_UNASSIGN_MEDIA_PHOTO = 'APP_UNASSIGN_MEDIA_PHOTO'
export const APP_UNASSIGN_MEDIA_PHOTO_FAIL = 'APP_UNASSIGN_MEDIA_PHOTO_FAIL'
export const APP_PUT_MEDIA_PHOTO_START = 'APP_PUT_MEDIA_PHOTO_START'
export const APP_PUT_MEDIA_PHOTO = 'APP_PUT_MEDIA_PHOTO'
export const APP_PUT_MEDIA_PHOTO_FAIL = 'APP_PUT_MEDIA_PHOTO_FAIL'
export const APP_ASSIGN_MEDIA_PHOTO_START = 'APP_ASSIGN_MEDIA_PHOTO_START'
export const APP_ASSIGN_MEDIA_PHOTO = 'APP_ASSIGN_MEDIA_PHOTO'
export const APP_ASSIGN_MEDIA_PHOTO_FAIL = 'APP_ASSIGN_MEDIA_PHOTO_FAIL'

//quotes UI
export const SAVE_QUOTES_PROFESSION_CARDS = 'SAVE_QUOTES_PROFESSION_CARDS'
export const SAVE_QUOTES_EXCERPT = 'SAVE_QUOTES_EXCERPT'
export const SAVE_QUOTES_AUTHOR = 'SAVE_QUOTES_AUTHOR'
export const SAVE_QUOTES_PUBLICATION_NAME = 'SAVE_QUOTES_PUBLICATION_NAME'
export const SAVE_QUOTES_PUBLICATION_LINK = 'SAVE_QUOTES_PUBLICATION_LINK'
export const CLEAR_QUOTES = 'CLEAR_QUOTES'

//quote
export const APP_POST_QUOTE_START = 'APP_POST_QUOTE_START'
export const APP_POST_QUOTE = 'APP_POST_QUOTE'
export const APP_POST_QUOTE_FAIL = 'APP_POST_QUOTE_FAIL'
export const APP_DELETE_QUOTE_START = 'APP_DELETE_QUOTE_START'
export const APP_DELETE_QUOTE = 'APP_DELETE_QUOTE'
export const APP_DELETE_QUOTE_FAIL = 'APP_DELETE_QUOTE_FAIL'
export const APP_GET_FEATURED_QUOTE_START = 'APP_GET_FEATURED_QUOTE_START'
export const APP_GET_FEATURED_QUOTE = 'APP_GET_FEATURED_QUOTE'
export const APP_GET_FEATURED_QUOTE_FAIL = 'APP_GET_FEATURED_QUOTE_FAIL'
export const APP_GET_CARD_QUOTE_START = 'APP_GET_CARD_QUOTE_START'
export const APP_GET_CARD_QUOTE = 'APP_GET_CARD_QUOTE'
export const APP_GET_CARD_QUOTE_FAIL = 'APP_GET_CARD_QUOTE_FAIL'
export const APP_GET_QUOTE_START = 'APP_GET_QUOTE_START'
export const APP_GET_QUOTE = 'APP_GET_QUOTE'
export const APP_GET_QUOTE_FAIL = 'APP_GET_QUOTE_FAIL'
export const APP_PUT_FEATURED_QUOTE_START = 'APP_PUT_FEATURED_QUOTE_START'
export const APP_PUT_FEATURED_QUOTE = 'APP_PUT_FEATURED_QUOTE'
export const APP_PUT_FEATURED_QUOTE_FAIL = 'APP_PUT_FEATURED_QUOTE_FAIL'
export const APP_UNASSIGN_QUOTE_START = 'APP_UNASSIGN_QUOTE_START'
export const APP_UNASSIGN_QUOTE = 'APP_UNASSIGN_QUOTE'
export const APP_UNASSIGN_QUOTE_FAIL = 'APP_UNASSIGN_QUOTE_FAIL'
export const APP_PUT_QUOTE_START = 'APP_PUT_QUOTE_START'
export const APP_PUT_QUOTE = 'APP_PUT_QUOTE'
export const APP_PUT_QUOTE_FAIL = 'APP_PUT_QUOTE_FAIL'
export const APP_ASSIGN_QUOTE_START = 'APP_ASSIGN_QUOTE_START'
export const APP_ASSIGN_QUOTE = 'APP_ASSIGN_QUOTE'
export const APP_ASSIGN_QUOTE_FAIL = 'APP_ASSIGN_QUOTE_FAIL'
export const APP_DELETE_QUOTES_START = 'APP_DELETE_QUOTES_START'
export const APP_DELETE_QUOTES = 'APP_DELETE_QUOTES'
export const APP_DELETE_QUOTES_FAIL = 'APP_DELETE_QUOTES_FAIL'

//upcomming ui
export const SAVE_UPCOMING_PROFESSION_CARDS = 'SAVE_UPCOMING_PROFESSION_CARDS'
export const SAVE_UPCOMING_EVENT_TITLE = 'SAVE_UPCOMING_EVENT_TITLE'
export const SAVE_UPCOMING_EVENT_DATE = 'SAVE_UPCOMING_EVENT_DATE'
export const SAVE_UPCOMING_ROLE = 'SAVE_UPCOMING_ROLE'
export const SAVE_UPCOMING_LINK = 'SAVE_UPCOMING_LINK'
export const SAVE_UPCOMING_DESCRIPTION = 'SAVE_UPCOMING_DESCRIPTION'
export const SAVE_UPCOMING_THUMBNAIL = 'SAVE_UPCOMING_THUMBNAIL'
export const CLEAR_UPCOMING = 'CLEAR_UPCOMING'

//upcoming
export const APP_POST_UPCOMING_START = 'APP_POST_UPCOMING_START'
export const APP_POST_UPCOMING = 'APP_POST_UPCOMING'
export const APP_POST_UPCOMING_FAIL = 'APP_POST_UPCOMING_FAIL'
export const APP_DELETE_UPCOMING_START = 'APP_DELETE_UPCOMING_START'
export const APP_DELETE_UPCOMING = 'APP_DELETE_UPCOMING'
export const APP_DELETE_UPCOMING_FAIL = 'APP_DELETE_UPCOMING_FAIL'
export const APP_UNASSIGN_UPCOMING_START = 'APP_UNASSIGN_UPCOMING_START'
export const APP_UNASSIGN_UPCOMING = 'APP_UNASSIGN_UPCOMING'
export const APP_UNASSIGN_UPCOMING_FAIL = 'APP_UNASSIGN_UPCOMING_FAIL'
export const APP_GET_CARD_UPCOMING_START = 'APP_GET_CARD_UPCOMING_START'
export const APP_GET_CARD_UPCOMING = 'APP_GET_CARD_UPCOMING'
export const APP_GET_CARD_UPCOMING_FAIL = 'APP_GET_CARD_UPCOMING_FAIL'
export const APP_GET_UPCOMING_START = 'APP_GET_UPCOMING_START'
export const APP_GET_UPCOMING = 'APP_GET_UPCOMING'
export const APP_GET_UPCOMING_FAIL = 'APP_GET_UPCOMING_FAIL'
export const APP_PUT_UPCOMING_START = 'APP_PUT_UPCOMING_START'
export const APP_PUT_UPCOMING = 'APP_PUT_UPCOMING'
export const APP_PUT_UPCOMING_FAIL = 'APP_PUT_UPCOMING_FAIL'
export const APP_ASSIGN_UPCOMING_START = 'APP_ASSIGN_UPCOMING_START'
export const APP_ASSIGN_UPCOMING = 'APP_ASSIGN_UPCOMING'
export const APP_ASSIGN_UPCOMING_FAIL = 'APP_ASSIGN_UPCOMING_FAIL'

//links ui
export const SAVE_LINKS = 'SAVE_LINKS'
export const UPDATE_LINKS = 'UPDATE_LINKS'
export const CLEAR_LINKS = 'CLEAR_LINKS'

//links
export const APP_POST_LINKS_START = 'APP_POST_LINKS_START'
export const APP_POST_LINKS = 'APP_POST_LINKS'
export const APP_POST_LINKS_FAIL = 'APP_POST_LINKS_FAIL'
export const APP_DELETE_LINKS_START = 'APP_DELETE_LINKS_START'
export const APP_DELETE_LINKS = 'APP_DELETE_LINKS'
export const APP_DELETE_LINKS_FAIL = 'APP_DELETE_LINKS_FAIL'

//profile ui
export const APP_SAVE_SELCTED_PROFESSION_CARD_ID = 'APP_SAVE_SELCTED_PROFESSION_CARD_ID'
export const APP_SAVE_SELCTED_PROFESSION_CATEGORY_ID = 'APP_SAVE_SELCTED_PROFESSION_CATEGORY_ID'
export const APP_SAVE_SELCTED_USER_PROFESSION_CATEGORY_ID = 'APP_SAVE_SELCTED_USER_PROFESSION_CATEGORY_ID'
export const APP_SAVE_PUBLIC_USER_ID = 'APP_SAVE_PUBLIC_USER_ID'
export const CLEAR_PUBLIC = 'CLEAR_PUBLIC'
export const CLEAR_USER_PROFESSION_DATA = 'CLEAR_USER_PROFESSION_DATA'
export const APP_SAVE_NOTIFICATION_TOKEN_ID = 'APP_SAVE_NOTIFICATION_TOKEN_ID'
export const LOGIN_POPUP_TAB = 'LOGIN_POPUP_TAB'
export const SHOW_LOGIN_POPUP = 'SHOW_LOGIN_POPUP'
export const HIDE_LOGIN_POPUP = 'HIDE_LOGIN_POPUP'
export const REGISTER_SUCCESS_POPUP = 'REGISTER_SUCCESS_POPUP'
export const VIEW_ALL_COLLEAGUES_USERID = 'VIEW_ALL_COLLEAGUES_USERID'
export const LOGIN_STATUS = 'LOGIN_STATUS'
export const DROPDOWN_STATUS = 'DROPDOWN_STATUS'

//resume profile edit
export const APP_GET_RESUME_BY_CARDID_START = 'APP_GET_RESUME_BY_CARDID_START'
export const APP_GET_RESUME_BY_CARDID = 'APP_GET_RESUME_BY_CARDID'
export const APP_GET_RESUME_BY_CARDID_FAIL = 'APP_GET_RESUME_BY_CARDID_FAIL'
export const APP_DELETE_RESUME_START = 'APP_DELETE_RESUME_START'
export const APP_DELETE_RESUME = 'APP_DELETE_RESUME'
export const APP_DELETE_RESUME_FAIL = 'APP_DELETE_RESUME_FAIL'
export const APP_ASSIGN_RESUME_START = 'APP_ASSIGN_RESUME_START'
export const APP_ASSIGN_RESUME = 'APP_ASSIGN_RESUME'
export const APP_ASSIGN_RESUME_FAIL = 'APP_ASSIGN_RESUME_FAIL'
export const CLEAR_RESUME_PROFILE_EDIT = 'CLEAR_RESUME_PROFILE_EDIT'

//performance ui
export const SAVE_PERFORMANCE_PROGRAM_INSTITUTION = 'SAVE_PERFORMANCE_PROGRAM_INSTITUTION'
export const SAVE_PERFORMANCE_EVENT = 'SAVE_PERFORMANCE_EVENT'
export const SAVE_PERFORMANCE_WORK = 'SAVE_PERFORMANCE_WORK'
export const SAVE_PERFORMANCE_NEW_WORK_DETAILS = 'SAVE_PERFORMANCE_NEW_WORK_DETAILS'
export const SAVE_PERFORMANCE_PROGRAM_DATE = 'SAVE_PERFORMANCE_PROGRAM_DATE'
export const SAVE_PERFORMANCE_PROGRAM_CARDIDS = 'SAVE_PERFORMANCE_PROGRAM_CARDIDS'
export const SAVE_PERFORMANCE_PROGRAM_INSTITUTION_LOCATION = 'SAVE_PERFORMANCE_PROGRAM_INSTITUTION_LOCATION'
export const CLEAR_PERFORMANCE_UI = 'CLEAR_PERFORMANCE_UI'
export const SAVE_PERFORMANCE_NEW_WORK_DETAILS_CREATOR_TYPE = 'SAVE_PERFORMANCE_NEW_WORK_DETAILS_CREATOR_TYPE'
export const SAVE_PERFORMANCE_NEW_WORK_DETAILS_CREATOR = 'SAVE_PERFORMANCE_NEW_WORK_DETAILS_CREATOR'
export const CLEAR_NEW_WORK_DETAILS_SEARCH = 'CLEAR_NEW_WORK_DETAILS_SEARCH'
export const SAVE_PERFORMANCE_NEW_WORK_DETAILS_SAVED_CREATORS = 'SAVE_PERFORMANCE_NEW_WORK_DETAILS_SAVED_CREATORS'
export const CLEAR_NEW_WORK_DETAILS = 'CLEAR_NEW_WORK_DETAILS'
export const SAVE_PERFORMANCE_WORKID_TO_ADD_ROLE = 'SAVE_PERFORMANCE_WORKID_TO_ADD_ROLE'
export const SAVE_PERFORMANCE_SELECTED_ROLE = 'SAVE_PERFORMANCE_SELECTED_ROLE'
export const SAVE_PERFORMANCE_ROLE_TYPE = 'SAVE_PERFORMANCE_ROLE_TYPE'
export const SAVE_PERFORMANCE_NEW_ROLE = 'SAVE_PERFORMANCE_NEW_ROLE'
export const SAVE_PERFORMANCE_NEW_COLLEAGUE = 'SAVE_PERFORMANCE_NEW_COLLEAGUE'
export const SAVE_PERFORMANCE_COLLEAGUE_LIST = 'SAVE_PERFORMANCE_COLLEAGUE_LIST'
export const SAVE_PERFORMANCE_COLLEAGUE_LIST_EDIT_INDEX = 'SAVE_PERFORMANCE_COLLEAGUE_LIST_EDIT_INDEX'
export const CLEAR_PERFORMANCE_COLLEAGUE_LIST = 'CLEAR_PERFORMANCE_COLLEAGUE_LIST'
export const CLEAR_PERFORMANCE_EVENT_ID = 'CLEAR_PERFORMANCE_EVENT_ID'
export const CLEAR_PERFORMANCE_WORK = 'CLEAR_PERFORMANCE_WORK'
export const CLEAR_PERFORMANCE_SAVED_COLLEAGUE_LIST = 'CLEAR_PERFORMANCE_SAVED_COLLEAGUE_LIST'

//performance
//events
export const APP_GET_EVENTS_BY_INSTUTUTIONID_START = 'APP_GET_EVENTS_BY_INSTUTUTIONID_START'
export const APP_GET_EVENTS_BY_INSTUTUTIONID = 'APP_GET_EVENTS_BY_INSTUTUTIONID'
export const APP_GET_EVENTS_BY_INSTUTUTIONID_FAIL = 'APP_GET_EVENTS_BY_INSTUTUTIONID_FAIL'
export const APP_GET_EVENT_TYPES_START = 'APP_GET_EVENT_TYPES_START'
export const APP_GET_EVENT_TYPES = 'APP_GET_EVENT_TYPES'
export const APP_GET_EVENT_TYPES_FAIL = 'APP_GET_EVENT_TYPES_FAIL'
export const APP_GET_EVENT_SUBTYPES_START = 'APP_GET_EVENT_SUBTYPES_START'
export const APP_GET_EVENT_SUBTYPES = 'APP_GET_EVENT_SUBTYPES'
export const APP_GET_EVENT_SUBTYPES_FAIL = 'APP_GET_EVENT_SUBTYPES_FAIL'
export const APP_POST_EVENT_START = 'APP_POST_EVENT_START'
export const APP_POST_EVENT = 'APP_POST_EVENT'
export const APP_POST_EVENT_FAIL = 'APP_POST_EVENT_FAIL'
export const APP_POST_NEW_EVENT_START = 'APP_POST_NEW_EVENT_START'
export const APP_POST_NEW_EVENT = 'APP_POST_NEW_EVENT'
export const APP_POST_NEW_EVENT_FAIL = 'APP_POST_NEW_EVENT_FAIL'
export const APP_GET_EVENT_START = 'APP_GET_EVENT_START'
export const APP_GET_EVENT = 'APP_GET_EVENT'
export const APP_GET_EVENT_FAIL = 'APP_GET_EVENT_FAIL'
export const APP_PUT_EVENT_START = 'APP_PUT_EVENT_START'
export const APP_PUT_EVENT = 'APP_PUT_EVENT'
export const APP_PUT_EVENT_FAIL = 'APP_PUT_EVENT_FAIL'
export const APP_GET_PERFORMANCE_DETAILS_START = 'APP_GET_PERFORMANCE_DETAILS_START'
export const APP_GET_PERFORMANCE_DETAILS = 'APP_GET_PERFORMANCE_DETAILS'
export const APP_GET_PERFORMANCE_DETAILS_FAIL = 'APP_GET_PERFORMANCE_DETAILS_FAIL'
export const APP_EVENT_REASSIGN_PROFESSIONS_START = 'APP_EVENT_REASSIGN_PROFESSIONS_START'
export const APP_EVENT_REASSIGN_PROFESSIONS = 'APP_EVENT_REASSIGN_PROFESSIONS'
export const APP_EVENT_REASSIGN_PROFESSIONS_FAIL = 'APP_EVENT_REASSIGN_PROFESSIONS_FAIL'
export const APP_GET_PENDING_HIDDEN_START = 'APP_GET_PENDING_HIDDEN_START'
export const APP_GET_PENDING_HIDDEN = 'APP_GET_PENDING_HIDDEN'
export const APP_GET_PENDING_HIDDEN_FAIL = 'APP_GET_PENDING_HIDDEN_FAIL'
export const APP_GET_PENDING_HIDDEN_STATUS_START = 'APP_GET_PENDING_HIDDEN_STATUS_START'
export const APP_GET_PENDING_HIDDEN_STATUS = 'APP_GET_PENDING_HIDDEN_STATUS'
export const APP_GET_PENDING_HIDDEN_STATUS_FAIL = 'APP_GET_PENDING_HIDDEN_STATUS_FAIL'

//works
export const APP_GET_WORK_BY_TEXT_START = 'APP_GET_WORK_BY_TEXT_START'
export const APP_GET_WORK_BY_TEXT = 'APP_GET_WORK_BY_TEXT'
export const APP_GET_WORK_BY_TEXT_FAIL = 'APP_GET_WORK_BY_TEXT_FAIL'
export const APP_POST_NEW_WORK_START = 'APP_POST_NEW_WORK_START'
export const APP_POST_NEW_WORK = 'APP_POST_NEW_WORK'
export const APP_POST_NEW_WORK_FAIL = 'APP_POST_NEW_WORK_FAIL'
export const APP_GET_WORK_UNAMMED_ROLES_START = 'APP_GET_WORK_UNAMMED_ROLES_START'
export const APP_GET_WORK_UNAMMED_ROLES = 'APP_GET_WORK_UNAMMED_ROLES'
export const APP_GET_WORK_UNAMMED_ROLES_FAIL = 'APP_GET_WORK_UNAMMED_ROLES_FAIL'
export const APP_GET_WORK_BY_ID_START = 'APP_GET_WORK_BY_ID_START'
export const APP_GET_WORK_BY_ID = 'APP_GET_WORK_BY_ID'
export const APP_GET_WORK_BY_ID_FAIL = 'APP_GET_WORK_BY_ID_FAIL'
export const APP_GET_WORK_DETAILS_BY_EVENTID_START = 'APP_GET_WORK_DETAILS_BY_EVENTID_START'
export const APP_GET_WORK_DETAILS_BY_EVENTID = 'APP_GET_WORK_DETAILS_BY_EVENTID'
export const APP_GET_WORK_DETAILS_BY_EVENTID_FAIL = 'APP_GET_WORK_DETAILS_BY_EVENTID_FAIL'
export const APP_PUT_NAMEDWORK_TO_EVENT_START = 'APP_PUT_NAMEDWORK_TO_EVENT_START'
export const APP_PUT_NAMEDWORK_TO_EVENT = 'APP_PUT_NAMEDWORK_TO_EVENT'
export const APP_PUT_NAMEDWORK_TO_EVENT_FAIL = 'APP_PUT_NAMEDWORK_TO_EVENT_FAIL'
export const APP_GET_WORK_DISCIPLINE_START = 'APP_GET_WORK_DISCIPLINE_START'
export const APP_GET_WORK_DISCIPLINE = 'APP_GET_WORK_DISCIPLINE'
export const APP_GET_WORK_DISCIPLINE_FAIL = 'APP_GET_WORK_DISCIPLINE_FAIL'
export const APP_GET_WORK_TYPES_START = 'APP_GET_WORK_TYPES_START'
export const APP_GET_WORK_TYPES = 'APP_GET_WORK_TYPES'
export const APP_GET_WORK_TYPES_FAIL = 'APP_GET_WORK_TYPES_FAIL'

//roles
export const APP_GET_ROLES_BY_WORKID_START = 'APP_GET_ROLES_BY_WORKID_START'
export const APP_GET_ROLES_BY_WORKID = 'APP_GET_ROLES_BY_WORKID'
export const APP_GET_ROLES_BY_WORKID_FAIL = 'APP_GET_ROLES_BY_WORKID_FAIL'
export const APP_POST_ROLES_BY_WORKID_START = 'APP_POST_ROLES_BY_WORKID_START'
export const APP_POST_ROLES_BY_WORKID = 'APP_POST_ROLES_BY_WORKID'
export const APP_POST_ROLES_BY_WORKID_FAIL = 'APP_POST_ROLES_BY_WORKID_FAIL'
export const APP_GET_ROLE_TYPES_START = 'APP_GET_ROLE_TYPES_START'
export const APP_GET_ROLE_TYPES = 'APP_GET_ROLE_TYPES'
export const APP_GET_ROLE_TYPES_FAIL = 'APP_GET_ROLE_TYPES_FAIL'
export const APP_GET_ROLE_CATEGORIES_START = 'APP_GET_ROLE_CATEGORIES_START'
export const APP_GET_ROLE_CATEGORIES = 'APP_GET_ROLE_CATEGORIES'
export const APP_GET_ROLE_CATEGORIES_FAIL = 'APP_GET_ROLE_CATEGORIES_FAIL'
export const APP_GET_ROLE_GROUPS_START = 'APP_GET_ROLE_GROUPS_START'
export const APP_GET_ROLE_GROUPS = 'APP_GET_ROLE_GROUPS'
export const APP_GET_ROLE_GROUPS_FAIL = 'APP_GET_ROLE_GROUPS_FAIL'
export const APP_POST_NEW_ROLES_BY_WORKID_START = 'APP_POST_NEW_ROLES_BY_WORKID_START'
export const APP_POST_NEW_ROLES_BY_WORKID = 'APP_POST_NEW_ROLES_BY_WORKID'
export const APP_POST_NEW_ROLES_BY_WORKID_FAIL = 'APP_POST_NEW_ROLES_BY_WORKID_FAIL'
export const APP_PUT_ROLE_START = 'APP_PUT_ROLE_START'
export const APP_PUT_ROLE = 'APP_PUT_ROLE'
export const APP_PUT_ROLE_FAIL = 'APP_PUT_ROLE_FAIL'
export const APP_DELETE_ROLE_START = 'APP_DELETE_ROLE_START'
export const APP_DELETE_ROLE = 'APP_DELETE_ROLE'
export const APP_DELETE_ROLE_FAIL = 'APP_DELETE_ROLE_FAIL'
export const APP_CONFIRM_ROLE_START = 'APP_CONFIRM_ROLE_START'
export const APP_CONFIRM_ROLE = 'APP_CONFIRM_ROLE'
export const APP_CONFIRM_ROLE_FAIL = 'APP_CONFIRM_ROLE_FAIL'

//search
export const APP_GET_SEARCH_RESULTS_START = 'APP_GET_SEARCH_RESULTS_START'
export const APP_GET_SEARCH_RESULTS = 'APP_GET_SEARCH_RESULTS'
export const APP_GET_SEARCH_RESULTS_FAIL = 'APP_GET_SEARCH_RESULTS_FAIL'
export const APP_GET_SEARCH_TEMPLATE_START = 'APP_GET_SEARCH_TEMPLATE_START'
export const APP_GET_SEARCH_TEMPLATE = 'APP_GET_SEARCH_TEMPLATE'
export const APP_GET_SEARCH_TEMPLATE_FAIL = 'APP_GET_SEARCH_TEMPLATE_FAIL'
export const APP_GET_SEARCH_TOP_TEMPLATE_START = 'APP_GET_SEARCH_TOP_TEMPLATE_START'
export const APP_GET_SEARCH_TOP_TEMPLATE = 'APP_GET_SEARCH_TOP_TEMPLATE'
export const APP_GET_SEARCH_TOP_TEMPLATE_FAIL = 'APP_GET_SEARCH_TOP_TEMPLATE_FAIL'

//payments
//contracts ui
export const SAVE_PAYMENT_CONTRACT_TYPE = 'SAVE_PAYMENT_CONTRACT_TYPE'
export const SAVE_PAYMENT_PRELIM_CONTRACT_STATUS = 'SAVE_PAYMENT_PRELIM_CONTRACT_STATUS'
export const SAVE_PAYMENT_CONTRACT_LOCATION = 'SAVE_PAYMENT_CONTRACT_LOCATION'
export const SAVE_PAYMENT_CONTRACT_PERFORMANCE_EVENTS = 'SAVE_PAYMENT_CONTRACT_PERFORMANCE_EVENTS'
export const SAVE_PAYMENT_CONTRACT_PERFORMANCE_EVENT_LOCATION = 'SAVE_PAYMENT_CONTRACT_PERFORMANCE_EVENT_LOCATION'
export const SAVE_PAYMENT_CONTRACT_DESCRIPTION = 'SAVE_PAYMENT_CONTRACT_DESCRIPTION'
export const SAVE_PAYMENT_CONTRACT_OFFER_DETAILS = 'SAVE_PAYMENT_CONTRACT_OFFER_DETAILS'
export const SAVE_PAYMENT_CONTRACT_REQUIRED_EVENTS_STATUS = 'SAVE_PAYMENT_CONTRACT_REQUIRED_EVENTS_STATUS'
export const SAVE_PAYMENT_CONTRACT_FLEXIBLE_EVENTS_EDIT_INDEX = 'SAVE_PAYMENT_CONTRACT_FLEXIBLE_EVENTS_EDIT_INDEX'
export const SAVE_PAYMENT_CONTRACT_FLEXIBLE_EVENTS = 'SAVE_PAYMENT_CONTRACT_FLEXIBLE_EVENTS'
export const SAVE_PAYMENT_CONTRACT_RIDER_TYPE = 'SAVE_PAYMENT_CONTRACT_RIDER_TYPE'
export const SAVE_PAYMENT_CONTRACT_RIDERS = 'SAVE_PAYMENT_CONTRACT_RIDERS'
export const SAVE_PAYMENT_CONTRACT_RIDERS_EDIT_INDEX = 'SAVE_PAYMENT_CONTRACT_RIDERS_EDIT_INDEX'
export const SAVE_PAYMENT_CONTRACT_SIGNING_FEE = 'SAVE_PAYMENT_CONTRACT_SIGNING_FEE'
export const SAVE_PAYMENT_CONTRACT_INSTALLMENT_CUSTOM_DATES = 'SAVE_PAYMENT_CONTRACT_INSTALLMENT_CUSTOM_DATES'
export const SAVE_PAYMENT_CONTRACT_INSTALLMENT_SELECTED_DATES = 'SAVE_PAYMENT_CONTRACT_INSTALLMENT_SELECTED_DATES'
export const SAVE_PAYMENT_CONTRACT_EDIT_STATUS = 'SAVE_PAYMENT_CONTRACT_EDIT_STATUS'
export const SAVE_PAYMENT_CONTRACT_SENT = 'SAVE_PAYMENT_CONTRACT_SENT'
export const CLEAR_PERFORMANCE_DETAILS = 'CLEAR_PERFORMANCE_DETAILS'
export const CLEAR_PERFORMANCE_EVENT_DATE_DETAILS = 'CLEAR_PERFORMANCE_EVENT_DATE_DETAILS'
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT'

//contracts
export const APP_POST_CONTRACT_START = 'APP_POST_CONTRACT_START'
export const APP_POST_CONTRACT = 'APP_POST_CONTRACT'
export const APP_POST_CONTRACT_FAIL = 'APP_POST_CONTRACT_FAIL'
export const APP_GET_CONTRACT_RIDER_TYPES_START = 'APP_GET_CONTRACT_RIDER_TYPES_START'
export const APP_GET_CONTRACT_RIDER_TYPES = 'APP_GET_CONTRACT_RIDER_TYPES'
export const APP_GET_CONTRACT_RIDER_TYPES_FAIL = 'APP_GET_CONTRACT_RIDER_TYPES_FAIL'
export const APP_GET_CONTRACT_RIDER_SUBTYPES_START = 'APP_GET_CONTRACT_RIDER_SUBTYPES_START'
export const APP_GET_CONTRACT_RIDER_SUBTYPES = 'APP_GET_CONTRACT_RIDER_SUBTYPES'
export const APP_GET_CONTRACT_RIDER_SUBTYPES_FAIL = 'APP_GET_CONTRACT_RIDER_SUBTYPES_FAIL'
export const APP_POST_CONTRACT_FLEXIBLE_EVENTS_START = 'APP_POST_CONTRACT_FLEXIBLE_EVENTS_START'
export const APP_POST_CONTRACT_FLEXIBLE_EVENTS = 'APP_POST_CONTRACT_FLEXIBLE_EVENTS'
export const APP_POST_CONTRACT_FLEXIBLE_EVENTS_FAIL = 'APP_POST_CONTRACT_FLEXIBLE_EVENTS_FAIL'
export const APP_POST_CONTRACT_PAYMENT_INSTALLMENTS_START = 'APP_POST_CONTRACT_PAYMENT_INSTALLMENTS_START'
export const APP_POST_CONTRACT_PAYMENT_INSTALLMENTS = 'APP_POST_CONTRACT_PAYMENT_INSTALLMENTS'
export const APP_POST_CONTRACT_PAYMENT_INSTALLMENTS_FAIL = 'APP_POST_CONTRACT_PAYMENT_INSTALLMENTS_FAIL'
export const APP_POST_CONTRACT_RIDERS_START = 'APP_POST_CONTRACT_RIDERS_START'
export const APP_POST_CONTRACT_RIDERS = 'APP_POST_CONTRACT_RIDERS'
export const APP_POST_CONTRACT_RIDERS_FAIL = 'APP_POST_CONTRACT_RIDERS_FAIL'
export const APP_POST_CONTRACT_REQUIRED_EVENTS_START = 'APP_POST_CONTRACT_REQUIRED_EVENTS_START'
export const APP_POST_CONTRACT_REQUIRED_EVENTS = 'APP_POST_CONTRACT_REQUIRED_EVENTS'
export const APP_POST_CONTRACT_REQUIRED_EVENTS_FAIL = 'APP_POST_CONTRACT_REQUIRED_EVENTS_FAIL'
export const APP_SEND_OFFER_START = 'APP_SEND_OFFER_START'
export const APP_SEND_OFFER = 'APP_SEND_OFFER'
export const APP_SEND_OFFER_FAIL = 'APP_SEND_OFFER_FAIL'
export const APP_GET_SENT_OFFERS_START = 'APP_GET_SENT_OFFERS_START'
export const APP_GET_SENT_RECEIVED_OFFERS = 'APP_GET_SENT_RECEIVED_OFFERS'
export const APP_GET_SENT_OFFERS_FAIL = 'APP_GET_SENT_OFFERS_FAIL'
export const APP_GET_RECEIVED_OFFERS_START = 'APP_GET_RECEIVED_OFFERS_START'
export const APP_GET_RECEIVED_OFFERS_FAIL = 'APP_GET_RECEIVED_OFFERS_FAIL'
export const APP_PUT_NEGOTIATE_OFFER_START = 'APP_PUT_NEGOTIATE_OFFER_START'
export const APP_PUT_NEGOTIATE_OFFER = 'APP_PUT_NEGOTIATE_OFFER'
export const APP_PUT_NEGOTIATE_OFFER_FAIL = 'APP_PUT_NEGOTIATE_OFFER_FAIL'
export const APP_ACCEPT_OFFER_START = 'APP_ACCEPT_OFFER_START'
export const APP_ACCEPT_OFFER = 'APP_ACCEPT_OFFER'
export const APP_ACCEPT_OFFER_FAIL = 'APP_ACCEPT_OFFER_FAIL'
export const APP_REJECT_OFFER_START = 'APP_REJECT_OFFER_START'
export const APP_REJECT_OFFER = 'APP_REJECT_OFFER'
export const APP_REJECT_OFFER_FAIL = 'APP_REJECT_OFFER_FAIL'
export const APP_FINALIZE_OFFER_START = 'APP_FINALIZE_OFFER_START'
export const APP_FINALIZE_OFFER = 'APP_FINALIZE_OFFER'
export const APP_FINALIZE_OFFER_FAIL = 'APP_FINALIZE_OFFER_FAIL'
export const APP_RESCIND_OFFER_START = 'APP_RESCIND_OFFER_START'
export const APP_RESCIND_OFFER = 'APP_RESCIND_OFFER'
export const APP_RESCIND_OFFER_FAIL = 'APP_RESCIND_OFFER_FAIL'
export const CLEAR_PAYMENT_MAIN = 'CLEAR_PAYMENT_MAIN'
export const APP_GET_SENT_CONTRACTS_START = 'APP_GET_SENT_CONTRACTS_START'
export const APP_GET_SENT_RECEIVED_CONTRACTS = 'APP_GET_SENT_RECEIVED_CONTRACTS'
export const APP_GET_SENT_CONTRACTS_FAIL = 'APP_GET_SENT_CONTRACTS_FAIL'
export const APP_GET_RECEIVED_CONTRACTS_START = 'APP_GET_RECEIVED_CONTRACTS_START'
export const APP_GET_RECEIVED_CONTRACTS_FAIL = 'APP_GET_RECEIVED_CONTRACTS_FAIL'
export const APP_GET_DRAFT_OFFERS_START = 'APP_GET_DRAFT_OFFERS_START'
export const APP_GET_DRAFT_OFFERS_FAIL = 'APP_GET_DRAFT_OFFERS_FAIL'
export const APP_SEND_CONTRACT_SEEN_START = 'APP_SEND_CONTRACT_SEEN_START'
export const APP_SEND_CONTRACT_SEEN = 'APP_SEND_CONTRACT_SEEN'
export const APP_SEND_CONTRACT_SEEN_FAIL = 'APP_SEND_CONTRACT_SEEN_FAIL'
export const APP_RECEIVED_CONTRACT_SEEN_START = 'APP_RECEIVED_CONTRACT_SEEN_START'
export const APP_RECEIVED_CONTRACT_SEEN = 'APP_RECEIVED_CONTRACT_SEEN'
export const APP_RECEIVED_CONTRACT_SEEN_FAIL = 'APP_RECEIVED_CONTRACT_SEEN_FAIL'

//long pooling contract
export const APP_GET_SENT_OFFER_PRESENT_STATUS_START = 'APP_GET_SENT_OFFER_PRESENT_STATUS_START'
export const APP_GET_SENT_OFFER_PRESENT_STATUS = 'APP_GET_SENT_OFFER_PRESENT_STATUS'
export const APP_GET_SENT_OFFER_PRESENT_STATUS_FAIL = 'APP_GET_SENT_OFFER_PRESENT_STATUS_FAIL'
export const APP_GET_RECEIVED_OFFER_PRESENT_STATUS_START = 'APP_GET_RECEIVED_OFFER_PRESENT_STATUS_START'
export const APP_GET_RECEIVED_OFFER_PRESENT_STATUS = 'APP_GET_RECEIVED_OFFER_PRESENT_STATUS'
export const APP_GET_RECEIVED_OFFER_PRESENT_STATUS_FAIL = 'APP_GET_RECEIVED_OFFER_PRESENT_STATUS_FAIL'
export const APP_GET_SENT_CONTRACT_PRESENT_STATUS_START = 'APP_GET_SENT_CONTRACT_PRESENT_STATUS_START'
export const APP_GET_SENT_CONTRACT_PRESENT_STATUS = 'APP_GET_SENT_CONTRACT_PRESENT_STATUS'
export const APP_GET_SENT_CONTRACT_PRESENT_STATUS_FAIL = 'APP_GET_SENT_CONTRACT_PRESENT_STATUS_FAIL'
export const APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS_START = 'APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS_START'
export const APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS = 'APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS'
export const APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS_FAIL = 'APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS_FAIL'
export const APP_GET_SESSION_BOOKINGS_STATUS_START = 'APP_GET_SESSION_BOOKINGS_STATUS_START'
export const APP_GET_SESSION_BOOKINGS_PRESENT_STATUS = 'APP_GET_SESSION_BOOKINGS_PRESENT_STATUS'
export const APP_GET_SESSION_BOOKINGS_PRESENT_STATUS_FAIL = 'APP_GET_SESSION_BOOKINGS_PRESENT_STATUS_FAIL'
export const APP_GET_FAILED_PAYMENT_SLOTS_START = 'APP_GET_FAILED_PAYMENT_SLOTS_START'
export const APP_GET_FAILED_PAYMENT_SLOTS = 'APP_GET_FAILED_PAYMENT_SLOTS'
export const APP_GET_FAILED_PAYMENT_SLOTS_FAIL = 'APP_GET_FAILED_PAYMENT_SLOTS_FAIL'

// slots ui
export const SAVE_PAYMENT_SLOTS_TRAVEL_LOCATION = 'SAVE_PAYMENT_SLOTS_TRAVEL_LOCATION'
export const CLEAR_PAYMENT_SLOTS = 'CLEAR_PAYMENT_SLOTS'
export const SAVE_AVAILABILITY_OFF_DATES = 'SAVE_AVAILABILITY_OFF_DATES'
export const SAVE_AVAILABILITY_SCHEDULE = 'SAVE_AVAILABILITY_SCHEDULE'
export const SAVE_AVAILABILITY_ADVANCE_BOOKING = 'SAVE_AVAILABILITY_ADVANCE_BOOKING'
export const SAVE_CUSTOM_POLICY = 'SAVE_CUSTOM_POLICY'
export const SAVE_PAYMENT_SLOTS_EDIT_STATUS = 'SAVE_PAYMENT_SLOTS_EDIT_STATUS'
export const CLEAR_PAYMENT_SLOTS_EDIT_STATUS = 'CLEAR_PAYMENT_SLOTS_EDIT_STATUS'
export const SAVE_SLOT_BOOKING_LOCATION = 'SAVE_SLOT_BOOKING_LOCATION'
export const SAVE_SLOT_BOOKING_DATA = 'SAVE_SLOT_BOOKING_DATA'

//slot creation
export const APP_POST_PAYMENT_SLOTS_START = 'APP_POST_PAYMENT_SLOTS_START'
export const APP_POST_PAYMENT_SLOTS = 'APP_POST_PAYMENT_SLOTS'
export const APP_POST_PAYMENT_SLOTS_FAIL = 'APP_POST_PAYMENT_SLOTS_FAIL'
export const APP_GET_PAYMENT_SLOTS_START = 'APP_GET_PAYMENT_SLOTS_START'
export const APP_GET_PAYMENT_SLOTS = 'APP_GET_PAYMENT_SLOTS'
export const APP_GET_PAYMENT_SLOTS_FAIL = 'APP_GET_PAYMENT_SLOTS_FAIL'
export const APP_GET_ALL_CARD_PAYMENT_SLOTS_START = 'APP_GET_ALL_CARD_PAYMENT_SLOTS_START'
export const APP_GET_ALL_CARD_PAYMENT_SLOTS = 'APP_GET_ALL_CARD_PAYMENT_SLOTS'
export const APP_GET_ALL_CARD_PAYMENT_SLOTS_FAIL = 'APP_GET_ALL_CARD_PAYMENT_SLOTS_FAIL'

//slot booking
export const APP_POST_PAYMENT_SLOTS_BOOKING_START = 'APP_POST_PAYMENT_SLOTS_BOOKING_START'
export const APP_POST_PAYMENT_SLOTS_BOOKING = 'APP_POST_PAYMENT_SLOTS_BOOKING'
export const APP_POST_PAYMENT_SLOTS_BOOKING_FAIL = 'APP_POST_PAYMENT_SLOTS_BOOKING_FAIL'
export const APP_GET_PAYMENT_SLOTS_BOOKING_START = 'APP_GET_PAYMENT_SLOTS_BOOKING_START'
export const APP_GET_PAYMENT_SLOTS_BOOKING = 'APP_GET_PAYMENT_SLOTS_BOOKING'
export const APP_GET_FAILED_PAYMENT_SLOTS_BOOKING = 'APP_GET_FAILED_PAYMENT_SLOTS_BOOKING'
export const APP_GET_PAYMENT_SLOTS_BOOKING_FAIL = 'APP_GET_PAYMENT_SLOTS_BOOKING_FAIL'
export const APP_ACCEPT_PAYMENT_SLOTS_BOOKING_START = 'APP_ACCEPT_PAYMENT_SLOTS_BOOKING_START'
export const APP_ACCEPT_PAYMENT_SLOTS_BOOKING = 'APP_ACCEPT_PAYMENT_SLOTS_BOOKING'
export const APP_ACCEPT_PAYMENT_SLOTS_BOOKING_FAIL = 'APP_ACCEPT_PAYMENT_SLOTS_BOOKING_FAIL'
export const APP_DECLINE_PAYMENT_SLOTS_BOOKING_START = 'APP_DECLINE_PAYMENT_SLOTS_BOOKING_START'
export const APP_DECLINE_PAYMENT_SLOTS_BOOKING = 'APP_DECLINE_PAYMENT_SLOTS_BOOKING'
export const APP_DECLINE_PAYMENT_SLOTS_BOOKING_FAIL = 'APP_DECLINE_PAYMENT_SLOTS_BOOKING_FAIL'
export const APP_CANCEL_PAYMENT_SLOTS_BOOKING_START = 'APP_CANCEL_PAYMENT_SLOTS_BOOKING_START'
export const APP_CANCEL_PAYMENT_SLOTS_BOOKING = 'APP_CANCEL_PAYMENT_SLOTS_BOOKING'
export const APP_CANCEL_PAYMENT_SLOTS_BOOKING_FAIL = 'APP_CANCEL_PAYMENT_SLOTS_BOOKING_FAIL'
export const APP_CHECKIN_PAYMENT_SLOTS_BOOKING_START = 'APP_CHECKIN_PAYMENT_SLOTS_BOOKING_START'
export const APP_CHECKIN_PAYMENT_SLOTS_BOOKING = 'APP_CHECKIN_PAYMENT_SLOTS_BOOKING'
export const APP_CHECKIN_PAYMENT_SLOTS_BOOKING_FAIL = 'APP_CHECKIN_PAYMENT_SLOTS_BOOKING_FAIL'
export const APP_GET_FREE_SLOTS_BOOKING_START = 'APP_GET_FREE_SLOTS_BOOKING_START'
export const APP_GET_FREE_SLOTS_BOOKING = 'APP_GET_FREE_SLOTS_BOOKING'
export const APP_GET_FREE_SLOTS_BOOKING_FAIL = 'APP_GET_FREE_SLOTS_BOOKING_FAIL'
export const APP_GET_NO_EVENT_SLOTS_BOOKING_START = 'APP_GET_NO_EVENT_SLOTS_BOOKING_START'
export const APP_GET_NO_EVENT_SLOTS_BOOKING = 'APP_GET_NO_EVENT_SLOTS_BOOKING'
export const APP_GET_NO_EVENT_SLOTS_BOOKING_FAIL = 'APP_GET_NO_EVENT_SLOTS_BOOKING_FAIL'
export const APP_PUT_NO_EVENT_SLOTS_BOOKING_START = 'APP_PUT_NO_EVENT_SLOTS_BOOKING_START'
export const APP_PUT_NO_EVENT_SLOTS_BOOKING = 'APP_PUT_NO_EVENT_SLOTS_BOOKING'
export const APP_PUT_NO_EVENT_SLOTS_BOOKING_FAIL = 'APP_PUT_NO_EVENT_SLOTS_BOOKING_FAIL'
export const APP_SESSION_INTRO_SEEN = 'APP_SESSION_INTRO_SEEN'
export const APP_GET_RESONANCE_FEE_START = 'APP_GET_RESONANCE_FEE_START'
export const APP_GET_RESONANCE_FEE = 'APP_GET_RESONANCE_FEE'
export const APP_GET_RESONANCE_FEE_FAIL = 'APP_GET_RESONANCE_FEE_FAIL'

//coach Mark
export const APP_BOOKING_SLOT_SEEN = 'APP_BOOKING_SLOT_SEEN'
export const APP_PAYMENT_TUTS_SEEN = 'APP_PAYMENT_TUTS_SEEN'

//stripe
export const STRIPE_GET_CUSTOMER_CARDS_START = 'STRIPE_GET_CUSTOMER_CARDS_START'
export const STRIPE_GET_CUSTOMER_CARDS = 'STRIPE_GET_CUSTOMER_CARDS'
export const STRIPE_GET_CUSTOMER_CARDS_FAIL = 'STRIPE_GET_CUSTOMER_CARDS_FAIL'
export const STRIPE_GET_CLIENT_SECKEY_START = 'STRIPE_GET_CLIENT_SECKEY_START'
export const STRIPE_GET_CLIENT_SECKEY = 'STRIPE_GET_CLIENT_SECKEY'
export const STRIPE_GET_CLIENT_SECKEY_FAIL = 'STRIPE_GET_CLIENT_SECKEY_FAIL'
export const STRIPE_POST_ADD_CARD_START = 'STRIPE_POST_ADD_CARD_START'
export const STRIPE_POST_ADD_CARD = 'STRIPE_POST_ADD_CARD'
export const STRIPE_POST_ADD_CARD_FAIL = 'STRIPE_POST_ADD_CARD_FAIL'
export const STRIPE_POST_AUTHORIZE_EXPRESS_ACC_START = 'STRIPE_POST_AUTHORIZE_EXPRESS_ACC_START'
export const STRIPE_POST_AUTHORIZE_EXPRESS_ACC = 'STRIPE_POST_AUTHORIZE_EXPRESS_ACC'
export const STRIPE_POST_AUTHORIZE_EXPRESS_ACC_FAIL = 'STRIPE_POST_AUTHORIZE_EXPRESS_ACC_FAIL'
export const STRIPE_GET_EXPRESS_ACC_LINK_START = 'STRIPE_GET_EXPRESS_ACC_LINK_START'
export const STRIPE_GET_EXPRESS_ACC_LINK = 'STRIPE_GET_EXPRESS_ACC_LINK'
export const STRIPE_GET_EXPRESS_ACC_LINK_FAIL = 'STRIPE_GET_EXPRESS_ACC_LINK_FAIL'
export const STRIPE_ACCOUNT_STATUS_CHANGE = 'STRIPE_ACCOUNT_STATUS_CHANGE'
export const STRIPE_GET_DEFAULT_CARD_START = 'STRIPE_GET_DEFAULT_CARD_START'
export const STRIPE_GET_DEFAULT_CARD = 'STRIPE_GET_DEFAULT_CARD'
export const STRIPE_GET_DEFAULT_CARD_FAIL = 'STRIPE_GET_DEFAULT_CARD_FAIL'
export const STRIPE_PUT_DEFAULT_CARD_START = 'STRIPE_PUT_DEFAULT_CARD_START'
export const STRIPE_PUT_DEFAULT_CARD = 'STRIPE_PUT_DEFAULT_CARD'
export const STRIPE_PUT_DEFAULT_CARD_FAIL = 'STRIPE_PUT_DEFAULT_CARD_FAIL'
export const STRIPE_DELETE_CARD_START = 'STRIPE_DELETE_CARD_START'
export const STRIPE_DELETE_CARD = 'STRIPE_DELETE_CARD'
export const STRIPE_DELETE_CARD_FAIL = 'STRIPE_DELETE_CARD_FAIL'
export const STRIPE_PAY_NOW_START = 'STRIPE_PAY_NOW_START'
export const STRIPE_PAY_NOW = 'STRIPE_PAY_NOW'
export const STRIPE_PAY_NOW_FAIL = 'STRIPE_PAY_NOW_FAIL'

//Notofication Update
export const APP_PUSH_NOTOFICATION = 'APP_PUSH_NOTOFICATION'
export const APP_PUSH_NOTOFICATION_START = 'APP_PUSH_NOTOFICATION_START'

//calender
export const APP_POST_CALENDER_ID_START = 'APP_POST_CALENDER_ID_START'
export const APP_POST_CALENDER_ID = 'APP_POST_CALENDER_ID'
export const APP_POST_CALENDER_ID_FAIL = 'APP_POST_CALENDER_ID_FAIL'

//search ui
export const SAVE_PROFESSION_FILTER_SORT = 'SAVE_PROFESSION_FILTER_SORT'
export const SAVE_PROFESSION_FILTER_PRICE = 'SAVE_PROFESSION_FILTER_PRICE'
export const SAVE_PROFESSION_FILTER_AVAILABILITY = 'SAVE_PROFESSION_FILTER_AVAILABILITY'
export const SAVE_PROFESSION_FILTER_ONLINE_STATUS = 'SAVE_PROFESSION_FILTER_ONLINE_STATUS'
export const SAVE_PROFESSION_FILTER_TRAVEL_STATUS = 'SAVE_PROFESSION_FILTER_TRAVEL_STATUS'
export const SAVE_GENDER_STATUS = 'SAVE_GENDER_STATUS'
export const SAVE_LOCATION_DIST_STATUS = 'SAVE_LOCATION_DIST_STATUS'
export const SAVE_LOCATION = 'SAVE_LOCATION'
export const CLEAR_PROFESSION_FILTERS = 'CLEAR_PROFESSION_FILTERS'
export const APP_SKILL_FILTER_SEEN = 'APP_SKILL_FILTER_SEEN'
export const SAVE_SELECTED_SEARCH_ITEM = 'SAVE_SELECTED_SEARCH_ITEM'
export const SAVE_SEARCH_TEXT = 'SAVE_SEARCH_TEXT'

//report
export const APP_POST_REPORT_START = 'APP_POST_REPORT_START'
export const APP_POST_REPORT = 'APP_POST_REPORT'
export const APP_POST_REPORT_FAIL = 'APP_POST_REPORT_FAIL'

//feedback
export const APP_POST_FEEDBACK_START = 'APP_POST_FEEDBACK_START'
export const APP_POST_FEEDBACK = 'APP_POST_FEEDBACK'
export const APP_POST_FEEDBACK_FAIL = 'APP_POST_FEEDBACK_FAIL'

//slot review
export const APP_EMIT_PAYMENT_SLOTS_REVIEW_START = 'APP_EMIT_PAYMENT_SLOTS_REVIEW_START'
export const APP_EMIT_PAYMENT_SLOTS_REVIEW = 'APP_EMIT_PAYMENT_SLOTS_REVIEW'
export const APP_EMIT_PAYMENT_SLOTS_REVIEW_FAIL = 'APP_EMIT_PAYMENT_SLOTS_REVIEW_FAIL'

//perservice ui
export const APP_SAVE_SERVICE_LOCATION = 'APP_SAVE_SERVICE_LOCATION'
export const CLEAR_PER_SERVICE = 'CLEAR_PER_SERVICE'
export const APP_SAVE_SERVICE_CUSTOM_POLICIES = 'APP_SAVE_SERVICE_CUSTOM_POLICIES'
export const APP_PER_SERVICE_INTRO_SEEN = 'APP_PER_SERVICE_INTRO_SEEN'
export const APP_SAVE_REQUEST_SERVICE_DATES = 'APP_SAVE_REQUEST_SERVICE_DATES'
export const APP_SAVE_CREATE_SERVICE_TYPE_AND_PRICE_OBJ = 'APP_SAVE_CREATE_SERVICE_TYPE_AND_PRICE_OBJ'
export const APP_SAVE_SERVICE_REQUEST_ID = 'APP_SAVE_SERVICE_REQUEST_ID'

//perService
export const APP_POST_PER_SERVICE_START = 'APP_POST_PER_SERVICE_START'
export const APP_POST_PER_SERVICE = 'APP_POST_PER_SERVICE'
export const APP_POST_PER_SERVICE_FAIL = 'APP_POST_PER_SERVICE_FAIL'
export const APP_POST_PER_SERVICE_REQUEST_START = 'APP_POST_PER_SERVICE_REQUEST_START'
export const APP_POST_PER_SERVICE_REQUEST = 'APP_POST_PER_SERVICE_REQUEST'
export const APP_POST_PER_SERVICE_REQUEST_FAIL = 'APP_POST_PER_SERVICE_REQUEST_FAIL'
export const APP_GET_SERVICES_START = 'APP_GET_SERVICES_START'
export const APP_GET_SERVICES = 'APP_GET_SERVICES'
export const APP_GET_SERVICES_FAIL = 'APP_GET_SERVICES_FAIL'
export const APP_GET_SERVICE_BY_ID_START = 'APP_GET_SERVICE_BY_ID_START'
export const APP_GET_SERVICE_BY_ID = 'APP_GET_SERVICE_BY_ID'
export const APP_GET_SERVICE_BY_ID_FAIL = 'APP_GET_SERVICE_BY_ID_FAIL'
export const APP_SERVICE_REQUEST_STATUS_UPDATE_START = 'APP_SERVICE_REQUEST_STATUS_UPDATE_START'
export const APP_SERVICE_REQUEST_STATUS_UPDATE = 'APP_SERVICE_REQUEST_STATUS_UPDATE'
export const APP_SERVICE_REQUEST_STATUS_UPDATE_FAIL = 'APP_SERVICE_REQUEST_STATUS_UPDATE_FAIL'
export const APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT_START = 'APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT_START'
export const APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT = 'APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT'
export const APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT_FAIL = 'APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT_FAIL'
export const APP_SERVICE_REQUEST_PAYMENT_FAILED_START = 'APP_SERVICE_REQUEST_PAYMENT_FAILED_START'
export const APP_SERVICE_REQUEST_PAYMENT_FAILED = 'APP_SERVICE_REQUEST_PAYMENT_FAILED'
export const APP_SERVICE_REQUEST_PAYMENT_FAILED_FAIL = 'APP_SERVICE_REQUEST_PAYMENT_FAILED_FAIL'
export const APP_SERVICE_REQUEST_RE_PAYMENT_START = 'APP_SERVICE_REQUEST_RE_PAYMENT_START'
export const APP_SERVICE_REQUEST_RE_PAYMENT = 'APP_SERVICE_REQUEST_RE_PAYMENT'
export const APP_SERVICE_REQUEST_RE_PAYMENT_FAIL = 'APP_SERVICE_REQUEST_RE_PAYMENT_FAIL'
export const APP_SERVICE_DETAILS_ONE_SEEN = 'APP_SERVICE_DETAILS_ONE_SEEN'
export const APP_SERVICE_DETAILS_TWO_SEEN = 'APP_SERVICE_DETAILS_TWO_SEEN'

//ask question
export const APP_POST_ASK_QUESTION_START = 'APP_POST_ASK_QUESTION_START'
export const APP_POST_ASK_QUESTION = 'APP_POST_ASK_QUESTION'
export const APP_POST_ASK_QUESTION_FAIL = 'APP_POST_ASK_QUESTION_FAIL'

//colleagues
export const APP_GET_REQUEST_STATUS_START = 'APP_GET_REQUEST_STATUS_START'
export const APP_GET_REQUEST_STATUS = 'APP_GET_REQUEST_STATUS'
export const APP_GET_REQUEST_STATUS_FAIL = 'APP_GET_REQUEST_STATUS_FAIL'
export const APP_POST_SEND_REQUEST_START = 'APP_POST_SEND_REQUEST_START'
export const APP_POST_SEND_REQUEST = 'APP_POST_SEND_REQUEST'
export const APP_POST_SEND_REQUEST_FAIL = 'APP_POST_SEND_REQUEST_FAIL'
export const APP_GET_ALL_COLLEAGUES_START = 'APP_GET_ALL_COLLEAGUES_START'
export const APP_GET_ALL_COLLEAGUES = 'APP_GET_ALL_COLLEAGUES'
export const APP_GET_ALL_COLLEAGUES_FAIL = 'APP_GET_ALL_COLLEAGUES_FAIL'
export const APP_GET_SENT_COLLEAGUES_REQUEST_START = 'APP_GET_SENT_COLLEAGUES_REQUEST_START'
export const APP_GET_SENT_COLLEAGUES_REQUEST = 'APP_GET_SENT_COLLEAGUES_REQUEST'
export const APP_GET_SENT_COLLEAGUES_REQUEST_FAIL = 'APP_GET_SENT_COLLEAGUES_REQUEST_FAIL'
export const APP_GET_RECEIVED_COLLEAGUES_REQUEST_START = 'APP_GET_RECEIVED_COLLEAGUES_REQUEST_START'
export const APP_GET_RECEIVED_COLLEAGUES_REQUEST = 'APP_GET_RECEIVED_COLLEAGUES_REQUEST'
export const APP_GET_RECEIVED_COLLEAGUES_REQUEST_FAIL = 'APP_GET_RECEIVED_COLLEAGUES_REQUEST_FAIL'
export const APP_UPDATE_COLLEAGUES_REQUEST_START = 'APP_UPDATE_COLLEAGUES_REQUEST_START'
export const APP_UPDATE_COLLEAGUES_REQUEST = 'APP_UPDATE_COLLEAGUES_REQUEST'
export const APP_UPDATE_COLLEAGUES_REQUEST_FAIL = 'APP_UPDATE_COLLEAGUES_REQUEST_FAIL'

//announcements
export const APP_GET_ANNOUNCEMEMTS_START = 'APP_GET_ANNOUNCEMEMTS_START'
export const APP_GET_ANNOUNCEMEMTS = 'APP_GET_ANNOUNCEMEMTS'
export const APP_GET_ANNOUNCEMEMTS_FAIL = 'APP_GET_ANNOUNCEMEMTS_FAIL'