import {
    APP_POST_REPORT_START,
    APP_POST_REPORT,
    APP_POST_REPORT_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    report:{},
    isLoadingReport: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const report = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_REPORT_START:
            return Object.assign({}, state, {
                isLoadingReport: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_REPORT:
            return Object.assign({}, state, {
                report: action.data,
                isLoadingReport: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_REPORT_FAIL:
            return Object.assign({}, state, {
                isLoadingReport: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.report.error
export const getMsg = state => state.report.message
export const getisLoadingReport = state => state.report.isLoadingReport
export const getReport = state => state.report.report
export const getIsSuccess = state => state.report.isSuccess
export const getstatusCode = state => state.report.statusCode