import React from "react";
import PeopleCard from "../cards/peopleCard";

export const ViewAll = (props) => {
    const renderCarousel = () => {
        return <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'row wrap', justifyContent: 'flex-start'}}>
            {props?.data?.map((item, index) => {
                return <PeopleCard key={index} img={item?.url} title={item?.title} text={item?.description} onClick={() => props?.onClick(item)} />
            })}
        </div>
    }

    return (
        <div className="professional__group">
            <h2>{props?.header}</h2>
                {renderCarousel()}
            <div className="clear"></div>
        </div>
    )
}