import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//assets
import { HightLightCarousel } from '../assets/layouts/carousel/highlightsCarousel'
import Spinner from "../assets/molecules/spinner";

//actions
import { startGetRandomUpcomings } from '../../redux/actions/upcoming/profileEdit/upcomings'

//reducers
import { getisLoadingAll, getAllupcomings } from '../../redux/reducers/upcoming/profileEdit/upcomings'


const HighLightsSection = () => {
    const dispatch = useDispatch()

    let history = useHistory()

    const allupcomings = useSelector(getAllupcomings)
    const isLoadingAll = useSelector(getisLoadingAll)

    useEffect(() => {
        dispatch(startGetRandomUpcomings())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingAll ? <div className="container paddingContainer minHeightContainer">
                <Spinner />
            </div> : <div className="container">
                {allupcomings && allupcomings?.length ? <HightLightCarousel header="Browse Highlights" data={allupcomings} onClickProfile={(navigation_name) => history.push({
                    pathname: `/search/people/${navigation_name}`,
                })} /> : null}
            <div className="clear"></div>
        </div>
    );
};

export default HighLightsSection;
