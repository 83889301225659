import React from "react";

const PersonDetailsCard = (props) => {
  return (
    <div className="personDetailsCard" onClick={props?.onClick}>
      <div className="personDetailsCard__icon">
        <img src={props.img} alt={props.name} />
        <div className="clear"></div>
      </div>
      <div className="personDetailsCard__body">
        <h4>{props.name}</h4>
        <p className="personDetailsCard__designation">{props.designation}</p>
        <p className="personDetailsCard__date">{props.date}</p>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default PersonDetailsCard;
