import React,{useRef} from "react";
import HightlightCard from "../cards/highlightCard";
import Slider from "react-slick";
import moment from 'moment'

// assets
import LeftArrow from '../../../../assets/images/arrowLeft.svg'
import RightArrow from '../../../../assets/images/arrowRight.svg'
import HighlightThumb from "../../../../assets/images/highlightsSmall.png"
import PersonDetailsCard from "../../../assets/molecules/personDetailsCard";
import ProfileIcon from "../../../../assets/images/profileIcon.svg";

export const HightLightCarousel = (props) => {
    let SliderRef = useRef(null)
    let settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 3,
        variableWidth: true,
        draggable:true,
        arrows:false
    };

    const renderCarousel = () => {
        return props?.data?.map((item, index) => {
            return <span key={index}>
                <HightlightCard
                    img={item?.thumbnail ? item?.thumbnail : HighlightThumb}
                    title={item?.title}
                    text={item?.role}
                    date={item?.date}
                    onClick={() => props?.onClickProfile(item?.navigation_name)}
                />
                <PersonDetailsCard
                    onClick={() => props?.onClickProfile(item?.navigation_name)}
                    img={item?.url ? item?.url : ProfileIcon}
                    name={item?.firstName + " " + item?.lastName}
                    designation={item?.location}
                    date={"Added " + moment(item?.timestamp).format("MMM DD, YYYY")}
                />
                </span>
        })
    }

    const next = () => {
        SliderRef.current.slickNext();
    }
    const previous = () => {
        SliderRef.current.slickPrev();
    }

    return (
        <div className="professional__group">
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <h2>{props?.header}</h2>
                {props?.data?.length > 4 ? <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div className="cursor--pointer arrow__leftMar" onClick={previous}>
                        <img alt="Left arrow" src={LeftArrow} />
                    </div>
                    <div className="cursor--pointer" onClick={next}>
                        <img alt="Right arrow" src={RightArrow} />
                    </div>
                </div>:null}
            </div>
            <Slider {...settings} ref={SliderRef} className={"highlights__slider--carousel"}>
                {renderCarousel()}
            </Slider>
            <div className="clear"></div>
        </div>
    )
}