import React,{useRef} from "react";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom'

// assets
import PeopleListingCard from "../cards/activityPeopleListingCard";
import LeftArrow from '../../../../assets/images/arrowLeft.svg'
import RightArrow from '../../../../assets/images/arrowRight.svg'
import People1 from "../../../../assets/images/profileIcon.svg";

export const ProfessionCardCarousel = (props) => {
    let history = useHistory()

    let SliderRef = useRef(null)
    
    let settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 2,
        variableWidth: true,
        draggable:true,
        arrows:false
    };

    const renderCarousel = () => {
        return props?.data?.map((card, index) => {
            if(index % 2 === 0){
                return <span key={index}>
                    <PeopleListingCard
                        img={props?.data[index]?.profile_pic ? props?.data[index]?.profile_pic : People1}
                        name={props?.data[index]?.firstName + " " + props?.data[index]?.lastName}
                        designation={props?.data[index]?.name}
                        city={props?.data[index]?.contract_location?.short_description ? props?.data[index]?.contract_location?.short_description : props?.data[index]?.contract_location}
                        // engagements={props?.data[index]?.booking_count}
                        description={props?.data[index]?.description}
                        skills={props?.data[index]?.skills}
                        onClickName={() => history.push({
                            pathname: `/search/people/${props?.data[index]?.navigation_name}`,
                            // state: { card: props?.data[index] }
                        })}
                        onClickButton={() => history.push({
                            pathname: `/search/people/${card?.navigation_name}`,
                            // state: { card: props?.data[index] }
                        })}
                    />
                    <PeopleListingCard
                        img={props?.data[index + 1]?.profile_pic ? props?.data[index + 1]?.profile_pic : People1}
                        name={props?.data[index + 1]?.firstName + " " + props?.data[index + 1]?.lastName}
                        designation={props?.data[index + 1]?.name}
                        city={props?.data[index + 1]?.contract_location?.short_description ? props?.data[index + 1]?.contract_location?.short_description : props?.data[index + 1]?.contract_location}
                        // engagements={props?.data[index + 1]?.booking_count}
                        description={props?.data[index + 1]?.description}
                        skills={props?.data[index + 1]?.skills}
                        onClickName={() => history.push({
                            pathname: `/search/people/${props?.data[index + 1]?.navigation_name}`,
                            // state: { card: props?.data[index + 1] }
                        })}
                        onClickButton={() => history.push({
                            pathname: `/search/people/${card?.navigation_name}`,
                            // state: { card: props?.data[index + 1] }
                        })}
                    />
                </span>
            }  
        })
    }

    const next = () => {
        SliderRef.current.slickNext();
    }
    const previous = () => {
        SliderRef.current.slickPrev();
    }

    return (
        <div className="professional__group">
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <h2>{props?.header}</h2>
                {props?.data?.length > 4 ? <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div className="cursor--pointer arrow__leftMar" onClick={previous}>
                        <img alt="Left arrow" src={LeftArrow} />
                    </div>
                    <div className="cursor--pointer" onClick={next}>
                        <img alt="Right arrow" src={RightArrow} />
                    </div>
                </div>:null}
            </div>
            <Slider {...settings} ref={SliderRef} className={"audio__slider--carousel"}>
                {renderCarousel()}
            </Slider>
            <div className="clear"></div>
        </div>
    )
}