
import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_GET_FEATURED_MEDIA_AUDIO_START,
    APP_GET_FEATURED_MEDIA_AUDIO,
    APP_GET_FEATURED_MEDIA_AUDIO_FAIL,
    APP_PUT_FEATURED_MEDIA_AUDIO_START,
    APP_PUT_FEATURED_MEDIA_AUDIO,
    APP_PUT_FEATURED_MEDIA_AUDIO_FAIL,
    APP_UNASSIGN_MEDIA_AUDIO_START,
    APP_UNASSIGN_MEDIA_AUDIO,
    APP_UNASSIGN_MEDIA_AUDIO_FAIL,
    APP_GET_CARD_MEDIA_AUDIO_START,
    APP_GET_CARD_MEDIA_AUDIO,
    APP_GET_CARD_MEDIA_AUDIO_FAIL,
    APP_GET_MEDIA_AUDIO_START,
    APP_GET_MEDIA_AUDIO,
    APP_GET_MEDIA_AUDIO_FAIL,
    APP_DELETE_MEDIA_AUDIOS_START,
    APP_DELETE_MEDIA_AUDIOS,
    APP_DELETE_MEDIA_AUDIOS_FAIL,
    APP_PUT_MEDIA_AUDIO_START,
    APP_PUT_MEDIA_AUDIO,
    APP_PUT_MEDIA_AUDIO_FAIL,
    APP_ASSIGN_MEDIA_AUDIO_START,
    APP_ASSIGN_MEDIA_AUDIO,
    APP_ASSIGN_MEDIA_AUDIO_FAIL,
    APP_LOGOUT,
} from '../../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

// get featured audio
export const emitFeaturedMediaAudioStart = () => ({
    type: APP_GET_FEATURED_MEDIA_AUDIO_START
})

export const emitFeaturedMediaAudio = data => {
    return {
        type: APP_GET_FEATURED_MEDIA_AUDIO,
        data
    }
}

export const emitFeaturedMediaAudioFailed = (data) => ({
    type: APP_GET_FEATURED_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getFeaturedMediaAudios = ({ jwt, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/audio/professioncard/${id}/featured/${true}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetFeaturedMediaAudios = (data) => {
    return (dispatch) => {
        dispatch(emitFeaturedMediaAudioStart())
        return getFeaturedMediaAudios(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitFeaturedMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitFeaturedMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitFeaturedMediaAudioFailed(err))
            })
    }

}


// get all audio by card
export const emitCardMediaAudioStart = () => ({
    type: APP_GET_CARD_MEDIA_AUDIO_START
})

export const emitCardMediaAudio = data => {
    return {
        type: APP_GET_CARD_MEDIA_AUDIO,
        data
    }
}

export const emitCardMediaAudioFailed = (data) => ({
    type: APP_GET_CARD_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getCardMediaAudios = ({ jwt, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/audios/${id}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCardMediaAudios = (data) => {
    return (dispatch) => {
        dispatch(emitCardMediaAudioStart())
        return getCardMediaAudios(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCardMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCardMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitCardMediaAudioFailed(err))
            })
    }

}

// get card audio by user id
const getCardMediaAudiosByUserId = ({ jwt, id, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/audios/${id}/user/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCardMediaAudiosByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitCardMediaAudioStart())
        return getCardMediaAudiosByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCardMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCardMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitCardMediaAudioFailed(err))
            })
    }

}
// get all audio
export const emitMediaAudioStart = () => ({
    type: APP_GET_MEDIA_AUDIO_START
})

export const emitMediaAudio = data => {
    return {
        type: APP_GET_MEDIA_AUDIO,
        data
    }
}

export const emitMediaAudioFailed = (data) => ({
    type: APP_GET_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

//all
const getMediaAudios = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/audios/user/audios'
    
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMediaAudios = (data) => {
    return (dispatch) => {
        dispatch(emitMediaAudioStart())
        return getMediaAudios(data)
            .then(parseJSON)
            .then(json => {
                // 
                if (json.statusCode === 200) {
                    dispatch(emitMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaAudioFailed(err))
            })
    }

}
//random
const getRandomMediaAudios = () => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/audios/random/audios'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
}

export const startGetRandomMediaAudios = () => {
    return (dispatch) => {
        dispatch(emitMediaAudioStart())
        return getRandomMediaAudios()
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetRandomMediaAudios");
                if (json.statusCode === 200) {
                    dispatch(emitMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaAudioFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitMediaAudioFailed(err))
            })
    }

}

//get all audio by user id
const getMediaAudiosByUserId = ({ jwt, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/users/audios/${userId}`
    
    
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMediaAudiosByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitMediaAudioStart())
        return getMediaAudiosByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaAudioFailed(err))
            })
    }

}

//delete Audio

export const emitMediaAudioDeleteStart = () => ({
    type: APP_DELETE_MEDIA_AUDIOS_START
})

export const emitMediaAudioDelete = data => {
    return {
        type: APP_DELETE_MEDIA_AUDIOS,
        data
    }
}

export const emitMediaAudioDeleteFailed = (data) => ({
    type: APP_DELETE_MEDIA_AUDIOS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const deleteMediaAudio = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/audios/delete/audios'
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteMediaAudios = (data) => {
    return (dispatch) => {
        dispatch(emitMediaAudioDeleteStart())
        return deleteMediaAudio(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMediaAudioDelete(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaAudioDeleteFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaAudioDeleteFailed(err))
            })
    }

}

// put audio as featured
export const emitPutFeaturedMediaAudioStart = () => ({
    type: APP_PUT_FEATURED_MEDIA_AUDIO_START
})

export const emitPutFeaturedMediaAudio = data => {
    return {
        type: APP_PUT_FEATURED_MEDIA_AUDIO,
        data
    }
}

export const emitPutFeaturedMediaAudioFailed = (data) => ({
    type: APP_PUT_FEATURED_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const putFeaturedMediaAudios = ({ jwt,data, id, featured }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/audios/featured/${featured}/professioncard/${id}`
    
    
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutFeaturedMediaAudios = (data) => {
    return (dispatch) => {
        dispatch(emitPutFeaturedMediaAudioStart())
        return putFeaturedMediaAudios(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitPutFeaturedMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPutFeaturedMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPutFeaturedMediaAudioFailed(err))
            })
    }

}

// unassign photo from profession card
export const emitUnassignMediaAudioStart = () => ({
    type: APP_UNASSIGN_MEDIA_AUDIO_START
})

export const emitUnassignMediaAudio = data => {
    return {
        type: APP_UNASSIGN_MEDIA_AUDIO,
        data
    }
}

export const emitUnassignMediaAudioFailed = (data) => ({
    type: APP_UNASSIGN_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const UnassignMediaAudios = ({ jwt, data, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/unassign/professioncard/audios/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startUnassignMediaAudios = (data) => {
    return (dispatch) => {
        dispatch(emitUnassignMediaAudioStart())
        return UnassignMediaAudios(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUnassignMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUnassignMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUnassignMediaAudioFailed(err))
            })
    }

}

//update media Audio
export const emitPutMediaAudioStart = () => ({
    type: APP_PUT_MEDIA_AUDIO_START
})

export const emitPutMediaAudio = data => {
    return {
        type: APP_PUT_MEDIA_AUDIO,
        data
    }
}

export const emitPutMediaAudioFailed = (data) => ({
    type: APP_PUT_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const putMediaAudio = ({ jwt, data, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/audio/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutMediaAudio = (data) => {
    return (dispatch) => {
        dispatch(emitPutMediaAudioStart())
        return putMediaAudio(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitPutMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPutMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPutMediaAudioFailed(err))
            })
    }
}

//reasssign/assign to profession cards
export const emitAssignMediaAudioStart = () => ({
    type: APP_ASSIGN_MEDIA_AUDIO_START
})

export const emitAssignMediaAudio = data => {
    return {
        type: APP_ASSIGN_MEDIA_AUDIO,
        data
    }
}

export const emitAssignMediaAudioFailed = (data) => ({
    type: APP_ASSIGN_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const assignMediaAudio = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/audios/reassign/professioncard/audios'
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignMediaAudio = (data) => {
    return (dispatch) => {
        dispatch(emitAssignMediaAudioStart())
        return assignMediaAudio(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitAssignMediaAudioFailed(err))
            })
    }
}
