import {
    STRIPE_GET_CUSTOMER_CARDS_START,
    STRIPE_GET_CUSTOMER_CARDS,
    STRIPE_GET_CUSTOMER_CARDS_FAIL,
    STRIPE_GET_CLIENT_SECKEY_START,
    STRIPE_GET_CLIENT_SECKEY,
    STRIPE_GET_CLIENT_SECKEY_FAIL,
    STRIPE_POST_ADD_CARD_START,
    STRIPE_POST_ADD_CARD,
    STRIPE_POST_ADD_CARD_FAIL,
    STRIPE_POST_AUTHORIZE_EXPRESS_ACC_START,
    STRIPE_POST_AUTHORIZE_EXPRESS_ACC,
    STRIPE_POST_AUTHORIZE_EXPRESS_ACC_FAIL,
    STRIPE_GET_EXPRESS_ACC_LINK_START,
    STRIPE_GET_EXPRESS_ACC_LINK,
    STRIPE_GET_EXPRESS_ACC_LINK_FAIL,
    STRIPE_GET_DEFAULT_CARD_START,
    STRIPE_GET_DEFAULT_CARD,
    STRIPE_GET_DEFAULT_CARD_FAIL,
    STRIPE_PUT_DEFAULT_CARD_START,
    STRIPE_PUT_DEFAULT_CARD,
    STRIPE_PUT_DEFAULT_CARD_FAIL,
    STRIPE_DELETE_CARD_START,
    STRIPE_DELETE_CARD,
    STRIPE_DELETE_CARD_FAIL,
    STRIPE_PAY_NOW_START,
    STRIPE_PAY_NOW,
    STRIPE_PAY_NOW_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    isLoadingCustomerCards:false,
    customerCards:[],
    isLoadingClientSecKey:false,
    clientSecKey:"",
    isLoadingAddCard: false,
    isLoadingExpressAccountLink: false,
    isLoadingAuthExpressAccount: false,
    expressAccountLink:"",
    isLoadingGetDefaultCard:false,
    defaultCard:{},
    isLoadingPutDefaultCard:false,
    isLoadingPutDeleteCard:false,
    isLoadingPayNowCard:false,
    paynow:{}
}


export const stripe = (state = initialState, action) => {
    switch (action.type) {
        case STRIPE_PAY_NOW_START:
            return Object.assign({}, state, {
                isLoadingPayNowCard: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_PAY_NOW:
            return Object.assign({}, state, {
                isLoadingPayNowCard: false,
                paynow:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_PAY_NOW_FAIL:
            return Object.assign({}, state, {
                isLoadingPayNowCard: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case STRIPE_DELETE_CARD_START:
            return Object.assign({}, state, {
                isLoadingPutDeleteCard: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_DELETE_CARD:
            return Object.assign({}, state, {
                isLoadingPutDeleteCard: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_DELETE_CARD_FAIL:
            return Object.assign({}, state, {
                isLoadingPutDeleteCard: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case STRIPE_PUT_DEFAULT_CARD_START:
            return Object.assign({}, state, {
                isLoadingPutDefaultCard: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_PUT_DEFAULT_CARD:
            return Object.assign({}, state, {
                isLoadingPutDefaultCard: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_PUT_DEFAULT_CARD_FAIL:
            return Object.assign({}, state, {
                isLoadingPutDefaultCard: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case STRIPE_GET_DEFAULT_CARD_START:
            return Object.assign({}, state, {
                isLoadingGetDefaultCard: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_GET_DEFAULT_CARD:
            return Object.assign({}, state, {
                isLoadingGetDefaultCard: false,
                defaultCard:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_GET_DEFAULT_CARD_FAIL:
            return Object.assign({}, state, {
                isLoadingGetDefaultCard: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case STRIPE_POST_AUTHORIZE_EXPRESS_ACC_START:
            return Object.assign({}, state, {
                isLoadingAuthExpressAccount: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_POST_AUTHORIZE_EXPRESS_ACC:
            return Object.assign({}, state, {
                isLoadingAuthExpressAccount: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_POST_AUTHORIZE_EXPRESS_ACC_FAIL:
            return Object.assign({}, state, {
                isLoadingAuthExpressAccount: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case STRIPE_GET_EXPRESS_ACC_LINK_START:
            return Object.assign({}, state, {
                isLoadingExpressAccountLink: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_GET_EXPRESS_ACC_LINK:
            return Object.assign({}, state, {
                isLoadingExpressAccountLink: false,
                expressAccountLink:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_GET_EXPRESS_ACC_LINK_FAIL:
            return Object.assign({}, state, {
                isLoadingExpressAccountLink: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case STRIPE_POST_ADD_CARD_START:
            return Object.assign({}, state, {
                isLoadingAddCard: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_POST_ADD_CARD:
            return Object.assign({}, state, {
                isLoadingAddCard: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_POST_ADD_CARD_FAIL:
            return Object.assign({}, state, {
                isLoadingAddCard: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case STRIPE_GET_CLIENT_SECKEY_START:
            return Object.assign({}, state, {
                isLoadingClientSecKey: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_GET_CLIENT_SECKEY:
            return Object.assign({}, state, {
                isLoadingClientSecKey: false,
                clientSecKey: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_GET_CLIENT_SECKEY_FAIL:
            return Object.assign({}, state, {
                isLoadingClientSecKey: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case STRIPE_GET_CUSTOMER_CARDS_START:
            return Object.assign({}, state, {
                isLoadingCustomerCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case STRIPE_GET_CUSTOMER_CARDS:
            return Object.assign({}, state, {
                isLoadingCustomerCards: false,
                customerCards: action.data && action.data.data && action.data.data[0] ? action.data.data :[],
                error: false,
                message: '',
                isSuccess: true
            })
        case STRIPE_GET_CUSTOMER_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingCustomerCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.stripe.error
export const getMsg = state => state.stripe.message
export const getIsSuccess = state => state.stripe.isSuccess
export const getstatusCode = state => state.stripe.statusCode
export const getisLoadingCustomerCards = state => state.stripe.isLoadingCustomerCards
export const getCustomerCards = state => state.stripe.customerCards
export const getisLoadingClientSecKey = state => state.stripe.isLoadingClientSecKey
export const getClientSecKey = state => state.stripe.clientSecKey
export const getisLoadingAddCard = state => state.stripe.isLoadingAddCard
export const getisLoadingExpressAccountLink = state => state.stripe.isLoadingExpressAccountLink
export const getExpressAccountLink = state => state.stripe.expressAccountLink
export const getisLoadingAuthExpressAccount = state => state.stripe.isLoadingAuthExpressAccount
export const getisLoadingGetDefaultCard = state => state.stripe.isLoadingGetDefaultCard
export const getDefaultCard = state => state.stripe.defaultCard
export const getisLoadingPutDefaultCard = state => state.stripe.isLoadingPutDefaultCard
export const getisLoadingPutDeleteCard = state => state.stripe.isLoadingPutDeleteCard
export const getisLoadingPayNowCard = state => state.stripe.isLoadingPayNowCard
export const getPaynow = state => state.stripe.paynow