import {
    APP_GET_DISCIPLINE_START,
    APP_GET_DISCIPLINE,
    APP_GET_DISCIPLINE_FAIL,
    APP_LOGOUT,
    SAVE_DISCIPLINE
} from '../../actions'

import { createSelector } from 'reselect'

const initialState = {
    disciplines:[],
    isLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    selectedDiscipline:[]
}


export const discipline = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_DISCIPLINE_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_DISCIPLINE:
            return Object.assign({}, state, {
                disciplines: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_DISCIPLINE_FAIL:
            return Object.assign({}, state, {
                disciplines: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case SAVE_DISCIPLINE:
            return Object.assign({}, state, {
                selectedDiscipline:action.data
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.discipline.error
export const getMsg = state => state.discipline.message
export const getisLoading = state => state.discipline.isLoading
export const getDiscipline = state => state.discipline.disciplines
export const getIsSuccess = state => state.discipline.isSuccess
export const getstatusCode = state => state.discipline.statusCode
export const getselectedDiscipline = state => state.discipline.selectedDiscipline

export const getDisciplineAllIds = state => {
    return state.discipline.disciplines.map(discipline => discipline.id)
}


export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))