import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Scrollbars } from "react-custom-scrollbars";

//assets
// import Notification from "../../../../assets/images/notification.png";
// import GreyNotification from "../../../../assets/images/notification.svg";
import ProfileIcon from "../../../../assets/images/profileIcon.svg";
import Popup from "../popup/popupCustom";
import LoginTab from "../../../user/loginTab";
import DummyImg from "../../../../assets/images/popLeftBg.png";
import PopupRegisterSuccess from '../popup/popupRegisterSuccess'
import BackArrow from "../../../../assets/images/backArrowWhite.svg"

//reducers
import { getLoginPopUpStatus, getDropdownStatus, getRegisterSuceessPopUpStatus } from '../../../../redux/reducers/ui/profile'
import { getSearchText } from '../../../../redux/reducers/ui/search'
import { getLoggedIn, getFirstName, getLastName, getJwt, getisProfileCreated, getverified, getNavigationName } from '../../../../redux/reducers/account/accounts'
import { getProfileImage } from '../../../../redux/reducers/profile'
import { getisLoadingSearchResults, getSearchResults } from '../../../../redux/reducers/search'

//actions
import { showLoginPopUp, hideLoginPopUp, loginPopUpTab, changeDropdownStatus, changeRegisterPopUp } from '../../../../redux/actions/ui/profile'
import { saveSearchText } from '../../../../redux/actions/ui/search'
import { startLogout } from '../../../../redux/actions/account'
import { startGetSearchResult } from '../../../../redux/actions/search/public'

//helpers
import { usePrevious } from '../../../../helpers/utils'


const DashBoardHead = (props) => {
  const dispatch = useDispatch()

  let history = useHistory()

  let searchRef = useRef(null)

  const [groupedOptions, serGroupedOptions] = useState([
    {
      label: "PROFESSIONS",
      options:[],
    },
    {
      label: "PEOPLE",
      options: [],
    },
  ])

  const uiSearchText = useSelector(getSearchText)
  const registerSuceessPopUpStatus = useSelector(getRegisterSuceessPopUpStatus)
  const loginPopUpStatus = useSelector(getLoginPopUpStatus)
  const navigationName = useSelector(getNavigationName)
  const isLoggedIn = useSelector(getLoggedIn)
  const isProfileCreated = useSelector(getisProfileCreated)
  const isverified = useSelector(getverified)
  const firstName = useSelector(getFirstName)
  const lastName = useSelector(getLastName)
  const jwt = useSelector(getJwt)
  const profileImage = useSelector(getProfileImage)
  const searchResults = useSelector(getSearchResults)
  const showDropDown = useSelector(getDropdownStatus)
  const isLoadingSearchResults = useSelector(getisLoadingSearchResults)
  const isLoadingSearchResultsPrev = usePrevious(isLoadingSearchResults)

  useEffect(()=>{
    if (props?.explore){
      searchRef.current.focus();
    }
    else{
      return null
    }
  },[props?.explore])

  useEffect(()=>{
    if (!isLoadingSearchResults && isLoadingSearchResultsPrev !== isLoadingSearchResults) {
      const filteredPeople = searchResults?.filter(item => item?.type === "name")?.map((people)=>{
        return { value: people?.id, label: people?.firstName + " " + people?.lastName, fullObj: people }
      })
      const filteredPro = searchResults?.filter(item => item?.type === "profession")?.map((pro)=>{
        return { value: pro?.id, label: pro?.name, fullObj: pro }
      })
      serGroupedOptions([
        {
          label: "PROFESSIONS",
          options: filteredPro,
        },
        {
          label: "PEOPLE",
          options: filteredPeople,
        },
      ])
    }
  }, [isLoadingSearchResults, isLoadingSearchResultsPrev])// eslint-disable-line react-hooks/exhaustive-deps

  const onLogOut = () =>{
    dispatch(startLogout({jwt}))
  }
  
  const handleInputChange = (text) =>{
    serGroupedOptions([
      {
        label: "PROFESSIONS",
        options: [],
      },
      {
        label: "PEOPLE",
        options: [],
      },
    ])
    dispatch(saveSearchText(text))
    if (text?.length > 2){
      if (!showDropDown){
        dispatch(changeDropdownStatus(true))
      }
      dispatch(startGetSearchResult(text))
    }
    else{
      dispatch(changeDropdownStatus(false))
      if(props?.results){
        history.push({
          pathname: '/'
        })
      }
    }
  }

  const onSearch = (event) =>{
    event.preventDefault();
    dispatch(changeDropdownStatus(false))
    if (uiSearchText?.length > 2){
      history.push({
        pathname: '/search-result'
      })
    }
  }

  const onChangeSelection = (temp) =>{
    dispatch(changeDropdownStatus(false))
    if (temp?.fullObj?.type === "name"){
      history.push({
        pathname: `/search/people/${temp?.fullObj?.navigation_name}`,
      })
    }
    else if (temp?.fullObj?.type === "profession"){
      history.push({
        pathname: `/search/template/${temp?.fullObj?.id}`,
        state: { profession: temp?.fullObj }
      })
    }
  }
  
  return (
    <React.Fragment>
      <div className={"header--search " + props.headerClassname}>
        <div className="container">
          <header>
            <Navbar collapseOnSelect expand="lg">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <h4 className="header__head"><span onClick={props.backButtonClick} className={"header__backButton backButton--" + props.backButton}><img src={BackArrow} alt="back button" /> Go back to {props?.name}'s profile</span> {props.heading} <span>{props.category}</span></h4>
                </Nav>
                {isLoggedIn ? <Nav className="navItems">
                  {/* <img
                    className="whiteNotify notification"
                    src={Notification}
                    alt="Notification"
                  /> */}
                  {/* <img
                    className="greyNotify notification"
                    src={GreyNotification}
                    alt="Notification"
                  /> */}
                  {isProfileCreated && isverified ? <img
                    className="profileImage"
                    src={profileImage ? profileImage : ProfileIcon}
                    alt="ProfileIcon"
                  /> : null}
                  <NavDropdown title={firstName + " " + lastName}>
                    <NavDropdown.Item className="myProfile" onClick={() => history.push({
                      pathname: `/search/people/${navigationName}`,
                    })}>
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item className="logout" onClick={() => onLogOut()}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav> : <Nav>
                    <button onClick={() => { dispatch(loginPopUpTab("login")); dispatch(showLoginPopUp())}} className="button--transparent">LOGIN</button>
                  </Nav>}
              </Navbar.Collapse>
            </Navbar>
          </header>
          <form className="search__container" onSubmit={(e) => uiSearchText ? onSearch(e) : null }>
            <input ref={searchRef} placeholder="Type Here..." value={uiSearchText} onChange={(e) => handleInputChange(e.target.value)}/>
            <button className="button button--primary" type="submit" >SEARCH</button>
            {uiSearchText ? <div className={showDropDown ? "searchSuggest searchSuggest__display" : "searchSuggest"}>
            <Scrollbars autoHeight autoHeightMin={20} autoHeightMax={250}>
                {isLoadingSearchResults ? <div className="searchSuggest__head">Loading...</div> : searchResults && searchResults?.length ? <div>
                  {groupedOptions?.filter(item=>item?.options && item?.options?.length)?.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="searchSuggest__head">{item?.label} <span onClick={() => {
                            dispatch(changeDropdownStatus(false))
                            if (!props?.results) {
                              history.push({
                                pathname: '/search-result',
                                state: { tab: item?.label === "PROFESSIONS" ? "professions" : "people"}
                              })
                            }
                            else{
                              props?.onSearchViewAll(item?.label === "PROFESSIONS" ? "professions" : "people")
                            }
                          }}>View ALL {item?.label}</span></div>
                          <ul>
                            {item?.options?.slice(0, 10)?.map((opt, index) => {
                              return (
                                <li key={index} onClick={() => onChangeSelection(opt)}><span><img alt="dummy" src={opt?.fullObj?.url ? opt?.fullObj?.url : opt?.fullObj?.image ? opt?.fullObj?.image : DummyImg} /></span> {opt?.label}</li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                  })}
                  <div className="searchSuggest__viewAll" onClick={() => {
                    dispatch(changeDropdownStatus(false))
                    if (!props?.results) {
                      history.push({
                        pathname: '/search-result'
                      })
                    }
                    else {
                      props?.onSearchViewAll("all")
                    }
                  }}>
                    VIEW ALL RESULTS
                </div>
                </div> : <div className="searchSuggest__head">No results found.</div> }
              </Scrollbars>
              <div className="clear"></div>
            </div> : null}
          </form>
          {props?.showText ? <p className="explore__explain">{props?.showText}</p> : null}
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      {!isLoggedIn && <Popup
        className={loginPopUpStatus ? "showPop" : "hidePop"}
        close={() => dispatch(hideLoginPopUp())}
        popupHead="Login"
        type={"login"}
      >
        <LoginTab />
      </Popup>}
      <PopupRegisterSuccess
        className={registerSuceessPopUpStatus && isProfileCreated && isverified ? "showPop" : "hidePop"}
        close={() => dispatch(changeRegisterPopUp(false))}>
      </PopupRegisterSuccess>
    </React.Fragment>
  );
};

export default DashBoardHead;
