export const navList = [
    { name: 'explore', to: '/', pathname:'/' },
    { name: 'dashboard', to: null, pathname:'/dashboard' },
    { name: 'colleagues', to: null, pathname:'/colleagues' },
    { name: 'engagements', to: null, pathname:'/engagements' },
    { name: 'support', to: null, pathname:'/support' },
]

export const navListLoggedIn = [
    { name: 'explore', to: '/', pathname:'/' },
    { name: 'dashboard', to: '/dashboard', pathname:'/dashboard' },
    { name: 'colleagues', to: '/colleagues', pathname:'/colleagues' },
    { name: 'engagements', to: null, pathname:'/engagements' },
    { name: 'support', to: null, pathname:'/support' },
]

