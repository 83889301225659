import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { fetchEducation } from '../../../redux/actions/resume/education'

//reducers
import { getisLoading, getEducation } from '../../../redux/reducers/resume/education'
import { getJwt } from '../../../redux/reducers/account/accounts'

//helpers
import { monthToName } from '../../../helpers/utils'

const ResumeEducation = (props) => {
    const dispatch = useDispatch()

    const education = useSelector(getEducation)
    const isLoadingJob = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(fetchEducation({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingJob ? <Spinner /> : <div>
            <div className="expDetail__secHead">Institution Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Type</p>
                <p className="expDetail__desc">{education.type}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Name</p>
                <p className="expDetail__desc">{education.institutionName}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Location</p>
                <p className="expDetail__desc">{education?.location?.description}</p>
            </div>

            {education.associatedtype || education.associatedinstitutionName || education?.associatedlocation?.description ? <div>
                <div className="expDetail__secHead">Associated Institution</div>
                {education.associatedtype ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Type</p>
                    <p className="expDetail__desc">{education.associatedtype}</p>
                </div> : null}
                {education.associatedinstitutionName ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Name</p>
                    <p className="expDetail__desc">{education.associatedinstitutionName}</p>
                </div> : null}
                {education?.associatedlocation?.description ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Location</p>
                    <p className="expDetail__desc">{education?.associatedlocation?.description}</p>
                </div> : null}
            </div>:null}

            <div className="expDetail__secHead">Degree Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Title</p>
                <p className="expDetail__desc">{education.title}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Major</p>
                <p className="expDetail__desc">{education.major}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Duration</p>
                <p className="expDetail__desc">{!education?.date?.stillStudying ?
                    (education.date ? monthToName[education?.date?.from_month] : null) + ", " + (education.date ? education?.date?.from_year : null) + " - " + (education.date ? monthToName[education?.date?.to_month] : null) + ", " + (education.date ? education?.date?.to_year : null) :
                    (education.date ? monthToName[education?.date?.from_month] : null) + ", " + (education.date ? education?.date?.from_year : null) + " - Present"}</p>
            </div>

            {education.information || (education?.additional_info && education?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {education.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{education.information}</p>
                </div> : null}
                {education?.additional_info && education?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {education?.additional_info && education?.additional_info.data[0] ? education?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div> : null}
        </div>
    );
};

export default ResumeEducation;
