import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'

import { composeWithDevTools } from 'redux-devtools-extension'
//reducer
import {RootReducer} from './reducers'

//persist librarys
import storage from 'redux-persist/lib/storage'

//Middleware
import thunk from 'redux-thunk'

const persistConfig = {
    key: 'state',
    storage: storage,
}

const persistedReducer = persistReducer(persistConfig, RootReducer)

const reduxStore = () => {
    let store = createStore(
        persistedReducer,
        {},
        composeWithDevTools(applyMiddleware(thunk))
    )
    let persistor = persistStore(store)
    return { store, persistor }
}


export default reduxStore