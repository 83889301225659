import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { fetchAccoladesScholarship } from '../../../redux/actions/resume/accolades/scholarship'

//reducers
import { getisLoading, getScholarship } from '../../../redux/reducers/resume/accolades/scholarship'
import { getJwt } from '../../../redux/reducers/account/accounts'

//helpers
import { monthToName } from '../../../helpers/utils'

const ResumeScholarship = (props) => {
    const dispatch = useDispatch()

    const scholarship = useSelector(getScholarship)
    const isLoading = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(fetchAccoladesScholarship({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoading ? <Spinner /> : <div>
            <div className="expDetail__secHead">Scholarship</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Title of Scholarship</p>
                <p className="expDetail__desc">{scholarship.title ? scholarship.title : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Year</p>
                <p className="expDetail__desc">{(scholarship.month ? monthToName[scholarship.month] : null) + " - " + (scholarship.year ? scholarship.year : null)}</p>
            </div>

            {scholarship.institutionName || scholarship?.location?.description || scholarship.affiliateinstitutionName ? <div>
                <div className="expDetail__secHead">Institution Info</div>
                {scholarship.institutionName ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Location</p>
                    <p className="expDetail__desc">{scholarship.institutionName}</p>
                </div> : null}
                {scholarship.location ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Location</p>
                    <p className="expDetail__desc">{scholarship.location.description}</p>
                </div> : null}
                {scholarship.affiliateinstitutionName ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Affiliated Institute</p>
                    <p className="expDetail__desc">{scholarship.affiliateinstitutionName}</p>
                </div> : null}
            </div> : null}

            {scholarship.information || (scholarship?.additional_info && scholarship?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {scholarship.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{scholarship.information}</p>
                </div> : null}
                {scholarship?.additional_info && scholarship?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {scholarship?.additional_info && scholarship?.additional_info.data[0] ? scholarship?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div> : null}
        </div>
    );
};

export default ResumeScholarship;
