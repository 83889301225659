import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { fetchMasterclass } from '../../../redux/actions/resume/masterclass'

//reducers
import { getisLoading, getMasterclass } from '../../../redux/reducers/resume/masterclass'
import { getJwt } from '../../../redux/reducers/account/accounts'

const ResumeMasterClass = (props) => {
    const dispatch = useDispatch()

    const masterclass = useSelector(getMasterclass)
    const isLoadingJob = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(fetchMasterclass({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingJob ? <Spinner /> : <div>
            <div className="expDetail__secHead">Position Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Position</p>
                <p className="expDetail__desc">{masterclass.position ? masterclass.position : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Name of the Position</p>
                <p className="expDetail__desc">{masterclass.positionName ? masterclass.positionName : null}</p>
            </div>

            <div className="expDetail__secHead">Event Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Event Type</p>
                <p className="expDetail__desc">{masterclass.eventType ? masterclass.eventType : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Title of Event</p>
                <p className="expDetail__desc">{masterclass.eventTitle ? masterclass.eventTitle : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Institution</p>
                <p className="expDetail__desc">{masterclass.institutionName ? masterclass.institutionName : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Year Attended</p>
                <p className="expDetail__desc">{masterclass.date ? masterclass?.date?.from_year : null}</p>
            </div>

            {masterclass.information || (masterclass?.additional_info && masterclass?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {masterclass.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{masterclass.information}</p>
                </div> : null}
                {masterclass?.additional_info && masterclass?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {masterclass?.additional_info && masterclass?.additional_info.data[0] ? masterclass?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div> : null}
        </div>
    );
};

export default ResumeMasterClass;
