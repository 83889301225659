
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_POST_USER_CARDS_START,
    APP_POST_USER_CARDS,
    APP_POST_USER_CARDS_FAIL,
    APP_GET_USER_PROFESSION_CARDS_START,
    APP_GET_USER_PROFESSION_CARDS,
    APP_GET_USER_PROFESSION_CARDS_FAIL,
    APP_DELETE_USER_PROFESSION_CARDS_START,
    APP_DELETE_USER_PROFESSION_CARDS,
    APP_DELETE_USER_PROFESSION_CARDS_FAIL,
    APP_GET_FAV_USER_PROFESSION_CARDS_START,
    APP_GET_FAV_USER_PROFESSION_CARDS,
    APP_GET_FAV_USER_PROFESSION_CARDS_FAIL,
    APP_POST_FAV_USER_PROFESSION_CARDS_START,
    APP_POST_FAV_USER_PROFESSION_CARDS,
    APP_POST_FAV_USER_PROFESSION_CARDS_FAIL,
    APP_GET_RANDOM_USER_PROFESSION_CARDS_START,
    APP_GET_RANDOM_USER_PROFESSION_CARDS,
    APP_GET_RANDOM_USER_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitPostCardsStart = () => ({
    type: APP_POST_USER_CARDS_START
})

export const emitPostCard = data => {
    return {
        type: APP_POST_USER_CARDS,
        data
    }
}

export const emitPostCardFailed = (data) => ({
    type: APP_POST_USER_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postCard = ({ jwt,card }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/card/add/card'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(card)
    })
}

export const startPostCard = (data) => {
    return (dispatch) => {
        dispatch(emitPostCardsStart())
        return postCard(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 201) {
                    dispatch(emitPostCard(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPostCardFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPostCardFailed(err))
            })
    }

}

//edit user card
const editCard = ({ jwt,card }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/card/skills`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(card)
    })
}

export const startEditCard = (data) => {
    return (dispatch) => {
        dispatch(emitPostCardsStart())
        return editCard(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 201) {
                    dispatch(emitPostCard(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPostCardFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPostCardFailed(err))
            })
    }

}


//get user cards

export const emitStartGetUserProfessionsCards = () => ({
    type: APP_GET_USER_PROFESSION_CARDS_START
})


export const emitGetUserProfessionCards = (data) => {
    return {
        type: APP_GET_USER_PROFESSION_CARDS,
        data
    }
}

export const emitGetProfessionCardsFailed = (data) => ({
    type: APP_GET_USER_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getUserProfessionCards = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/card/usercards'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserProfessionCards = (data) => {
    
    return (dispatch) => {
        dispatch(emitStartGetUserProfessionsCards())
        return getUserProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                // console.log(json,"startGetUserProfessionCards");
                if (json.statusCode === 200) {
                    dispatch(emitGetUserProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetProfessionCardsFailed(err))
            })
    }

}

//get user cards by user id
const getUserProfessionCardsByUserId = ({ jwt, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/card/user/cards/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserProfessionCardsByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitStartGetUserProfessionsCards())
        return getUserProfessionCardsByUserId(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetUserProfessionCardsByUserId");
                if (json.statusCode === 200) {
                    dispatch(emitGetUserProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitGetProfessionCardsFailed(err))
            })
    }

}

//get random user cards
export const emitStartRandomUserProfessionCards = () => ({
    type: APP_GET_RANDOM_USER_PROFESSION_CARDS_START
})


export const emitRandomUserProfessionCards = (data) => {
    return {
        type: APP_GET_RANDOM_USER_PROFESSION_CARDS,
        data
    }
}

export const emitRandomUserProfessionCardsFailed = (data) => ({
    type: APP_GET_RANDOM_USER_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getRandomUserProfessionCards = () => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/card/random/cards`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
}

export const startGetRandomUserProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitStartRandomUserProfessionCards())
        return getRandomUserProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetRandomUserProfessionCards");
                if (json.statusCode === 200) {
                    dispatch(emitRandomUserProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitRandomUserProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitRandomUserProfessionCardsFailed(err))
            })
    }

}

//delete user cards

export const emitStartDeleteUserProfessionsCards = () => ({
    type: APP_DELETE_USER_PROFESSION_CARDS_START
})


export const emitDeleteUserProfessionCards = (data) => {
    return {
        type: APP_DELETE_USER_PROFESSION_CARDS,
        data
    }
}

export const emitDeleteProfessionCardsFailed = (data) => ({
    type: APP_DELETE_USER_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const deleteUserProfessionCards = ({ jwt,data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/card/usercards'
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteUserProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitStartDeleteUserProfessionsCards())
        return deleteUserProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteUserProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitDeleteProfessionCardsFailed(err))
            })
    }

}

//get fav cards
export const emitStartGetFavUserProfessionsCards = () => ({
    type: APP_GET_FAV_USER_PROFESSION_CARDS_START
})


export const emitGetFavUserProfessionCards = (data) => {
    return {
        type: APP_GET_FAV_USER_PROFESSION_CARDS,
        data
    }
}

export const emitGetFavProfessionCardsFailed = (data) => ({
    type: APP_GET_FAV_USER_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getFavUserProfessionCards = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/card/favourite'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetFavUserProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitStartGetFavUserProfessionsCards())
        return getFavUserProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitGetFavUserProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetFavProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitGetFavProfessionCardsFailed(err))
            })
    }

}

//post fav cards
export const emitStartPostFavUserProfessionsCards = () => ({
    type: APP_POST_FAV_USER_PROFESSION_CARDS_START
})


export const emitPostFavUserProfessionCards = (data) => {
    return {
        type: APP_POST_FAV_USER_PROFESSION_CARDS,
        data
    }
}

export const emitPostFavProfessionCardsFailed = (data) => ({
    type: APP_POST_FAV_USER_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postFavUserProfessionCards = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/card/favourite'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPostFavUserProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitStartPostFavUserProfessionsCards())
        return postFavUserProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitPostFavUserProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPostFavProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPostFavProfessionCardsFailed(err))
            })
    }

}
