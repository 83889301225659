import { combineReducers } from 'redux';

//other reducers
import { accounts } from './account/accounts'
import { profile } from './profile'
import { introScreen } from './ui/introScreen'
import { maps } from './ui/maps'
import { fileUpload } from './fileUpload'
import { profession } from './profession/profession'
import { skill } from './profession/skills'
import { cards } from './profession/cards'
import { trainingProgramUI } from './ui/resume/training/program'
import { trainingIndividualUI } from './ui/resume/training/individual'
import { accoladesAwardUI } from './ui/resume/accolades/awards'
import { accoladesCompetitionUI } from './ui/resume/accolades/competition'
import { accoladesScholarshipUI } from './ui/resume/accolades/scholarship'
import { educationUI } from './ui/resume/education'
import { CommissionedWorkUI } from './ui/resume/commissionedWork'
import { jobTitleUI } from './ui/resume/jobTitle'
import { mediaAudioUI } from './ui/media/audio'
import { mediaVideoUI } from './ui/media/video'
import { masterClassUI } from './ui/resume/masterClass'
import { institution } from './resume/institution'
import { education } from './resume/education'
import { job } from './resume/jobTitle'
import { masterclass } from './resume/masterclass'
import { commissionedWork } from './resume/commissionedWork'
import { trainingProgram } from './resume/training/program'
import { trainingIndividual } from './resume/training/individual'
import { accoladesAwards } from './resume/accolades/award'
import { accoladesCompetitions } from './resume/accolades/competition'
import { accoladesScholarships } from './resume/accolades/scholarship'
import { mediaAudio } from './media/audio'
import { mediaVideo } from './media/video'
import { youtube } from './youtube'
import { mediaPhotoUI } from './ui/media/photos'
import { mediaPhoto } from './media/photo'
import { quotesUI } from './ui/quotes'
import { quote } from './quotes'
import { upcomingUI } from './ui/upcoming'
import { upcoming } from './upcoming'
import { linksUI } from './ui/links'
import { links } from './links'
import { profileUi } from './ui/profile'
import { mediaPhotoEdit } from './media/profileEdit/photo'
import { mediaVideoEdit } from './media/profileEdit/video'
import { mediaAudioEdit } from './media/profileEdit/audio'
import { mediaQuoteEdit } from './quotes/profileEdit/quotes'
import { upcomingEdit } from './upcoming/profileEdit/upcomings'
import { resumeEdit } from './resume/profileEdit'
import { performanceUI } from './ui/resume/performance'
import { Events } from './resume/performance/events'
import { Works } from './resume/performance/works'
import { Roles } from './resume/performance/roles'
import { search } from './search'
import { paymentsUI } from './ui/payments'
import { contracts } from './payments/contracts'
import { contractsLongPooling } from './payments/longpooling'
import { slots } from './payments/slots'
import { stripe } from './payments/stripe'
import { searchUI } from './ui/search'
import { report } from './report/report'
import { feedback } from './feedback'
import { slotsReview } from './payments/review'
import { discipline } from './profession/discipline'
import { serviceUi } from './ui/perService'
import { perService } from './payments/perService'
import { askQuestion } from './askQuestion'
import { colleagues } from './colleagues'
import { announcements } from './announcements'

export const RootReducer = combineReducers({
    accounts,
    introScreen,
    profile,
    maps,
    fileUpload,
    profession,
    skill,
    cards,
    trainingProgramUI,
    institution,
    trainingProgram,
    trainingIndividualUI,
    trainingIndividual,
    educationUI,
    jobTitleUI,
    masterClassUI,
    accoladesAwardUI,
    accoladesAwards,
    accoladesCompetitionUI,
    accoladesCompetitions,
    accoladesScholarshipUI,
    accoladesScholarships,
    education,
    job,
    masterclass,
    CommissionedWorkUI,
    commissionedWork,
    mediaAudioUI,
    mediaAudio,
    mediaVideoUI,
    mediaVideo,
    youtube,
    mediaPhotoUI,
    mediaPhoto,
    quotesUI,
    quote,
    upcomingUI,
    upcoming, 
    linksUI,
    links,
    profileUi,
    mediaPhotoEdit,
    mediaVideoEdit,
    mediaAudioEdit,
    mediaQuoteEdit,
    upcomingEdit,
    resumeEdit,
    performanceUI,
    Events,
    Works,
    Roles,
    search,
    paymentsUI,
    contracts,
    contractsLongPooling,
    slots,
    stripe,
    searchUI,
    report,
    feedback,
    slotsReview,
    discipline,
    serviceUi,
    perService,
    askQuestion,
    colleagues,
    announcements
})
