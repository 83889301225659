import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const AboutDetails = (props) => {

    return (<div>
        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={600}>
            <div className="popup__details">
                {/* <div className="popup__nameWrap"> */}
                    <h3>About</h3>
                    <div className="popup__date">{props?.text}</div>
                    <div className="clear"></div>
                {/* </div> */}
                <div className="clear"></div>
            </div>
        </Scrollbars>
    </div>)
};

export default AboutDetails;
