import { 
    APP_SAVE_NOTIFICATION_TOKEN_ID,
    APP_SAVE_SELCTED_PROFESSION_CARD_ID,
    APP_SAVE_SELCTED_PROFESSION_CATEGORY_ID,
    APP_SAVE_SELCTED_USER_PROFESSION_CATEGORY_ID,
    CLEAR_USER_PROFESSION_DATA,
    APP_SAVE_PUBLIC_USER_ID,
    CLEAR_PUBLIC,
    SHOW_LOGIN_POPUP,
    LOGIN_POPUP_TAB,
    LOGIN_STATUS,
    HIDE_LOGIN_POPUP,
    REGISTER_SUCCESS_POPUP,
    VIEW_ALL_COLLEAGUES_USERID,
    DROPDOWN_STATUS
} from '../../actions'

export const saveNotificationTokenId = (data) => ({
    type: APP_SAVE_NOTIFICATION_TOKEN_ID,
    data
})

export const saveProfessionCategoryId = (data) => ({
    type: APP_SAVE_SELCTED_PROFESSION_CATEGORY_ID,
    data
})

export const saveProfessionCardId = (data) => ({
    type: APP_SAVE_SELCTED_PROFESSION_CARD_ID,
    data
})

export const saveUserProfessionCategoryId = (data) => ({
    type: APP_SAVE_SELCTED_USER_PROFESSION_CATEGORY_ID,
    data
})

export const clearUserProfessionData = (data) => ({
    type: CLEAR_USER_PROFESSION_DATA,
    data
})

export const savePublicUserId = (data) => ({
    type: APP_SAVE_PUBLIC_USER_ID,
    data
})

export const clearPublic = (data) => ({
    type: CLEAR_PUBLIC,
    data
})

export const showLoginPopUp = (data) => ({
    type: SHOW_LOGIN_POPUP
})
export const loginPopUpTab = (data) => ({
    type: LOGIN_POPUP_TAB,
    data
})

export const hideLoginPopUp = (data) => ({
    type: HIDE_LOGIN_POPUP
})

export const changeLoginStatus = (data) => ({
    type: LOGIN_STATUS,
    data
})

export const changeDropdownStatus = (data) => ({
    type: DROPDOWN_STATUS,
    data
})

export const changeRegisterPopUp = (data) => ({
    type: REGISTER_SUCCESS_POPUP,
    data
})

export const setViewAllColleaguesUserId = (data) => ({
    type: VIEW_ALL_COLLEAGUES_USERID,
    data
})
