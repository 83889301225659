import {
    APP_GET_FEATURED_MEDIA_AUDIO_START,
    APP_GET_FEATURED_MEDIA_AUDIO,
    APP_GET_FEATURED_MEDIA_AUDIO_FAIL,
    APP_PUT_FEATURED_MEDIA_AUDIO_START,
    APP_PUT_FEATURED_MEDIA_AUDIO,
    APP_PUT_FEATURED_MEDIA_AUDIO_FAIL,
    APP_UNASSIGN_MEDIA_AUDIO_START,
    APP_UNASSIGN_MEDIA_AUDIO,
    APP_UNASSIGN_MEDIA_AUDIO_FAIL,
    APP_GET_CARD_MEDIA_AUDIO_START,
    APP_GET_CARD_MEDIA_AUDIO,
    APP_GET_CARD_MEDIA_AUDIO_FAIL,
    APP_GET_MEDIA_AUDIO_START,
    APP_GET_MEDIA_AUDIO,
    APP_GET_MEDIA_AUDIO_FAIL,
    APP_PUT_MEDIA_AUDIO_START,
    APP_PUT_MEDIA_AUDIO,
    APP_PUT_MEDIA_AUDIO_FAIL,
    APP_DELETE_MEDIA_AUDIOS_START,
    APP_DELETE_MEDIA_AUDIOS,
    APP_DELETE_MEDIA_AUDIOS_FAIL,
    APP_ASSIGN_MEDIA_AUDIO_START,
    APP_ASSIGN_MEDIA_AUDIO,
    APP_ASSIGN_MEDIA_AUDIO_FAIL,
    APP_LOGOUT,
} from '../../../actions'

const initialState = {
    allaudios: [],
    cardaudios: [],
    featuredAudios: [],
    isLoading: false,
    isLoadingAssign: false,
    isLoadingPut: false,
    isLoadingAll: false,
    isLoadingFeatured: false,
    isLoadingPutFeatured: false,
    isDeleteLoading: false,
    isLoadingUnassign: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const mediaAudioEdit = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_FEATURED_MEDIA_AUDIO_START:
            return Object.assign({}, state, {
                isLoadingFeatured: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_FEATURED_MEDIA_AUDIO:
            return Object.assign({}, state, {
                featuredAudios: action.data ? action.data:[],
                isLoadingFeatured: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FEATURED_MEDIA_AUDIO_FAIL:
            return Object.assign({}, state, {
                isLoadingFeatured: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_FEATURED_MEDIA_AUDIO_START:
            return Object.assign({}, state, {
                isLoadingPutFeatured: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_FEATURED_MEDIA_AUDIO:
            return Object.assign({}, state, {
                isLoadingPutFeatured: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_FEATURED_MEDIA_AUDIO_FAIL:
            return Object.assign({}, state, {
                isLoadingPutFeatured: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_CARD_MEDIA_AUDIO_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_CARD_MEDIA_AUDIO:
            return Object.assign({}, state, {
                cardaudios: action.data ? action.data:[],
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_CARD_MEDIA_AUDIO_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_MEDIA_AUDIO_START:
            return Object.assign({}, state, {
                isLoadingAll: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_MEDIA_AUDIO:
            return Object.assign({}, state, {
                allaudios: action.data ? action.data:[],
                isLoadingAll: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_MEDIA_AUDIO_FAIL:
            return Object.assign({}, state, {
                isLoadingAll: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_UNASSIGN_MEDIA_AUDIO_START:
            return Object.assign({}, state, {
                isLoadingUnassign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_UNASSIGN_MEDIA_AUDIO:
            return Object.assign({}, state, {
                isLoadingUnassign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_UNASSIGN_MEDIA_AUDIO_FAIL:
            return Object.assign({}, state, {
                isLoadingUnassign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_MEDIA_AUDIO_START:
            return Object.assign({}, state, {
                isLoadingPut: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_MEDIA_AUDIO:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_MEDIA_AUDIO_FAIL:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_ASSIGN_MEDIA_AUDIO_START:
            return Object.assign({}, state, {
                isLoadingAssign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_ASSIGN_MEDIA_AUDIO:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_ASSIGN_MEDIA_AUDIO_FAIL:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_MEDIA_AUDIOS_START:
            return Object.assign({}, state, {
                isDeleteLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_MEDIA_AUDIOS:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_MEDIA_AUDIOS_FAIL:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.mediaAudioEdit.error
export const getMsg = state => state.mediaAudioEdit.message
export const getisLoading = state => state.mediaAudioEdit.isLoading
export const getisLoadingFeatured = state => state.mediaAudioEdit.isLoadingFeatured
export const getisLoadingPutFeatured = state => state.mediaAudioEdit.isLoadingPutFeatured
export const getisLoadingAll = state => state.mediaAudioEdit.isLoadingAll
export const getisLoadingUnassign = state => state.mediaAudioEdit.isLoadingUnassign
export const getisLoadingAssign = state => state.mediaAudioEdit.isLoadingAssign
export const getisDeleteLoading = state => state.mediaAudioEdit.isDeleteLoading
export const getisLoadingPut = state => state.mediaAudioEdit.isLoadingPut
export const getCardAudios = state => state.mediaAudioEdit.cardaudios
export const getFeaturedAudios = state => state.mediaAudioEdit.featuredAudios
export const getAllaudios = state => state.mediaAudioEdit.allaudios
export const getIsSuccess = state => state.mediaAudioEdit.isSuccess
export const getstatusCode = state => state.mediaAudioEdit.statusCode

