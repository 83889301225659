import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

//assets
import Header from "../../assets/layouts/header/registrationHeader";
import Spinner from "../../assets/molecules/spinner";
import Sidebar from "../../assets/layouts/sidebar";
import ShowPass from "../../../assets/images/visibility.png"

//actions
import { startRestPassword } from '../../../redux/actions/account'

//reducers
import { getJwt, getError, getisLoading, getMsg, getLoggedIn } from '../../../redux/reducers/account/accounts'

//helper
import { usePrevious } from '../../../helpers/utils'

const ResetPass = (props) => {
  const dispatch = useDispatch();

  let history = useHistory()

  const [showPassword, setShowPassword] = useState(false) 
  const [showReEnterPassword, setShowReEnterPassword] = useState(false) 
  const [password, setPassword] = useState("") 
  const [passwordError, setPasswordError] = useState("") 
  const [reEnterPassword, setReEnterPassword] = useState("")
  const [reEnterPasswordError, setReEnterPasswordError] = useState("")
  const [error, setError] = useState("")

  const jwt = useSelector(getJwt)
  const msg = useSelector(getMsg)
  const apiError = useSelector(getError)
  const isLoggedIn = useSelector(getLoggedIn)
  const isLoading = useSelector(getisLoading)
  const isLoadingPrev = usePrevious(isLoading)

  useEffect(() => {
    if (!isLoading && isLoading !== isLoadingPrev && isLoadingPrev !== undefined && !apiError) {
      history.push({
        pathname: '/'
      })
    }
    else if (!isLoading && isLoading !== isLoadingPrev && isLoadingPrev !== undefined && apiError) {
      setError(msg && msg?.length ? msg === "password can't be same as before" ? "Please choose a password you have not used before." : msg : "Something went wrong, please try again later")
    }
  }, [isLoading, isLoadingPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (e) =>{
    e.preventDefault()
    if (password && reEnterPassword){
      if (password === reEnterPassword){
        dispatch(startRestPassword({ jwt, password }))
      }
      else{
        setError("Passwords do not match")
      }
    }
    else if (!password){
      setPasswordError("Enter a password")
    }
    else if (!reEnterPassword){
      setReEnterPasswordError("Re-Enter the password")
    }
  }

  return (
    !jwt || !isLoggedIn ? <Redirect
      to={{
        pathname: '/',
      }}
    /> : 
    <div className="dashboard">
      <Sidebar />
      <div className="main__dashboard forgotPass__wrapper">
          <Header className="forgotPass"></Header>
        <button class="button button--back forgotPass__back"></button>
        <div className="forgotPass__container">
          <h2>
            New Password <div className="subHead__dot"></div>
          </h2>
          <form className="resetPass__form" onSubmit={(e)=>onSubmit(e)}>
            <div className="form-group password__wrap">
              <label>New Password</label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Enter New Password"
                value={password}
                onChange={(e)=>{
                  setPasswordError("")
                  setReEnterPasswordError("")
                  setError("")
                  setPassword(e.target.value)
                }}
              />
              <button type="button" className={"inputIcon--password"} onClick={() => setShowPassword(!showPassword)}><img alt="show Password" src={ShowPass}/></button>
              {passwordError && <p className="error">{passwordError}</p>}
            </div>
            <div className="form-group password__wrap">
              <label>Re-enter New Password</label>
              <input
                type={showReEnterPassword ? "text" : "password"}
                className="form-control"
                placeholder="Re-enter New Password"
                value={reEnterPassword}
                onChange={(e) => {
                  setPasswordError("")
                  setReEnterPasswordError("")
                  setError("")
                  setReEnterPassword(e.target.value)
                }}
              />
              <button type="button" className={"inputIcon--password"} onClick={() => setShowReEnterPassword(!showReEnterPassword)}><img alt="show Password" src={ShowPass}/></button>
              {reEnterPasswordError && <p className="error">{reEnterPasswordError}</p>}
            </div>
            {error && <p className="error">{error}</p>}
            {isLoading ? <Spinner/> : <button type="submit" className="button save button--primary">
              SUBMIT
            </button>}
          </form>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  );
};

export default ResetPass;
