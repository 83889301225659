import React, { useEffect, useState} from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

//reducers
import { getSearchResults, getisLoadingSearchResults } from '../../../redux/reducers/search'
import { getSearchText } from '../../../redux/reducers/ui/search'

//assets
import { ViewAllCarousel } from '../../assets/layouts/carousel/viewAllCarousel'
import { ViewAll } from '../../assets/layouts/viewAll'
import Arrow from "../../../assets/images/arrowVilot.svg";
import Spinner from "../../assets/molecules/spinner";
import { SearchEmpty } from '../../assets/layouts/empty/searchEmpty'

const SearchTab = (props) => {
  let history = useHistory()

  const [professions,setProfessions] = useState([])
  const [peoples,setPeoples] = useState([])
  const [key, setKey] = useState(props?.tab ? props?.tab : 'all');

  const searchResults = useSelector(getSearchResults)
  const isLoadingSearchResults = useSelector(getisLoadingSearchResults)
  const searchText = useSelector(getSearchText)

  useEffect(() => {
    if (searchResults) {
      setProfessions(searchResults?.filter(item=>item?.type==="profession")?.map((item)=>{
        return {
          url: item?.image,
          title: item?.name,
          description: "",
          id: item?.id,
          imp_skills: item?.imp_skills
        }
      }))
      setPeoples(searchResults?.filter(item=>item?.type==="name")?.map((item)=>{
        return {
          url: item?.url,
          title: item?.firstName + " " + item?.lastName,
          description: "",
          id: item?.id,
          navigation_name: item?.navigation_name
        }
      }))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchResults) {
      setProfessions(searchResults?.filter(item=>item?.type==="profession")?.map((item)=>{
        return {
          url: item?.image,
          title: item?.name,
          description: "",
          id: item?.id,
          imp_skills: item?.imp_skills
        }
      }))
      setPeoples(searchResults?.filter(item => item?.type === "name")?.map((item)=>{
        return {
          url: item?.url,
          title: item?.firstName + " " + item?.lastName,
          description: "",
          id: item?.id,
          navigation_name: item?.navigation_name
        }
      }))
    }
  }, [searchResults])

  return (
    !searchText ? <Redirect
      to={{
        pathname: '/',
      }}
    /> :isLoadingSearchResults ? <Spinner/> : <div className="container">
      <Tabs className="searchTab" defaultActiveKey="all" activeKey={key}
        onSelect={(k) => setKey(k)}>
        <Tab eventKey="all" title="ALL">
            {(professions && professions?.length) || (peoples && peoples?.length) ?
              <div className="tab__body">
                  {professions && professions?.length ? <div>
                  <ViewAllCarousel header="Professions" data={professions?.slice(0, 15)} onClickViewAll={() => setKey("professions")} onClick={(profession) => history.push({
                    pathname: `/search/template/${profession?.id}`,
                    state: { profession }
                  })}/>
                  <button className="viewAllButton people__viewAll" onClick={() => setKey("professions")} >VIEW ALL PROFESSIONS <img src={Arrow} alt="arrow" /></button>
                    </div> : <div/>}
                  {peoples && peoples?.length ? <div>
                  <ViewAllCarousel header="People" data={peoples?.slice(0, 15)} onClickViewAll={() => setKey("people")} onClick={(people) => history.push({
                    pathname: `/search/people/${people?.navigation_name}`,
                  })}/>
                  <button className="viewAllButton people__viewAll" onClick={() => setKey("people")}>VIEW ALL PEOPLE <img src={Arrow} alt="arrow" /></button>
                    </div> : <div/>}
                <div className="clear"></div>
              </div>:
              <SearchEmpty />}
        </Tab>
        <Tab eventKey="professions" title="PROFESSIONS">
            {professions && professions?.length ? <div className="tab__body">
              <ViewAll header="" data={professions} onClick={(profession) => history.push({
                pathname: `/search/template/${profession?.id}`,
                state: { profession }
              })}/>
            <div className="clear"></div>
            </div> : <SearchEmpty />}
        </Tab>
        <Tab eventKey="people" title="PEOPLE">
            {peoples && peoples?.length ? <div className="tab__body">
              <ViewAll header="" data={peoples} onClick={(people) => history.push({
                pathname: `/search/people/${people?.navigation_name}`,
              })}/> 
            <div className="clear"></div>
            </div> : <SearchEmpty />}
        </Tab>
      </Tabs>
      <div className="clear"></div>
    </div>
  );
};

export default SearchTab;
