import {
    SAVE_ACCOLADES_AWARD_TITLE,
    SAVE_ACCOLADES_AWARD_DATE,
    SAVE_ACCOLADES_AWARD_INSTITUTION_ID,
    SAVE_ACCOLADES_AWARD_INSTITUTION_LOCATION,
    SAVE_ACCOLADES_AWARD_AFFILIATE_INSTITUTION_ID,
    CLEAR_ACCOLADES_AWARD,
    APP_LOGOUT
} from '../../../../../actions'

const initialState = {
    accoladesAwardTitle:"",
    accoladesAwardDate:{},
    accoladesAwardInstitutionId:[],
    accoladesAwardInstitutionLocation:{},
    accoladesAwardAffiliateInstitutionId:null,
}

export const accoladesAwardUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ACCOLADES_AWARD_TITLE:
            return Object.assign({}, state, {
                accoladesAwardTitle: action.data
            })
        case SAVE_ACCOLADES_AWARD_DATE:
            return Object.assign({}, state, {
                accoladesAwardDate: action.data
            })
        case SAVE_ACCOLADES_AWARD_INSTITUTION_ID:
            return Object.assign({}, state, {
                accoladesAwardInstitutionId: action.data
            })
        case SAVE_ACCOLADES_AWARD_INSTITUTION_LOCATION:
            return Object.assign({}, state, {
                accoladesAwardInstitutionLocation: action.data
            })
        case SAVE_ACCOLADES_AWARD_AFFILIATE_INSTITUTION_ID:
            return Object.assign({}, state, {
                accoladesAwardAffiliateInstitutionId: action.data
            })
        case CLEAR_ACCOLADES_AWARD:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getAccoladesAwardTitle = state => state.accoladesAwardUI.accoladesAwardTitle
export const getAccoladesAwardDate = state => state.accoladesAwardUI.accoladesAwardDate
export const getAccoladesAwardInstitutionId = state => state.accoladesAwardUI.accoladesAwardInstitutionId
export const getAccoladesAwardInstitutionLocation = state => state.accoladesAwardUI.accoladesAwardInstitutionLocation
export const getAccoladesAwardAffiliateInstitutionId = state => state.accoladesAwardUI.accoladesAwardAffiliateInstitutionId