import React,{useRef} from "react";
import PeopleCard from "../cards/peopleCard";
import Slider from "react-slick";

// assets
import LeftArrow from '../../../../assets/images/arrowLeft.svg'
import RightArrow from '../../../../assets/images/arrowRight.svg'

export const NormalCarousel = (props) => {
    let SliderRef = useRef(null)
    let settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 3,
        variableWidth: true,
        draggable:true,
        arrows:false
    };

    const renderCarousel = () => {
        return props?.data?.map((item, index) => {
            return <PeopleCard key={index} img={item?.url} title={item?.title} text={item?.description} onClick={() => props?.onClick(item)} />
        })
    }

    const next = () => {
        SliderRef.current.slickNext();
    }
    const previous = () => {
        SliderRef.current.slickPrev();
    }

    return (
        <div className={"professional__group " + props?.className}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <h2>{props?.header}</h2>
                {props?.data?.length > 4 ? <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div className="cursor--pointer arrow__leftMar" onClick={previous}>
                        <img alt="Left arrow" src={LeftArrow} />
                    </div>
                    <div className="cursor--pointer" onClick={next}>
                        <img alt="Right arrow" src={RightArrow} />
                    </div>
                </div>:null}
            </div>
            <Slider {...settings} ref={SliderRef}>
                {renderCarousel()}
            </Slider>
            <div className="clear"></div>
        </div>
    )
}