import React,{useEffect} from 'react'
import { Provider } from 'react-redux'
import configureStore from '../redux/configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import ReactGA from 'react-ga';

import Router from './router/index.js'

let { store, persistor } = configureStore()
function App() {

  useEffect(()=>{
    ReactGA.initialize(process.env.REACT_APP_GA);
  },[])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  )
}

export default App