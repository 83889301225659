import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";

//assets
import Spinner from "../../assets/molecules/spinner";
import AudioThumb from "../../../assets/images/audioDetail.png";
import AudioIcons from "../../../assets/images/linkBg.png";
import { OpenLink } from '../../../helpers/utils'

//actions
import { startGetMediaAudio } from "../../../redux/actions/media/audio";

//reducers
import { getisLoading, getAudio } from "../../../redux/reducers/media/audio";
import { getJwt } from "../../../redux/reducers/account/accounts";

const AudioDetails = (props) => {
  const dispatch = useDispatch();

  const audio = useSelector(getAudio);
  const isLoadingJob = useSelector(getisLoading);
  const jwt = useSelector(getJwt);

  useEffect(() => {
    dispatch(startGetMediaAudio({ jwt: jwt ? jwt : "", ids: [props?.id] }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoadingJob ? (
    <Spinner />
  ) : (
    <div>
      {audio[0]?.thumbnail ? (
          <div className="audioThumb" onClick={() => OpenLink(audio[0]?.url)}>
          <img alt="audio thumb" src={audio[0]?.thumbnail} />
        <div className="audioThumb__icons">
          <div>
          <img  alt="thumb icon" className="audioThumb__icon" src={AudioIcons} />
          <div className="audioThumb__link">Click to open link</div>
        </div>
          <div className="clear"></div>
        </div>
          <div className="clear"></div>
        </div>
      ) : (
        <div className="audioThumb" onClick={() => OpenLink(audio[0]?.url)}>
          <img  alt="thumb" src={AudioThumb} />
        <div className="audioThumb__icons">
          <div>
          <img className="audioThumb__icon" alt="icon" src={AudioIcons} />
          <div className="audioThumb__link">Click to open link</div>
        </div>
          <div className="clear"></div>
        </div>
          <div className="clear"></div>
        </div>
      )}
      <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={350}>
        <div className="popup__details">
          <div className="popup__nameWrap">
            <h3>{audio[0]?.title}</h3>
            <div className="popup__date">{audio[0]?.year}</div>
            <div className="clear"></div>
          </div>
          {audio[0]?.artists ? (
            <div className="popup__data">
              <div className="popup__label">Artists &amp; Collaborators:</div>
              <div className="popup__desc">{audio[0]?.artists}</div>
              <div className="clear"></div>
            </div>
          ) : null}
          {audio[0]?.creators ? (
            <div className="popup__data">
              <div className="popup__label">Creators:</div>
              <div className="popup__desc">{audio[0]?.creators}</div>
              <div className="clear"></div>
            </div>
          ) : null}
          {audio[0]?.showname ? (
            <div className="popup__data">
              <div className="popup__label">Show / Work:</div>
              <div className="popup__desc">{audio[0]?.showname}</div>
              <div className="clear"></div>
            </div>
          ) : null}
          {audio[0]?.institutionName ? (
            <div className="popup__data">
              <div className="popup__label">Institution:</div>
              <div className="popup__desc">{audio[0]?.institutionName}</div>
              <div className="clear"></div>
            </div>
          ) : null}
          {audio[0]?.description ? (
            <div className="popup__data">
              <div className="popup__label">Description:</div>
              <div className="popup__desc">{audio[0]?.description}</div>
              <div className="clear"></div>
            </div>
          ) : null}
            {audio[0]?.url ? <div className="popup__data">
              <div className="popup__label">URL:</div>
              <div className="popup__desc">{audio[0]?.url}</div>
              <div className="clear"></div>
            </div> : null}
          <div className="clear"></div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default AudioDetails;
