import React from "react";

const PictureDetails = (props) => {

    return (<div>
            <div className="popup__details popup__imgWithoutFixed">
                <img alt="utl icon" src={props?.url}/>
            </div>
    </div>)
};

export default PictureDetails;
