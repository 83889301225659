import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_CREATE_JOB_START,
    APP_CREATE_JOB,
    APP_CREATE_JOB_FAIL,
    APP_CREATE_JOB_ADDITIONAL_INFO_START,
    APP_CREATE_JOB_ADDITIONAL_INFO,
    APP_CREATE_JOB_ADDITIONAL_INFO_FAIL,
    APP_CREATE_JOB_TO_PROFESSION_CARDS_START,
    APP_CREATE_JOB_TO_PROFESSION_CARDS,
    APP_CREATE_JOB_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../actions'


//create job

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitJobStart = () => ({
    type: APP_CREATE_JOB_START
})

export const emitJob = data => {
    return {
        type: APP_CREATE_JOB,
        data
    }
}

export const emitJobFailed = (data) => ({
    type: APP_CREATE_JOB_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postJob = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/jobtitles/jobtitle'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createJob = (data) => {
    return (dispatch) => {
        dispatch(emitJobStart())
        return postJob(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitJob(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobFailed(err))
            })
    }

}

// add additional info to job

export const emitJobAdditionalInfoStart = () => ({
    type: APP_CREATE_JOB_ADDITIONAL_INFO_START
})

export const emitJobAdditionalInfo = data => {
    return {
        type: APP_CREATE_JOB_ADDITIONAL_INFO,
        data
    }
}

export const emitJobAdditionalInfoFailed = (data) => ({
    type: APP_CREATE_JOB_ADDITIONAL_INFO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postJobAdditionalInfo = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/jobtitle/information/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createJobAdditionalInfo = (data) => {
    return (dispatch) => {
        dispatch(emitJobAdditionalInfoStart())
        return postJobAdditionalInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitJobAdditionalInfo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobAdditionalInfoFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobAdditionalInfoFailed(err))
            })
    }
}

// get job

const getJob = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/jobtitle/${id}`;
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchJob = (data) => {
    return (dispatch) => {
        dispatch(emitJobStart())
        return getJob(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitJob(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobFailed(err))
            })
    }

}

// add job to professions Cards


export const emitJobToProfessionCardsStart = () => ({
    type: APP_CREATE_JOB_TO_PROFESSION_CARDS_START
})

export const emitJobToProfessionCards = data => {
    return {
        type: APP_CREATE_JOB_TO_PROFESSION_CARDS,
        data
    }
}

export const emitJobToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_JOB_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postJobToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/jobtitles/jobtitle/professioncard'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignJobToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitJobToProfessionCardsStart())
        return postJobToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitJobToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobToProfessionCardsFailed(err))
            })
    }
}

// update job

const putJob = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/jobtitle/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateJob = (data) => {
    return (dispatch) => {
        dispatch(emitJobStart())
        return putJob(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitJob(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobFailed(err))
            })
    }

}

// get jobTitle resume by card id

export const emitJobTitleByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitJobTitleByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitJobTitleByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getJobTitleByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/jobtitle/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetJobTitleByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitJobTitleByCardIdStart())
        return getJobTitleByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitJobTitleByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobTitleByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobTitleByCardIdFailed(err))
            })
    }
}

// get jobTitle resume by card id by user id
const getJobTitleByCardIdByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/jobtitle/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetJobTitleByCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitJobTitleByCardIdStart())
        return getJobTitleByCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitJobTitleByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobTitleByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobTitleByCardIdFailed(err))
            })
    }
}

//get user job title - no card
const getUserJobTitle = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/user/jobtitle`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserJobTitle = (data) => {
    return (dispatch) => {
        dispatch(emitJobTitleByCardIdStart())
        return getUserJobTitle(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitJobTitleByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobTitleByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobTitleByCardIdFailed(err))
            })
    }
}

//get user job title - no card by user id
const getUserJobTitleByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/users/jobtitle/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserJobTitleByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitJobTitleByCardIdStart())
        return getUserJobTitleByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitJobTitleByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitJobTitleByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitJobTitleByCardIdFailed(err))
            })
    }
}

// delete jobTitle by id

export const emitDeleteJobTitleStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteJobTitle = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteJobTitleFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteJobTitle = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/delete/jobtitles`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteJobTitle = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteJobTitleStart())
        return deleteJobTitle(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteJobTitle(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteJobTitleFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteJobTitleFailed(err))
            })
    }
}


// assign jobTitle to card

export const emitAssignJobTitleStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignJobTitle = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignJobTitleFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignJobTitle = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/jobtitles/reassign/professioncard/jobtitles`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignJobTitle = (data) => {
    return (dispatch) => {
        dispatch(emitAssignJobTitleStart())
        return assignJobTitle(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignJobTitle(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignJobTitleFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignJobTitleFailed(err))
            })
    }
}