import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'

//assets
// import Notification from "../../../../assets/images/notification.png";
import ProfileIcon from "../../../../assets/images/profileIcon.svg";
import PopupLogout from "../popup/popupLogout";

//reducers
import { getFirstName, getLastName, getJwt, getisProfileCreated, getverified } from '../../../../redux/reducers/account/accounts'
import { getProfileImage } from '../../../../redux/reducers/profile'

//actions
import { startLogout } from '../../../../redux/actions/account'

const DashBoardHead = (props) => {
  const dispatch = useDispatch()

  const [PopClassName, setPopClassName] = useState("hidePop");

  const firstName = useSelector(getFirstName)
  const lastName = useSelector(getLastName)
  const jwt = useSelector(getJwt)
  const isProfileCreated = useSelector(getisProfileCreated)
  const isverified = useSelector(getverified)
  const profileImage = useSelector(getProfileImage)

  const onLogOut = () => {
    dispatch(startLogout({ jwt }))
  }

  const openPopup = () => {
    setPopClassName("showPop");
  };
  const closePopup = () => {
    setPopClassName("hidePop");
  };

  return (
    <React.Fragment>
      <div className={"header--welcome " + props.className}>
        <div className="container">
          <header>
            <Navbar collapseOnSelect expand="lg">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                {!props?.forgotPass ? <Nav className="navItems">
                  {/* <img
                    className="notification"
                    src={Notification}
                    alt="Notification"
                  /> */}
                  {isProfileCreated && isverified ? <img
                    className="profileImage"
                    src={profileImage ? profileImage : ProfileIcon}
                    alt="ProfileIcon"
                  />:null}
                  <NavDropdown title={firstName + " " + lastName}>
                    <NavDropdown.Item className="logout" onClick={() => openPopup()}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>:null}
              </Navbar.Collapse>
            </Navbar>
          </header>
          {props.children}
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <PopupLogout
        className={PopClassName}
        close={() => closePopup()}
        onClick={() => onLogOut()}
      />
    </React.Fragment>
  );
};

export default DashBoardHead;
