import React from "react";
import PeopleCard from "../cards/peopleCard";

export const ViewAll = (props) => {
    const renderCarousel = () => {
        return <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'row wrap', justifyContent: 'flex-start'}}>
            {props?.data?.map((item, index) => {
                const image = props?.sent ? item?.user_profile_pic : props?.received ? item?.sender_profile_pic : item?.profile_pic
                const name = props?.sent ? (item?.user_firstname + " " + item?.user_lastname) : props?.received ? (item?.sender_firstname + " " + item?.sender_lastname) : (item?.firstName + " " + item?.lastName)
                return <PeopleCard key={index} img={image} title={name} text={""} onClick={() => props?.onClick(item)} onClickButton={() => props?.onClickButton(item)} type={props?.type} toastText={props?.toastText}/>
            })}
        </div>
    }

    return (
        <div className="professional__group">
            <h2>{props?.header}</h2>
                {renderCarousel()}
            <div className="clear"></div>
        </div>
    )
}