import {
    SAVE_UPCOMING_PROFESSION_CARDS,
    SAVE_UPCOMING_EVENT_TITLE,
    SAVE_UPCOMING_EVENT_DATE,
    SAVE_UPCOMING_ROLE,
    SAVE_UPCOMING_LINK,
    SAVE_UPCOMING_DESCRIPTION,
    SAVE_UPCOMING_THUMBNAIL,
    CLEAR_UPCOMING,
    APP_LOGOUT
} from '../../../actions'

const initialState = {
    professionCardIds: [],
    title:"",
    date:"",
    role:"",
    link:"",
    description:"",
    thumbnail:""
}

export const upcomingUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_UPCOMING_PROFESSION_CARDS:
            return Object.assign({}, state, {
                professionCardIds: action.data
            })
        case SAVE_UPCOMING_EVENT_TITLE:
            return Object.assign({}, state, {
                title: action.data
            })
        case SAVE_UPCOMING_EVENT_DATE:
            return Object.assign({}, state, {
                date: action.data
            })
        case SAVE_UPCOMING_ROLE:
            return Object.assign({}, state, {
                role: action.data
            })
        case SAVE_UPCOMING_LINK:
            return Object.assign({}, state, {
                link: action.data
            })
        case SAVE_UPCOMING_DESCRIPTION:
            return Object.assign({}, state, {
                description: action.data
            })
        case SAVE_UPCOMING_THUMBNAIL:
            return Object.assign({}, state, {
                thumbnail: action.data
            })
        case CLEAR_UPCOMING:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getProfessionCardIds = state => state.upcomingUI.professionCardIds
export const getTitle = state => state.upcomingUI.title
export const getDate = state => state.upcomingUI.date
export const getRole = state => state.upcomingUI.role
export const getLink= state => state.upcomingUI.link
export const getDescription = state => state.upcomingUI.description
export const getThumbnail = state => state.upcomingUI.thumbnail
