import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'

//assets
import Header from "../../assets/layouts/header/registrationHeader";
import Spinner from "../../assets/molecules/spinner";
import Sidebar from "../../assets/layouts/sidebar";

//actions
import { startResetPasswordOtp } from '../../../redux/actions/account'

//reducers
import { getError, getisLoading} from '../../../redux/reducers/account/accounts'

//helper
import { usePrevious } from '../../../helpers/utils'

const ForgotPassword = (props) => {
  const dispatch = useDispatch();

  const [email,setEmail] =useState("")
  const [error,setError] =useState("")

  const apiError = useSelector(getError)
  const isLoading = useSelector(getisLoading)
  const isLoadingPrev = usePrevious(isLoading)

  let history = useHistory()

  useEffect(()=>{
    if (!isLoading && isLoading !== isLoadingPrev && isLoadingPrev !== undefined && !apiError){
      history.push({
        pathname: '/forgot-password/verify',
        state:{email:email}
      })
    }
    else if (!isLoading && isLoading !== isLoadingPrev && isLoadingPrev !== undefined && apiError ){
      setError("Something went wrong, please try again later")
    }
  }, [isLoading, isLoadingPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSendEmail = (e) =>{
    e.preventDefault()
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    if (email){
      if (reg.test(email) === false) {
        setError("Enter a valid email ID")
      } else {
        dispatch(startResetPasswordOtp({ email }))
      }
    }
    else{
      setError("Enter an email ID")
    }
  }

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="main__dashboard forgotPass__wrapper">
        <Header className="forgotPass" forgotPass></Header>
        <button class="button button--back forgotPass__back" onClick={() => history.goBack()}></button>
        <div className="forgotPass__container">
          <h2>
            Reset Your Password <div className="subHead__dot"></div>
          </h2>
          <p>Enter the email associated with your Resonance account</p>
          <form className="resetPass__form" onSubmit={(e) => onSendEmail(e)}>
            <div class="form-group">
              <label>Email</label>
              <input
                className="email form-control inputIcon"
                placeholder="Enter Email Address"
                type="text"
                value={email}
                onChange={(e) => { setError("");setEmail(e.target.value)}}
              />
            </div>
            {error && <p className="error">{error}</p>}
            {isLoading ? <Spinner/> : <button type="submit" className="button save button--primary">
              SEND SECURITY CODE
            </button>}
          </form>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  );
};

export default ForgotPassword;
