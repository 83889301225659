import React,{useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//action
import { startLogin } from '../../../redux/actions/account'
import { hideLoginPopUp } from '../../../redux/actions/ui/profile'

//reducers
import { getisLoading, getError} from '../../../redux/reducers/account/accounts'

//assets
import Spinner from "../../assets/molecules/spinner";
import ShowPass from "../../../assets/images/visibility.png"

//helpers
import { usePrevious } from '../../../helpers/utils'

const Login = () => {
  let history = useHistory()

  const [email,setEmail] = useState("") 
  const [password,setPassword] = useState("") 
  const [emailError, setEmailError] = useState("") 
  const [passwordError, setPasswordError] = useState("") 
  const [error, setError] = useState("") 
  const [showPassword, setShowPassword] = useState(false) 

  const dispatch = useDispatch()

  const isLoadingLogin = useSelector(getisLoading)
  const isLoadingLoginPrev = usePrevious(isLoadingLogin)
  const errorLogin = useSelector(getError)

  useEffect(()=>{
    if (!isLoadingLogin && errorLogin && isLoadingLoginPrev !== isLoadingLogin && isLoadingLoginPrev !== undefined){
      setError("Invalid Credentials")
    }
  }, [isLoadingLogin, errorLogin, isLoadingLoginPrev])

  const onLogin = (event) =>{
    event.preventDefault();
    if (email && password){
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
      if (reg.test(email) === false) {
        setEmailError("Invalid email address")
      }
      else{
        const data = {
          "email": email,
          "password": password
        }
        dispatch(startLogin(data))
      }
    }
    else{
      if(!email){
        setEmailError("Enter an email address")
      }
      if(!password){
        setPasswordError("Enter a password")
      }
    }
  }

  return (isLoadingLogin ? <Spinner/> :
    <form onSubmit={(e) => onLogin(e)}>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control email inputIcon"
          placeholder="Enter email address"
          value={email}
          onChange={(e) => {
            setEmailError("")
            setPasswordError("")
            setError("")
            setEmail(e.target.value)}
          }
        />
        {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="form-group password__wrap">
        <label>Password</label>
        <input
          type={showPassword ? "text" : "password"}
          className="form-control"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => {
            setEmailError("")
            setPasswordError("")
            setError("")
            setPassword(e.target.value)}
          }
        />
        <div className="forgotPassBtn addPointer" onClick={() => {
          dispatch(hideLoginPopUp())
          history.push({
          pathname: '/forgot-password/email'
        })}}>FORGOT PASSWORD</div>
        <button type="button" className={"inputIcon--password"} onClick={() => setShowPassword(!showPassword)}><img alt="show Password" src={ShowPass}/></button>
        {passwordError && <p className="error">{passwordError}</p>}
        {error && <p className="error">{error}</p>}
      </div>
      <button type="submit" className={email && password ? "button button--primary width--100 mar--t25" : "button button--disabled width--100 mar--t25"} >
        LOGIN
      </button>
    </form>
  );
};

export default Login;
