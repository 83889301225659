import {
    APP_CREATE_ACCOLADES_AWARD_START,
    APP_CREATE_ACCOLADES_AWARD,
    APP_CREATE_ACCOLADES_AWARD_FAIL,
    APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_START,
    APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO,
    APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_FAIL,
    APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_START,
    APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS,
    APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../../../actions'

import { createSelector } from 'reselect'

const initialState = {
    isLoading: false,
    isLoadingAdditionalInfo: false,
    isLoadingAccoladesAwardsToProfessionCards: false,
    error: false,
    message: '',
    isSuccess: false,
    award: {},
    statusCode: 201
}


export const accoladesAwards = (state = initialState, action) => {
    switch (action.type) {
        case APP_CREATE_ACCOLADES_AWARD_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_ACCOLADES_AWARD:
            return Object.assign({}, state, {
                award: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_ACCOLADES_AWARD_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_START:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                award: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_FAIL:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingAccoladesAwardsToProfessionCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                isLoadingAccoladesAwardsToProfessionCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingAccoladesAwardsToProfessionCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.accoladesAwards.error
export const getMsg = state => state.accoladesAwards.message
export const getisLoading = state => state.accoladesAwards.isLoading
export const getIsSuccess = state => state.accoladesAwards.isSuccess
export const getstatusCode = state => state.accoladesAwards.statusCode
export const getAward = state => state.accoladesAwards.award
export const getisLoadingAdditionalInfo = state => state.accoladesAwards.isLoadingAdditionalInfo
export const getisLoadingAccoladesAwardsToProfessionCards = state => state.accoladesAwards.isLoadingAccoladesAwardsToProfessionCards

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
