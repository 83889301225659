import {
    SAVE_TRAINING_PROGRAM_NAME,
    SAVE_TRAINING_PROGRAM_TYPE,
    SAVE_TRAINING_PROGRAM_LOCATION,
    SAVE_TRAINING_PROGRAM_INSTITUTION_INFO,
    SAVE_TRAINING_PROGRAM_COLLEAGUES,
    CLEAR_TRAINING_PROGRAM_COLLEAGUES,
    CLEAR_TRAINING_PROGRAM,
    APP_LOGOUT
} from '../../../../actions'

const initialState = {
    programName:"",
    programType:"",
    programLocation:{},
    programInstitution:[],
    programStudyPosition:"",
    programStudyDateToMonth:"",
    programStudyDateToYear:"",
    programStudyDateFromMonth:"",
    programStudyDateFromYear:"",
    programSubProgram:"",
    colleagues:[]
}

export const trainingProgramUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_TRAINING_PROGRAM_NAME:
            return Object.assign({}, state, {
                programName: action.data
            })
        case SAVE_TRAINING_PROGRAM_TYPE:
            return Object.assign({}, state, {
                programType: action.data
            })
        case SAVE_TRAINING_PROGRAM_LOCATION:
            return Object.assign({}, state, {
                programLocation: action.data
            })
        case CLEAR_TRAINING_PROGRAM:
            return initialState
        case SAVE_TRAINING_PROGRAM_INSTITUTION_INFO:
            return Object.assign({}, state, {
                programInstitution: action.data,
            })
        case SAVE_TRAINING_PROGRAM_COLLEAGUES:
            return Object.assign({}, state, {
                colleagues: action.data,
            })
        case CLEAR_TRAINING_PROGRAM_COLLEAGUES:
            return Object.assign({}, state, {
                colleagues: [],
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getprogramName = state => state.trainingProgramUI.programName
export const getProgramType = state => state.trainingProgramUI.programType
export const getProgramLocation = state => state.trainingProgramUI.programLocation
export const getProgramInstitution = state => state.trainingProgramUI.programInstitution
export const getColleagues = state => state.trainingProgramUI.colleagues