import React from "react";

const BrowseVideoDescription = (props) => {
  return (
    <div className="browseVideoDescription">
      <div className="browseVideoDescription__title">
        <h3>{props.title}</h3>
        <div className="date__wrap">
          <p className="date">{props.date}</p>
        </div>
        <div className="clear"></div>
      </div>
      <div className="browseVideoDescription__body">
        {props.children}
        <div className="clear"></div>
      </div>

      <div className="clear"></div>
    </div>
  );
};

export default BrowseVideoDescription;
