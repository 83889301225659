import {
    APP_POST_UPCOMING_START,
    APP_POST_UPCOMING,
    APP_POST_UPCOMING_FAIL,
    APP_DELETE_UPCOMING_START,
    APP_DELETE_UPCOMING,
    APP_DELETE_UPCOMING_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    upcoming: [],
    isLoading: false,
    isDeleteLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const upcoming = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_UPCOMING_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_UPCOMING:
            return Object.assign({}, state, {
                upcoming: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_UPCOMING_FAIL:
            return Object.assign({}, state, {
                upcoming: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_UPCOMING_START:
            return Object.assign({}, state, {
                isDeleteLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_UPCOMING:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_UPCOMING_FAIL:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.upcoming.error
export const getMsg = state => state.upcoming.message
export const getisLoading = state => state.upcoming.isLoading
export const getisDeleteLoading = state => state.upcoming.isDeleteLoading
export const getUpcoming = state => state.upcoming.upcoming
export const getIsSuccess = state => state.upcoming.isSuccess
export const getstatusCode = state => state.upcoming.statusCode