import React, { useState } from "react";
import { navList, navListLoggedIn } from "../../../../helpers/enums";
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'


//reducers
import {
  getLoggedIn
} from '../../../../redux/reducers/account/accounts'

//actions
import { showLoginPopUp, loginPopUpTab } from '../../../../redux/actions/ui/profile'

import Logo from "../../../../assets/images/logo2.png";
import Popup from "../popup/popupDownload";

const LeftNavbar = (props) => {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(getLoggedIn)

  const [navState, setnavState] = useState(true);
  const [PopClassName, setPopClassName] = useState("hidePop");
  let history = useHistory()
  let location = useLocation()

  const activeFunction = ({name, to}) => {
    setnavState(true);
    if(to){
      history.push({
        pathname: to
      })
    }
    else{
      if (name === "support") {
        window.open("https://www.yourresonance.com/support", "_blank")
      }
      else if (name === "colleagues" || name === "dashboard") {
        dispatch(loginPopUpTab("login"));
        dispatch(showLoginPopUp());
      }
      else {
        setPopClassName("showPop");
      }
    }
    
  };

  const onToggleNav = () => {
    setnavState(!navState);
  };

  const leftNav = () => {
    setnavState(true);
  };

  window.addEventListener("click", function (e) {
    if (!document.getElementById("leftNav")?.contains(e.target)) {
      leftNav();
    }
  });

  const closePopup = () => {
    setPopClassName("hidePop");
  };

  return (
    <div
      id="leftNav"
      className={"left__navigation " + (navState ? "" : "collapse")}
    >
      <div className="logo">
        <img src={Logo} alt="" />
        <div className="clear"></div>
      </div>
      <div className="logo__background"></div>
      <div className="navBar">
        <div
          onClick={() => onToggleNav()}
          className={"navbar__toggle " + (navState ? "" : "navClose")}
        >
          <input type="checkbox" />
          <span></span>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>

      <div className={"menu__wrapper " + (navState ? "" : "collapse")}>
        <ul className="menu">
          {isLoggedIn ? navListLoggedIn?.map((list, index) => {
            return (
              <li
                key={index}
                className={
                  "item item__" +
                  list.name +
                  " " +
                  (location.pathname === list.pathname ? "active" : "")
                }
                onClick={() => { activeFunction(list)}}
              >
                <div>
                  {list.name}
                </div>
              </li>
            );
          }) : navList?.map((list, index) => {
            return (
              <li
                key={index}
                className={
                  "item item__" +
                  list.name +
                  " " +
                  (location.pathname === list.pathname ? "active" : "")
                }
                onClick={() => { activeFunction(list) }}
              >
                <div>
                  {list.name}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="clear"></div>
      </div>
      <Popup
        className={PopClassName}
        close={() => closePopup()}
      ></Popup>
      <div className="clear"></div>
    </div>
  );
};
export default LeftNavbar;
