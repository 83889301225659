import React from "react";

//components
import ResumeJobTitle from './jobtitle'
import ResumeAward from './award'
import ResumeCompetition from './competition'
import ResumeScholarship from './scholarship'
import ResumeIndivuduals from './individual'
import ResumeProgram from './program'
import ResumeEducation from './education'
import ResumeMasterClass from './masterclass'
import ResumeOriginalWork from './originalwork'
import ResumeEvents from './event'

const Resume = (props) => {

    const renderResumes = (type,id) =>{
        if (type === "jobtitle"){
            return <ResumeJobTitle id={id}/>
        }
        else if (type === "award"){
            return <ResumeAward id={id}/>
        }
        else if (type === "individual"){
            return <ResumeIndivuduals id={id}/>
        }
        else if (type === "program"){
            return <ResumeProgram id={id}/>
        }
        else if (type === "originalwork"){
            return <ResumeOriginalWork id={id}/>
        }
        else if (type === "education"){
            return <ResumeEducation id={id}/>
        }
        else if (type === "scholarship"){
            return <ResumeScholarship id={id}/>
        }
        else if (type === "event"){
            return <ResumeEvents id={id}/>
        }
        else if (type === "masterclass"){
            return <ResumeMasterClass id={id}/>
        }
        else if (type === "competition"){
            return <ResumeCompetition id={id}/>
        }
    }

    return (
        <div>
            {renderResumes(props?.resume?.type, props?.resume?.id)}
        </div>
    );
};

export default Resume;
