import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_CREATE_TRAINING_PROGRAM_START,
    APP_CREATE_TRAINING_PROGRAM,
    APP_CREATE_TRAINING_PROGRAM_FAIL,
    APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_START,
    APP_CREATE_TRAINING_PROGRAM_COLLEAGUES,
    APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_FAIL,
    APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_START,
    APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO,
    APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_FAIL,
    APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_START,
    APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS,
    APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../actions'


//create training program

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitTrainingProgramStart = () => ({
    type: APP_CREATE_TRAINING_PROGRAM_START
})

export const emitTrainingProgram = data => {
    return {
        type: APP_CREATE_TRAINING_PROGRAM,
        data
    }
}

export const emitTrainingProgramFailed = (data) => ({
    type: APP_CREATE_TRAINING_PROGRAM_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postTrainingProgram = ({ jwt,data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/training/program'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createTrainingProgram = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramStart())
        return postTrainingProgram(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingProgram(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramFailed(err))
            })
    }

}

// add collegues to training card

export const emitTrainingProgramColleaguesStart = () => ({
    type: APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_START
})

export const emitTrainingProgramColleagues = data => {
    return {
        type: APP_CREATE_TRAINING_PROGRAM_COLLEAGUES,
        data
    }
}

export const emitTrainingProgramColleaguesFailed = (data) => ({
    type: APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postTrainingProgramColleagues = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/training/program/new/colleagues'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createTrainingProgramColleagues = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramColleaguesStart())
        return postTrainingProgramColleagues(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingProgramColleagues(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramColleaguesFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramColleaguesFailed(err))
            })
    }

}
// add additional info to training program card

export const emitTrainingProgramAdditionalInfoStart = () => ({
    type: APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_START
})

export const emitTrainingProgramAdditionalInfo = data => {
    return {
        type: APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO,
        data
    }
}

export const emitTrainingProgramAdditionalInfoFailed = (data) => ({
    type: APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postTrainingProgramAdditionalInfo = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/training/program/new/information'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createTrainingProgramAdditionalInfo = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramAdditionalInfoStart())
        return postTrainingProgramAdditionalInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingProgramAdditionalInfo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramAdditionalInfoFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramAdditionalInfoFailed(err))
            })
    }
}

// get training program

const getTrainingProgram = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/program/${id}`;
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchTrainingProgram = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramStart())
        return getTrainingProgram(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingProgram(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramFailed(err))
            })
    }

}

// add program to professions Cards


export const emitTrainingProgramToProfessionCardsStart = () => ({
    type: APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_START
})

export const emitTrainingProgramToProfessionCards = data => {
    return {
        type: APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS,
        data
    }
}

export const emitTrainingProgramToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postTrainingProgramToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/training/program/professioncards'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignTrainingProgramToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramToProfessionCardsStart())
        return postTrainingProgramToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingProgramToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramToProfessionCardsFailed(err))
            })
    }
}

// update training program info

const putTrainingProgramInfo = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/program/programinfo/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateTrainingProgramInfo = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramStart())
        return putTrainingProgramInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingProgram(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramFailed(err))
            })
    }

}

// update training program institution info

const putTrainingProgramInsitutionInfo = ({ jwt, data ,id}) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/program/institutioninfo/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateTrainingProgramInsitutionInfo = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramStart())
        return putTrainingProgramInsitutionInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingProgram(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramFailed(err))
            })
    }

}
// update training program study info

const putTrainingProgramStudyInfo = ({ jwt, data , id}) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/program/studyinfo/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateTrainingProgramStudyInfo = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramStart())
        return putTrainingProgramStudyInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingProgram(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramFailed(err))
            })
    }

}

// update training program

const putTrainingProgram = ({ jwt, data , id}) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/program/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateTrainingProgram = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramStart())
        return putTrainingProgram(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingProgram(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramFailed(err))
            })
    }

}

// get trainingProgram resume by card id

export const emitTrainingProgramByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitTrainingProgramByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitTrainingProgramByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getTrainingProgramByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/program/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetTrainingProgramByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramByCardIdStart())
        return getTrainingProgramByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingProgramByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramByCardIdFailed(err))
            })
    }
}

// get user training program - no card
const getUserTrainingProgram = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/user/program`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserTrainingProgram = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramByCardIdStart())
        return getUserTrainingProgram(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingProgramByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramByCardIdFailed(err))
            })
    }
}


// get user training program by user id
const getUserTrainingProgramByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/users/program/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserTrainingProgramByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramByCardIdStart())
        return getUserTrainingProgramByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingProgramByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramByCardIdFailed(err))
            })
    }
}
// get user training program by card id and user id
const getUserTrainingProgramByCardByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/program/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserTrainingProgramByCardByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingProgramByCardIdStart())
        return getUserTrainingProgramByCardByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingProgramByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingProgramByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingProgramByCardIdFailed(err))
            })
    }
}

// delete trainingProgram by id

export const emitDeleteTrainingProgramStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteTrainingProgram = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteTrainingProgramFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteTrainingProgram = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/delete/programs`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteTrainingProgram = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteTrainingProgramStart())
        return deleteTrainingProgram(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteTrainingProgram(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteTrainingProgramFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteTrainingProgramFailed(err))
            })
    }
}

//assign program to cards
export const emitAssignTrainingProgramStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignTrainingProgram = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignTrainingProgramFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignTrainingProgram = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/training/reassign/professioncard/programs`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignTrainingProgram = (data) => {
    return (dispatch) => {
        dispatch(emitAssignTrainingProgramStart())
        return assignTrainingProgram(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignTrainingProgram(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteTrainingProgramFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignTrainingProgramFailed(err))
            })
    }
}

