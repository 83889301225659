import React from "react";

const PeopleListingCard = (props) => {
  return (
    <div className="listingCard">
      <div className="listingCard__wrap1">
        <div className="listingCard__img" onClick={() => props?.onClickName()}>
          <img src={props.img} alt={props.name}/>
        </div>
        <div className="listingCard__name">
          <h5 className="listingCard__peopleName" onClick={()=>props?.onClickName()}>
            {props.name} <span>&gt;</span>
          </h5>
          <h5 onClick={()=>props?.onClickName()}>
            {props.designation} <span> | {props.city}</span>
          </h5>
          <div className="customFilter__choose">
            {props?.skills && props?.skills?.map((skill,index)=>{
              return(
                <div className="peopleSkills" key={index}>
                  {skill?.name}
                </div>
              )
            })}
            <div className="clear"></div>
              </div>
        <div className="clear"></div>
          {parseInt(props.engagements) ? <div className="likeIconBg">{props.engagements} Engagements</div> :null}
        <div className="clear"></div>
        </div>
        <span className="read-more-content--searchCards">
          {props?.description?.length > (props?.skills?.length > 3 ? 200 : 300 )
            ? props?.description?.slice(0, (props?.skills?.length > 3 ? 200 : 300)) + "..."
            : props?.description}
        </span>
        {props?.description?.length > (props?.skills?.length > 3 ? 200 : 300) ? (
          <span
            className="read-more-content--searchCards--readmore"
            onClick={() => { props?.onClickName()}}
          >
            View More
          </span>
        ) : null}
        <div className="clear"></div>
      </div>

      <div className="listingCard__wrap2">
          <div>
        <h3>{props.price}</h3>
        <p>{props.for}</p>
          <button className={"button button--primary " + props.onlineInPerson} onClick={() => props?.onClickButton()}>{props.buttonText}</button>
        <p className={"online " + props.onlineInPerson}>
          {props.onlineInPersonDesc}
        </p>
        <p className={"travel " + props.travel}>Willing to travel</p>
        <div className="clear"></div>
      </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default PeopleListingCard;
