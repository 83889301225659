import React, {useState} from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

//reducers
import {
  getLoggedIn,
  getisProfileCreated,
  getverified,
} from "../../../redux/reducers/account/accounts";

//components
import UserDetails from './details'
import UserType from './type'

const UserInfo = (props) => {
  const [page, setPage] = useState(1)
  const isLoggedIn = useSelector(getLoggedIn)
  const isProfileCreated = useSelector(getisProfileCreated)
  const isVerified = useSelector(getverified)
  return (
    isLoggedIn && isVerified && !isProfileCreated ? page === 1 ? <UserType onChangePage={() => setPage(2)} /> : <UserDetails onChangePage={() => setPage(1)} /> : <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
};

export default UserInfo;
