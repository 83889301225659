import {
    SAVE_PROFESSION_FILTER_SORT,
    SAVE_PROFESSION_FILTER_PRICE,
    SAVE_PROFESSION_FILTER_AVAILABILITY,
    SAVE_PROFESSION_FILTER_ONLINE_STATUS,
    SAVE_PROFESSION_FILTER_TRAVEL_STATUS,
    SAVE_GENDER_STATUS,
    SAVE_LOCATION,
    SAVE_LOCATION_DIST_STATUS,
    SAVE_SELECTED_SEARCH_ITEM,
    SAVE_SEARCH_TEXT,
    CLEAR_PROFESSION_FILTERS
} from '../../actions'

export const clearProfessionFilters = () => ({
    type: CLEAR_PROFESSION_FILTERS
})

export const saveProfessionFiltersSort = (data) => ({
    type: SAVE_PROFESSION_FILTER_SORT,
    data
})

export const saveProfessionFiltersPrice = (data) => ({
    type: SAVE_PROFESSION_FILTER_PRICE,
    data
})

export const saveProfessionFiltersAvailability = (data) => ({
    type: SAVE_PROFESSION_FILTER_AVAILABILITY,
    data
})

export const saveProfessionFiltersOnlineStatus = (data) => ({
    type: SAVE_PROFESSION_FILTER_ONLINE_STATUS,
    data
})

export const saveProfessionFiltersTravelStatus = (data) => ({
    type: SAVE_PROFESSION_FILTER_TRAVEL_STATUS,
    data
})

export const saveGenderStatus = (data) => ({
    type: SAVE_GENDER_STATUS,
    data
})

export const saveLocationDistStatus = (data) => ({
    type: SAVE_LOCATION_DIST_STATUS,
    data
})

export const saveSearchLocation = (data) => ({
    type: SAVE_LOCATION,
    data
})

export const saveSelectedSearchItem = (data) => ({
    type: SAVE_SELECTED_SEARCH_ITEM,
    data
})

export const saveSearchText = (data) => ({
    type: SAVE_SEARCH_TEXT,
    data
})