import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { fetchJob } from '../../../redux/actions/resume/jobTitle'

//reducers
import { getisLoading, getJob} from '../../../redux/reducers/resume/jobTitle'
import { getJwt } from '../../../redux/reducers/account/accounts'

//helpers
import { monthToName } from '../../../helpers/utils'

const ResumeJobTitle = (props) => {
    const dispatch = useDispatch()

    const job = useSelector(getJob)
    const isLoadingJob = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(()=>{
        dispatch(fetchJob({ jwt: jwt ? jwt : "", id: props?.id}))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingJob ? <Spinner/> : <div>
            <div className="expDetail__secHead">Position Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Type of Job</p>
                <p className="expDetail__desc">{job.type ? job.type : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Job Title</p>
                <p className="expDetail__desc">{job.title ? job.title : null}</p>
            </div>

            {job.institutionName || job?.location?.description ? <div>
                <div className="expDetail__secHead">Associations</div>
                {job.institutionName ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Institute Name</p>
                    <p className="expDetail__desc">{job.institutionName}</p>
                </div> : null}
                {job.location ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Location</p>
                    <p className="expDetail__desc">{job.location.description}</p>
                </div> : null}
            </div>:null}

            <div className="expDetail__secHead">Involvement</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">From Month and Year</p>
                <p className="expDetail__desc">{(job.date ? monthToName[job.date.from_month] : null) + " " + (job.date ? job.date.from_year : null)}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">To Month and Year</p>
                <p className="expDetail__desc">{(job.date ? monthToName[job.date.to_month] : null) + " " + (job.date ? job.date.to_year : null)}</p>
            </div>

            {job.information || (job?.additional_info && job?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {job.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{job.information}</p>
                </div> : null}
                {job?.additional_info && job?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {job?.additional_info && job?.additional_info.data[0] ? job?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div>:null}
        </div>
    );
};

export default ResumeJobTitle;
