import React, { useRef, useState } from "react";
import VideoThubDefault from "../../../../assets/images/videoSmall.png";
import VideoThumbBig from "../../../../assets/images/videoDetail.png";
import RightArrow from "../../../../assets/images/arrowRight.svg";
import Slider from "react-slick";
import LeftArrow from "../../../../assets/images/arrowLeft.svg";
import Play from "../../../../assets/images/play.svg";

const VideoCard = (props) => {
  const VideoRef = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(props?.videos[0]);
  const videoGallery = {
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
  };
  const nextVideo = () => {
    VideoRef?.current.slickNext();
  };
  const previousVideo = () => {
    VideoRef?.current.slickPrev();
  };
  return (
    <div className="videoSlider">
      <div className="preview" onClick={() => props?.onClick(selectedVideo)}>
        <img className="videoSlider__play" src={Play} alt="play button" />
        <img
          src={
            selectedVideo?.thumbnail ? selectedVideo?.thumbnail : VideoThumbBig
          }
          alt="thumbnail"
        />
      </div>
      <div className="clear"></div>
      <Slider ref={VideoRef} className="videoGallery" {...videoGallery}>
        {props?.videos?.map((video, index) => {
          return (
            <div
              key={index}
              className="videoSlider__items"
              onClick={() => setSelectedVideo(video)}
            >
              <img
                src={video?.thumbnail ? video?.thumbnail : VideoThubDefault}
                alt="video thumb"
              />
              <p
                className={
                  "videoSlider__items__text " +
                  (selectedVideo?.id === video?.id
                    ? "videoSlider__items__text--active"
                    : "")
                }
              >
                {video?.title}
              </p>
            </div>
          );
        })}
      </Slider>
      <div onClick={previousVideo} className="arrow__left">
        <img src={LeftArrow} alt="left arrow" />
      </div>
      <div onClick={nextVideo} className="arrow__right">
        <img src={RightArrow} alt="Right arrow" />
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default VideoCard;
