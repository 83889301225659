
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_GET_ANNOUNCEMEMTS_START,
    APP_GET_ANNOUNCEMEMTS,
    APP_GET_ANNOUNCEMEMTS_FAIL,
    APP_LOGOUT,
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

//get request status between two users
export const emitAnnouncementsStart = () => ({
    type: APP_GET_ANNOUNCEMEMTS_START
})

export const emitAnnouncements = data => {
    return {
        type: APP_GET_ANNOUNCEMEMTS,
        data
    }
}

export const emitAnnouncementsFailed = (data) => ({
    type: APP_GET_ANNOUNCEMEMTS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getAnnouncements = () => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/announcements/announcement`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
}

export const startGetAnnouncements = () => {
    return (dispatch) => {
        dispatch(emitAnnouncementsStart())
        return getAnnouncements()
            .then(parseJSON)
            .then(json => {
                console.log(json, "startGetAnnouncements");
                if (json.statusCode === 200) {
                    dispatch(emitAnnouncements(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAnnouncementsFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startGetRequestStatus err");
                dispatch(emitAnnouncementsFailed(err))
            })
    }

}