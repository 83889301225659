
import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_UNASSIGN_UPCOMING_START,
    APP_UNASSIGN_UPCOMING,
    APP_UNASSIGN_UPCOMING_FAIL,
    APP_GET_CARD_UPCOMING_START,
    APP_GET_CARD_UPCOMING,
    APP_GET_CARD_UPCOMING_FAIL,
    APP_GET_UPCOMING_START,
    APP_GET_UPCOMING,
    APP_GET_UPCOMING_FAIL,
    APP_PUT_UPCOMING_START,
    APP_PUT_UPCOMING,
    APP_PUT_UPCOMING_FAIL,
    APP_ASSIGN_UPCOMING_START,
    APP_ASSIGN_UPCOMING,
    APP_ASSIGN_UPCOMING_FAIL,
    APP_LOGOUT,
} from '../../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

// get all upcoming by card
export const emitCardUpcomingStart = () => ({
    type: APP_GET_CARD_UPCOMING_START
})

export const emitCardUpcoming = data => {
    return {
        type: APP_GET_CARD_UPCOMING,
        data
    }
}

export const emitCardUpcomingFailed = (data) => ({
    type: APP_GET_CARD_UPCOMING_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getCardUpcomings = ({ jwt, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/upcomings/upcomings/${id}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCardUpcomings = (data) => {
    return (dispatch) => {
        dispatch(emitCardUpcomingStart())
        return getCardUpcomings(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCardUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCardUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitCardUpcomingFailed(err))
            })
    }

}

// get all upcoming by card by user id
const getCardUpcomingsByByUserId = ({ jwt, id, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/upcomings/upcomings/${id}/user/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCardUpcomingsByByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitCardUpcomingStart())
        return getCardUpcomingsByByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCardUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCardUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitCardUpcomingFailed(err))
            })
    }

}
// get all upcoming
export const emitUpcomingStart = () => ({
    type: APP_GET_UPCOMING_START
})

export const emitUpcoming = data => {
    return {
        type: APP_GET_UPCOMING,
        data
    }
}

export const emitUpcomingFailed = (data) => ({
    type: APP_GET_UPCOMING_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

//all
const getUpcomings = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/upcomings/user/upcomings'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUpcomings = (data) => {
    return (dispatch) => {
        dispatch(emitUpcomingStart())
        return getUpcomings(data)
            .then(parseJSON)
            .then(json => {
                // 
                if (json.statusCode === 200) {
                    dispatch(emitUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUpcomingFailed(err))
            })
    }

}

//random
const getRandomUpcomings = () => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/upcomings/random/upcomings'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
}

export const startGetRandomUpcomings = () => {
    return (dispatch) => {
        dispatch(emitUpcomingStart())
        return getRandomUpcomings()
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetRandomUpcomings");
                if (json.statusCode === 200) {
                    dispatch(emitUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpcomingFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitUpcomingFailed(err))
            })
    }

}

// get all upcoming by user id
const getUpcomingsByUserId = ({ jwt, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/upcomings/users/upcomings/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUpcomingsByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitUpcomingStart())
        return getUpcomingsByUserId(data)
            .then(parseJSON)
            .then(json => {
                // 
                if (json.statusCode === 200) {
                    dispatch(emitUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUpcomingFailed(err))
            })
    }

}

// unassign photo from profession card
export const emitUnassignUpcomingStart = () => ({
    type: APP_UNASSIGN_UPCOMING_START
})

export const emitUnassignUpcoming = data => {
    return {
        type: APP_UNASSIGN_UPCOMING,
        data
    }
}

export const emitUnassignUpcomingFailed = (data) => ({
    type: APP_UNASSIGN_UPCOMING_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const UnassignUpcomings = ({ jwt, data, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/upcomings/unassign/professioncard/upcomings/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startUnassignUpcomings = (data) => {
    return (dispatch) => {
        dispatch(emitUnassignUpcomingStart())
        return UnassignUpcomings(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUnassignUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUnassignUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUnassignUpcomingFailed(err))
            })
    }

}

//update upcoming
export const emitPutUpcomingStart = () => ({
    type: APP_PUT_UPCOMING_START
})

export const emitPutUpcoming = data => {
    return {
        type: APP_PUT_UPCOMING,
        data
    }
}

export const emitPutUpcomingFailed = (data) => ({
    type: APP_PUT_UPCOMING_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const putUpcoming = ({ jwt, data, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/upcomings/edit/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutUpcoming = (data) => {
    return (dispatch) => {
        dispatch(emitPutUpcomingStart())
        return putUpcoming(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitPutUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPutUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPutUpcomingFailed(err))
            })
    }
}

//reasssign/assign to profession cards
export const emitAssignUpcomingStart = () => ({
    type: APP_ASSIGN_UPCOMING_START
})

export const emitAssignUpcoming = data => {
    return {
        type: APP_ASSIGN_UPCOMING,
        data
    }
}

export const emitAssignUpcomingFailed = (data) => ({
    type: APP_ASSIGN_UPCOMING_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const assignUpcoming = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/upcomings/reassign/professioncard/upcomings'
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignUpcoming = (data) => {
    return (dispatch) => {
        dispatch(emitAssignUpcomingStart())
        return assignUpcoming(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitAssignUpcomingFailed(err))
            })
    }
}
