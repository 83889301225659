import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_POST_ASK_QUESTION_START,
    APP_POST_ASK_QUESTION,
    APP_POST_ASK_QUESTION_FAIL,
    APP_LOGOUT
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitAskQuestionPostStart = () => ({
    type: APP_POST_ASK_QUESTION_START
})

export const emitAskQuestionPost = data => {
    return {
        type: APP_POST_ASK_QUESTION,
        data
    }
}

export const emitAskQuestionPostFailed = (data) => ({
    type: APP_POST_ASK_QUESTION_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

//with login
const postAskQuestionLoggedIn = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/questions/question'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

//without login
const postAskQuestion = ({ data }) => {
    let Authorization = composeAuth("")
    let url = process.env.REACT_APP_SERVER + '/api/v1/questions/question/open'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPostAskQuestion = (data,isLoggedIn) => {
    return (dispatch) => {
        dispatch(emitAskQuestionPostStart())
        if (isLoggedIn){
            return postAskQuestionLoggedIn(data)
                .then(parseJSON)
                .then(json => {
                    if (json.statusCode === 201) {
                        dispatch(emitAskQuestionPost(json.data))
                    } else if (json.statusCode === 401 || json.statusCode === 422) {
                        dispatch(emitAuthFail())
                    } else {
                        dispatch(emitAskQuestionPostFailed(json))
                    }
                })
                .catch(err => {
                    dispatch(emitAskQuestionPostFailed(err))
                })
        }
        else{
            return postAskQuestion(data)
                .then(parseJSON)
                .then(json => {
                    if (json.statusCode === 201) {
                        dispatch(emitAskQuestionPost(json.data))
                    } else if (json.statusCode === 401 || json.statusCode === 422) {
                        dispatch(emitAuthFail())
                    } else {
                        dispatch(emitAskQuestionPostFailed(json))
                    }
                })
                .catch(err => {
                    dispatch(emitAskQuestionPostFailed(err))
                })
        }
    }

}
