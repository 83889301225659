import React, { useState, useEffect } from "react";
import { Tabs, Tab, Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";

//assets
import Header from "../assets/layouts/header/dashboardHeader";
import { ViewAll } from "../assets/layouts/viewAll/colleagues";
import { SearchEmpty } from "../assets/layouts/empty/searchEmpty";
import Spinner from "../assets/molecules/spinner";

//reducers
import {
  getLoggedIn,
  getUserId
} from "../../redux/reducers/account/accounts";
import {
  getisLoadingAllColleagues,
  getMyColleagues,
} from "../../redux/reducers/colleagues";
import {
  getViewAllColleaguesUserId,
  getViewAllColleaguesUserName
} from "../../redux/reducers/ui/profile";

//actions
import { changeDropdownStatus, setViewAllColleaguesUserId } from "../../redux/actions/ui/profile";
import {
  startGetUserColleagues,
} from "../../redux/actions/colleagues";

const MyColleaguesTab = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState("myColleagues");

  const isLoggedIn = useSelector(getLoggedIn);
  const userId = useSelector(getViewAllColleaguesUserId);
  const userName = useSelector(getViewAllColleaguesUserName);
  const loggedinUserId = useSelector(getUserId);
  const isLoadingAllColleagues = useSelector(getisLoadingAllColleagues);
  const myColleagues = useSelector(getMyColleagues);

  useEffect(() => {
    dispatch(startGetUserColleagues({ userId: userId }));
    return () =>{
      dispatch(setViewAllColleaguesUserId(null));
    }
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    !userId ? <Redirect
      to={{
        pathname: '/',
      }}
    /> : isLoggedIn && loggedinUserId === userId ? <Redirect
          to={{
              pathname: '/colleagues',
          }}
      />:
    <div
      className="main__dashboard bgLines"
      onClick={() => dispatch(changeDropdownStatus(false))}
    >
      <Header backButton="true" name={userName} backButtonClick={() => { history.goBack()}}  headerClassname="withoutSearch" heading="" />
      <div className="minHeightContainer">
        {isLoadingAllColleagues? (
          <Spinner />
        ) : (
          <div className="container paddingContainer minHeightContainer">
            <Tabs
              className="searchTab--colleagues"
              defaultActiveKey={"activity"}
              activeKey={tabValue}
              onSelect={(value) => setTabValue(value)}
            >
              <Tab eventKey="myColleagues" title={<React.Fragment>
                COLLEAGUES
                <Badge variant='light'>{myColleagues?.length}</Badge>
              </React.Fragment>}>
                {myColleagues && myColleagues?.length ? (
                  <div className="tab__body colleagues__tabbody">
                    <p className="colleagues__explain">
                      Browse through {userName} list of colleagues
                    </p>
                    <ViewAll
                      loggedInUserId={userId}
                      header=""
                      data={myColleagues}
                      onClick={(people) => {
                        history.push({
                          pathname: `/search/people/${people?.navigation_name}`,
                        });
                      }}
                      type=""
                    />
                    <div className="clear"></div>
                  </div>
                ) : (
                  <SearchEmpty text="No Colleagues Added!" />
                )}
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default MyColleaguesTab;
