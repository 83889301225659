import {
    SAVE_PAYMENT_CONTRACT_TYPE,
    SAVE_PAYMENT_PRELIM_CONTRACT_STATUS,
    SAVE_PAYMENT_CONTRACT_LOCATION,
    SAVE_PAYMENT_CONTRACT_OFFER_DETAILS,
    SAVE_PAYMENT_CONTRACT_PERFORMANCE_EVENT_LOCATION,
    SAVE_PAYMENT_CONTRACT_PERFORMANCE_EVENTS,
    SAVE_PAYMENT_CONTRACT_DESCRIPTION,
    SAVE_PAYMENT_CONTRACT_REQUIRED_EVENTS_STATUS,
    SAVE_PAYMENT_CONTRACT_FLEXIBLE_EVENTS_EDIT_INDEX,
    SAVE_PAYMENT_CONTRACT_FLEXIBLE_EVENTS,
    SAVE_PAYMENT_CONTRACT_RIDER_TYPE,
    SAVE_PAYMENT_CONTRACT_RIDERS,
    SAVE_PAYMENT_CONTRACT_RIDERS_EDIT_INDEX,
    SAVE_PAYMENT_CONTRACT_SIGNING_FEE,
    SAVE_PAYMENT_CONTRACT_INSTALLMENT_CUSTOM_DATES,
    SAVE_PAYMENT_CONTRACT_INSTALLMENT_SELECTED_DATES,
    SAVE_PAYMENT_CONTRACT_EDIT_STATUS,
    SAVE_PAYMENT_CONTRACT_SENT,
    CLEAR_PERFORMANCE_DETAILS,
    CLEAR_PERFORMANCE_EVENT_DATE_DETAILS,
    CLEAR_PAYMENT,

    SAVE_PAYMENT_SLOTS_TRAVEL_LOCATION,
    CLEAR_PAYMENT_SLOTS,
    CLEAR_PAYMENT_SLOTS_EDIT_STATUS,
    SAVE_AVAILABILITY_OFF_DATES,
    SAVE_AVAILABILITY_SCHEDULE,
    SAVE_AVAILABILITY_ADVANCE_BOOKING,
    SAVE_CUSTOM_POLICY,
    SAVE_PAYMENT_SLOTS_EDIT_STATUS,

    SAVE_SLOT_BOOKING_LOCATION,
    SAVE_SLOT_BOOKING_DATA,
    APP_LOGOUT
} from '../../../actions'

const initialState = {
    contractType:"",
    prelimContractStatus:false,
    contractLocation:{},
    contractPerformanceEventLocation:{},
    dates:[],
    contractDescription:"",
    offerDetails:{},
    requiredEventStatus:false,
    flexibleEventsEditIndex:null,
    flexibleEvents:[],
    riderType:null,
    riders:[],
    ridersEditIndex:null,
    signingFee:"0",
    customDates:[],
    selectedDates:[],
    contractEditStatus:false,
    contractSent:false,

    slotTravelLocation:{},
    offDates:[],
    schedules:{},
    advanceBooking:null,
    customPolicy:[],
    slotEditStatus:false,

    slotBookingLocation:{},
    slotBookingData:{}
}

export const paymentsUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PAYMENT_CONTRACT_SENT:
            return Object.assign({}, state, {
                contractSent: action.data
            })
        case SAVE_PAYMENT_CONTRACT_TYPE:
            return Object.assign({}, state, {
                contractType: action.data
            })
        case SAVE_PAYMENT_PRELIM_CONTRACT_STATUS:
            return Object.assign({}, state, {
                prelimContractStatus: action.data
            })
        case SAVE_PAYMENT_CONTRACT_LOCATION:
            return Object.assign({}, state, {
                contractLocation: action.data
            })
        case SAVE_PAYMENT_CONTRACT_PERFORMANCE_EVENTS:
            return Object.assign({}, state, {
                dates: action.data
            })
        case SAVE_PAYMENT_CONTRACT_OFFER_DETAILS:
            return Object.assign({}, state, {
                offerDetails: action.data
            })
        case SAVE_PAYMENT_CONTRACT_PERFORMANCE_EVENT_LOCATION:
            return Object.assign({}, state, {
                contractPerformanceEventLocation: action.data
            })
        case CLEAR_PERFORMANCE_DETAILS:
            return Object.assign({}, state, {
                contractPerformanceEventLocation: {},
            })
        case SAVE_PAYMENT_CONTRACT_DESCRIPTION:
            return Object.assign({}, state, {
                contractDescription: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_REQUIRED_EVENTS_STATUS:
            return Object.assign({}, state, {
                requiredEventStatus: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_FLEXIBLE_EVENTS_EDIT_INDEX:
            return Object.assign({}, state, {
                flexibleEventsEditIndex: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_FLEXIBLE_EVENTS:
            return Object.assign({}, state, {
                flexibleEvents: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_RIDER_TYPE:
            return Object.assign({}, state, {
                riderType: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_RIDERS:
            return Object.assign({}, state, {
                riders: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_RIDERS_EDIT_INDEX:
            return Object.assign({}, state, {
                ridersEditIndex: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_SIGNING_FEE:
            return Object.assign({}, state, {
                signingFee: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_INSTALLMENT_CUSTOM_DATES:
            return Object.assign({}, state, {
                customDates: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_INSTALLMENT_SELECTED_DATES:
            return Object.assign({}, state, {
                selectedDates: action.data,
            })
        case SAVE_PAYMENT_CONTRACT_EDIT_STATUS:
            return Object.assign({}, state, {
                contractEditStatus: true,
            })
        case CLEAR_PERFORMANCE_EVENT_DATE_DETAILS:
            return Object.assign({}, state, {
                dates: []
            })
        case SAVE_PAYMENT_SLOTS_TRAVEL_LOCATION:
            return Object.assign({}, state, {
                slotTravelLocation: action.data
            })
        case CLEAR_PAYMENT_SLOTS:
            return Object.assign({}, state, {
                slotTravelLocation: {},
                offDates:[],
                schedules: {},
                advanceBooking: null,
                customPolicy: [],
            })
        case CLEAR_PAYMENT_SLOTS_EDIT_STATUS:
            return Object.assign({}, state, {
                slotEditStatus: false
            })
        case SAVE_AVAILABILITY_SCHEDULE:
            return Object.assign({}, state, {
                schedules: action.data
            })
        case SAVE_AVAILABILITY_ADVANCE_BOOKING:
            return Object.assign({}, state, {
                advanceBooking: action.data
            })
        case SAVE_AVAILABILITY_OFF_DATES:
            return Object.assign({}, state, {
                offDates: action.data
            })
        case SAVE_CUSTOM_POLICY:
            return Object.assign({}, state, {
                customPolicy: action.data
            })
        case SAVE_PAYMENT_SLOTS_EDIT_STATUS:
            return Object.assign({}, state, {
                slotEditStatus: action.data
            })
        case CLEAR_PAYMENT:
            return Object.assign({}, state, {
                contractLocation: {},
                contractPerformanceEventLocation: {},
                dates: [],
                contractDescription: "",
                offerDetails: {},
                requiredEventStatus: false,
                flexibleEventsEditIndex: null,
                flexibleEvents: [],
                riderType: null,
                riders: [],
                ridersEditIndex: null,
                signingFee: "",
                customDates: [],
                selectedDates: [],
                contractEditStatus: false,
                contractSent: false
            })

        case SAVE_SLOT_BOOKING_LOCATION:
            return Object.assign({}, state, {
                slotBookingLocation: action.data
            })
        case SAVE_SLOT_BOOKING_DATA:
            return Object.assign({}, state, {
                slotBookingData: action.data
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getContractType = state => state.paymentsUI.contractType
export const getPrelimContractStatus = state => state.paymentsUI.prelimContractStatus
export const getContractLocation = state => state.paymentsUI.contractLocation
export const getContractPerformanceEventLocation = state => state.paymentsUI.contractPerformanceEventLocation
export const getPerformanceEventsDates = state => state.paymentsUI.dates
export const getContractDescription = state => state.paymentsUI.contractDescription
export const getOfferDetails = state => state.paymentsUI.offerDetails
export const getRequiredEventStatus = state => state.paymentsUI.requiredEventStatus
export const getFlexibleEvents = state => state.paymentsUI.flexibleEvents
export const getFlexibleEventsEditIndex = state => state.paymentsUI.flexibleEventsEditIndex
export const getRiderType = state => state.paymentsUI.riderType
export const getRiderUI = state => state.paymentsUI.riders
export const getRidersEditIndex = state => state.paymentsUI.ridersEditIndex
export const getSigningFeeUI = state => state.paymentsUI.signingFee
export const getInstallmentsCustomDate = state => state.paymentsUI.customDates
export const getInstallmentsSelectedDates = state => state.paymentsUI.selectedDates
export const getContractEditStatus = state => state.paymentsUI.contractEditStatus
export const getContractSent= state => state.paymentsUI.contractSent

export const getSlotTravelLocation = state => state.paymentsUI.slotTravelLocation
export const getSchedules = state => state.paymentsUI.schedules
export const getAdvanceBooking = state => state.paymentsUI.advanceBooking
export const getSlotOffDates = state => state.paymentsUI.offDates
export const getCustomPolicy = state => state.paymentsUI.customPolicy
export const getSlotEditStatus = state => state.paymentsUI.slotEditStatus

export const getSlotBookingLocation = state => state.paymentsUI.slotBookingLocation
export const getSlotBookingData = state => state.paymentsUI.slotBookingData