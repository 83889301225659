import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Scrollbars } from "react-custom-scrollbars";

//assets
import Spinner from "../../assets/molecules/spinner";
import VideoThumb from "../../../assets/images/videoDetail.png";
import VideoIcon from "../../../assets/images/play.svg";
import { OpenLink } from '../../../helpers/utils'

//actions
import { startGetMediaVideo } from '../../../redux/actions/media/video'

//reducers
import { getisLoading, getVideo } from '../../../redux/reducers/media/video'
import { getJwt } from '../../../redux/reducers/account/accounts'

const VideoDetails = (props) => {
    const dispatch = useDispatch()

    const video = useSelector(getVideo)
    const isLoadingJob = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(startGetMediaVideo({ jwt: jwt ? jwt : "", ids: [props?.id] }))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingJob ? <Spinner /> : <div>
            {video[0]?.type === "Youtube" ? <div className="videoWithIframe">
                <iframe
                title="video"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
                width="100%"
                height="315"
                src={"https://www.youtube.com/embed/" + video[0]?.url}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe></div> : video[0]?.thumbnail ? <div onClick={() => OpenLink(video[0]?.url)}>
                        <img alt="video" src={video[0]?.thumbnail} />
                </div> : <div className="videoPop__wrap" onClick={() => OpenLink(video[0]?.url)}>
                        <img alt="video thumb" src={VideoThumb} />
                        <img alt="video icon" className="videoPop__icon" src={VideoIcon} />
                    </div>}
                    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={350}>
            <div className="popup__details">
                <div className="popup__nameWrap">
                    <h3>{video[0]?.title}</h3>
                    <div className="popup__date">{video[0]?.year}</div>
                    <div className="clear"></div>
                </div>
                {video[0]?.artists ? <div className="popup__data">
                    <div className="popup__label">Artists & Collaborators:</div>
                    <div className="popup__desc">{video[0]?.artists}</div>
                    <div className="clear"></div>
                </div> : null}
                {video[0]?.creators ? <div className="popup__data">
                    <div className="popup__label">Creators:</div>
                    <div className="popup__desc">{video[0]?.creators}</div>
                    <div className="clear"></div>
                </div> : null}
                {video[0]?.showname ? <div className="popup__data">
                    <div className="popup__label">Show / Work:</div>
                    <div className="popup__desc">{video[0]?.showname}</div>
                    <div className="clear"></div>
                </div> : null }
                {video[0]?.institutionName ? <div className="popup__data">
                    <div className="popup__label">Institution:</div>
                    <div className="popup__desc">{video[0]?.institutionName}</div>
                    <div className="clear"></div>
                </div> : null}
                {video[0]?.description ? <div className="popup__data">
                    <div className="popup__label">Description:</div>
                    <div className="popup__desc">{video[0]?.description}</div>
                    <div className="clear"></div>
                </div> : null}
                {video[0]?.url ? <div className="popup__data">
                    <div className="popup__label">URL:</div>
                    <div className="popup__desc">{video[0]?.type === "Youtube" ? ("https://www.youtube.com/embed/" + video[0]?.url) : video[0]?.url}</div>
                    <div className="clear"></div>
                </div> : null}
                <div className="clear"></div>
            </div>
            </Scrollbars>
        </div>
    )
};

export default VideoDetails;
