import {
    SAVE_ACCOLADES_SCHOLARSHIP_TITLE,
    SAVE_ACCOLADES_SCHOLARSHIP_DATE,
    SAVE_ACCOLADES_SCHOLARSHIP_INSTITUTION_ID,
    SAVE_ACCOLADES_SCHOLARSHIP_INSTITUTION_LOCATION,
    SAVE_ACCOLADES_SCHOLARSHIP_AFFILIATE_INSTITUTION_ID,
    CLEAR_ACCOLADES_SCHOLARSHIP,
    APP_LOGOUT
} from '../../../../../actions'

const initialState = {
    accoladesScholarshipTitle:"",
    accoladesScholarshipDate:{},
    accoladesScholarshipInstitutionId:[],
    accoladesScholarshipInstitutionLocation:{},
    accoladesScholarshipAffiliateInstitutionId:null,
}

export const accoladesScholarshipUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ACCOLADES_SCHOLARSHIP_TITLE:
            return Object.assign({}, state, {
                accoladesScholarshipTitle: action.data
            })
        case SAVE_ACCOLADES_SCHOLARSHIP_DATE:
            return Object.assign({}, state, {
                accoladesScholarshipDate: action.data
            })
        case SAVE_ACCOLADES_SCHOLARSHIP_INSTITUTION_ID:
            return Object.assign({}, state, {
                accoladesScholarshipInstitutionId: action.data
            })
        case SAVE_ACCOLADES_SCHOLARSHIP_INSTITUTION_LOCATION:
            return Object.assign({}, state, {
                accoladesScholarshipInstitutionLocation: action.data
            })
        case SAVE_ACCOLADES_SCHOLARSHIP_AFFILIATE_INSTITUTION_ID:
            return Object.assign({}, state, {
                accoladesScholarshipAffiliateInstitutionId: action.data
            })
        case CLEAR_ACCOLADES_SCHOLARSHIP:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getAccoladesScholarshipTitle = state => state.accoladesScholarshipUI.accoladesScholarshipTitle
export const getAccoladesScholarshipDate = state => state.accoladesScholarshipUI.accoladesScholarshipDate
export const getAccoladesScholarshipInstitutionId = state => state.accoladesScholarshipUI.accoladesScholarshipInstitutionId
export const getAccoladesScholarshipInstitutionLocation = state => state.accoladesScholarshipUI.accoladesScholarshipInstitutionLocation
export const getAccoladesScholarshipAffiliateInstitutionId = state => state.accoladesScholarshipUI.accoladesScholarshipAffiliateInstitutionId