import {
    SAVE_JOBTITLE_ASSOCIATION_LOCATION,
    SAVE_JOBTITLE_ASSOCIATION_INSTITUTION_ID,
    SAVE_JOBTITLE_TITLE,
    SAVE_JOBTITLE_TYPE,
    SAVE_JOBTITLE_DATE,
    CLEAR_JOBTITLE,
    APP_LOGOUT
} from '../../../../actions'

const initialState = {
    jobTitle:"",
    jobType:"",
    jobDate:{},
    jobTitleAssociationLocation: {},
    jobTitleAssociationInstitutionId:null
}

export const jobTitleUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_JOBTITLE_TITLE:
            return Object.assign({}, state, {
                jobTitle: action.data
            })
        case SAVE_JOBTITLE_TYPE:
            return Object.assign({}, state, {
                jobType: action.data
            })
        case SAVE_JOBTITLE_DATE:
            return Object.assign({}, state, {
                jobDate: action.data
            })
        case SAVE_JOBTITLE_ASSOCIATION_LOCATION:
            return Object.assign({}, state, {
                jobTitleAssociationLocation: action.data
            })
        case SAVE_JOBTITLE_ASSOCIATION_INSTITUTION_ID:
            return Object.assign({}, state, {
                jobTitleAssociationInstitutionId: action.data
            })
        case CLEAR_JOBTITLE:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getjobTitle = state => state.jobTitleUI.jobTitle
export const getjobType = state => state.jobTitleUI.jobType
export const getjobDate = state => state.jobTitleUI.jobDate
export const getjobTitleAssociationLocation = state => state.jobTitleUI.jobTitleAssociationLocation
export const getjobTitleAssociationInstitutionId = state => state.jobTitleUI.jobTitleAssociationInstitutionId