import {
    APP_POST_USER_CARDS_START,
    APP_POST_USER_CARDS,
    APP_POST_USER_CARDS_FAIL,
    APP_GET_USER_PROFESSION_CARDS_START,
    APP_GET_USER_PROFESSION_CARDS,
    APP_GET_USER_PROFESSION_CARDS_FAIL,
    APP_GET_RANDOM_USER_PROFESSION_CARDS_START,
    APP_GET_RANDOM_USER_PROFESSION_CARDS,
    APP_GET_RANDOM_USER_PROFESSION_CARDS_FAIL,
    APP_DELETE_USER_PROFESSION_CARDS_START,
    APP_DELETE_USER_PROFESSION_CARDS,
    APP_DELETE_USER_PROFESSION_CARDS_FAIL,
    APP_GET_FAV_USER_PROFESSION_CARDS_START,
    APP_GET_FAV_USER_PROFESSION_CARDS,
    APP_GET_FAV_USER_PROFESSION_CARDS_FAIL,
    APP_POST_FAV_USER_PROFESSION_CARDS_START,
    APP_POST_FAV_USER_PROFESSION_CARDS,
    APP_POST_FAV_USER_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../actions'

import { createSelector } from 'reselect'
import _ from 'underscore'
import { getById } from '../../../helpers/utils'

const initialState = {
    card:{},
    isLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    getCardsIsloading: false,
    getRandomCardsIsloading: false,
    deleteCardIsLoading:false,
    userCards: [],
    randomUserCards: [],
    favCards:[],
    isLoadingGetFavCards:false,
    isLoadingPostFavCards:false
}


export const cards = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_FAV_USER_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingPostFavCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_FAV_USER_PROFESSION_CARDS:
            return Object.assign({}, state, {
                isLoadingPostFavCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_FAV_USER_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingPostFavCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_FAV_USER_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingGetFavCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_FAV_USER_PROFESSION_CARDS:
            return Object.assign({}, state, {
                favCards:action.data,
                isLoadingGetFavCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FAV_USER_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetFavCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_USER_CARDS_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_USER_CARDS:
            return Object.assign({}, state, {
                card:action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_USER_CARDS_FAIL:
            return Object.assign({}, state, {
                card: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_USER_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                getCardsIsloading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_USER_PROFESSION_CARDS:
            return Object.assign({}, state, {
                userCards: action.data,
                getCardsIsloading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_USER_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                getCardsIsloading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_RANDOM_USER_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                getRandomCardsIsloading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_RANDOM_USER_PROFESSION_CARDS:
            console.log(action.data,"action.data");
            return Object.assign({}, state, {
                randomUserCards: action.data,
                getRandomCardsIsloading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_RANDOM_USER_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                getRandomCardsIsloading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_USER_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                deleteCardIsLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_USER_PROFESSION_CARDS:
            return Object.assign({}, state, {
                deleteCardIsLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_USER_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                deleteCardIsLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.cards.error
export const getMsg = state => state.cards.message
export const getisLoading = state => state.cards.isLoading
export const getcard = state => state.cards.card
export const getIsSuccess = state => state.cards.isSuccess
export const getstatusCode = state => state.cards.statusCode
export const getUserCards = state => state.cards.userCards.sort((a, b) => a.id - b.id)
export const getRandomUserCards = state => state.cards.randomUserCards
export const getFavCards = state => state.cards.favCards
export const getFavCardsIds = state => {
    return state.cards.favCards.map((card)=>card.cardid)
}
export const getisLoadingGetFavCards = state => state.cards.isLoadingGetFavCards
export const getisLoadingPostFavCards = state => state.cards.isLoadingPostFavCards

export const getCardsIsloading = state => state.cards.getCardsIsloading
export const getRandomCardsIsloading = state => state.cards.getRandomCardsIsloading
export const deleteCardIsLoading = state => state.cards.deleteCardIsLoading
export const getUserCardsByCategoryId = state => {
    const groupBy = _.groupBy(
        state.cards.userCards.sort((a, b) => a.id - b.id),
        data => data.userprofessionid
    )
    return groupBy
}

export const getUserCardsById = state => {
    return getById(state.cards.userCards.sort((a,b)=>a.id-b.id))
}

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))