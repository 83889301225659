import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { fetchAccoladesAward } from '../../../redux/actions/resume/accolades/award'

//reducers
import { getisLoading, getAward } from '../../../redux/reducers/resume/accolades/award'
import { getJwt } from '../../../redux/reducers/account/accounts'

//helpers
import { monthToName } from '../../../helpers/utils'

const ResumeAward = (props) => {
    const dispatch = useDispatch()

    const award = useSelector(getAward)
    const isLoading = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(fetchAccoladesAward({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoading ? <Spinner /> : <div>
            <div className="expDetail__secHead">Awards</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Title of Award</p>
                <p className="expDetail__desc">{award.title ? award.title : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Year</p>
                <p className="expDetail__desc">{(award.month ? monthToName[award.month] : null) + " - " + (award.year ? award.year : null)}</p>
            </div>

            {award.institutionName || award?.location?.description || award.affiliateinstitutionName ? <div>
                <div className="expDetail__secHead">Institution Info</div>
                {award.institutionName ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Location</p>
                    <p className="expDetail__desc">{award.institutionName}</p>
                </div> : null}
                {award.location ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Location</p>
                    <p className="expDetail__desc">{award.location.description}</p>
                </div> : null}
                {award.affiliateinstitutionName ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Affiliated Institute</p>
                    <p className="expDetail__desc">{award.affiliateinstitutionName}</p>
                </div> : null}
            </div> : null}
            
            {award.information || (award?.additional_info && award?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {award.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{award.information}</p>
                </div> : null}
                {award?.additional_info && award?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {award?.additional_info && award?.additional_info.data[0] ? award?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div> : null}
        </div>
    );
};

export default ResumeAward;
