import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Scrollbars } from "react-custom-scrollbars";
import moment from 'moment'

//assets
import Spinner from "../../assets/molecules/spinner";
import HighlightThumb from "../../../assets/images/highlightsDetail.png";
import { OpenLink } from '../../../helpers/utils'

//actions
import { startGetUpcomingById } from '../../../redux/actions/upcoming'

//reducers
import { getisLoading, getUpcoming } from '../../../redux/reducers/upcoming'
import { getJwt } from '../../../redux/reducers/account/accounts'

const HighlightsDetails = (props) => {
    const dispatch = useDispatch()

    const upcoming = useSelector(getUpcoming)
    const isLoadingJob = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(startGetUpcomingById({ jwt: jwt ? jwt : "", ids: [props?.id] }))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingJob ? <Spinner /> : <div>
            {upcoming[0]?.thumbnail ? <div className="detailsPage__img">
                <img alt="thumb" src={upcoming[0]?.thumbnail} />
            </div> : <div>
                    <img alt="highlight thumb" src={HighlightThumb} />
                </div>}
                <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={350}>
            <div className="popup__details">
                <div className="popup__nameWrap">
                    <h3>{upcoming[0]?.title}</h3>
                    <div className="popup__date">{moment(upcoming[0]?.date).format("MMM YYYY")}</div>
                    <div className="clear"></div>
                </div>
                {upcoming[0]?.role ? <div className="popup__data">
                    <div className="popup__label">Info:</div>
                    <div className="popup__desc">{upcoming[0]?.role}</div>
                    <div className="clear"></div>
                </div> : null }
                {upcoming[0]?.webLink ? <div className="popup__data">
                    <div className="popup__label">Web Link:</div>
                    <div className="popup__desc addPointer" onClick={() => OpenLink(upcoming[0]?.webLink)}>{upcoming[0]?.webLink}</div>
                    <div className="clear"></div>
                </div> : null }
                {upcoming[0]?.description ? <div className="popup__data">
                    <div className="popup__label">Description:</div>
                    <div className="popup__desc">{upcoming[0]?.description}</div>
                    <div className="clear"></div>
                </div> : null }
                <div className="clear"></div>
            </div>
            </Scrollbars>
        </div>
    )
};

export default HighlightsDetails;
