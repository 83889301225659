import Youtube from "../../assets/images/youtube.png";
import Tweet from "../../assets/images/tweet.png";
import Vimeo from "../../assets/images/vimeo.png";
import Website from "../../assets/images/website.png";

export const linkImages = {
    "Facebook": Website,
    "Website": Website,
    "Instagram": Website,
    "Google": Website,
    "Twitter": Tweet,
    "Soundcloud": Website,
    "Youtube": Youtube,
    "Vimeo": Vimeo,
    "Other Links": Website,
}