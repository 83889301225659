import React from "react";

const ExperianceCard = (props) => {
  return (
    <div className={props?.className} onClick={()=>props?.onClick()}>
      <div className="expCard__wrap1">
        <div className="expCard__skill">{props.skill}</div>
        <div className="clear"></div>
        <div className="expCard__nameWrap">
          <div className="expCard__letter">{props.letter}</div>
          <div className="expCard__name">
          <h5>{props.name}</h5>
          <p>{props.description}</p>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>

      <div className="expCard__wrap2">
        <div className="expCard__location">
          {props.location}
          <div className="clear"></div>
        </div>
        <div className="expCard__date">
          {props.date}
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default ExperianceCard;
