
import {
    SAVE_PERFORMANCE_PROGRAM_INSTITUTION,
    SAVE_PERFORMANCE_PROGRAM_DATE,
    SAVE_PERFORMANCE_EVENT,
    SAVE_PERFORMANCE_WORK,
    SAVE_PERFORMANCE_NEW_WORK_DETAILS,
    SAVE_PERFORMANCE_NEW_WORK_DETAILS_CREATOR_TYPE,
    SAVE_PERFORMANCE_NEW_WORK_DETAILS_CREATOR,
    SAVE_PERFORMANCE_NEW_WORK_DETAILS_SAVED_CREATORS,
    SAVE_PERFORMANCE_PROGRAM_INSTITUTION_LOCATION,
    SAVE_PERFORMANCE_PROGRAM_CARDIDS,
    SAVE_PERFORMANCE_WORKID_TO_ADD_ROLE,
    CLEAR_PERFORMANCE_UI,
    CLEAR_NEW_WORK_DETAILS_SEARCH,
    CLEAR_NEW_WORK_DETAILS,
    SAVE_PERFORMANCE_SELECTED_ROLE,
    SAVE_PERFORMANCE_ROLE_TYPE,
    SAVE_PERFORMANCE_NEW_ROLE,
    SAVE_PERFORMANCE_NEW_COLLEAGUE,
    SAVE_PERFORMANCE_COLLEAGUE_LIST,
    SAVE_PERFORMANCE_COLLEAGUE_LIST_EDIT_INDEX,
    CLEAR_PERFORMANCE_COLLEAGUE_LIST,
    CLEAR_PERFORMANCE_EVENT_ID,
    CLEAR_PERFORMANCE_WORK,
    CLEAR_PERFORMANCE_SAVED_COLLEAGUE_LIST,
    APP_LOGOUT
} from '../../../../actions'

const initialState = {
    institutionId: [],
    cardIds:[],
    location:{},
    date:{},
    eventId:[],
    workIds:{},
    workDetails:{},
    workDetailsCreatorType:[],
    workDetailsCreator:[],
    workDetailsSavedCreators:[],
    workIdToAddRole:"",
    selectedRole:{},
    roleType:[],
    newRole:{},
    newColleague:{},
    colleaguesList:[],
    colleaguesListEditIndex:null
}

export const performanceUI = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_NEW_WORK_DETAILS:
            return Object.assign({}, state, {
                workIds: {},
                workDetails: {},
                workDetailsCreatorType: [],
                workDetailsCreator: [],
                workDetailsSavedCreators: []
            })
        case SAVE_PERFORMANCE_COLLEAGUE_LIST:
            return Object.assign({}, state, {
                colleaguesList: action.data
            })
        case SAVE_PERFORMANCE_COLLEAGUE_LIST_EDIT_INDEX:
            return Object.assign({}, state, {
                colleaguesListEditIndex: action.data,
                workDetailsCreator: [],
                selectedRole:{}
            })
        case CLEAR_PERFORMANCE_COLLEAGUE_LIST:
            return Object.assign({}, state, {
                workDetailsCreator: [],
                selectedRole:{}
            })
        case CLEAR_PERFORMANCE_SAVED_COLLEAGUE_LIST:
            return Object.assign({}, state, {
                colleaguesList: [],
            })
        case SAVE_PERFORMANCE_NEW_COLLEAGUE:
            return Object.assign({}, state, {
                newColleague: action.data
            })
        case SAVE_PERFORMANCE_NEW_ROLE:
            return Object.assign({}, state, {
                newRole: action.data
            })
        case SAVE_PERFORMANCE_PROGRAM_INSTITUTION:
            return Object.assign({}, state, {
                institutionId: action.data
            })
        case SAVE_PERFORMANCE_ROLE_TYPE:
            return Object.assign({}, state, {
                roleType: action.data
            })
        case SAVE_PERFORMANCE_EVENT:
            return Object.assign({}, state, {
                eventId: action.data
            })
        case SAVE_PERFORMANCE_WORK:
            return Object.assign({}, state, {
                workIds: action.data
            })
        case SAVE_PERFORMANCE_NEW_WORK_DETAILS:
            return Object.assign({}, state, {
                workDetails: action.data
            })
        case SAVE_PERFORMANCE_SELECTED_ROLE:
            return Object.assign({}, state, {
                selectedRole: action.data
            })
        case SAVE_PERFORMANCE_NEW_WORK_DETAILS_CREATOR_TYPE:
            return Object.assign({}, state, {
                workDetailsCreatorType: action.data
            })
        case SAVE_PERFORMANCE_NEW_WORK_DETAILS_CREATOR:
            return Object.assign({}, state, {
                workDetailsCreator: action.data
            })
        case SAVE_PERFORMANCE_NEW_WORK_DETAILS_SAVED_CREATORS:
            return Object.assign({}, state, {
                workDetailsSavedCreators: action.data
            })
        case CLEAR_NEW_WORK_DETAILS_SEARCH:
            return Object.assign({}, state, {
                workDetailsCreator: [],
                workDetailsCreatorType: []
            })
        case SAVE_PERFORMANCE_PROGRAM_CARDIDS:
            return Object.assign({}, state, {
                cardIds: action.data
            })
        case SAVE_PERFORMANCE_PROGRAM_INSTITUTION_LOCATION:
            return Object.assign({}, state, {
                location: action.data
            })
        case SAVE_PERFORMANCE_PROGRAM_DATE:
            return Object.assign({}, state, {
                date: action.data
            })
        case SAVE_PERFORMANCE_WORKID_TO_ADD_ROLE:
            return Object.assign({}, state, {
                workIdToAddRole: action.data
            })
        case CLEAR_PERFORMANCE_UI:
            return Object.assign({}, state, {
                institutionId: [],
                cardIds: [],
                location: {},
                date: {},
                workIds: {},
                workDetails: {},
                workDetailsCreatorType: [],
                workDetailsCreator: [],
                workDetailsSavedCreators: [],
                workIdToAddRole: "",
                selectedRole: {},
                roleType: [],
                newRole: {},
                newColleague: {},
                colleaguesList: [],
                colleaguesListEditIndex: null
            })
        case CLEAR_PERFORMANCE_EVENT_ID:
            return Object.assign({}, state, {
                eventId: []
            })
        case CLEAR_PERFORMANCE_WORK:
            return Object.assign({}, state, {
                workIds: {}
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getPerformanceInstitutionId = state => state.performanceUI.institutionId
export const getPerformanceCardIds = state => state.performanceUI.cardIds
export const getPerformanceInstituionLocation = state => state.performanceUI.location
export const getDate = state => state.performanceUI.date
export const getUIEventId = state => state.performanceUI.eventId
export const getUIWorkIds = state => state.performanceUI.workIds
export const getWorkDetails = state => state.performanceUI.workDetails
export const getworkDetailsCreatorType = state => state.performanceUI.workDetailsCreatorType
export const getworkDetailsCreator = state => state.performanceUI.workDetailsCreator
export const getWorkDetailsSavedCreators = state => state.performanceUI.workDetailsSavedCreators
export const getWorkIdToAddRole = state => state.performanceUI.workIdToAddRole
export const getSelectedRole = state => state.performanceUI.selectedRole
export const getRoleType = state => state.performanceUI.roleType
export const getNewRoleDetails = state => state.performanceUI.newRole
export const getNewColleague = state => state.performanceUI.newColleague
export const getColleaguesList = state => state.performanceUI.colleaguesList
export const getColleaguesListEditIndex = state => state.performanceUI.colleaguesListEditIndex