import {
    APP_POST_MEDIA_VIDEO_START,
    APP_POST_MEDIA_VIDEO,
    APP_POST_MEDIA_VIDEO_FAIL,
    APP_DELETE_MEDIA_VIDEO_START,
    APP_DELETE_MEDIA_VIDEO,
    APP_DELETE_MEDIA_VIDEO_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    video: {},
    isLoading: false,
    isDeleteLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const mediaVideo = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_MEDIA_VIDEO_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_MEDIA_VIDEO:
            return Object.assign({}, state, {
                video: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_MEDIA_VIDEO_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_MEDIA_VIDEO_START:
            return Object.assign({}, state, {
                isDeleteLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_MEDIA_VIDEO:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_MEDIA_VIDEO_FAIL:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.mediaVideo.error
export const getMsg = state => state.mediaVideo.message
export const getisLoading = state => state.mediaVideo.isLoading
export const getisDeleteLoading = state => state.mediaVideo.isDeleteLoading
export const getVideo = state => state.mediaVideo.video
export const getIsSuccess = state => state.mediaVideo.isSuccess
export const getstatusCode = state => state.mediaVideo.statusCode