import {
    APP_CREATE_MASTERCLASS_START,
    APP_CREATE_MASTERCLASS,
    APP_CREATE_MASTERCLASS_FAIL,
    APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_START,
    APP_CREATE_MASTERCLASS_ADDITIONAL_INFO,
    APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_FAIL,
    APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_START,
    APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS,
    APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'

const initialState = {
    isLoading: false,
    isLoadingAdditionalInfo: false,
    isLoadingMasterclassToProfessionCards: false,
    error: false,
    message: '',
    isSuccess: false,
    masterclass: {},
    statusCode: 201
}


export const masterclass = (state = initialState, action) => {
    switch (action.type) {
        case APP_CREATE_MASTERCLASS_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_MASTERCLASS:
            return Object.assign({}, state, {
                masterclass: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_MASTERCLASS_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_START:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_MASTERCLASS_ADDITIONAL_INFO:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                masterclass: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_FAIL:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingMasterclassToProfessionCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                isLoadingMasterclassToProfessionCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingMasterclassToProfessionCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.masterclass.error
export const getMsg = state => state.masterclass.message
export const getisLoading = state => state.masterclass.isLoading
export const getIsSuccess = state => state.masterclass.isSuccess
export const getstatusCode = state => state.masterclass.statusCode
export const getMasterclass = state => state.masterclass.masterclass
export const getisLoadingAdditionalInfo = state => state.masterclass.isLoadingAdditionalInfo
export const getisLoadingMasterclassToProfessionCards = state => state.masterclass.isLoadingMasterclassToProfessionCards

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
