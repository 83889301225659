import {
    SAVE_LINKS,
    UPDATE_LINKS,
    CLEAR_LINKS,
    APP_LOGOUT
} from '../../../actions'

const initialState = {
    links:[],
}

export const linksUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_LINKS:
            initialState.links.push(action.data)
            return Object.assign({}, state, {
                links: initialState.links
            })
        case UPDATE_LINKS:
            initialState.links[action.index] = action.data
            return Object.assign({}, state, {
                links: initialState.links
            })
        case CLEAR_LINKS:
            initialState.links = []
            return Object.assign({}, state, {
                links: initialState.links
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getLinks = state => state.linksUI.links
