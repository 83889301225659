import {
    SAVE_EDUCATION_LOCATION,
    SAVE_EDUCATION_INSTITUTION_ID,
    SAVE_EDUCATION_ASSOCIATED_LOCATION,
    SAVE_EDUCATION_ASSOCIATED_INSTITUTION_ID,
    SAVE_EDUCATION_INSTITUTION_TYPE,
    SAVE_EDUCATION_ASSOCIATED_INSTITUTION_TYPE,
    CLEAR_EDUCATION,
    APP_LOGOUT
} from '../../../../actions'

const initialState = {
    educationLocation: {},
    educationInstitutionId:[],
    educationInstitutionType:"",
    educationAssociatedInstitutionType:null,
    educationAssociatedLocation: {},
    educationAssociatedInstitutionId:null
}

export const educationUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_EDUCATION_LOCATION:
            return Object.assign({}, state, {
                educationLocation: action.data
            })
        case SAVE_EDUCATION_INSTITUTION_ID:
            return Object.assign({}, state, {
                educationInstitutionId: action.data
            })
        case SAVE_EDUCATION_ASSOCIATED_LOCATION:
            return Object.assign({}, state, {
                educationAssociatedLocation: action.data
            })
        case SAVE_EDUCATION_ASSOCIATED_INSTITUTION_ID:
            return Object.assign({}, state, {
                educationAssociatedInstitutionId: action.data
            })
        case SAVE_EDUCATION_INSTITUTION_TYPE:
            return Object.assign({}, state, {
                educationInstitutionType: action.data
            })
        case SAVE_EDUCATION_ASSOCIATED_INSTITUTION_TYPE:
            return Object.assign({}, state, {
                educationAssociatedInstitutionType: action.data
            })
        case CLEAR_EDUCATION:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getEducationLocation = state => state.educationUI.educationLocation
export const getEducationInstitutionId = state => state.educationUI.educationInstitutionId
export const getEducationAssociatedLocation = state => state.educationUI.educationAssociatedLocation
export const getEducationAssociatedInstitutionId = state => state.educationUI.educationAssociatedInstitutionId
export const getEducationInstitutionType = state => state.educationUI.educationInstitutionType
export const getEducationAssociatedInstitutionType = state => state.educationUI.educationAssociatedInstitutionType