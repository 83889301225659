import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { fetchTrainingProgram } from '../../../redux/actions/resume/training/program'

//reducers
import { getisLoading, getProgram } from '../../../redux/reducers/resume/training/program'
import { getJwt } from '../../../redux/reducers/account/accounts'

//helpers
import { monthToName } from '../../../helpers/utils'

const ResumeProgram = (props) => {
    const dispatch = useDispatch()

    const program = useSelector(getProgram)
    const isLoading = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(fetchTrainingProgram({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoading ? <Spinner /> : <div>
            <div className="expDetail__secHead">Program Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Name of Program</p>
                <p className="expDetail__desc">{program['institutionprogramName'] ? program['institutionprogramName'] : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Type</p>
                <p className="expDetail__desc">{program['type'] ? program['type'] : null}</p>
            </div>
            {program?.location?.description ? <div className="expDetail__wrap">
                <p className="expDetail__label">Location</p>
                <p className="expDetail__desc">{program.location.description ? program.location.description : null}</p>
            </div> : null}

            <div className="expDetail__secHead">Institution Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Name of Institute</p>
                <p className="expDetail__desc">{program['institutionid'] ? program['institutionid'] : null}</p>
            </div>

            <div className="expDetail__secHead">Study Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Position</p>
                <p className="expDetail__desc">{program['position'] ? program['position'] : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Year Attended</p>
                <p className="expDetail__desc">{program['date'] ? program.dateRange ? monthToName[program['date'].from_month] + " " + program['date'].from_year : monthToName[program['date'].from_month] + " " + program['date'].from_year + " - " + monthToName[program['date'].to_month] + " " + program['date'].to_year : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Sub Program</p>
                <p className="expDetail__desc">{program['subprogram'] ? program['subprogram'] : null}</p>
            </div>

            <div className="expDetail__secHead">Associated Users</div>
            <div className="expDetail__wrap">
                {program?.colleagues?.data?.length > 0 ?
                    program?.colleagues?.data.map((c, index) => {
                        if (c) {
                            return (
                                <div key={index}>
                                    <p className="expDetail__label">{c.firstName + " " + c.lastName}</p>
                                    <p className="expDetail__desc">{c.role}</p>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })
                    : null}
            </div>

            {program.information || (program?.additional_info && program?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {program.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{program.information}</p>
                </div> : null}
                {program?.additional_info && program?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {program?.additional_info && program?.additional_info.data[0] ? program?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div> : null}
        </div>
    );
};

export default ResumeProgram;
