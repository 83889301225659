import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_CREATE_TRAINING_INDIVIDUAL_START,
    APP_CREATE_TRAINING_INDIVIDUAL,
    APP_CREATE_TRAINING_INDIVIDUAL_FAIL,
    APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_START,
    APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO,
    APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_FAIL,
    APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_START,
    APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS,
    APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../actions'


//create training individual

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitTrainingIndividualStart = () => ({
    type: APP_CREATE_TRAINING_INDIVIDUAL_START
})

export const emitTrainingIndividual = data => {
    return {
        type: APP_CREATE_TRAINING_INDIVIDUAL,
        data
    }
}

export const emitTrainingIndividualFailed = (data) => ({
    type: APP_CREATE_TRAINING_INDIVIDUAL_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postTrainingIndividual = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/individual/individuals'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createTrainingIndividual = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualStart())
        return postTrainingIndividual(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingIndividual(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualFailed(err))
            })
    }

}

//get individual by id
const getTrainingIndividual = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/individuals/${id}`
    
    
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetTrainingIndividual = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualStart())
        return getTrainingIndividual(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingIndividual(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualFailed(err))
            })
    }

}


// add additional info to training individual card

export const emitTrainingIndividualAdditionalInfoStart = () => ({
        type: APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_START
    })

    export const emitTrainingIndividualAdditionalInfo = data => {
        return {
            type: APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO,
            data
        }
    }

    export const emitTrainingIndividualAdditionalInfoFailed = (data) => ({
        type: APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_FAIL,
        message: data.message,
        statusCode: data.statusCode,

    })

    const postTrainingIndividualAdditionalInfo = ({ jwt, data }) => {
        let url = process.env.REACT_APP_SERVER + '/api/v1/individual/information'
        let Authorization = composeAuth(jwt)
        return fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization
            },
            body: JSON.stringify(data)
        })
    }

    export const createTrainingIndividualAdditionalInfo = (data) => {
        return (dispatch) => {
            dispatch(emitTrainingIndividualAdditionalInfoStart())
            return postTrainingIndividualAdditionalInfo(data)
                .then(parseJSON)
                .then(json => {
                    
                    if (json.statusCode === 201) {
                        dispatch(emitTrainingIndividualAdditionalInfo(json.data))
                    } else if (json.statusCode === 401 || json.statusCode === 422) {
                        dispatch(emitAuthFail())
                    } else {
                        dispatch(emitTrainingIndividualAdditionalInfoFailed(json))
                    }
                })
                .catch(err => {
                    
                    dispatch(emitTrainingIndividualAdditionalInfoFailed(err))
                })
        }
    }

// add Individual to professions Cards


export const emitTrainingIndividualToProfessionCardsStart = () => ({
    type: APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_START
})

export const emitTrainingIndividualToProfessionCards = data => {
    return {
        type: APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS,
        data
    }
}

export const emitTrainingIndividualToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postTrainingIndividualToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/individual/professioncards'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignTrainingIndividualToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualToProfessionCardsStart())
        return postTrainingIndividualToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingIndividualToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualToProfessionCardsFailed(err))
            })
    }
}

// update training program info

const putTrainingIndividualUserDetails = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/edit/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateTrainingIndividualUserDetails = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualStart())
        return putTrainingIndividualUserDetails(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingIndividual(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualFailed(err))
            })
    }

}

// update training program

const putTrainingIndividual = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/edit/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateTrainingIndividual = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualStart())
        return putTrainingIndividual(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitTrainingIndividual(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualFailed(err))
            })
    }

}

// get trainingIndividual resume by card id

export const emitTrainingIndividualByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitTrainingIndividualByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitTrainingIndividualByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getTrainingIndividualByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/individual/professioncard/${stringifyIds}`
    
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetTrainingIndividualByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualByCardIdStart())
        return getTrainingIndividualByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingIndividualByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualByCardIdFailed(err))
            })
    }
}

// get trainingIndividual resume by card id by user id
const getTrainingIndividualByCardIdByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/individual/professioncard/${stringifyIds}/user/${userId}`
    
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetTrainingIndividualByCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualByCardIdStart())
        return getTrainingIndividualByCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingIndividualByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualByCardIdFailed(err))
            })
    }
}

// get user training program - no card
const getUserTrainingIndividual = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/user/individual`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserTrainingIndividual = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualByCardIdStart())
        return getUserTrainingIndividual(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingIndividualByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualByCardIdFailed(err))
            })
    }
}

// get user training program - no card by user id
const getUserTrainingIndividualByUserId = ({ jwt,userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/users/individual/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserTrainingIndividualByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitTrainingIndividualByCardIdStart())
        return getUserTrainingIndividualByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitTrainingIndividualByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitTrainingIndividualByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitTrainingIndividualByCardIdFailed(err))
            })
    }
}

// delete trainingIndividual by id

export const emitDeleteTrainingIndividualStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteTrainingIndividual = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteTrainingIndividualFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteTrainingIndividual = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/delete/individuals`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteTrainingIndividual = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteTrainingIndividualStart())
        return deleteTrainingIndividual(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteTrainingIndividual(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteTrainingIndividualFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteTrainingIndividualFailed(err))
            })
    }
}

// assign trainingIndividual to card

export const emitAssignTrainingIndividualStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignTrainingIndividual = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignTrainingIndividualFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignTrainingIndividual = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/individual/reassign/professioncard/individuals`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignTrainingIndividual = (data) => {
    return (dispatch) => {
        dispatch(emitAssignTrainingIndividualStart())
        return assignTrainingIndividual(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignTrainingIndividual(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignTrainingIndividualFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignTrainingIndividualFailed(err))
            })
    }
}
