import { parseJSON, composeAuth } from '../../../helpers/utils'

import { updateProfileCreated } from '../account/index' 

import {
    APP_USER_PROFILE_START,
    APP_USER_PROFILE_ADD,
    APP_USER_PROFILE_FAIL,
    APP_GET_FEATURED_USER_PROFILE_DATA_START,
    APP_GET_FEATURED_USER_PROFILE_DATA,
    APP_GET_FEATURED_USER_PROFILE_DATA_NO_CARD,
    APP_GET_FEATURED_USER_PROFILE_DATA_FAIL,
    APP_LOGOUT,
    APP_DELETE_PROFILE_CARD_START,
    APP_DELETE_PROFILE_CARD,
    APP_DELETE_PROFILE_CARD_FAILED,
    APP_DESCRIPTION_USER_PROFESSION_CARDS_START,
    APP_DESCRIPTION_USER_PROFESSION_CARDS,
    APP_DESCRIPTION_USER_PROFESSION_CARDS_FAIL,
    APP_PUBLIC_USER_PROFILE_ADD,
    APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA,
    APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA_NO_CARD
} from '../../actions'


//user Profile data

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitFetchStart = () => ({
    type: APP_USER_PROFILE_START
})

export const emitUserProfile = data => {
    const { dob, gender, phone, location, url, location_display, profilecards, eventwarning, resumewarning, firstName, lastName, coverpic, country_code, ethnicity, navigationName} = data
    return {
        type: APP_USER_PROFILE_ADD,
        dob,
        gender,
        phone,
        location,
        url,
        location_display,
        profilecards,
        eventwarning,
        resumewarning,
        firstName,
        lastName,
        coverpic,
        country_code,
        ethnicity,
        navigationName
    }
}

export const emitPublicUserProfile = data => {
    const { dob, gender, phone, location, url, location_display, profilecards, eventwarning, resumewarning, firstName, lastName, coverpic, country_code, ethnicity, userid, navigationName, spotlight} = data
    return {
        type: APP_PUBLIC_USER_PROFILE_ADD,
        dob,
        gender,
        phone,
        location,
        url,
        location_display,
        profilecards,
        eventwarning,
        resumewarning,
        firstName,
        lastName,
        coverpic,
        country_code,
        ethnicity,
        userid,
        navigationName,
        spotlight
    }
}

export const emitUserProfileFailed = (data) => ({
    type: APP_USER_PROFILE_FAIL,
    message: data.message,
    statusCode:data.statusCode,

})


//post
const postProfileUpdate = ({ jwt, dob, gender, phone, location, url: imageUrl, country_code, ethnicity }) => {
    
    
    let url = process.env.REACT_APP_SERVER + '/api/v1/userprofile/profile'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify({ dob, gender, phone, location, url: imageUrl, country_code, ethnicity })
    })
}

export const startUserProfileData = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return postProfileUpdate(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 201) {
                    dispatch(emitUserProfile(json.data))
                    dispatch(updateProfileCreated(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                }else {
                    dispatch(emitUserProfileFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitUserProfileFailed(err))
            })
    }

}

//get

const getProfileUpdate = ({ jwt }) => {

    let url = process.env.REACT_APP_SERVER + '/api/v1/userprofile/profile'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchUserProfileData = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return getProfileUpdate(data)
            .then(parseJSON)
            .then((json) => {
                if (json.statusCode === 200) {
                    dispatch(emitUserProfile(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUserProfileFailed(json))
                }
            })
            .catch(err => {
                
                
                dispatch(emitUserProfileFailed(err))
            })
    }

} 

//get user profile by user id

const getProfileDataByUserId = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/userprofile/user/profile/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchUserProfileDataByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return getProfileDataByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitPublicUserProfile(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUserProfileFailed(json))
                }
            })
            .catch(err => {
                
                
                dispatch(emitUserProfileFailed(err))
            })
    }

}

//get user profile by user nav name

const getProfileDataByUserNavName = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/userprofile/id/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchUserProfileDataByUserNavName = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return getProfileDataByUserNavName(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitPublicUserProfile(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUserProfileFailed(json))
                }
            })
            .catch(err => {
                
                
                dispatch(emitUserProfileFailed(err))
            })
    }

}

//put

const putProfileUpdate = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/userprofile/profile'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutUserProfileData = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return putProfileUpdate(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUserProfile(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUserProfileFailed(json))
                }
            })
            .catch(err => {
                
                
                dispatch(emitUserProfileFailed(err))
            })
    }

}

//delete profile card

export const emitProfileCardStart = () => ({
    type: APP_DELETE_PROFILE_CARD_START
})

export const emitProfileCard = (profilecards) => ({
    type: APP_DELETE_PROFILE_CARD,
    profilecards
})

export const emitProfileCardFailed = () => ({
    type: APP_DELETE_PROFILE_CARD_FAILED
})

const deleteCard = ({ jwt, profilecardid }) => {

    let url = process.env.REACT_APP_SERVER + '/api/v1/userprofile/profilecard'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify({ profilecardid })
    })
}

export const deleteProfileCard = (data) => {
    return (dispatch) => {
        dispatch(emitProfileCardStart())
        return deleteCard(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitProfileCard(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitProfileCardFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitProfileCardFailed(err))
            })
    }

}

//get featured profile data for profession card by id

export const emitGetFeaturedProfileDataStart = () => ({
    type: APP_GET_FEATURED_USER_PROFILE_DATA_START
})

export const emitGetFeaturedProfileData = data => {
    return {
        type: APP_GET_FEATURED_USER_PROFILE_DATA,
        data
    }
}

export const emitGetFeaturedPublicProfileData = data => {
    return {
        type: APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA,
        data
    }
}

export const emitGetFeaturedProfileDataFailed = (data) => ({
    type: APP_GET_FEATURED_USER_PROFILE_DATA_FAIL,
    message: data.message,
    statusCode: data.statusCode,
    
})

const getFeaturedProfileData = ({ jwt, professionCardId, featured }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/userprofile/view/professioncard/${professionCardId}/featured/${featured}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetFeaturedProfileData = (data) => {
    return (dispatch) => {
        dispatch(emitGetFeaturedProfileDataStart())
        return getFeaturedProfileData(data)
        .then(parseJSON)
        .then(json => {
            
            if (json.statusCode === 200) {
                dispatch(emitGetFeaturedProfileData(json.data))
            } else if (json.statusCode === 401 || json.statusCode === 422) {
                dispatch(emitAuthFail())
            } else {
                dispatch(emitGetFeaturedProfileDataFailed(json))
            }
        })
        .catch(err => {
            
            dispatch(emitGetFeaturedProfileDataFailed(err))
        })
    }
}

//get featured profile data for profession card by id  and user id
const getFeaturedProfileDataByUserId = ({ jwt, userId, professionCardId, featured }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/userprofile/view/user/${userId}/professioncard/${professionCardId}/featured/${featured}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetFeaturedProfileDataByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitGetFeaturedProfileDataStart())
        return getFeaturedProfileDataByUserId(data)
        .then(parseJSON)
        .then(json => {
            if (json.statusCode === 200) {
                dispatch(emitGetFeaturedPublicProfileData(json.data))
            } else if (json.statusCode === 401 || json.statusCode === 422) {
                dispatch(emitAuthFail())
            } else {
                dispatch(emitGetFeaturedProfileDataFailed(json))
            }
        })
        .catch(err => {
            
            dispatch(emitGetFeaturedProfileDataFailed(err))
        })
    }
}

//get featured profile data for no card

export const emitGetFeaturedProfileDataNoCard = data => {
    return {
        type: APP_GET_FEATURED_USER_PROFILE_DATA_NO_CARD,
        data
    }
}

export const emitGetFeaturedPublicProfileDataNoCard = data => {
    return {
        type: APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA_NO_CARD,
        data
    }
}

const getFeaturedProfileDataNoCard = ({ jwt, featured }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/userprofile/view/nocard/featured/${featured}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetFeaturedProfileDataNoCard = (data) => {
    return (dispatch) => {
        dispatch(emitGetFeaturedProfileDataStart())
        return getFeaturedProfileDataNoCard(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitGetFeaturedProfileDataNoCard(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetFeaturedProfileDataFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetFeaturedProfileDataFailed(err))
            })
    }
}

//get featured profile data for no card by user id
const getFeaturedProfileDataNoCardByUserId = ({ jwt, userId, featured }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/userprofile/view/nocard/user/${userId}/featured/${featured}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetFeaturedProfileDataNoCardByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitGetFeaturedProfileDataStart())
        return getFeaturedProfileDataNoCardByUserId(data)
            .then(parseJSON)
            .then(json => {
                if (json.statusCode === 200) {
                    dispatch(emitGetFeaturedPublicProfileDataNoCard(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetFeaturedProfileDataFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetFeaturedProfileDataFailed(err))
            })
    }
}

// add description to card
export const emitUserProfessionsCardDescriptionStart = () => ({
    type: APP_DESCRIPTION_USER_PROFESSION_CARDS_START
})


export const emitUserProfessionsCardDescription = (data) => {
    return {
        type: APP_DESCRIPTION_USER_PROFESSION_CARDS,
        data
    }
}

export const emitUserProfessionsCardDescriptionFailed = (data) => ({
    type: APP_DESCRIPTION_USER_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const addUserProfessionCardDescription = ({ jwt, data, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/card/card/description/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAddUserProfessionCardDescription = (data) => {
    return (dispatch) => {
        dispatch(emitUserProfessionsCardDescriptionStart())
        return addUserProfessionCardDescription(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUserProfessionsCardDescription(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUserProfessionsCardDescriptionFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUserProfessionsCardDescriptionFailed(err))
            })
    }

}

// add description to user profile
const addUserDescription = ({ jwt, data}) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/userprofile/description`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAddDescription = (data) => {
    return (dispatch) => {
        dispatch(emitUserProfessionsCardDescriptionStart())
        return addUserDescription(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUserProfessionsCardDescription(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUserProfessionsCardDescriptionFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUserProfessionsCardDescriptionFailed(err))
            })
    }

}