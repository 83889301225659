import {
    APP_CREATE_ACCOLADES_COMPETITION_START,
    APP_CREATE_ACCOLADES_COMPETITION,
    APP_CREATE_ACCOLADES_COMPETITION_FAIL,
    APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_START,
    APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO,
    APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_FAIL,
    APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_START,
    APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS,
    APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../../../actions'

import { createSelector } from 'reselect'

const initialState = {
    isLoading: false,
    isLoadingAdditionalInfo: false,
    isLoadingAccoladesCompetitionsToProfessionCards: false,
    error: false,
    message: '',
    isSuccess: false,
    Competition: {},
    statusCode: 201
}


export const accoladesCompetitions = (state = initialState, action) => {
    switch (action.type) {
        case APP_CREATE_ACCOLADES_COMPETITION_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_ACCOLADES_COMPETITION:
            return Object.assign({}, state, {
                Competition: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_ACCOLADES_COMPETITION_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_START:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                Competition: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_FAIL:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingAccoladesCompetitionsToProfessionCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                isLoadingAccoladesCompetitionsToProfessionCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingAccoladesCompetitionsToProfessionCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.accoladesCompetitions.error
export const getMsg = state => state.accoladesCompetitions.message
export const getisLoading = state => state.accoladesCompetitions.isLoading
export const getIsSuccess = state => state.accoladesCompetitions.isSuccess
export const getstatusCode = state => state.accoladesCompetitions.statusCode
export const getCompetition = state => state.accoladesCompetitions.Competition
export const getisLoadingAdditionalInfo = state => state.accoladesCompetitions.isLoadingAdditionalInfo
export const getisLoadingAccoladesCompetitionsToProfessionCards = state => state.accoladesCompetitions.isLoadingAccoladesCompetitionsToProfessionCards

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
