import {
    SAVE_MEDIA_VIDEO_PROFESSION_CARDS,
    SAVE_MEDIA_VIDEO_TYPE,
    SAVE_MEDIA_VIDEO_URL,
    CLEAR_MEDIA_VIDEO,
    APP_LOGOUT
} from '../../../actions'

const initialState = {
    professionCardIds: [],
    type:"",
    videoUrl:""
}

export const mediaVideoUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_MEDIA_VIDEO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                professionCardIds: action.data
            })
        case SAVE_MEDIA_VIDEO_TYPE:
            return Object.assign({}, state, {
                type: action.data
            })
        case SAVE_MEDIA_VIDEO_URL:
            return Object.assign({}, state, {
                videoUrl: action.data
            })
        case CLEAR_MEDIA_VIDEO:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getProfessionCardIds = state => state.mediaVideoUI.professionCardIds
export const getType = state => state.mediaVideoUI.type
export const getVideoUrl = state => state.mediaVideoUI.videoUrl
