import {
    APP_GET_FEATURED_MEDIA_PHOTO_START,
    APP_GET_FEATURED_MEDIA_PHOTO,
    APP_GET_FEATURED_MEDIA_PHOTO_FAIL,
    APP_PUT_FEATURED_MEDIA_PHOTO_START,
    APP_PUT_FEATURED_MEDIA_PHOTO,
    APP_PUT_FEATURED_MEDIA_PHOTO_FAIL,
    APP_UNASSIGN_MEDIA_PHOTO_START,
    APP_UNASSIGN_MEDIA_PHOTO,
    APP_UNASSIGN_MEDIA_PHOTO_FAIL,
    APP_GET_CARD_MEDIA_PHOTO_START,
    APP_GET_CARD_MEDIA_PHOTO,
    APP_GET_CARD_MEDIA_PHOTO_FAIL,
    APP_GET_MEDIA_PHOTO_START,
    APP_GET_MEDIA_PHOTO,
    APP_GET_MEDIA_PHOTO_FAIL,
    APP_DELETE_MEDIA_PHOTO_START,
    APP_DELETE_MEDIA_PHOTO,
    APP_DELETE_MEDIA_PHOTO_FAIL,
    APP_PUT_MEDIA_PHOTO_START,
    APP_PUT_MEDIA_PHOTO,
    APP_PUT_MEDIA_PHOTO_FAIL,
    APP_ASSIGN_MEDIA_PHOTO_START,
    APP_ASSIGN_MEDIA_PHOTO,
    APP_ASSIGN_MEDIA_PHOTO_FAIL,
    APP_LOGOUT,
} from '../../../actions'

const initialState = {
    allphotos:[],
    cardphotos: [],
    featuredPhotos: [],
    isLoading: false,
    isLoadingAssign: false,
    isLoadingPut: false,
    isLoadingAll: false,
    isLoadingFeatured: false,
    isLoadingUnassign: false,
    isDeleteLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const mediaPhotoEdit = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_FEATURED_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isLoadingFeatured: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_FEATURED_MEDIA_PHOTO:
            return Object.assign({}, state, {
                featuredPhotos: action.data ? action.data:[],
                isLoadingFeatured: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FEATURED_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                isLoadingFeatured: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_FEATURED_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isLoadingPutFeatured: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_FEATURED_MEDIA_PHOTO:
            return Object.assign({}, state, {
                isLoadingPutFeatured: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_FEATURED_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                isLoadingPutFeatured: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_CARD_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_CARD_MEDIA_PHOTO:
            return Object.assign({}, state, {
                cardphotos:action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_CARD_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isLoadingAll: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_MEDIA_PHOTO:
            return Object.assign({}, state, {
                allphotos: action.data ? action.data : [],
                isLoadingAll: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                isLoadingAll: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isDeleteLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_MEDIA_PHOTO:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_UNASSIGN_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isLoadingUnassign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_UNASSIGN_MEDIA_PHOTO:
            return Object.assign({}, state, {
                isLoadingUnassign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_UNASSIGN_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                isLoadingUnassign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isLoadingPut: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_MEDIA_PHOTO:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_ASSIGN_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isLoadingAssign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_ASSIGN_MEDIA_PHOTO:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_ASSIGN_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.mediaPhotoEdit.error
export const getMsg = state => state.mediaPhotoEdit.message
export const getisLoading = state => state.mediaPhotoEdit.isLoading
export const getisLoadingFeatured = state => state.mediaPhotoEdit.isLoadingFeatured
export const getisLoadingPutFeatured = state => state.mediaPhotoEdit.isLoadingPutFeatured
export const getisLoadingAll = state => state.mediaPhotoEdit.isLoadingAll
export const getisLoadingUnassign = state => state.mediaPhotoEdit.isLoadingUnassign
export const getisLoadingAssign = state => state.mediaPhotoEdit.isLoadingAssign
export const getisDeleteLoading = state => state.mediaPhotoEdit.isDeleteLoading
export const getisLoadingPut = state => state.mediaPhotoEdit.isLoadingPut
export const getCardPhotos = state => state.mediaPhotoEdit.cardphotos
export const getFeaturedPhotos = state => state.mediaPhotoEdit.featuredPhotos
export const getAllphotos = state => state.mediaPhotoEdit.allphotos
export const getIsSuccess = state => state.mediaPhotoEdit.isSuccess
export const getstatusCode = state => state.mediaPhotoEdit.statusCode

