import React from 'react';
import { OpenLink } from '../../../../helpers/utils'

const LinksCard = (props) => {
    return (
        <div className="linksCard" onClick={(e) => {
            e.preventDefault();
            OpenLink(props.description)
        }}>
        <div className="links__img">
            <img src={props.img} alt="icon" />
            </div>
        <div className="links__text">
            <h5>{props.heading}</h5>
            <p className="links__text__desc">{props.description}</p>
        </div>
        <div className="clear"></div>
        </div>
    );
};

export default LinksCard;