import React from "react";
import Close from "../../../../assets/images/close.png";

const PopupPlain = (props) => {
  return (
    <div className={"popup__plain " + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          <div className="popup__content">
            {props.children}
            <span onClick={props.close} className="close__popup">
              <img src={Close} alt="close" />
            </span>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default PopupPlain;
