import { parseJSON, composeAuth } from '../../../../../helpers/utils'

import {
    APP_CREATE_ACCOLADES_COMPETITION_START,
    APP_CREATE_ACCOLADES_COMPETITION,
    APP_CREATE_ACCOLADES_COMPETITION_FAIL,
    APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_START,
    APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO,
    APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_FAIL,
    APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_START,
    APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS,
    APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../../actions'


//create competition

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitAccoladesCompetitionStart = () => ({
    type: APP_CREATE_ACCOLADES_COMPETITION_START
})

export const emitAccoladesCompetition = data => {
    return {
        type: APP_CREATE_ACCOLADES_COMPETITION,
        data
    }
}

export const emitAccoladesCompetitionFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_COMPETITION_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesCompetition = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/accolades/competition/competition'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createAccoladesCompetition = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionStart())
        return postAccoladesCompetition(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesCompetition(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionFailed(err))
            })
    }

}

// add additional info to competition

export const emitAccoladesCompetitionAdditionalInfoStart = () => ({
    type: APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_START
})

export const emitAccoladesCompetitionAdditionalInfo = data => {
    return {
        type: APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO,
        data
    }
}

export const emitAccoladesCompetitionAdditionalInfoFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_COMPETITION_ADDITIONAL_INFO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesCompetitionAdditionalInfo = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/competition/information/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createAccoladesCompetitionAdditionalInfo = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionAdditionalInfoStart())
        return postAccoladesCompetitionAdditionalInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesCompetitionAdditionalInfo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionAdditionalInfoFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionAdditionalInfoFailed(err))
            })
    }
}

// get competition

const getAccoladesCompetition = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/competition/${id}`;
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchAccoladesCompetition = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionStart())
        return getAccoladesCompetition(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesCompetition(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionFailed(err))
            })
    }

}

// add competition to professions Cards


export const emitAccoladesCompetitionToProfessionCardsStart = () => ({
    type: APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_START
})

export const emitAccoladesCompetitionToProfessionCards = data => {
    return {
        type: APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS,
        data
    }
}

export const emitAccoladesCompetitionToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_COMPETITION_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesCompetitionToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/accolades/competition/competition/professioncard'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignAccoladesCompetitionToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionToProfessionCardsStart())
        return postAccoladesCompetitionToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesCompetitionToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionToProfessionCardsFailed(err))
            })
    }
}

// update competition

const putAccoladesCompetition = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/competition/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateAccoladesCompetition = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionStart())
        return putAccoladesCompetition(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesCompetition(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionFailed(err))
            })
    }

}

// get accoladesCompetition resume by card id

export const emitAccoladesCompetitionByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitAccoladesCompetitionByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitAccoladesCompetitionByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getAccoladesCompetitionByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/competition/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetAccoladesCompetitionByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionByCardIdStart())
        return getAccoladesCompetitionByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesCompetitionByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionByCardIdFailed(err))
            })
    }
}

// get accoladesCompetition resume by card id by user id
const getAccoladesCompetitionByCardIdByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/competition/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetAccoladesCompetitionByCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionByCardIdStart())
        return getAccoladesCompetitionByCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesCompetitionByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionByCardIdFailed(err))
            })
    }
}

//get user accolades competition - no cards
const getUserAccoladesCompetition = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/user/competition`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserAccoladesCompetition = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionByCardIdStart())
        return getUserAccoladesCompetition(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesCompetitionByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionByCardIdFailed(err))
            })
    }
}

//get user accolades competition - no cards by user id
const getUserAccoladesCompetitionByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/users/competition/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserAccoladesCompetitionByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesCompetitionByCardIdStart())
        return getUserAccoladesCompetitionByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesCompetitionByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesCompetitionByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesCompetitionByCardIdFailed(err))
            })
    }
}

// delete accoladesCompetition by id

export const emitDeleteAccoladesCompetitionStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteAccoladesCompetition = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteAccoladesCompetitionFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteAccoladesCompetition = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/delete/competitions`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteAccoladesCompetition = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteAccoladesCompetitionStart())
        return deleteAccoladesCompetition(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteAccoladesCompetition(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteAccoladesCompetitionFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteAccoladesCompetitionFailed(err))
            })
    }
}

// assign accoladesCompetition to card

export const emitAssignAccoladesCompetitionStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignAccoladesCompetition = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignAccoladesCompetitionFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignAccoladesCompetition = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/competition/reassign/professioncard/competitions`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignAccoladesCompetition = (data) => {
    return (dispatch) => {
        dispatch(emitAssignAccoladesCompetitionStart())
        return assignAccoladesCompetition(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignAccoladesCompetition(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignAccoladesCompetitionFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignAccoladesCompetitionFailed(err))
            })
    }
}