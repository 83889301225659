import React from "react";
import Slider from "react-slick";
import { Scrollbars } from "react-custom-scrollbars";

//assets
import SkillBook from "../assets/layouts/cards/skillBook";
import LeftArrow from '../../assets/images/arrowLeft.svg'
import RightArrow from '../../assets/images/arrowRight.svg'

const Sidebar = (props) => {
  let settings = {
    arrows:false,
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
    speed: 500,
  };

  const next = () => {
    props?.cardSliderRef?.current?.slickNext();
  };
  const previous = () => {
    props?.cardSliderRef?.current?.slickPrev();
  };

  return (
    <div className="sidebar">
      <div className="skillTabList">
        <Slider {...settings} ref={props?.cardSliderRef}>
          {props?.userProfessionsArr?.map((pro, index) => {
            return (
              <li
                onClick={() => props.onChangeUserProfession(pro)}
                key={index}
                className={
                  props?.selectedUserProfession?.id === pro?.id ? "active" : ""
                }
              >
                {pro?.name}
              </li>
            );
          })}
        </Slider>
        <div onClick={previous} className="arrow__left">
            <img alt="left arrow" src={LeftArrow} />
        </div>
        <div onClick={next} className="arrow__right">
            <img alt="right arrow" src={RightArrow} />
        </div>
      </div>

      <div className="skillSet__wrap">
        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={430}>
          {props?.userCards?.map((card, index) => {
            return (
              <SkillBook
                cardActive={card?.active}
                key={index}
                selected={props?.selectedUserCard?.id === card?.id}
                className={
                  props?.selectedUserCard?.id === card?.id ? "highlight" : ""
                }
                name={card?.name}
                engagements={
                  card?.contracttype === "contract"
                    ? card?.final_contracts
                    : card?.contracttype === "slot"
                    ? card?.final_slots
                    : card?.contracttype === "service"
                    ? card?.final_bookings
                    : 0
                }
                skillCount={props?.allSkillsByCardId[card?.id]?.length}
                skills={props?.allSpecializationSkillsByCardId[card?.id]}
                online={card?.contracttype === "service" || card?.contracttype === "contract" ? "" :card?.online_status ? "yes" : "no"}
                travel={
                    card?.contracttype === "service" || card?.contracttype === "contract"
                    ? ""
                    : card?.travel_status === "set"
                    ? "yes"
                    : "no"
                }
                price={
                  card?.contracttype === "contract"
                    ? card?.contract_amount
                      ? "$" + card?.contract_amount
                      : ""
                    : card?.contracttype === "slot"
                    ? card?.amount
                      ? "$" + card?.amount
                      : ""
                    : card?.contracttype === "service"
                        ? card?.amount
                            ? "$" + card?.amount
                            : "Get a Quote"
                        : ""
                }
                priceFor={
                  card?.contracttype === "service"
                        ? `per ${card?.type}`
                    : card?.contracttype === "slot"
                    ? `for ${card?.slot_length} mins`
                    : card?.contracttype === "contract"
                    ? "per Engagement"
                    : ""
                }
                buttonText={card?.contracttype === "service"
                  ? 'SEND REQUEST'
                  : card?.contracttype === "slot"
                    ? 'BOOK NOW'
                    : card?.contracttype === "contract"
                      ? "BOOK NOW"
                      : ""}
                onClickCard={() => props.onChangeCard(card)}
                onClickAskMe={() => props.onClickAskMe()}
                onClickButtton={(e) => props.onClickButtton(e)}
              />
            );
          })}
        </Scrollbars>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default Sidebar;
