
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_POST_MEDIA_VIDEO_START,
    APP_POST_MEDIA_VIDEO,
    APP_POST_MEDIA_VIDEO_FAIL,
    APP_DELETE_MEDIA_VIDEO_START,
    APP_DELETE_MEDIA_VIDEO,
    APP_DELETE_MEDIA_VIDEO_FAIL,
    APP_LOGOUT,
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitMediaVideoStart = () => ({
    type: APP_POST_MEDIA_VIDEO_START
})

export const emitMediaVideo = data => {
    return {
        type: APP_POST_MEDIA_VIDEO,
        data
    }
}

export const emitMediaVideoFailed = (data) => ({
    type: APP_POST_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postMediaVideo = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/videos/video'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPostMediaVideo = (data) => {
    return (dispatch) => {
        dispatch(emitMediaVideoStart())
        return postMediaVideo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaVideoFailed(err))
            })
    }

}

//update media video

const putMediaVideo = ({ jwt, data ,id}) => {
    let Authorization = composeAuth(jwt)
    let url =  process.env.REACT_APP_SERVER + `/api/v1/media/videos/video/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutMediaVideo = (data) => {
    return (dispatch) => {
        dispatch(emitMediaVideoStart())
        return putMediaVideo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaVideoFailed(err))
            })
    }
}
//get media video

const getMediaVideo = ({ jwt ,ids}) => {
    let Authorization = composeAuth(jwt)
    let stringifyIds = encodeURI(JSON.stringify(ids))
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/video/id/${stringifyIds}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMediaVideo = (data) => {
    return (dispatch) => {
        dispatch(emitMediaVideoStart())
        return getMediaVideo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaVideoFailed(err))
            })
    }
}

//delete Video

export const emitMediaVideoDeleteStart = () => ({
    type: APP_DELETE_MEDIA_VIDEO_START
})

export const emitMediaVideoDelete = data => {
    return {
        type: APP_DELETE_MEDIA_VIDEO,
        data
    }
}

export const emitMediaVideoDeleteFailed = (data) => ({
    type: APP_DELETE_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const deleteMediaVideo = ({ jwt, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/video/${id}`
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startDeleteMediaVideo = (data) => {
    return (dispatch) => {
        dispatch(emitMediaVideoDeleteStart())
        return deleteMediaVideo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMediaVideoDelete(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaVideoDeleteFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaVideoDeleteFailed(err))
            })
    }

}
