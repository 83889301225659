import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//assets
import { NormalCarousel } from '../assets/layouts/carousel/normalCarousel'
import People1 from "../../assets/images/profileIcon.svg";
import Spinner from "../assets/molecules/spinner";

//actions
import { startGetRandomUsers } from '../../redux/actions/account'

//reducers
import { getAnnouncements } from '../../redux/reducers/announcements'
import { getisLoadingGetSpolightUsers, getSpotlightUsers } from '../../redux/reducers/account/accounts'

//helpers
import { usePrevious } from '../../helpers/utils'

const UserSection = () => {
    const dispatch = useDispatch()

    let history = useHistory()

    const [allSpotligthUsers, setAllSpotligthUsers] = useState([])

    const spotlightUsers = useSelector(getSpotlightUsers)
    const announcements = useSelector(getAnnouncements)
    const isLoadingGetSpolightUsers = useSelector(getisLoadingGetSpolightUsers)
    const isLoadingGetSpolightUsersPrev = usePrevious(isLoadingGetSpolightUsers)

    useEffect(() => {
        dispatch(startGetRandomUsers())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadingGetSpolightUsersPrev !== isLoadingGetSpolightUsers && !isLoadingGetSpolightUsers && isLoadingGetSpolightUsers !== undefined) {
            setAllSpotligthUsers(spotlightUsers?.map((item) => {
                return {
                    url: item?.url ? item?.url : People1,
                    title: item?.firstName + " " + item?.lastName,
                    description: item?.location,
                    id: item?.id,
                    navigation_name: item?.navigation_name
                }
            }))
        }
    }, [isLoadingGetSpolightUsers, isLoadingGetSpolightUsersPrev]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        isLoadingGetSpolightUsers ? <div className="container paddingContainer minHeightContainer">
            <Spinner />
        </div> : <div className="container">
                {allSpotligthUsers && allSpotligthUsers?.length ? <NormalCarousel className={"professional__group--extraTopMargin"} header="New to Resonance" data={allSpotligthUsers} onClick={(people) => history.push({
                pathname: `/search/people/${people?.navigation_name}`,
            })} /> : null}
            <div className="clear"></div>
        </div>
    );
};

export default UserSection;
