import {
    APP_GET_ROLES_BY_WORKID_START,
    APP_GET_ROLES_BY_WORKID,
    APP_GET_ROLES_BY_WORKID_FAIL,
    APP_POST_ROLES_BY_WORKID_START,
    APP_POST_ROLES_BY_WORKID,
    APP_POST_ROLES_BY_WORKID_FAIL,
    APP_POST_NEW_ROLES_BY_WORKID_START,
    APP_POST_NEW_ROLES_BY_WORKID,
    APP_POST_NEW_ROLES_BY_WORKID_FAIL,
    APP_GET_ROLE_TYPES_START,
    APP_GET_ROLE_TYPES,
    APP_GET_ROLE_TYPES_FAIL,
    APP_GET_ROLE_CATEGORIES_START,
    APP_GET_ROLE_CATEGORIES,
    APP_GET_ROLE_CATEGORIES_FAIL,
    APP_GET_ROLE_GROUPS_START,
    APP_GET_ROLE_GROUPS,
    APP_GET_ROLE_GROUPS_FAIL,
    APP_PUT_ROLE_START,
    APP_PUT_ROLE,
    APP_PUT_ROLE_FAIL,
    APP_DELETE_ROLE_START,
    APP_DELETE_ROLE,
    APP_DELETE_ROLE_FAIL,
    APP_CONFIRM_ROLE_START,
    APP_CONFIRM_ROLE,
    APP_CONFIRM_ROLE_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'
import { getById } from '../../../../helpers/utils'

const initialState = {
    isLoadingGetRolesByWorkId: false,
    isLoadingPostRolesByWorkId: false,
    isLoadingRoleTypes: false,
    isLoadingRoleCategories: false,
    isLoadingRoleGroups: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    rolesByWorkId: [],
    roleTypes:[],
    roleCategories:[],
    roleGroups:[],
    isLoadingPostNewRolesByWorkId:false,
    isLoadingPutRole:false,
    isLoadingDeleteRole:false,
    isLoadingConfirmRole:false,
    newRole:{}
}


export const Roles = (state = initialState, action) => {
    switch (action.type) {
        case APP_CONFIRM_ROLE_START:
            return Object.assign({}, state, {
                isLoadingConfirmRole: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CONFIRM_ROLE:
            return Object.assign({}, state, {
                isLoadingConfirmRole: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CONFIRM_ROLE_FAIL:
            return Object.assign({}, state, {
                isLoadingConfirmRole: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_ROLE_START:
            return Object.assign({}, state, {
                isLoadingPutRole: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_ROLE:
            return Object.assign({}, state, {
                isLoadingPutRole: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_ROLE_FAIL:
            return Object.assign({}, state, {
                isLoadingPutRole: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_ROLE_START:
            return Object.assign({}, state, {
                isLoadingDeleteRole: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_ROLE:
            return Object.assign({}, state, {
                isLoadingDeleteRole: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_ROLE_FAIL:
            return Object.assign({}, state, {
                isLoadingDeleteRole: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_ROLE_CATEGORIES_START:
            return Object.assign({}, state, {
                isLoadingRoleCategories: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ROLE_CATEGORIES:
            return Object.assign({}, state, {
                isLoadingRoleCategories: false,
                roleCategories:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ROLE_CATEGORIES_FAIL:
            return Object.assign({}, state, {
                isLoadingRoleCategories: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_ROLE_GROUPS_START:
            return Object.assign({}, state, {
                isLoadingRoleGroups: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ROLE_GROUPS:
            return Object.assign({}, state, {
                isLoadingRoleGroups: false,
                roleGroups:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ROLE_GROUPS_FAIL:
            return Object.assign({}, state, {
                isLoadingRoleGroups: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_ROLE_TYPES_START:
            return Object.assign({}, state, {
                isLoadingRoleTypes: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ROLE_TYPES:
            return Object.assign({}, state, {
                isLoadingRoleTypes: false,
                roleTypes:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ROLE_TYPES_FAIL:
            return Object.assign({}, state, {
                isLoadingRoleTypes: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_ROLES_BY_WORKID_START:
            return Object.assign({}, state, {
                isLoadingPostRolesByWorkId: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_ROLES_BY_WORKID:
            return Object.assign({}, state, {
                isLoadingPostRolesByWorkId: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_ROLES_BY_WORKID_FAIL:
            return Object.assign({}, state, {
                isLoadingPostRolesByWorkId: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_NEW_ROLES_BY_WORKID_START:
            return Object.assign({}, state, {
                isLoadingPostNewRolesByWorkId: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_NEW_ROLES_BY_WORKID:
            return Object.assign({}, state, {
                isLoadingPostNewRolesByWorkId: false,
                newRole:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_NEW_ROLES_BY_WORKID_FAIL:
            return Object.assign({}, state, {
                isLoadingPostNewRolesByWorkId: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_ROLES_BY_WORKID_START:
            return Object.assign({}, state, {
                isLoadingGetRolesByWorkId: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ROLES_BY_WORKID:
            return Object.assign({}, state, {
                isLoadingGetRolesByWorkId: false,
                rolesByWorkId:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ROLES_BY_WORKID_FAIL:
            return Object.assign({}, state, {
                isLoadingGetRolesByWorkId: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.Roles.error
export const getMsg = state => state.Roles.message
export const getIsSuccess = state => state.Roles.isSuccess
export const getstatusCode = state => state.Roles.statusCode
export const getisLoadingGetRolesByWorkId = state => state.Roles.isLoadingGetRolesByWorkId
export const getisLoadingPostRolesByWorkId = state => state.Roles.isLoadingPostRolesByWorkId
export const getRolesByWorkId = state => state.Roles.rolesByWorkId
export const getisLoadingRoleTypes = state => state.Roles.isLoadingRoleTypes
export const getRoleTypes = state => state.Roles.roleTypes
export const getisLoadingRoleCategories = state => state.Roles.isLoadingRoleCategories
export const getRoleCategories = state => state.Roles.roleCategories
export const getisLoadingRoleGroups = state => state.Roles.isLoadingRoleGroups
export const getRoleGroups = state => state.Roles.roleGroups
export const getisLoadingPostNewRolesByWorkId = state => state.Roles.isLoadingPostNewRolesByWorkId
export const getNewRoles = state => state.Roles.newRole
export const getisLoadingDeleteRole = state => state.Roles.isLoadingDeleteRole
export const getisLoadingPutRole = state => state.Roles.isLoadingPutRole
export const getisLoadingConfirmRole = state => state.Roles.isLoadingConfirmRole

export const getRolesByWorkIdById = state => {
    return getById(state.Roles.rolesByWorkId)
}

export const getRoleTypesById = state => {
    return getById(state.Roles.roleTypes)
}

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
