import {
    APP_POST_PER_SERVICE_START,
    APP_POST_PER_SERVICE,
    APP_POST_PER_SERVICE_FAIL,
    APP_POST_PER_SERVICE_REQUEST_START,
    APP_POST_PER_SERVICE_REQUEST,
    APP_POST_PER_SERVICE_REQUEST_FAIL,
    APP_GET_SERVICES_START,
    APP_GET_SERVICES,
    APP_GET_SERVICES_FAIL,
    APP_GET_SERVICE_BY_ID_START,
    APP_GET_SERVICE_BY_ID,
    APP_GET_SERVICE_BY_ID_FAIL,
    APP_SERVICE_REQUEST_STATUS_UPDATE_START,
    APP_SERVICE_REQUEST_STATUS_UPDATE,
    APP_SERVICE_REQUEST_STATUS_UPDATE_FAIL,
    APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT_START,
    APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT,
    APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT_FAIL,
    APP_SERVICE_REQUEST_PAYMENT_FAILED_START,
    APP_SERVICE_REQUEST_PAYMENT_FAILED,
    APP_SERVICE_REQUEST_PAYMENT_FAILED_FAIL,
    APP_SERVICE_REQUEST_RE_PAYMENT_START,
    APP_SERVICE_REQUEST_RE_PAYMENT,
    APP_SERVICE_REQUEST_RE_PAYMENT_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    perServiceData:{},
    perServiceRequestData:{},
    isLoadingPostPerService:false,
    isLoadingPostPerServiceRequest:false,
    isLoadingGetServiceRequest:false,
    serviceRequests:[],
    isLoadingGetServiceRequestById:false,
    serviceRequest:{},
    isLoadingServiceRequestStatusUpdation:false,
    isLoadingServiceRequestFailedPaymentCount:false,
    isLoadingServiceRequestFailedPayment:false,
    failedPaymentServiceRequests:[],
    failedPaymentServiceRequestsCount:"",
    isLoadingServiceRequestRePayment:false,
    paymentNextAction:{},
}


export const perService = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_PER_SERVICE_START:
            return Object.assign({}, state, {
                isLoadingPostPerService: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_PER_SERVICE:
            return Object.assign({}, state, {
                isLoadingPostPerService: false,
                error: false,
                message: '',
                isSuccess: true,
                perServiceData: action?.data
            })
        case APP_POST_PER_SERVICE_FAIL:
            return Object.assign({}, state, {
                isLoadingPostPerService: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
            })
        case APP_POST_PER_SERVICE_REQUEST_START:
            return Object.assign({}, state, {
                isLoadingPostPerServiceRequest: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_PER_SERVICE_REQUEST:
            return Object.assign({}, state, {
                isLoadingPostPerServiceRequest: false,
                error: false,
                message: '',
                isSuccess: true,
                perServiceRequestData: action?.data
            })
        case APP_POST_PER_SERVICE_REQUEST_FAIL:
            return Object.assign({}, state, {
                isLoadingPostPerServiceRequest: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
            })
        case APP_GET_SERVICES_START:
            return Object.assign({}, state, {
                isLoadingGetServiceRequest: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SERVICES:
            return Object.assign({}, state, {
                isLoadingGetServiceRequest: false,
                error: false,
                message: '',
                isSuccess: true,
                serviceRequests: action?.data
            })
        case APP_GET_SERVICES_FAIL:
            return Object.assign({}, state, {
                isLoadingGetServiceRequest: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
                serviceRequests:[]
            })
        case APP_GET_SERVICE_BY_ID_START:
            return Object.assign({}, state, {
                isLoadingGetServiceRequestById: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SERVICE_BY_ID:
            return Object.assign({}, state, {
                isLoadingGetServiceRequestById: false,
                error: false,
                message: '',
                isSuccess: true,
                serviceRequest: action?.data && action?.data[0] ? action?.data[0] : {}
            })
        case APP_GET_SERVICE_BY_ID_FAIL:
            return Object.assign({}, state, {
                isLoadingGetServiceRequestById: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
                serviceRequest:{}
            })
        case APP_SERVICE_REQUEST_STATUS_UPDATE_START:
            return Object.assign({}, state, {
                isLoadingServiceRequestStatusUpdation: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_SERVICE_REQUEST_STATUS_UPDATE:
            return Object.assign({}, state, {
                isLoadingServiceRequestStatusUpdation: false,
                error: false,
                message: '',
                isSuccess: true,
            })
        case APP_SERVICE_REQUEST_STATUS_UPDATE_FAIL:
            return Object.assign({}, state, {
                isLoadingServiceRequestStatusUpdation: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
            })
        case APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT_START:
            return Object.assign({}, state, {
                isLoadingServiceRequestFailedPaymentCount: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT:
            return Object.assign({}, state, {
                isLoadingServiceRequestFailedPaymentCount: false,
                error: false,
                message: '',
                isSuccess: true,
                failedPaymentServiceRequestsCount: action?.data?.failed_service_bookings
            })
        case APP_SERVICE_REQUEST_PAYMENT_FAILED_COUNT_FAIL:
            return Object.assign({}, state, {
                isLoadingServiceRequestFailedPaymentCount: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
            })
        case APP_SERVICE_REQUEST_PAYMENT_FAILED_START:
            return Object.assign({}, state, {
                isLoadingServiceRequestFailedPayment: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_SERVICE_REQUEST_PAYMENT_FAILED:
            return Object.assign({}, state, {
                isLoadingServiceRequestFailedPayment: false,
                error: false,
                message: '',
                isSuccess: true,
                failedPaymentServiceRequests:action.data
            })
        case APP_SERVICE_REQUEST_PAYMENT_FAILED_FAIL:
            return Object.assign({}, state, {
                isLoadingServiceRequestFailedPayment: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
            })
        case APP_SERVICE_REQUEST_RE_PAYMENT_START:
            return Object.assign({}, state, {
                isLoadingServiceRequestRePayment: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_SERVICE_REQUEST_RE_PAYMENT:
            return Object.assign({}, state, {
                isLoadingServiceRequestRePayment: false,
                error: false,
                message: '',
                isSuccess: true,
                paymentNextAction: action.data.next_action
            })
        case APP_SERVICE_REQUEST_RE_PAYMENT_FAIL:
            return Object.assign({}, state, {
                isLoadingServiceRequestRePayment: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.perService.error
export const getMsg = state => state.perService.message
export const getIsSuccess = state => state.perService.isSuccess
export const getstatusCode = state => state.perService.statusCode
export const getPerServiceData = state => state.perService.perServiceData
export const getisLoadingPostPerService = state => state.perService.isLoadingPostPerService
export const getPerServiceRequestData = state => state.perService.perServiceRequestData
export const getIsLoadingPostPerServiceRequest = state => state.perService.isLoadingPostPerServiceRequest
export const getIsLoadingServiceRequestStatusUpdation = state => state.perService.isLoadingServiceRequestStatusUpdation

export const getIsLoadingGetServiceRequest = state => state.perService.isLoadingGetServiceRequest
export const getServiceRequests = state => state.perService.serviceRequests

export const getIsLoadingGetServiceRequestById = state => state.perService.isLoadingGetServiceRequestById
export const getServiceRequest = state => state.perService.serviceRequest
export const getIsLoadingServiceRequestFailedPaymentCount = state => state.perService.isLoadingServiceRequestFailedPaymentCount
export const getIsLoadingServiceRequestFailedPayment = state => state.perService.isLoadingServiceRequestFailedPayment
export const getFailedPaymentServiceRequests = state => state.perService.failedPaymentServiceRequests
export const getFailedPaymentServiceRequestsCount = state => state.perService.failedPaymentServiceRequestsCount
export const getIsLoadingServiceRequestRePayment = state => state.perService.isLoadingServiceRequestRePayment
export const getPaymentNextAction = state => state.perService.paymentNextAction

export const getServiceRequestsOpen = state => {
    return state.perService.serviceRequests?.filter((booking)=>booking?.status === "open")
}
export const getServiceRequestsAccepted = state => {
    return state.perService.serviceRequests?.filter((booking)=>booking?.status === "accept")
}
export const getServiceRequestsFinalized = state => {
    return state.perService.serviceRequests?.filter((booking) => booking?.status === "finalize")
}
export const getServiceRequestsCompleted = state => {
    return state.perService.serviceRequests?.filter((booking) => booking?.status === "paid")
}
export const getServiceRequestsCancelled = state => {
    return state.perService.serviceRequests?.filter((booking) => booking?.status === "cancel")
}
export const getServiceRequestsFailed = state => {
    return state.perService.serviceRequests?.filter((booking) => booking?.status === "failed")
}






