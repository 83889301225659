import {
    APP_GET_ANNOUNCEMEMTS_START,
    APP_GET_ANNOUNCEMEMTS,
    APP_GET_ANNOUNCEMEMTS_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    announcements: [],
    isLoadingGetAnnouncements: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const announcements = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_ANNOUNCEMEMTS_START:
            return Object.assign({}, state, {
                isLoadingGetAnnouncements: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ANNOUNCEMEMTS:
            return Object.assign({}, state, {
                announcements: action?.data,
                isLoadingGetAnnouncements: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ANNOUNCEMEMTS_FAIL:
            return Object.assign({}, state, {
                announcements: [],
                isLoadingGetAnnouncements: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.announcements.error
export const getMsg = state => state.announcements.message
export const getisLoadingGetAnnouncements = state => state.announcements.isLoadingGetAnnouncements
export const getAnnouncements = state => state.announcements.announcements
export const getIsSuccess = state => state.announcements.isSuccess
export const getstatusCode = state => state.announcements.statusCode