import React from "react";
import { useDispatch } from 'react-redux'

import Header from "../assets/layouts/header/dashboardHeader";
import Explore from "./explore";

//reducers
import { changeDropdownStatus } from '../../redux/actions/ui/profile'

const DashboardLayout = (props) => {
  const dispatch = useDispatch()
  return (
    <div className="main__dashboard bgLines" onClick={() => dispatch(changeDropdownStatus(false))}>
        <Header
        heading="Explore"
        explore
        showText="Welcome to Resonance! You can search for all kinds of professionals, including performers, teachers, career coaches, and more! You can also look for specific colleagues that you’ve worked with. Start your search above!"
        />
        <div className="minHeightContainer">
        <Explore />
        </div>
        <div className="clear"></div>
      </div>
  );
};

export default DashboardLayout;
