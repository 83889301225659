import {
    SAVE_PROFESSION_FILTER_SORT,
    SAVE_PROFESSION_FILTER_PRICE,
    SAVE_PROFESSION_FILTER_AVAILABILITY,
    SAVE_PROFESSION_FILTER_ONLINE_STATUS,
    SAVE_PROFESSION_FILTER_TRAVEL_STATUS,
    SAVE_GENDER_STATUS,
    SAVE_LOCATION_DIST_STATUS,
    CLEAR_PROFESSION_FILTERS,
    SAVE_LOCATION,
    SAVE_SELECTED_SEARCH_ITEM,
    SAVE_SEARCH_TEXT,
    APP_LOGOUT
} from '../../actions'

const initialState = {
    professionFilterSort:"",
    professionFilterPrice:[1,500],
    professionFilterAvailability:[],
    professionFilterOnlineStatus:false,
    professionFilterTravelStatus:[],
    professionFilterGenderStatus:["male","female","other"],
    professionFilterLocationDistStatus:[20],
    professionFilterLocation:{},
    selectedSearchItem:null,
    searchText:""
}

export const searchUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PROFESSION_FILTER_SORT:
            return Object.assign({}, state, {
                professionFilterSort: action.data
            })
        case SAVE_PROFESSION_FILTER_PRICE:
            return Object.assign({}, state, {
                professionFilterPrice: action.data
            })
        case SAVE_PROFESSION_FILTER_AVAILABILITY:
            return Object.assign({}, state, {
                professionFilterAvailability: action.data
            })
        case SAVE_PROFESSION_FILTER_ONLINE_STATUS:
            return Object.assign({}, state, {
                professionFilterOnlineStatus: action.data
            })
        case SAVE_PROFESSION_FILTER_TRAVEL_STATUS:
            return Object.assign({}, state, {
                professionFilterTravelStatus: action.data
            })
        case SAVE_GENDER_STATUS:
            return Object.assign({}, state, {
                professionFilterGenderStatus: action.data
            })
        case SAVE_LOCATION_DIST_STATUS:
            return Object.assign({}, state, {
                professionFilterLocationDistStatus: action.data
            })
        case SAVE_LOCATION:
            return Object.assign({}, state, {
                professionFilterLocation: action.data
            })
        case SAVE_SELECTED_SEARCH_ITEM:
            return Object.assign({}, state, {
                selectedSearchItem: action.data
            })
        case SAVE_SEARCH_TEXT:
            return Object.assign({}, state, {
                searchText: action.data
            })
        case CLEAR_PROFESSION_FILTERS:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getProfessionFilterSort = state => state.searchUI.professionFilterSort
export const getProfessionFilterPrice = state => state.searchUI.professionFilterPrice
export const getProfessionFilterAvailability = state => state.searchUI.professionFilterAvailability
export const getProfessionFilterOnlineStatus = state => state.searchUI.professionFilterOnlineStatus
export const getProfessionFilterTravelStatus = state => state.searchUI.professionFilterTravelStatus
export const getProfessionFilterGenderStatus = state => state.searchUI.professionFilterGenderStatus
export const getProfessionFilterLocationDistStatus = state => state.searchUI.professionFilterLocationDistStatus
export const getProfessionFilterLocation = state => state.searchUI.professionFilterLocation
export const getSelectedSearchItem = state => state.searchUI.selectedSearchItem
export const getSearchText = state => state.searchUI.searchText