import {
    SAVE_MASTERCLASS_INSTITUTION_ID,
    SAVE_MASTERCLASS_POSITION,
    SAVE_MASTERCLASS_POSITION_NAME,
    CLEAR_MASTERCLASS,
    APP_LOGOUT
} from '../../../../actions'

const initialState = {
    masterClassPosition:"",
    masterClassPositionName:"",
    masterClassInstitutionId:null,
}

export const masterClassUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_MASTERCLASS_INSTITUTION_ID:
            return Object.assign({}, state, {
                masterClassInstitutionId: action.data
            })
        case SAVE_MASTERCLASS_POSITION:
            return Object.assign({}, state, {
                masterClassPosition: action.data
            })
        case SAVE_MASTERCLASS_POSITION_NAME:
            return Object.assign({}, state, {
                masterClassPositionName: action.data
            })
        case CLEAR_MASTERCLASS:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getMasterClassInstitutionId = state => state.masterClassUI.masterClassInstitutionId
export const getMasterClassPosition = state => state.masterClassUI.masterClassPosition
export const getMasterClassPositionName = state => state.masterClassUI.masterClassPositionName