import {
    APP_EMIT_PAYMENT_SLOTS_REVIEW_START,
    APP_EMIT_PAYMENT_SLOTS_REVIEW,
    APP_EMIT_PAYMENT_SLOTS_REVIEW_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    slotReviews: [],
    isLoadingPaymentSlotsReview: false,
}


export const slotsReview = (state = initialState, action) => {
    switch (action.type) {
        case APP_EMIT_PAYMENT_SLOTS_REVIEW_START:
            return Object.assign({}, state, {
                isLoadingPaymentSlotsReview: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_EMIT_PAYMENT_SLOTS_REVIEW:
            return Object.assign({}, state, {
                isLoadingPaymentSlotsReview: false,
                slotReviews:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_EMIT_PAYMENT_SLOTS_REVIEW_FAIL:
            return Object.assign({}, state, {
                isLoadingPaymentSlotsReview: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.slotsReview.error
export const getMsg = state => state.slotsReview.message
export const getIsSuccess = state => state.slotsReview.isSuccess
export const getstatusCode = state => state.slotsReview.statusCode
export const getSlotReviews = state => state.slotsReview.slotReviews
export const getisLoadingPaymentSlotsReview = state => state.slotsReview.isLoadingPaymentSlotsReview






