import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_CREATE_EDUCATION_START,
    APP_CREATE_EDUCATION,
    APP_CREATE_EDUCATION_FAIL,
    APP_CREATE_EDUCATION_ADDITIONAL_INFO_START,
    APP_CREATE_EDUCATION_ADDITIONAL_INFO,
    APP_CREATE_EDUCATION_ADDITIONAL_INFO_FAIL,
    APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_START,
    APP_CREATE_EDUCATION_TO_PROFESSION_CARDS,
    APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../actions'


//create education

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitEducationStart = () => ({
    type: APP_CREATE_EDUCATION_START
})

export const emitEducation = data => {
    return {
        type: APP_CREATE_EDUCATION,
        data
    }
}

export const emitEducationFailed = (data) => ({
    type: APP_CREATE_EDUCATION_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postEducation = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/educations/education'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createEducation = (data) => {
    return (dispatch) => {
        dispatch(emitEducationStart())
        return postEducation(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitEducation(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationFailed(err))
            })
    }

}

// add additional info to education

export const emitEducationAdditionalInfoStart = () => ({
    type: APP_CREATE_EDUCATION_ADDITIONAL_INFO_START
})

export const emitEducationAdditionalInfo = data => {
    return {
        type: APP_CREATE_EDUCATION_ADDITIONAL_INFO,
        data
    }
}

export const emitEducationAdditionalInfoFailed = (data) => ({
    type: APP_CREATE_EDUCATION_ADDITIONAL_INFO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postEducationAdditionalInfo = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/education/information/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createEducationAdditionalInfo = (data) => {
    return (dispatch) => {
        dispatch(emitEducationAdditionalInfoStart())
        return postEducationAdditionalInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitEducationAdditionalInfo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationAdditionalInfoFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationAdditionalInfoFailed(err))
            })
    }
}

// get education

const getEducation = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/education/${id}`;
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchEducation = (data) => {
    return (dispatch) => {
        dispatch(emitEducationStart())
        return getEducation(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitEducation(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationFailed(err))
            })
    }

}

// add education to professions Cards


export const emitEducationToProfessionCardsStart = () => ({
    type: APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_START
})

export const emitEducationToProfessionCards = data => {
    return {
        type: APP_CREATE_EDUCATION_TO_PROFESSION_CARDS,
        data
    }
}

export const emitEducationToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postEducationToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/educations/education/professioncard'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignEducationToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitEducationToProfessionCardsStart())
        return postEducationToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitEducationToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationToProfessionCardsFailed(err))
            })
    }
}

// update education

const putEducation = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/education/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateEducation = (data) => {
    return (dispatch) => {
        dispatch(emitEducationStart())
        return putEducation(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 201) {
                    dispatch(emitEducation(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationFailed(err))
            })
    }

}

// get education resume by card id

export const emitEducationByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitEducationByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitEducationByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getEducationByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/education/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetEducationByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitEducationByCardIdStart())
        return getEducationByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitEducationByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationByCardIdFailed(err))
            })
    }
}

// get education resume by card id by user id

const getEducationByCardIdByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/education/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetEducationByCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitEducationByCardIdStart())
        return getEducationByCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitEducationByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationByCardIdFailed(err))
            })
    }
}

// get user education - no card
const getUserEducation = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/user/education`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserEducation = (data) => {
    return (dispatch) => {
        dispatch(emitEducationByCardIdStart())
        return getUserEducation(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitEducationByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationByCardIdFailed(err))
            })
    }
}

// get user education - no card by user id
const getUserEducationByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/users/education/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserEducationByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitEducationByCardIdStart())
        return getUserEducationByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitEducationByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEducationByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEducationByCardIdFailed(err))
            })
    }
}

// delete education by id

export const emitDeleteEducationStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteEducation = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteEducationFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteEducation = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/delete/educations`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteEducation = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteEducationStart())
        return deleteEducation(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteEducation(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteEducationFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteEducationFailed(err))
            })
    }
}

// assign education to cards

export const emitAssignEducationStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignEducation = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignEducationFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignEducation = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/educations/reassign/professioncard/educations`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignEducation = (data) => {
    return (dispatch) => {
        dispatch(emitAssignEducationStart())
        return assignEducation(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignEducation(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignEducationFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignEducationFailed(err))
            })
    }
}