import {
    APP_CREATE_EDUCATION_START,
    APP_CREATE_EDUCATION,
    APP_CREATE_EDUCATION_FAIL,
    APP_CREATE_EDUCATION_ADDITIONAL_INFO_START,
    APP_CREATE_EDUCATION_ADDITIONAL_INFO,
    APP_CREATE_EDUCATION_ADDITIONAL_INFO_FAIL,
    APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_START,
    APP_CREATE_EDUCATION_TO_PROFESSION_CARDS,
    APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'

const initialState = {
    isLoading: false,
    isLoadingAdditionalInfo: false,
    isLoadingEducationToProfessionCards: false,
    error: false,
    message: '',
    isSuccess: false,
    education: {},
    statusCode: 201
}


export const education = (state = initialState, action) => {
    switch (action.type) {
        case APP_CREATE_EDUCATION_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_EDUCATION:
            return Object.assign({}, state, {
                education: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_EDUCATION_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_EDUCATION_ADDITIONAL_INFO_START:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_EDUCATION_ADDITIONAL_INFO:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                education: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_EDUCATION_ADDITIONAL_INFO_FAIL:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingEducationToProfessionCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_EDUCATION_TO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                isLoadingEducationToProfessionCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_EDUCATION_TO_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingEducationToProfessionCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.education.error
export const getMsg = state => state.education.message
export const getisLoading = state => state.education.isLoading
export const getIsSuccess = state => state.education.isSuccess
export const getstatusCode = state => state.education.statusCode
export const getEducation = state => state.education.education
export const getisLoadingAdditionalInfo = state => state.education.isLoadingAdditionalInfo
export const getisLoadingEducationToProfessionCards = state => state.education.isLoadingEducationToProfessionCards

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
