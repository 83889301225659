import {
    APP_INTRO_SEEN,
    APP_FLEXIBLE_EVENTS_SEEN,
    APP_PER_SERVICE_INTRO_SEEN,
    APP_BOOKING_SLOT_SEEN,
    APP_PAYMENT_TUTS_SEEN,
    APP_FIRST_TIME_SEARCH,
    APP_SESSION_INTRO_SEEN,
    APP_SKILL_FILTER_SEEN,
    APP_SERVICE_DETAILS_ONE_SEEN,
    APP_SERVICE_DETAILS_TWO_SEEN,
    APP_LOGOUT
} from '../../actions'

const initialState = {
    isSeen: false,
    flexibleEventsSeen:false,
    bookingslots:false,
    paymentTutsSeen:false,
    firstTimeSearh:true,
    sessionIntroSeen:false,
    serviceIntroSeen:false,
    skillFilterSeen:false,
    serviceDetailsOneIsSeen:false,
    serviceDetailsTwoIsSeen:false
}

export const introScreen = (state = initialState, action) => {
    switch (action.type) {
        case APP_INTRO_SEEN:
            return Object.assign({}, state, {
                isSeen: true,
            })
        case APP_FLEXIBLE_EVENTS_SEEN:
            return Object.assign({}, state, {
                flexibleEventsSeen: true,
            })
        case APP_BOOKING_SLOT_SEEN:
            return Object.assign({}, state, {
                bookingslots: true,
            })
        case APP_PAYMENT_TUTS_SEEN:
            return Object.assign({}, state, {
                paymentTutsSeen: true,
            })
        case APP_FIRST_TIME_SEARCH:
            return Object.assign({}, state, {
                firstTimeSearh: false,
            })
        case APP_SESSION_INTRO_SEEN:
            return Object.assign({}, state, {
                sessionIntroSeen: action.data,
            })
        case APP_PER_SERVICE_INTRO_SEEN:
            return Object.assign({}, state, {
                serviceIntroSeen: action.data,
            })
        case APP_SKILL_FILTER_SEEN:
            return Object.assign({}, state, {
                skillFilterSeen: action.data,
            })
        case APP_SERVICE_DETAILS_ONE_SEEN:
            return Object.assign({}, state, {
                serviceDetailsOneIsSeen: true,
            })
        case APP_SERVICE_DETAILS_TWO_SEEN:
            return Object.assign({}, state, {
                serviceDetailsTwoIsSeen: true,
                serviceDetailsOneIsSeen: true,
            })
        case APP_LOGOUT:
            return Object.assign({}, state, {
                firstTimeSearh: true,
                serviceIntroSeen: false,
            })
        default:
            return state
    }
}

export const getisSeen = state => state.introScreen.isSeen
export const getFlexibleEventsSeen = state => state.introScreen.flexibleEventsSeen
export const getBookingslots = state => state.introScreen.bookingslots
export const getPaymentTutsSeen = state => state.introScreen.paymentTutsSeen
export const getFirstTimeSearh = state => state.introScreen.firstTimeSearh
export const getSessionIntroSeen = state => state.introScreen.sessionIntroSeen
export const getSkillFilterSeen = state => state.introScreen.skillFilterSeen
export const getServiceIntroSeen = state => state.introScreen.serviceIntroSeen
export const getServiceDetailsOneIsSeen = state => state.introScreen.serviceDetailsOneIsSeen
export const getServiceDetailsTwoIsSeen = state => state.introScreen.serviceDetailsTwoIsSeen



