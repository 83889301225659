import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { startGetEvents } from '../../../redux/actions/resume/performance/events'

//reducers
import { getisLoadingGet, getEventDetails } from '../../../redux/reducers/resume/performance/events'
import { getJwt } from '../../../redux/reducers/account/accounts'

const ResumeEvents = (props) => {
    const dispatch = useDispatch()

    const event = useSelector(getEventDetails)
    const isLoading = useSelector(getisLoadingGet)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(startGetEvents({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoading ? <Spinner /> : <div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Roles</p>
                {event[0]?.roles?.data?.map((role,index)=>{
                    return (
                        <p key={index} className="expDetail__desc">
                            {role?.firstName + " " + role?.lastName} | <span>{role?.workrolename}</span>
                        </p>
                    )
                })}
            </div>
            <div className="expDetail__secHead">Event</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Event Name</p>
                <p className="expDetail__desc">{event[0]?.eventName}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">From Date</p>
                <p className="expDetail__desc">{event[0]?.fromDate}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">To Date</p>
                <p className="expDetail__desc">{event[0]?.toDate}</p>
            </div>
        </div>
    );
};

export default ResumeEvents;
