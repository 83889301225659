import {
    APP_POST_MEDIA_PHOTO_START,
    APP_POST_MEDIA_PHOTO,
    APP_POST_MEDIA_PHOTO_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    photo: [],
    isLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const mediaPhoto = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_MEDIA_PHOTO_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_MEDIA_PHOTO:
            return Object.assign({}, state, {
                photo: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_MEDIA_PHOTO_FAIL:
            return Object.assign({}, state, {
                photo: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.mediaPhoto.error
export const getMsg = state => state.mediaPhoto.message
export const getisLoading = state => state.mediaPhoto.isLoading
export const getPhoto = state => state.mediaPhoto.photo
export const getIsSuccess = state => state.mediaPhoto.isSuccess
export const getstatusCode = state => state.mediaPhoto.statusCode