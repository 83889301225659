import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//asset
import ProfImg from "../../../../assets/images/profileIcon.svg";
import AcceptRequestIcon from "../../../../assets/images/acceptRequestIcon.svg";
import CancelRequestIcon from "../../../../assets/images/cancelRequestIcon.svg";

const PeopleCard = (props) => {

  const renderAddPeopleTooltip = arg => {
    return (
      <Tooltip {...arg} className="copyTooltip--colleague">
        <p>{props?.toastText}</p>
      </Tooltip>
    );
  }

  return (
    <div className={"peopleCard " + props?.className} onClick={props.onClick}>
      <img src={props?.img ? props?.img : ProfImg} className={"card-img-top " + props?.imageClassName} alt="card Icon"/>
      {props?.type ? <div className="overlay__image"></div> : null }
      <div style={{display:'flex', flexDirection:'row', alignItems:'baseline',}}>
        <span className="peaopleCard__title">
          {props.title}
        </span>
        <div className="peopleCard__dot">
        </div>
      </div>
      <p className="peaopleCard__desc">
        {props.text}
      </p>
      {props?.type ? <OverlayTrigger placement="top" overlay={renderAddPeopleTooltip}>
        <button className={"peopleCard__button"} onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.onClickButton() }}><img src={props?.type === "Cancel" ? CancelRequestIcon : AcceptRequestIcon} alt="Accept Request Icon" /></button>
      </OverlayTrigger>: null}
    </div>
  );
};

export default PeopleCard;

