import {
    APP_GET_ALL_SKILLS_START,
    APP_GET_ALL_SKILLS,
    APP_GET_ALL_SKILLS_FAIL,
    APP_GET_ALL_USER_SKILLS_START,
    APP_GET_ALL_USER_SKILLS,
    APP_GET_ALL_USER_SKILLS_FAIL,
    APP_PUT_USER_SKILLS_START,
    APP_PUT_USER_SKILLS,
    APP_PUT_USER_SKILLS_FAIL,
    SAVE_SELECTED_SKILLS,
    APP_LOGOUT,
    APP_POST_USER_CARDS
} from '../../actions'

import { createSelector } from 'reselect'
import _ from 'underscore'
import { getById } from '../../../helpers/utils.js'

const initialState = {
    allSkills: [],
    allUserSkills: [],
    isLoading: false,
    isUserSkillsLoading:false,
    isPostSkillsLoading:false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    selectedSkills: [],
    editedCard: {},
}


export const skill = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_ALL_SKILLS_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ALL_SKILLS:
            return Object.assign({}, state, {
                selectedSkills: [],
                allSkills: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ALL_SKILLS_FAIL:
            return Object.assign({}, state, {
                allSkills: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_ALL_USER_SKILLS_START:
            return Object.assign({}, state, {
                isUserSkillisLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ALL_USER_SKILLS:
            return Object.assign({}, state, {
                allUserSkills: action.data,
                isUserSkillisLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ALL_USER_SKILLS_FAIL:
            return Object.assign({}, state, {
                allUserSkills: [],
                isUserSkillisLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_USER_SKILLS_START:
            return Object.assign({}, state, {
                isPostSkillsLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_USER_SKILLS:
            return Object.assign({}, state, {
                isPostSkillsLoading: false,
                editedCard:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_USER_SKILLS_FAIL:
            return Object.assign({}, state, {
                isPostSkillsLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case SAVE_SELECTED_SKILLS:
            return Object.assign({}, state, {
                selectedSkills: action.data
            })
        case APP_POST_USER_CARDS:
            return Object.assign({}, state, {
                selectedSkills: []
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.skill.error
export const getMsg = state => state.skill.message
export const getisLoading = state => state.skill.isLoading
export const getisPostSkillsLoading = state => state.skill.isPostSkillsLoading
export const getAllSkills = state => state.skill.allSkills
export const getAllSearchSkills = state => {
    var flags = [], output = [], l = state.skill.allSkills.length, i;
    for (i = 0; i < l; i++) {
        if (flags[state.skill.allSkills[i].id]) continue;
        flags[state.skill.allSkills[i].id] = true;
        output.push(state.skill.allSkills[i]);
    }
    return output
}

export const allUserSkills = state => state.skill.allUserSkills

export const getallUserSkillsIds = state => {
     return state.skill.allUserSkills.map((skill)=>{
         return skill.skillid
     })
}
export const userSkillisLoading = state => state.skill.isUserSkillisLoading

export const getIsSuccess = state => state.skill.isSuccess
export const getstatusCode = state => state.skill.statusCode
export const getSelectedSkills = state => state.skill.selectedSkills
export const getEditedCard = state => state.skill.editedCard

export const getAllSkillIds = state => {
    return state.skill.allSkills.map(skill => skill.id)
}

export const getAllSpecializationSkillsByCardId = state => {
    const specialization = state.skill.allUserSkills?state.skill.allUserSkills.filter((skill) => skill.type === "superskill" || skill.type === "specialization"):[]
    const groupBy = _.groupBy(
        specialization,
        data => data.cardid
    )
    return groupBy
}

export const getAllSpecializationWithoutSuperSkillsByCardId = state => {
    const specialization = state.skill.allUserSkills ? state.skill.allUserSkills.filter((skill) => skill.type !== "superskill" && skill.type !== "skill"):[]
    const groupBy = _.groupBy(
        specialization,
        data => data.cardid
    )
    return groupBy
}
export const getSuperSkillByCardId = state => {
    const specialization = state.skill.allUserSkills ? state.skill.allUserSkills.filter((skill) => skill.type === "superskill"):[]
    const groupBy = _.groupBy(
        specialization,
        data => data.cardid
    )
    return groupBy
}

export const getAllNormalSkillsByCardId = state => {
    const normal = state.skill.allUserSkills?state.skill.allUserSkills.filter((skill) => skill.type === "skill"):[]
    const groupBy = _.groupBy(
        normal,
        data => data.cardid
    )
    return groupBy
}

export const getAllSkillsByCardId = state => {
    const groupBy = _.groupBy(
        state.skill.allUserSkills,
        data => data.cardid
    )
    return groupBy
}

export const getAllSkillById = state => {
    return getById(state.skill.allSkills)
}

export const getAllSkillDiscipline = state => {
    var flags = [], output = [], l = state.skill.allSkills.length, i;
    for (i = 0; i < l; i++) {
        if (flags[state.skill.allSkills[i].disciplineid]) continue;
        flags[state.skill.allSkills[i].disciplineid] = true;
        output.push({
            id: state.skill.allSkills[i].disciplineid,
            name: state.skill.allSkills[i].disciplinename,
            discipline_order: state.skill.allSkills[i].discipline_order,
        });
    }
    output.sort((a, b) => a.discipline_order - b.discipline_order)
    return output
}

export const getAllSkillSubDisciplineByDiscipline = state => {

    const groupBy = _.groupBy(
        state.skill.allSkills,
        data => data.disciplineid
    )

    Object.keys(groupBy).forEach(key =>{
        let array = groupBy[key]
        var flags = [], output = [], l = array.length, i;
        for (i = 0; i < l; i++) {
            if (flags[array[i].subdisciplineid]) continue;
            flags[array[i].subdisciplineid] = true;
            output.push({
                id: array[i].subdisciplineid,
                disciplineid: array[i].disciplineid,
                name: array[i].subdisciplinename
            });
            groupBy[key] = output
        }
    })
    
    return groupBy
}

export const getAllSkillBySubDiscipline = state => {
    const groupBy = _.groupBy(
        state.skill.allSkills,
        data => data.subdisciplineid
    )

    Object.keys(groupBy).forEach(key => {
        let array = groupBy[key]
        var flags = [], output = [], l = array.length, i;
        for (i = 0; i < l; i++) {
            if (flags[array[i].id]) continue;
            flags[array[i].id] = true;
            output.push(array[i]);
            groupBy[key] = output
        }
    })

    return groupBy
}


export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))