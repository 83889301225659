
import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_GET_FEATURED_MEDIA_VIDEO_START,
    APP_GET_FEATURED_MEDIA_VIDEO,
    APP_GET_FEATURED_MEDIA_VIDEO_FAIL,
    APP_PUT_FEATURED_MEDIA_VIDEO_START,
    APP_PUT_FEATURED_MEDIA_VIDEO,
    APP_PUT_FEATURED_MEDIA_VIDEO_FAIL,
    APP_UNASSIGN_MEDIA_VIDEO_START,
    APP_UNASSIGN_MEDIA_VIDEO,
    APP_UNASSIGN_MEDIA_VIDEO_FAIL,
    APP_GET_CARD_MEDIA_VIDEO_START,
    APP_GET_CARD_MEDIA_VIDEO,
    APP_GET_CARD_MEDIA_VIDEO_FAIL,
    APP_GET_MEDIA_VIDEO_START,
    APP_GET_MEDIA_VIDEO,
    APP_GET_MEDIA_VIDEO_FAIL,
    APP_PUT_MEDIA_VIDEO_START,
    APP_PUT_MEDIA_VIDEO,
    APP_PUT_MEDIA_VIDEO_FAIL,
    APP_DELETE_MEDIA_VIDEOS_START,
    APP_DELETE_MEDIA_VIDEOS,
    APP_DELETE_MEDIA_VIDEOS_FAIL,
    APP_ASSIGN_MEDIA_VIDEO_START,
    APP_ASSIGN_MEDIA_VIDEO,
    APP_ASSIGN_MEDIA_VIDEO_FAIL,
    APP_LOGOUT,
} from '../../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

// get featured videos
export const emitFeaturedMediaVideoStart = () => ({
    type: APP_GET_FEATURED_MEDIA_VIDEO_START
})

export const emitFeaturedMediaVideo = data => {
    return {
        type: APP_GET_FEATURED_MEDIA_VIDEO,
        data
    }
}

export const emitFeaturedMediaVideoFailed = (data) => ({
    type: APP_GET_FEATURED_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getFeaturedMediaVideos = ({ jwt, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/video/featured/${true}/professioncard/${id}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetFeaturedMediaVideos = (data) => {
    return (dispatch) => {
        dispatch(emitFeaturedMediaVideoStart())
        return getFeaturedMediaVideos(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitFeaturedMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitFeaturedMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitFeaturedMediaVideoFailed(err))
            })
    }

}


// get all videos by card
export const emitCardMediaVideoStart = () => ({
    type: APP_GET_CARD_MEDIA_VIDEO_START
})

export const emitCardMediaVideo = data => {
    return {
        type: APP_GET_CARD_MEDIA_VIDEO,
        data
    }
}

export const emitCardMediaVideoFailed = (data) => ({
    type: APP_GET_CARD_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getCardMediaVideos = ({ jwt, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/video/${id}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCardMediaVideos = (data) => {
    return (dispatch) => {
        dispatch(emitCardMediaVideoStart())
        return getCardMediaVideos(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCardMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCardMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitCardMediaVideoFailed(err))
            })
    }

}

// get all videos by card by user id
const getCardMediaVideosByUserId = ({ jwt, id, userId  }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/video/${id}/user/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCardMediaVideosByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitCardMediaVideoStart())
        return getCardMediaVideosByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCardMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCardMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitCardMediaVideoFailed(err))
            })
    }

}
// get all videos
export const emitMediaVideoStart = () => ({
    type: APP_GET_MEDIA_VIDEO_START
})

export const emitMediaVideo = data => {
    
    
    return {
        type: APP_GET_MEDIA_VIDEO,
        data
    }
}

export const emitMediaVideoFailed = (data) => ({
    type: APP_GET_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

//all
const getMediaVideos = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/videos/user/videos'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMediaVideos = (data) => {
    return (dispatch) => {
        dispatch(emitMediaVideoStart())
        return getMediaVideos(data)
            .then(parseJSON)
            .then(json => {
                // 
                if (json.statusCode === 200) {
                    dispatch(emitMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaVideoFailed(err))
            })
    }

}

//random
const getRandomMediaVideos = () => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/videos/random/videos'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
}

export const startGetRandomMediaVideos = (data) => {
    return (dispatch) => {
        dispatch(emitMediaVideoStart())
        return getRandomMediaVideos(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetRandomMediaVideos");
                if (json.statusCode === 200) {
                    dispatch(emitMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaVideoFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitMediaVideoFailed(err))
            })
    }

}

// get all videos by user id
const getMediaVideosByUserId = ({ jwt, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/users/videos/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMediaVideosByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitMediaVideoStart())
        return getMediaVideosByUserId(data)
            .then(parseJSON)
            .then(json => {
                // 
                if (json.statusCode === 200) {
                    dispatch(emitMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaVideoFailed(err))
            })
    }

}

// put videos as featured
export const emitPutFeaturedMediaVideoStart = () => ({
    type: APP_PUT_FEATURED_MEDIA_VIDEO_START
})

export const emitPutFeaturedMediaVideo = data => {
    return {
        type: APP_PUT_FEATURED_MEDIA_VIDEO,
        data
    }
}

export const emitPutFeaturedMediaVideoFailed = (data) => ({
    type: APP_PUT_FEATURED_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const putFeaturedMediaVideos = ({ jwt, data, id, featured }) => {
    let Authorization = composeAuth(jwt)
    let stringifyfeatured = encodeURI(JSON.stringify(featured))
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/videos/featured/${stringifyfeatured}/professioncard/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutFeaturedMediaVideos = (data) => {
    return (dispatch) => {
        dispatch(emitPutFeaturedMediaVideoStart())
        return putFeaturedMediaVideos(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitPutFeaturedMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPutFeaturedMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPutFeaturedMediaVideoFailed(err))
            })
    }

}

// unassign video from profession card
export const emitUnassignMediaVideoStart = () => ({
    type: APP_UNASSIGN_MEDIA_VIDEO_START
})

export const emitUnassignMediaVideo = data => {
    return {
        type: APP_UNASSIGN_MEDIA_VIDEO,
        data
    }
}

export const emitUnassignMediaVideoFailed = (data) => ({
    type: APP_UNASSIGN_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const UnassignMediaVideos = ({ jwt, data, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/unassign/professioncard/videos/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startUnassignMediaVideos = (data) => {
    return (dispatch) => {
        dispatch(emitUnassignMediaVideoStart())
        return UnassignMediaVideos(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUnassignMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUnassignMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUnassignMediaVideoFailed(err))
            })
    }

}

//update media Video
export const emitPutMediaVideoStart = () => ({
    type: APP_PUT_MEDIA_VIDEO_START
})

export const emitPutMediaVideo = data => {
    return {
        type: APP_PUT_MEDIA_VIDEO,
        data
    }
}

export const emitPutMediaVideoFailed = (data) => ({
    type: APP_PUT_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const putMediaVideo = ({ jwt, data, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/videos/video/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutMediaVideo = (data) => {
    return (dispatch) => {
        dispatch(emitPutMediaVideoStart())
        return putMediaVideo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitPutMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPutMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPutMediaVideoFailed(err))
            })
    }
}

//reasssign/assign to profession cards
export const emitAssignMediaVideoStart = () => ({
    type: APP_ASSIGN_MEDIA_VIDEO_START
})

export const emitAssignMediaVideo = data => {
    return {
        type: APP_ASSIGN_MEDIA_VIDEO,
        data
    }
}

export const emitAssignMediaVideoFailed = (data) => ({
    type: APP_ASSIGN_MEDIA_VIDEO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const assignMediaVideo = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/videos/reassign/professioncard/videos'
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignMediaVideo = (data) => {
    return (dispatch) => {
        dispatch(emitAssignMediaVideoStart())
        return assignMediaVideo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignMediaVideo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignMediaVideoFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitAssignMediaVideoFailed(err))
            })
    }
}

//delete Videos

export const emitMediaVideosDeleteStart = () => ({
    type: APP_DELETE_MEDIA_VIDEOS_START
})

export const emitMediaVideosDelete = data => {
    return {
        type: APP_DELETE_MEDIA_VIDEOS,
        data
    }
}

export const emitMediaVideosDeleteFailed = (data) => ({
    type: APP_DELETE_MEDIA_VIDEOS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const deleteMediaVideos = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/videos/delete/videos'
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteMediaVideos = (data) => {
    return (dispatch) => {
        dispatch(emitMediaVideosDeleteStart())
        return deleteMediaVideos(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMediaVideosDelete(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaVideosDeleteFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaVideosDeleteFailed(err))
            })
    }

}

