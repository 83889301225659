
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_GET_ALL_SKILLS_START,
    APP_GET_ALL_SKILLS,
    APP_GET_ALL_SKILLS_FAIL,
    APP_GET_ALL_USER_SKILLS_START,
    APP_GET_ALL_USER_SKILLS,
    APP_GET_ALL_USER_SKILLS_FAIL,
    APP_PUT_USER_SKILLS_START,
    APP_PUT_USER_SKILLS,
    APP_PUT_USER_SKILLS_FAIL,
    SAVE_SELECTED_SKILLS,
    APP_LOGOUT,
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

//get skill pool

export const emitAllSkillsStart = () => ({
    type: APP_GET_ALL_SKILLS_START
})

export const emitAllSkills = data => {
    return {
        type: APP_GET_ALL_SKILLS,
        data
    }
}

export const emitAllSkillsFailed = (data) => ({
    type: APP_GET_ALL_SKILLS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getAllSkills = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/skill/skillpool'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
    })
}

export const startGetAllSkills = (data) => {
    return (dispatch) => {
        dispatch(emitAllSkillsStart())
        return getAllSkills(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAllSkills(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAllSkillsFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitAllSkillsFailed(err))
            })
    }

}

export const saveSelectedSkills = (data) => ({
    type: SAVE_SELECTED_SKILLS,
    data

})

//get all user skills

export const emitAllUserSkillsStart = () => ({
    type: APP_GET_ALL_USER_SKILLS_START
})

export const emitAllUserSkills = data => {
    return {
        type: APP_GET_ALL_USER_SKILLS,
        data
    }
}

export const emitAllUserSkillsFailed = (data) => ({
    type: APP_GET_ALL_USER_SKILLS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getAllUserSkills = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/skill/userskillpool'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
    })
}

export const startGetAllUserSkills = (data) => {
    return (dispatch) => {
        dispatch(emitAllUserSkillsStart())
        return getAllUserSkills(data)
            .then(parseJSON)
            .then(json => {
                
                
                
                if (json.statusCode === 200) {
                    dispatch(emitAllUserSkills(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAllUserSkillsFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitAllUserSkillsFailed(err))
            })
    }

}

// get all user skills by user id

const getAllUserSkillsByUserId = ({ jwt, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/skill/user/skillpool/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
    })
}

export const startGetAllUserSkillsByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitAllUserSkillsStart())
        return getAllUserSkillsByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 200) {
                    dispatch(emitAllUserSkills(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAllUserSkillsFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitAllUserSkillsFailed(err))
            })
    }

}

//save skills in card

export const emitPutUserSkillsStart = () => ({
    type: APP_PUT_USER_SKILLS_START
})

export const emitPutUserSkills = (data) => {
    return {
        type: APP_PUT_USER_SKILLS,
        data
    }
}

export const emitPutUserSkillsFailed = (data) => ({
    type: APP_PUT_USER_SKILLS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const putUserSkills = ({ jwt, data, cardId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/card/edit/card/${cardId}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutUserSkills = (data) => {
    return (dispatch) => {
        dispatch(emitPutUserSkillsStart())
        return putUserSkills(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startPutUserSkills");
                if (json.statusCode === 201) {
                    dispatch(emitPutUserSkills(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPutUserSkillsFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPutUserSkillsFailed(err))
            })
    }

}


//delete skills from profession card
const deleteUserSkills = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/card/skills'
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteUserSkills = (data) => {
    return (dispatch) => {
        dispatch(emitAllUserSkillsStart())
        return deleteUserSkills(data)
            .then(parseJSON)
            .then(json => {

                
                
                if (json.statusCode === 201) {
                    dispatch(emitAllUserSkills())
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAllUserSkillsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAllUserSkillsFailed(err))
            })
    }

}

