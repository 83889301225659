import React from "react";
import AppStore from "../../../../assets/images/appStore.png";
import PlayStore from "../../../../assets/images/playStore.png";
import Logo from "../../../../assets/images/logo-white.png";
import Fb from "../../../../assets/images/fb.png";
import Twitter from "../../../../assets/images/twitter.png";
import Insta from "../../../../assets/images/insta.png";
import moment from 'moment'

//version
import { version } from '../../../../../package.json'

const Footer = () => {
  return (
    <footer className="footer main__dashboard">
      <div className="container">
        <div className="footer__wrap1">
          <div className="row">
            <div className="col-md-3">
              <ul className="social__footer">
                <li onClick={() => window.open("https://www.facebook.com/YourResonance", "_blank")}>
                  <img src={Fb} alt="facebook" />
                </li>
                <li onClick={() => window.open("https://www.instagram.com/yourresonance", "_blank")}>
                  <img src={Insta} alt="Insta" />
                </li>
                <li onClick={() => window.open("https://twitter.com/ResonanceNetApp", "_blank")}>
                  <img src={Twitter} alt="Twitter" />
                </li>
              </ul>

          <div className="clear"></div>
              <img className="footer__appStore addPointer" src={AppStore} alt="app store" onClick={() => window.open("https://apps.apple.com/us/app/resonance-network/id1508162127", "_blank")}/>
              <br />
              <img className="footer__appStore addPointer" src={PlayStore} alt="play store" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.resonancenetwork.resonance", "_blank")}/>
          <div className="clear"></div>
            </div>
            <div className="col-md-6">
              <h5>Links</h5>
              <ul className="footer__links1">
                <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/about", "_blank")}>About</li>
                <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/get-hired", "_blank")}>Get Hired</li>
                <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/contact", "_blank")}>Contact</li>
                <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/blog", "_blank")}>Articles</li>
              </ul>
              <ul className="footer__links1">
                <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/support", "_blank")}>Support Center</li>
                <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/online-help", "_blank")}>How Online Works</li>
                <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/help-for-employers", "_blank")}>Help for Employers</li>
                <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/help-for-professionals", "_blank")}>Help for Pros</li>
              </ul>
            </div>
            <div className="col-md-3 footer__logoWrap">
              <div>
              <img className="footer__logo" src={Logo} alt="Resonance" />
              <p className="footer__logoText">The Future is Creative.</p>
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="footer__wrap2">
          <p>&copy; {moment().format("YYYY")} Resonance Network Company. All Rights Reserved</p>
         <div className="clear"></div>
         <ul className="footer__terms">
            <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/terms", "_blank")}>Terms of service</li>
            <li className="addPointer" onClick={() => window.open("https://www.yourresonance.com/privacy", "_blank")}>Privacy policy</li>
            <li className="addPointer" onClick={() => window.open("mailto:support@yourresonance.com")}>Email: support@yourresonance.com</li>
            <li className="addPointer" onClick={() => window.open("tel:+18884057495")}>Phone: +1 (888) 405-7495</li>
         </ul>
          <p className="footer__logoText">v {version}</p>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </footer>
  );
};

export default Footer;
