import React, { useEffect, useState, useRef } from "react";
import Autocomplete from "react-google-autocomplete";
import ReactSelect from 'react-select';
import _ from 'underscore'
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

//assets
import Header from "../assets/layouts/header/dashboardHeader";
import PeopleListingCard from "../assets/layouts/cards/peopleListingCard";
import CategoriesSort from "../assets/molecules/categoriesSort";
import SkillsWithClose from "../assets/molecules/skillsWithClose";
import Spinner from "../assets/molecules/spinner";
import Popup from "../assets/layouts/popup/popupSkill";
import Img from "../../assets/images/people/samantha.jpg";
import closeIcon from "../../assets/images/close.svg";
import { SearchEmpty } from '../assets/layouts/empty/searchEmpty'

//reducers
import { getTemplateProfessionCards, getisLoadingSearchTemplate } from "../../redux/reducers/search";
import { getisLoading, getAllSkills, getAllSkillById } from "../../redux/reducers/profession/skills";

//actions
import { startGetSearchTemplate, startGetSearchTemplateBySkillIdFilters } from "../../redux/actions/search";
import { startGetAllSkills } from "../../redux/actions/profession/skills";
import { changeDropdownStatus } from '../../redux/actions/ui/profile'

//helpers
import { usePrevious } from '../../helpers/utils'

const SearchDetail = (props) => {
  const dispatch = useDispatch();
  let history = useHistory()

  let { id: templateId } = useParams();
  let location = useLocation();
  let profession = location?.state?.profession;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [
    "",
    "Closest Match",
    "Lowest Price",
    "Highest Price",
    "# of Engagements",
  ];
  const filterRef = useRef();
  const footRef =  useRef();
  const filterTabRef = useRef();

  const [priceValue, setPriceValue] = useState([1, 500]);
  const [finalPriceValue, setFinalPriceValue] = useState([1, 500]);
  const [milesValue, setMilesValue] = useState(40);
  const [professionCards, setProfessionCards] = useState([]);
  const [sortType, setSortType] = useState("# of Engagements");
  const [importantSkills, setImportantSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedAvailability, setSelectedAvailability] = useState([]);
  const [availabilityStatus, setAvailabilityStatus] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [travelToProfessional, setTravelToProfessional] = useState(false);
  const [professionalTravels, setProfessionalTravels] = useState(false);
  const [gender, setGender] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [PopClassName, setPopClassName] = useState("hidePop");
  const [locationSearchValue, setLocationSearchValue] = useState("");
  const [showMilesModal, setShowMilesModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);

  const templateProfessionCards = useSelector(getTemplateProfessionCards)
  const isLoadingSearchTemplate = useSelector(getisLoadingSearchTemplate)
  const isLoadingSearchTemplatePrev = usePrevious(isLoadingSearchTemplate)
  const isLoadingSkills = useSelector(getisLoading)
  const isLoadingSkillsPrev = usePrevious(isLoadingSkills)
  const allSkills = useSelector(getAllSkills)
  const allSkillsById = useSelector(getAllSkillById)

  const valuetext = (priceValue) => {
    return `$${priceValue}`;
  };

  const valuelabel = (values) => {
    return values;
  };

  const onClearAllFilters = () => {
    setPriceValue([0,500])
    setFinalPriceValue([0,500])
    setMilesValue(0)
    setSelectedSkills([])
    setSelectedAvailability([])
    setAvailabilityStatus(false)
    setOnlineStatus(false)
    setTravelToProfessional(false)
    setProfessionalTravels(false)
    setGender([])
    setSelectedLocation({})
    setLocationSearchValue("")
    dispatch(startGetSearchTemplate({ jwt: "", id: templateId }));
  };

  const handlePriceSlideChange = (event, newValue) => {
    setPriceValue(newValue);
  };

  const handlePriceSlideChangeCommited = (event, newValue) => {
    setFinalPriceValue(newValue);
  };

  const handleLocationSlideChange = (event, newValues) => {
    setMilesValue(newValues);
  };

  const handleSortChange = (event) => {
    setSortType(event.target.value);
  };

  //skill funtions
  const handleSkillChange = (e) => {
    const temp = selectedSkills?.map(item => item)
    if (temp.includes(e)) {
      var index = temp.indexOf(e);
      if (index !== -1) {
        setSelectedSkills(temp.filter((item) => item !== e));
      }
    } else {
      setSelectedSkills(temp.concat([e]));
    }
  };

  const handleSeeMoreSkillChange = (selectedOption) =>{
    const temp = selectedSkills?.map(item => item)
    setSelectedSkills(temp?.concat([selectedOption?.value?.toString()]))
  }

  const onClickRemoveSkill = (id) =>{
    setSelectedSkills(selectedSkills?.filter((item)=>item !== id))
  }

  //availability functions
  const onChangeAvailability = (e) => {
    const temp = selectedAvailability?.map(item => item)
    if (temp.includes(e.target.value)) {
      var index = temp.indexOf(e.target.value);
      if (index !== -1) {
        setSelectedAvailability(temp.filter((item) => item !== e.target.value));
      }
    } else {
      setSelectedAvailability(temp.concat([e.target.value]));
    }
  }

  //gender functions
  const onChangeGender = (e) => {
    const temp = gender?.map(item => item)
    if (temp.includes(e.target.value)) {
      var index = temp.indexOf(e.target.value);
      if (index !== -1) {
        setGender(temp.filter((item) => item !== e.target.value));
      }
    } else {
      setGender(temp.concat([e.target.value]));
    }
  }
  
  //popup
  const openPopup = () => {
    setPopClassName("showPop");
  };
  const closePopup = () => {
    setPopClassName("hidePop");
  };

  useEffect(() => {
    setProfessionCards([])
    dispatch(startGetSearchTemplate({ jwt: "", id: templateId }));
    dispatch(startGetAllSkills({ jwt: "" }));
  }, [templateId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoadingSearchTemplatePrev !== isLoadingSearchTemplate && !isLoadingSearchTemplate && isLoadingSearchTemplate !== undefined ){
      if (sortType) {
        let temp = templateProfessionCards?.filter((item) => {
          if (item?.distance && selectedLocation?.lat && selectedLocation?.lng) {
            return parseFloat(item?.distance) <= parseFloat(milesValue)
          }
          else {
            return true
          }
        })?.map(item => item)
        if (sortType === "Closest Match") {
          setProfessionCards(temp?.filter(item => item?.type === "best"))
        }
        if (sortType === "Lowest Price") {
          setProfessionCards(temp?.sort((a, b) => a?.contract_amount - b?.contract_amount))
        }
        if (sortType === "Highest Price") {
          setProfessionCards(temp?.sort((a, b) => b?.contract_amount - a?.contract_amount))
        }
        if (sortType === "# of Engagements") {
          setProfessionCards(temp?.sort((a, b) => b?.booking_count - a?.booking_count))
        }
      }
      else if (sortType === "") {
        let temp = templateProfessionCards?.filter((item) => {
          if (item?.distance && selectedLocation?.lat && selectedLocation?.lng) {
            return parseFloat(item?.distance) <= parseFloat(milesValue)
          }
          else {
            return true
          }
        })?.map(item => item)
        setProfessionCards(temp)
      }
    }
  }, [isLoadingSearchTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingSkills && isLoadingSkills !== undefined && isLoadingSkillsPrev !== isLoadingSkills){
      const impSkills = profession?.imp_skills
      const filteredImpSkills = _.uniq(allSkills?.filter(item => impSkills?.includes(item?.id)),(x)=>x?.id)
      setImportantSkills(filteredImpSkills)
    }
  }, [isLoadingSkills, isLoadingSkillsPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sortType){
      let temp = templateProfessionCards?.filter((item) => {
        if (item?.distance && selectedLocation?.lat && selectedLocation?.lng) {
          return parseFloat(item?.distance) <= parseFloat(milesValue)
        }
        else {
          return true
        }
      })?.map(item=>item)
      if (sortType === "Closest Match"){
        setProfessionCards(temp?.filter(item=> item?.type === "best"))
      }
      if (sortType === "Lowest Price") {
        setProfessionCards(temp?.sort((a, b) => a?.contract_amount - b?.contract_amount))
      }
      if (sortType === "Highest Price") {
        setProfessionCards(temp?.sort((a, b) => b?.contract_amount - a?.contract_amount))
      }
      if (sortType === "# of Engagements") {
        setProfessionCards(temp?.sort((a, b) => b?.booking_count - a?.booking_count))
      }
    }
    else if(sortType === "") {
      let temp = templateProfessionCards?.filter((item) => {
        if (item?.distance && selectedLocation?.lat && selectedLocation?.lng) {
          return parseFloat(item?.distance) <= parseFloat(milesValue)
        }
        else {
          return true
        }
      })?.map(item => item)
      setProfessionCards(temp)
    }
  }, [sortType, milesValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterStatus){
      onSubmit()
    }
    setFilterStatus(true)
  }, [finalPriceValue, selectedSkills, selectedAvailability, availabilityStatus, onlineStatus, travelToProfessional, professionalTravels, gender, selectedLocation]); // eslint-disable-line react-hooks/exhaustive-deps


  const onSubmit = () => {
    const genderSearch = gender.length
      ? gender
      : ["male", "female", "other"];
    let travel = []
    if (travelToProfessional){
      travel.push("travel")
    }
    if (professionalTravels){
      travel.push("set")
    }
    const data = {
      jwt: "",
      id: templateId,
      skillIds: selectedSkills?.map(item=>parseInt(item)),
      availability: availabilityStatus ? ["monday","tuesday","wednesday","thursday","friday","saturday","sunday"] : selectedAvailability,
      leastprice: finalPriceValue[0],
      highestprice: finalPriceValue[1] ? finalPriceValue[1] === 500 ? 1000000 : finalPriceValue[1] : 1000000,
      onlinestatus: onlineStatus,
      travelstatus: travel,
      lat: selectedLocation?.lat,
      lng: selectedLocation?.lng,
      gender: genderSearch,
    }
    dispatch(startGetSearchTemplateBySkillIdFilters(data))
  }
  useScrollPosition(
    ({ currPos }) => {
      let element = document?.getElementById("filterRefer");
      if (
        currPos.y < 25 && element
      ) {
        element?.classList?.add("filterReferFixed");
      } else if (currPos.y > 0) {
        element?.classList?.remove("filterReferFixed");
      }
    },
    [],
    filterRef
  );

  useScrollPosition(
    ({ currPos }) => {
      let element = document?.getElementById("filterRefer");
      let leftTabHt = filterTabRef?.current?.offsetHeight;
      let windowHt = window?.innerHeight || document?.documentElement?.clientHeight || document?.body?.clientHeight;
      let FootTarget = windowHt - leftTabHt;
      let PostionFixRef = (windowHt - FootTarget - currPos.y) - 25;
      let ScrollFix = windowHt - leftTabHt;
      let ScrollHt = windowHt - 100;
      if(ScrollFix < 0) {
        element?.classList?.add("filterOverFlow");
        element.style.height = ScrollHt;
        }
      else if (PostionFixRef > 0) {
        document?.getElementById("filterRefer").classList?.add("filterReferFixedAfter");
      } 
      else if(PostionFixRef < 0 || PostionFixRef === 0) {
        element?.classList?.remove("filterReferFixedAfter");
      }
      //alert(ScrollFix);
    },
    [],
    footRef
  );

  const onClickDiv = () =>{
    if (showMilesModal){
      setShowMilesModal(false)
    }
    dispatch(changeDropdownStatus(false))
  }

  return (
    <React.Fragment>
      <div className="main__dashboard" onClick={() => onClickDiv()}>
        <Header
          heading="Explore > Your Search Results for "
          category={'"' + profession?.name + '"'}
          headerClassname="searchDetailHeader"
        />
        <div className="minHeightContainer">
        {isLoadingSkills ? <Spinner/> :<div className="container">
          <div className="margin--top25">
            <div className="row">
              <div className="col-md-3">
                <button className="button button--back" onClick={() => history.push({
                  pathname: '/'
                })}>Back to Explore</button>
              </div>
              <div className="col-md-4">
                <h4 className="prof__result">
                  Viewing Results for “
                  {profession?.title ? profession?.title : profession?.name ? profession?.name : ""}”
                </h4>
              </div>
              <div className="col-md-5">
                <div className="location__wrap">
                  <Autocomplete
                    onPlaceSelected={(place) => {
                      const locationObj = {
                        lat : place?.geometry?.location?.lat(),
                        lng : place?.geometry?.location?.lng(),
                        placeId: place?.place_id,
                        desc: place?.formatted_address
                      }
                      setSelectedLocation(locationObj)
                      setLocationSearchValue(place?.formatted_address)
                    }}
                    types={['establishment', 'geocode']}
                    className="location locationSearch"
                    id={selectedLocation?.placeId ? "locationSearchLeft" : "locationSearch"}
                    fields={["geometry.location","place_id","formatted_address"]}
                    value={locationSearchValue}
                    onChange={(e)=>setLocationSearchValue(e.target.value)}
                  />
                  {selectedLocation?.placeId ? <div className="position-relative" onClick={() => setShowMilesModal(true)}>
                    <input
                    id="distance"
                    disabled
                    value={milesValue + " Miles"}
                    className="distance"
                    placeholder="miles"
                  />
                    <div className="closeLocation"><img src={closeIcon} alt="close" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowMilesModal(false); setLocationSearchValue(""); setSelectedLocation({})}}/> </div>
                  </div> : <span></span>}
                  {showMilesModal ? <div id="distPop" className="distance__popup">
                    Search within{" "}
                    <input value={milesValue} id="miles" type="text" onChange={(e) => setMilesValue(e.target.value)}/> miles
                    <Slider
                      value={milesValue}
                      getAriaValueText={valuelabel}
                      onChange={handleLocationSlideChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range"
                      valueLabelFormat={valuelabel}
                      min={0}
                      max={100}
                    />
                    <div className="distanceBtn__wrap">
                      <button className="button button--withoutBg" onClick={() => setShowMilesModal(false)}>
                        CONFIRM
                      </button>
                    </div>
                    <div className="clear"></div>
                  </div> : <div></div>}
                </div>
                <div className="sort__wrap">
                  <FormControl>
                    <InputLabel className="sort" id="sortMultiple">
                      Sort
                    </InputLabel>
                    <Select
                      labelId="sortMultiple"
                      id="sortMultiple__checkbox"
                      value={sortType}
                      onChange={handleSortChange}
                      input={<Input />}
                      renderValue={() => {}}
                      MenuProps={MenuProps}
                    >
                      {names.map((name,index) => (
                        <MenuItem className="sortItem" key={index} value={name}>
                          <Checkbox checked={sortType === name} />
                          <p className="sortItem__text"> {name ? name : "Clear Sort"} </p>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="searchDetail__wrap2" ref={filterRef}>
            <div className="row row-eq-height">
              <div className="filterParentRef">
              {/* <Scrollbars autoHeight autoHeightMin={2860} autoHeightMax={3000}> */}
                  <div  id="filterRefer" className="filters" ref={filterTabRef}>
                    <div className="bg--white">

                {/* <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={600}> */}
                      <div className="filters__head">
                        <h5>Filters</h5>
                        <button className="button button--withoutBg" onClick={()=>onClearAllFilters()}>
                          Clear Filters
                        </button>
                        <div className="clear"></div>
                      </div>
                      <div className="filters__body">
                        <h5 className="filters__subhead">Skills</h5>
                        <div className="customFilter__choose mb-3">
                          {importantSkills?.map((item,index)=>{
                            return(
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={"option-" + index}
                                  value={item?.id}
                                  name="Skills"
                                  checked={selectedSkills.includes(item?.id?.toString())}
                                  onChange={(e)=> handleSkillChange(e.target.value)}
                                />
                                <label htmlFor={"option-" + index}>{item?.name}</label>
                              </div>
                            )
                          })}
                          <div className="clear"></div>
                          <button onClick={() => openPopup()} className="button button--withoutBg">
                            CLICK TO SEE MORE
                          </button>
                          <div className="clear"></div>
                        </div>
                        <hr />
                        <h5 className="filters__subhead">Price</h5>
                        <p>Select Price Range/hr</p>
                        <Slider
                          value={priceValue}
                          onChangeCommitted={handlePriceSlideChangeCommited}
                          onChange={handlePriceSlideChange}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          valueLabelFormat={valuetext}
                          min={0}
                          max={500}
                        />
                        <p>Time duration varies in search results</p>
                        <hr />

                        <h5 className="filters__subhead">Availability</h5>

                        <div className="filters__LRContainer">
                          <div className="filters__leftText">
                            Anytime - I’m Flexible
                          </div>
                          <div className="filters__rightButton">
                            <label className="switch">
                              <input type="checkbox" onChange={() => setAvailabilityStatus(!availabilityStatus)} checked={availabilityStatus}/>
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div className="clear"></div>
                        </div>
                        <div className="day__Choose mb-3">
                          <input type="checkbox" id="mon" value="monday" name="days" onChange={onChangeAvailability} checked={availabilityStatus ? availabilityStatus : selectedAvailability.includes("monday")}/>
                          <label htmlFor="mon">M</label>
                          <input type="checkbox" id="tue" value="tuesday" name="days" onChange={onChangeAvailability} checked={availabilityStatus ? availabilityStatus : selectedAvailability.includes("tuesday")}/>
                          <label htmlFor="tue">T</label>
                          <input type="checkbox" id="wed" value="wednesday" name="days" onChange={onChangeAvailability} checked={availabilityStatus ? availabilityStatus : selectedAvailability.includes("wednesday")}/>
                          <label htmlFor="wed">W</label>
                          <input type="checkbox" id="thur" value="thursday" name="days" onChange={onChangeAvailability} checked={availabilityStatus ? availabilityStatus : selectedAvailability.includes("thursday")}/>
                          <label htmlFor="thur">T</label>
                          <input type="checkbox" id="fri" value="friday" name="days" onChange={onChangeAvailability} checked={availabilityStatus ? availabilityStatus : selectedAvailability.includes("friday")}/>
                          <label htmlFor="fri">F</label>
                          <input type="checkbox" id="sat" value="saturday" name="days" onChange={onChangeAvailability} checked={availabilityStatus ? availabilityStatus : selectedAvailability.includes("saturday")}/>
                          <label htmlFor="sat">S</label>
                          <input type="checkbox" id="sun" value="sunday" name="days" onChange={onChangeAvailability} checked={availabilityStatus ? availabilityStatus : selectedAvailability.includes("sunday")}/>
                          <label htmlFor="sun">S</label>
                          <div className="clear"></div>
                        </div>
                        <hr />

                        <h5 className="filters__subhead">TRAVEL PREFERENCE</h5>
                        <div className="filters__LRContainer">
                          <div className="online filters__leftText">
                            Offers Online Sessions
                          </div>
                          <div className="filters__rightButton">
                            <label className="switch">
                              <input type="checkbox" onChange={() => setOnlineStatus(!onlineStatus)} checked={onlineStatus}/>
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div className="clear"></div>
                        </div>
                        <div className="filters__LRContainer">
                          <div className="travelTo filters__leftText">
                            Travel to Professional
                          </div>
                          <div className="filters__rightButton">
                            <label className="checkbox__container">
                              <input type="checkbox" onChange={() => setTravelToProfessional(!travelToProfessional)} checked={travelToProfessional}/>
                              <span className="checkmark"></span>
                            </label>
                          </div>

                          <div className="clear"></div>
                        </div>

                        <div className="filters__LRContainer">
                          <div className="travel filters__leftText">
                            Professional Travels
                          </div>
                          <div className="filters__rightButton">
                            <label className="checkbox__container">
                              <input type="checkbox" onChange={() => setProfessionalTravels(!professionalTravels)} checked={professionalTravels}/>
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="clear"></div>
                        </div>
                        <hr />
                        <h5 className="filters__subhead">GENDER</h5>
                        <div className="customFilter__choose mb-3">
                          <input
                            type="checkbox"
                            id="male"
                            value="male"
                            name="gender"
                            onChange={onChangeGender} checked={gender.includes("male")}
                          />
                          <label htmlFor="male">Male</label>
                          <input
                            type="checkbox"
                            id="female"
                            value="female"
                            name="gender"
                            onChange={onChangeGender} checked={gender.includes("female")}
                          />
                          <label htmlFor="female">Female</label>
                          <input
                            type="checkbox"
                            id="other"
                            value="other"
                            name="gender"
                            onChange={onChangeGender} checked={gender.includes("other")}
                          />
                          <label htmlFor="other">Other</label>
                          <div className="clear"></div>
                        </div>
                      </div>
                      {/* </Scrollbars> */}
                      <div className="clear"></div>
                    </div>
                    <div className="clear"></div>
                  </div>
                  {/* </Scrollbars> */}
                <div className="clear"></div>
              </div>
              <div className="rightListing">
                {selectedSkills && selectedSkills?.length ? <CategoriesSort onClick={() => setSelectedSkills([])} buttonText="CLEAR ALL">
                  {_.uniq(allSkills,(x)=>x?.id)?.filter(item => selectedSkills.includes(item?.id?.toString()))?.map((item,index)=>{
                    return(
                      <SkillsWithClose key={index} name={item?.name} onClick={() => onClickRemoveSkill(item?.id?.toString())} />
                    )
                  })}
                </CategoriesSort>:<div></div>}
                {isLoadingSearchTemplate ? <Spinner /> : professionCards && professionCards?.length ? <div>
                  {professionCards?.filter((card) => card.type ? card.type === "best" : true).map((card, index) => {
                    return (
                      <PeopleListingCard
                        key={index}
                        img={card?.url ? card?.url : Img}
                        name={card?.firstName + " " + card?.lastName}
                        designation={card?.cardname}
                        city={card?.location?.short_description}
                        engagements={card?.booking_count}
                        description={card?.card_description}
                        price={card?.contract_amount && parseInt(card?.contract_amount) ? `$${card?.contract_amount}` : "Get a Quote"}
                        for={card?.contracttype === "service" ? `per ${card?.parameter}` : `for ${card?.parameter} mins`}
                        buttonText={card?.contracttype === "service" ? "SEND REQUEST" : "BOOK NOW"}
                        onlineInPersonDesc={card?.online_status ? "Online & In person" : "In person"}
                        onlineInPerson={card?.contracttype === "service" ? "no" : "yes"}
                        travel={card?.contracttype === "service" ? "no" : card?.travel_status === "set" ? "yes" : "no"}
                        skills={card?.cardskills?.data.filter((skill) => skill?.skilltype === "specialization" || skill?.skilltype === "superskill")}
                        onClickName={() => history.push({
                          pathname: `/search/people/${card?.navigation_name}`,
                          state: { card }
                        })}
                        onClickButton={() => history.push({
                          pathname: `/search/people/${card?.navigation_name}`,
                          state: { card }
                        })}
                      ></PeopleListingCard>
                    )
                  })}
                  {professionCards?.filter((card) => card.type === "similar").map((card, index) => {
                    return (
                      <PeopleListingCard
                        key={index}
                        img={card?.url ? card?.url : Img}
                        name={card?.firstName + " " + card?.lastName}
                        designation={card?.cardname}
                        city={card?.location?.short_description}
                        engagements={card?.booking_count}
                        description={card?.card_description}
                        price={card?.contract_amount && parseInt(card?.contract_amount) ? `$${card?.contract_amount}` : "Get a Quote"}
                        for={card?.contracttype === "service" ? `per ${card?.parameter}` : `for ${card?.parameter} mins`}
                        buttonText={card?.contracttype === "service" ? "SEND REQUEST" : "BOOK NOW"}
                        onlineInPersonDesc={card?.online_status ? "Online & In person" : "In person"}
                        onlineInPerson={card?.contracttype === "service" ? "no" : "yes"}
                        travel={card?.contracttype === "service" ? "no" : card?.travel_status === "set" ? "yes" : "no"}
                        skills={card?.cardskills?.data.filter((skill) => skill?.skilltype === "specialization" || skill?.skilltype === "superskill")}
                        onClickName={() => history.push({
                          pathname: `/search/people/${card?.navigation_name}`,
                          state: { card }
                        })}
                        onClickButton={() => history.push({
                          pathname: `/search/people/${card?.navigation_name}`,
                          state: { card }
                        })}
                      ></PeopleListingCard>
                    )
                  })}
                </div> : <SearchEmpty/>}
                <div className="clear"></div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>}
          <div className="clear"></div>
        </div>
        <div className="ht--0" ref={footRef}>
          &nbsp;
        </div>
        <div className="clear"></div>
      </div>
      <Popup
        className={PopClassName}
        close={() => closePopup()}
        popupHead="Select skills"
        buttonText="Apply"
      >
        <ReactSelect
          value={""}
          isSearchable
          name="skills"
          options={_.uniq(allSkills,(x)=>x?.id)?.filter(item => !selectedSkills.includes(item?.id?.toString()))?.map((item) => {
            return { value: item?.id?.toString(), label: item?.name }
          })}
          placeholder=""
          onChange={(selectedOption) => handleSeeMoreSkillChange(selectedOption)}
        />
        <br/>
        <div className="customFilter__choose mb-3">
          {importantSkills?.map((item, index) => {
            return (
              <div key={index}>
                <input
                  type="checkbox"
                  id={"option-" + index}
                  value={item?.id}
                  name="Skills"
                  checked={selectedSkills.includes(item?.id?.toString())}
                  onChange={(e) => handleSkillChange(e.target.value)}
                />
                <label htmlFor={"option-" + index}>{item?.name}</label>
              </div>
            )
          })}
          {selectedSkills?.filter((item) => !importantSkills?.map((item) => item?.id?.toString())?.includes(item))?.map((item, index) => {
            const skill = allSkillsById[item]
            return (
              <div key={importantSkills?.length + index}>
                <input
                  type="checkbox"
                  id={"option-" + (importantSkills?.length + index)}
                  value={skill?.id}
                  name="Skills"
                  checked={selectedSkills.includes(skill?.id?.toString())}
                  onChange={(e) => handleSkillChange(skill?.id?.toString())}
                />
                <label htmlFor={"option-" + (importantSkills?.length + index)}>{skill?.name}</label>
              </div>
            )
          })}
          <div className="clear"></div>
        </div>
      </Popup>
      </React.Fragment>
  );
};

export default SearchDetail;
