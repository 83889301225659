import React,{ useState, useEffect } from "react";
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import OtpInput from 'react-otp-input';
import Sidebar from "../../assets/layouts/sidebar";

//reducers
import {
  getLoggedIn,
  getverified,
  getEmail,
  getJwt,
  getisLoading,
  getError,
  getFirstName
} from '../../../redux/reducers/account/accounts'
import { getLoginStatus } from '../../../redux/reducers/ui/profile'

//actions
import { startLogout, resendOtp, verifyAccount } from '../../../redux/actions/account'
import { showLoginPopUp, loginPopUpTab } from '../../../redux/actions/ui/profile'

//assets
import Header from "../../assets/layouts/header/registrationHeader";
import Spinner from "../../assets/molecules/spinner";

//helpers
import { usePrevious } from '../../../helpers/utils'

const EmailVerification = (props) => {
  const dispatch = useDispatch()

  const [OTP, setOTP] = useState("");
  const [error, setError] = useState("");
  const [resendStatus, setResendStatus] = useState(false);

  const isLoggedIn = useSelector(getLoggedIn)
  const verifyError = useSelector(getError)
  const isLoading = useSelector(getisLoading)
  const isLoadingPrev = usePrevious(isLoading)
  const email = useSelector(getEmail)
  const isVerified = useSelector(getverified)
  const jwt = useSelector(getJwt)
  const firstName = useSelector(getFirstName)
  const loginStatus = useSelector(getLoginStatus)

  useEffect(()=>{
    if (loginStatus){
      onResend()
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if (!isLoading && isLoading !== isLoadingPrev && isLoading !== undefined){
      if (verifyError){
        setError("Code is incorrect. Please check and try again.")
      }
    }
  }, [isLoading, isLoadingPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  const onResend = () => {
    dispatch(resendOtp({ jwt }))
  }

  const onLogOut = () => {
    dispatch(startLogout({ jwt }))
    dispatch(loginPopUpTab("register"))
    dispatch(showLoginPopUp())
  }

  const onSubmit = () => {
    setResendStatus(false)
    if (OTP){
      dispatch(verifyAccount({ jwt, otp: OTP }))
    }
    else{
      setError("Please enter a code.")
    }
  }

  return (
    !isLoggedIn && !isVerified ? <Redirect
        to={{
            pathname: '/',
        }}
    /> :
    <div className="dashboard">
      <Sidebar/>
      <div className="main__dashboard userDetailPage">
        <Header className="welcome">
            <h1>Welcome {firstName}</h1>
        </Header>
        <div className="email__container">
          {isLoading ? <Spinner /> :<div>
            <h2>Email Verification</h2>
            <p>
              We have sent a security code to <br />
                <b>{email}</b>
            </p>
            <div>
              <label>Enter Code</label>
                <OtpInput
                  value={OTP}
                  onChange={(text) => { setError(""); setOTP(text)}}
                  numInputs={6}
                  inputStyle={{
                    width:50
                  }}
                />
                {error && <p className="error">{error}</p>}
              <button
                type="submit"
                className="button button--primary width--100 mar--t25"
                  onClick={() => onSubmit()}
              >
                Verify
              </button>
            </div>
              <div className="link__container">
                <p className="link" onClick={() => {setError("");setResendStatus(true);onResend()}}>RESEND CODE</p>
                {resendStatus && <p className="error--resend">Code Sent Successfully!</p>}
            </div>
            <div className="link__container">
                <p className="link" onClick={() => onLogOut()}>REGISTER WITH A DIFFERENT EMAIL</p>
            </div>
          </div>}
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default EmailVerification;
