import {
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    CLEAR_RESUME_PROFILE_EDIT,
    APP_LOGOUT,
} from '../../../actions'

const initialState = {
    isLoadingGet: false,
    isLoadingDelete: false,
    isLoadingAssign: false,
    error: false,
    message: '',
    isSuccess: false,
    resume: [],
    statusCode: 201
}


export const resumeEdit = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_RESUME_BY_CARDID_START:
            return Object.assign({}, state, {
                isLoadingGet: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_RESUME_BY_CARDID:
            return Object.assign({}, state, {
                resume: state.resume.concat(action.data),
                isLoadingGet: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_RESUME_BY_CARDID_FAIL:
            return Object.assign({}, state, {
                isLoadingGet: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_RESUME_START:
            return Object.assign({}, state, {
                isLoadingDelete: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_RESUME:
            return Object.assign({}, state, {
                isLoadingDelete: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_RESUME_FAIL:
            return Object.assign({}, state, {
                isLoadingDelete: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_ASSIGN_RESUME_START:
            return Object.assign({}, state, {
                isLoadingAssign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_ASSIGN_RESUME:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_ASSIGN_RESUME_FAIL:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case CLEAR_RESUME_PROFILE_EDIT:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.resumeEdit.error
export const getMsg = state => state.resumeEdit.message
export const getisLoadingGet = state => state.resumeEdit.isLoadingGet
export const getisLoadingDelete = state => state.resumeEdit.isLoadingDelete
export const getisLoadingAssign = state => state.resumeEdit.isLoadingAssign
export const getIsSuccess = state => state.resumeEdit.isSuccess
export const getstatusCode = state => state.resumeEdit.statusCode
export const getResume = state => state.resumeEdit.resume
