import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//assets
import Spinner from "../assets/molecules/spinner";
import { AudioCarousel } from "../assets/layouts/carousel/audioCarousel";

//actions
import { startGetRandomMediaAudios } from "../../redux/actions/media/profileEdit/audio";

//reducers
import {
  getisLoadingAll,
  getAllaudios,
} from "../../redux/reducers/media/profileEdit/audio";

const AudiosSection = () => {
  const dispatch = useDispatch();

  let history = useHistory();

  const allaudios = useSelector(getAllaudios);
  const isLoadingAll = useSelector(getisLoadingAll);

  useEffect(() => {
    dispatch(startGetRandomMediaAudios());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoadingAll ? (
    <div className="container paddingContainer minHeightContainer">
      <Spinner />
    </div>
  ) : (
    <div className="container">
      {allaudios && allaudios?.length ? (
        <AudioCarousel
          header="Browse Audio"
          data={allaudios}
            onClickProfile={(navigation_name) => history.push({
              pathname: `/search/people/${navigation_name}`,
            })}
        />
      ) : null}
      <div className="clear"></div>
    </div>
  );
};

export default AudiosSection;
