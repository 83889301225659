import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//assets
import Header from "../../assets/layouts/header/registrationHeader";
import Sidebar from "../../assets/layouts/sidebar";

//reducers
import { getJwt, getisLoading, getFirstName } from "../../../redux/reducers/account/accounts";

//actions
import { updateActivity } from "../../../redux/actions/account";

//helpers
import { usePrevious } from "../../../helpers/utils";

//assets
import Spinner from "../../assets/molecules/spinner";

const UserType = (props) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  const jwt = useSelector(getJwt);
  const isLoading = useSelector(getisLoading);
  const isLoadingPrev = usePrevious(isLoading);
  const firstName = useSelector(getFirstName)


  useEffect(() => {
    if (
      !isLoading &&
      isLoading !== isLoadingPrev &&
      isLoadingPrev !== undefined
    ) {
      props?.onChangePage();
    }
  }, [isLoading, isLoadingPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeUserType = (type) => {
      if (selected.includes(type)) {
        var index = selected.indexOf(type);
      if (index !== -1) {
          setSelected(selected.filter((item) => item !== type));
      }
    } else {
          setSelected(selected.concat([type]));
    }
  };

  const onSubmit = () => {
    if (selected && selected.length > 0) {
      let activity = ["BU"];
      if (selected.includes("Teacher")) {
        activity.concat(["PU"]);
      }
      const data = {
        jwt,
        activity,
        selectedProfile: selected,
      };
      dispatch(updateActivity(data));
    }
  };

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="main__dashboard userDetailPage">
        <Header className="welcome">
          <h1>Welcome {firstName}</h1>
          <p>What would you like to do on Resonance?</p>
          <div className="carousel__container">
            <span className="active">.</span>
            <span >.</span>
          </div>
        </Header>
        <div className="userType__container">
          <div className="row row-eq-height">
            <div className="col">
              <div
                id="checkboxContainer"
                onClick={(e) => {e.preventDefault();onChangeUserType("Student")}}
                className={selected.includes("Student") ? "userType__container__col checkSelected" : "userType__container__col"}
              >
                <div className="row">
                  <div className="col-md-8">
                    <h5>Hire a Professional</h5>
                  </div>
                  <div className="col-md-4">
                    <label className="checkbox__container">
                      <input
                        id="hireProfessionalCheck"
                        type="checkbox"
                        value={"Student"}
                        onChange={(e) => onChangeUserType(e.target.value)}
                        checked={selected.includes("Student")}
                      />

                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <p>
                  I want to book teachers or hire creative
                  <br />
                  professionals.
                </p>
                <ul>
                  <li>Search for teachers and professionals</li>
                  <li>Book directly on the platform</li>
                  <li>Work in person or remotely</li>
                  <li>Automate your payment</li>
                  <li>Build a profile</li>
                </ul>
                <div className="clear"></div>
              </div>
              <div className="clear"></div>
            </div>
            <div className="col">
              <div
                id="checkboxContainerSecond"
                onClick={(e) => { e.preventDefault(); onChangeUserType("Teacher") }}
                className={selected.includes("Teacher") ? "userType__container__col checkSelected" : "userType__container__col"}
              >
                <div className="row">
                  <div className="col-md-8">
                    <h5>Get Hired</h5>
                  </div>
                  <div className="col-md-4">
                    <label className="checkbox__container">
                      <input
                        id="getHiredCheck"
                        type="checkbox"
                        value={"Teacher"}
                        onChange={(e) => onChangeUserType(e.target.value)}
                        checked={selected.includes("Teacher")}
                      />

                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <p>
                  I'm a teacher or creative professional looking to offer my
                  services and earn money.
                </p>
                <ul>
                  <li>Create your profile</li>
                  <li>Work in person or remotely</li>
                  <li>Set payment rates and cancellation policies</li>
                  <li>Set up your work schedule</li>
                  <li>Manage your professions</li>
                </ul>
                <div className="clear"></div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <button
              className="button button--primary"
              onClick={() => onSubmit()}
            >
              CONTINUE
            </button>
          )}
          <div className="clear"></div>
        </div>
        <div className="highlights__container">
          <div className="highlights__wrap">
            <h5>HIGHLIGHTS</h5>
            <ul className="highlights__list">
              <li className="highlight1">Multi-Profession Profile</li>
              <li className="highlight2">Powerful Search Filters</li>
            </ul>
            <ul className="highlights__list">
              <li className="highlight3">Automatic Booking</li>
              <li className="highlight4">Integrated Scheduling</li>
            </ul>
            <ul className="highlights__list">
              <li className="highlight5">Automated Payments</li>
              <li className="highlight6">Multi-Platform Video Sessions</li>
            </ul>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default UserType;
