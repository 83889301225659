import React from 'react';

const SkillBook = (props) => {
    return (
        <div className={"skillbook " + props.className} onClick={props.onClickCard}>
            <div className="skillbook__desigWrap">
            <h2>{props.name}</h2>
            {parseInt(props.engagements) ? <p className="skillbook__engagements">{props.engagements} Successful Engagements</p> :null}
            <div className="tick">
                tick
            </div>
            <div className="clear"></div>
            </div>
            <div className="skillbook__skillsWrap">
            Skills ({props.skillCount})
            <div className="customFilter__choose mb-3">
            {props?.skills && props?.skills?.map((skill,index)=>{
              return(
                <div className="peopleSkills" key={index}>
                  {skill?.skillname}
                </div>
              )
            })}
            <br/>
          </div>
            <div className="clear"></div>
            </div>
        {props?.cardActive ?<div>
          {props.online || props.travel ? <div className="skillbook__travelWrap">
                {props.online ? props.online === "yes" ? <p className={"online " + props.online}>Online &amp; In Person</p> :<p className={"online " + props.online}>In Person</p>:null}
                {props.travel && props.travel === "yes" ? <p className={"travel " + props.travel}>Willing to Travel</p> : null}
              <div className="clear"></div>
              </div>:null}
              <div className="skillbook__buttonWrap">
              <div className="skillbook__price">
                  <h3>{props.price}</h3>
                  <p>{props.priceFor}</p>
              <div className="clear"></div>
              </div>
              <div className="skillbook__button">
              <button className={props?.selected ? "button button--white" : "button button__violetBorder"} onClick={(e)=>props?.onClickButtton(e)}>{props?.buttonText}</button>
              <div className="clear"></div>
              </div>
              <div className="clear"></div>
              </div>
        </div> : <div>
            <div className="skillbook__travelWrap">
              <p className={"noHire"}>Not Available for Hire</p>
              <div className="clear"></div>
            </div>
            <div className="skillbook__buttonWrap">
              <div className="skillbook__button--nohire">
                <button className={props?.selected ? "button button--white button--no-hire" : "button button__violetBorder button--no-hire"} onClick={()=>props.onClickAskMe()}>ASK A QUESTION</button>
                <div className="clear"></div>
              </div>
              <div className="clear"></div>
            </div>
          </div>}
            <div className="clear"></div>
        </div>
    );
};

export default SkillBook;