import React, { useRef } from "react";
import Slider from "react-slick";

// assets
import PeopleCard from "../cards/peopleCard";
import ViewAll from "../../molecules/viewAll";
import LeftArrow from '../../../../assets/images/arrowLeft.svg'
import RightArrow from '../../../../assets/images/arrowRight.svg'

export const ViewAllCarousel = (props) => {
    let SliderRef = useRef(null)
    let settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 3,
        variableWidth: true,
        draggable: true,
        arrows: false
    };

    const renderCarousel = () => {
        return props?.data?.map((item, index) => {
            return <PeopleCard key={index} img={item?.url} title={item?.title} text={item?.description} onClick={() => props?.onClick(item)} />
        })
    }

    const next = () => {
        SliderRef.current.slickNext();
    }
    const previous = () => {
        SliderRef.current.slickPrev();
    }

    return (
        <div className="professional__sliderWrap">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h2>{props?.header}</h2>
                {props?.data?.length > 3 ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div className="arrow__leftMar" onClick={previous}>
                        <img alt="Left arrow" src={LeftArrow} />
                    </div>
                    <div onClick={next}>
                        <img alt="Right arrow" src={RightArrow} />
                    </div>
                </div> : null}
            </div>
            <Slider className="peopleSlider__group" {...settings} ref={SliderRef}>
                {renderCarousel()}
                {props?.data?.length > 0 ? <ViewAll type={props?.header} category={props.header} onClick={props.onClickViewAll} /> :null}
            </Slider>
            <div className="clear"></div>
        </div>
    )
}