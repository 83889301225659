import {
    APP_SAVE_SERVICE_LOCATION,
    APP_SAVE_SERVICE_CUSTOM_POLICIES,
    APP_SAVE_REQUEST_SERVICE_DATES,
    APP_SAVE_CREATE_SERVICE_TYPE_AND_PRICE_OBJ,
    APP_SAVE_SERVICE_REQUEST_ID,
    CLEAR_PER_SERVICE,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    serviceLocation:{},
    customPolicies:[],
    requestServiceDates:[],
    createServiceTypeAndPriceObj:{},
    serviceRequestId:""
}

export const serviceUi = (state = initialState, action) => {
    switch (action.type) {
        case APP_SAVE_SERVICE_LOCATION:
            return Object.assign({}, state, {
                serviceLocation: action.data,
            })
        case APP_SAVE_SERVICE_CUSTOM_POLICIES:
            return Object.assign({}, state, {
                customPolicies: action.data,
            })
        case APP_SAVE_REQUEST_SERVICE_DATES:
            return Object.assign({}, state, {
                requestServiceDates: action.data,
            })
        case APP_SAVE_CREATE_SERVICE_TYPE_AND_PRICE_OBJ:
            return Object.assign({}, state, {
                createServiceTypeAndPriceObj: action.data,
            })
        case APP_SAVE_SERVICE_REQUEST_ID:
            return Object.assign({}, state, {
                serviceRequestId: action.data,
            })
        case CLEAR_PER_SERVICE:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getServiceLocation = state => state.serviceUi.serviceLocation
export const getCustomPolicies = state => state.serviceUi.customPolicies
export const getRequestServiceDates = state => state.serviceUi.requestServiceDates
export const getCreateServiceTypeAndPriceObj = state => state.serviceUi.createServiceTypeAndPriceObj
export const getServiceRequestId = state => state.serviceUi.serviceRequestId

