import React from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'

import Login from "../login";
import Register from "../register";

//reducers
import { getLoginPopUpTab } from '../../../redux/reducers/ui/profile'

//actions
import { loginPopUpTab } from '../../../redux/actions/ui/profile'

const LoginTab = () => {
  const dispatch = useDispatch()
  const loginPopUpTabValue = useSelector(getLoginPopUpTab)
    return (
      <Tabs className="loginTab" defaultActiveKey={loginPopUpTabValue} activeKey={loginPopUpTabValue} onSelect={(value) => dispatch(loginPopUpTab(value))}>
        <Tab eventKey="register" title="Register">
          <div className="tab__body">
            <Register />
            <div className="clear"></div>
          </div>
        </Tab>
        <Tab eventKey="login" title="Login">
          <div className="tab__body">
              <Login />
            <div className="clear"></div>
          </div>
        </Tab>
      </Tabs>
    );
};

export default LoginTab;