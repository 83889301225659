import React, { useState, useEffect } from "react";
import { Tabs, Tab, Row, Col, Nav, Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//assets
import Header from "../assets/layouts/header/dashboardHeader";
import { ViewAll } from "../assets/layouts/viewAll/colleagues";
import { SearchEmpty } from "../assets/layouts/empty/searchEmpty";
import Spinner from "../assets/molecules/spinner";
import PopUpRemoveColleague from "../assets/layouts/popup/popUpRemoveColleague";
import EmptyColleague from "../../assets/images/emptyColleague.svg";

//reducers
import {
  getLoggedIn,
  getJwt,
  getUserId,
} from "../../redux/reducers/account/accounts";
import {
  getisLoadingAllColleagues,
  getMyColleagues,
  getisLoadingSentColleaguesRequest,
  getSentColleaguesRequest,
  getisLoadingReceivedColleaguesRequest,
  getReceivedColleaguesRequest,
  getisLoadingUpdateRequest,
  getError,
} from "../../redux/reducers/colleagues";

//actions
import { changeDropdownStatus } from "../../redux/actions/ui/profile";
import {
  startGetMyColleagues,
  startGetMySentColleaguesRequest,
  startGetMyReceivedColleaguesRequest,
  startAcceptRequest,
  startCancelRequest,
  startUnfriendRequest,
} from "../../redux/actions/colleagues";

//helper
import { usePrevious } from "../../helpers/utils";

const MyColleaguesTab = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState("myColleagues");
  const [subtabValue, setsubTabValue] = useState("received");
  const [PopClassName, setPopClassName] = useState("hidePop");
  const [selectedColleagueRemove, setSelectedColleagueRemove] = useState();
  const [selectedRequest, setSelectedRequest] = useState();
  const [clickStatus, setClickStatus] = useState("");
  
  const openPopup = () => {
    setPopClassName("showPop");
  };
  const closePopup = () => {
    setPopClassName("hidePop");
  };

  const isLoggedIn = useSelector(getLoggedIn);
  const loggedInUserId = useSelector(getUserId);
  const jwt = useSelector(getJwt);
  const isLoadingAllColleagues = useSelector(getisLoadingAllColleagues);
  const myColleagues = useSelector(getMyColleagues);
  const isLoadingSentColleaguesRequest = useSelector(
    getisLoadingSentColleaguesRequest
  );
  const sentColleaguesRequest = useSelector(getSentColleaguesRequest);
  const isLoadingReceivedColleaguesRequest = useSelector(
    getisLoadingReceivedColleaguesRequest
  );
  const receivedColleaguesRequest = useSelector(getReceivedColleaguesRequest);

  const isLoadingUpdateRequest = useSelector(getisLoadingUpdateRequest);
  const errorColleagues = useSelector(getError);
  const isLoadingUpdateRequestPrev = usePrevious(isLoadingUpdateRequest);

  useEffect(() => {
    dispatch(startGetMyColleagues({ jwt }));
    dispatch(startGetMySentColleaguesRequest({ jwt }));
    dispatch(startGetMyReceivedColleaguesRequest({ jwt }));
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingUpdateRequest &&
      isLoadingUpdateRequest !== isLoadingUpdateRequestPrev &&
      isLoadingUpdateRequestPrev !== undefined &&
      !errorColleagues
    ) {
      dispatch(startGetMyColleagues({ jwt }));
      dispatch(startGetMySentColleaguesRequest({ jwt }));
      dispatch(startGetMyReceivedColleaguesRequest({ jwt }));
    }
  }, [isLoadingUpdateRequest, isLoadingUpdateRequestPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickUnFollow = () => {
    const userId = selectedColleagueRemove?.user_id
    dispatch(startUnfriendRequest({ jwt, userId: userId }));
    closePopup()
  };

  const onClickAccept = (item) => {
    dispatch(startAcceptRequest({ jwt, requestId: item?.id }));
    setTabValue("myColleagues");
  };

  const onClickCancel = () => {
    dispatch(startCancelRequest({ jwt, requestId: selectedRequest?.id }));
    closePopup()
  };

  return (
    <div
      className="main__dashboard bgLines"
      onClick={() => dispatch(changeDropdownStatus(false))}
    >
      <Header heading="Colleague Network" />
      <div className="minHeightContainer">
        {isLoadingAllColleagues ||
        isLoadingSentColleaguesRequest ||
        isLoadingReceivedColleaguesRequest ||
        isLoadingUpdateRequest ? (
          <Spinner />
        ) : (
          <div className="container paddingContainer minHeightContainer">
            <Tabs
              className="searchTab--colleagues"
              defaultActiveKey={"activity"}
              activeKey={tabValue}
              onSelect={(value) => setTabValue(value)}
            >
              <Tab eventKey="myColleagues" title={<React.Fragment>
                MY COLLEAGUES
              <Badge variant='light'>{myColleagues?.length}</Badge>
            </React.Fragment>}>
                {myColleagues && myColleagues?.length ? (
                  <div className="tab__body colleagues__tabbody">
                    <p className="colleagues__explain">
                        Browse through your colleagues list
                    </p>
                    <ViewAll
                      loggedInUserId
                      header=""
                      data={myColleagues}
                      onClick={(people) => {
                        history.push({
                          pathname: `/search/people/${people?.navigation_name}`,
                        });
                      }}
                      type="Cancel"
                      onClickButton={(item) => { openPopup(); setSelectedColleagueRemove(item); setClickStatus("remove")}}
                      toastText="Remove this person from your colleagues"
                    />
                    <div className="clear"></div>
                  </div>
                ) : (
                      <SearchEmpty text="You haven’t added any colleagues yet! Browse Resonance to find people you know, and tap the “Add Colleague” button on their profile banner to send a request." image={EmptyColleague}  />
                )}
              </Tab>
              <Tab eventKey="pendingRequests" title={<React.Fragment>
                REQUESTS
              <Badge variant='light'>{sentColleaguesRequest?.length + receivedColleaguesRequest?.length}</Badge>
            </React.Fragment>}>
                <div className="tab__body colleagues__tabbody">
                    <p className="colleagues__explain">See colleague requests that you’ve received and that you’ve sent</p>
                <Tab.Container
                  className="loginTab--colleagues"
                  defaultActiveKey={"sent"}
                  activeKey={subtabValue}
                  onSelect={(value) => setsubTabValue(value)}
                >
                    <div className="tab__body--left">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="received">
                            RECEIVED <span>({receivedColleaguesRequest?.length})</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="sent">SENT <span>({(sentColleaguesRequest?.length)})</span></Nav.Link>
                        </Nav.Item>
                      </Nav>
                      </div>
                      <div className="tab__body--right">
                      <Tab.Content>
                        <Tab.Pane eventKey="sent">
                          {sentColleaguesRequest &&
                          sentColleaguesRequest?.length ? (
                            <ViewAll
                              header=""
                              data={sentColleaguesRequest}
                              onClick={(people) =>
                                history.push({
                                  pathname: `/search/people/${people?.navigation_name}`,
                                })
                              }
                              sent
                              type="Cancel"
                                  onClickButton={(item) => { openPopup(); setSelectedRequest(item); setClickStatus("cancel") }}
                              toastText="Cancel this colleague request"
                            />
                          ) : (
                                <SearchEmpty text="You haven’t sent any colleague requests. Browse Resonance to find people you know, and tap the “Add Colleague” button on their profile banner to send a request." image={EmptyColleague}/>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="received">
                          {receivedColleaguesRequest &&
                          receivedColleaguesRequest?.length ? (
                            <ViewAll
                              header=""
                              data={receivedColleaguesRequest}
                              onClick={(people) =>
                                history.push({
                                  pathname: `/search/people/${people?.navigation_name}`,
                                })
                              }
                              received
                              type="Accept"
                              onClickButton={(item) => onClickAccept(item)}
                              toastText="Accept request and add to colleagues"
                            />
                          ) : (
                                <SearchEmpty text="You haven’t received any colleague requests. Browse Resonance to find people you know, and tap the “Add Colleague” button on their profile banner to send a request." image={EmptyColleague} />
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                      </div>
                </Tab.Container>
                </div>
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
      <div className="clear"></div>
      <PopUpRemoveColleague
        className={PopClassName}
        close={() => closePopup()}
        onClick={() => clickStatus === "remove" ? onClickUnFollow() : onClickCancel()}
        text={clickStatus === "remove" ? "Are you sure you want to remove this person from your colleague list?" : "Are you sure you want to cancel this colleague request?"}
      />
    </div>
  );
};

export default MyColleaguesTab;
