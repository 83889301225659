import React from "react";
import popLeftBg from "../../../../assets/images/popLeftBg.png";
import LogoWhite from "../../../../assets/images/logoWhite.png";
import AppStore from "../../../../assets/images/appStore.png";
import PlayStore from "../../../../assets/images/playStore.png";
import Close from "../../../../assets/images/close.png";
const PopupRegisterSuccess = (props) => {
  return (
    <div className={"popup__custom popup__container " + props.className}>
      <div className="popup__box">
        <div className="popup__wrap downloadApp">
          <div className="popup__left">
            <img className="popLeftBgHt" src={popLeftBg} alt="background" />
            <div className={"askQuestion__left " + props.askQues}>
              <div>
                <img src={LogoWhite} alt="logo" />
                <div>
                  <button
                    onClick={() => {
                      window.open(
                        "https://www.yourresonance.com/download",
                        "_blank"
                      );
                    }}
                    className="button button--white"
                  >
                    Download
                  </button>
                  <p className="donHaveAcc">
                    Already have the app?{" "}
                    <span
                      onClick={() => {
                        window.open(
                          "http://redirect.yourresonance.com/",
                          "_blank"
                        );
                      }}
                    >
                      Tap here on mobile to open
                    </span>
                  </p>
                  <div className="store__wrap">
                    <img
                      className="appstore"
                      src={AppStore}
                      alt="logo"
                      onClick={() =>
                        window.open(
                          "https://apps.apple.com/in/app/resonance-hire-professionals/id1508162127",
                          "_blank"
                        )
                      }
                    />
                    <img
                      className="playstore"
                      src={PlayStore}
                      alt="logo"
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.resonancenetwork.resonance",
                          "_blank"
                        )
                      }
                    />
                    <div className="clear"></div>
                  </div>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="popup__content donloadApp__right register__thanks">
            <div className="downloadApp__alignText">
              <h2>Thanks for registering on Resonance!</h2>
              <p>Click “Continue” to browse for people and services. </p>
              <p>
                To continue building your profile, download the Resonance Mobile
                App! Click the “Download” button on the right to download the
                app and use all the amazing features that the platform offers!
              </p>
              <h5>HIGHLIGHTS</h5>
              <ul className="highlights__list">
                <li className="highlight1">Multi-Profession Profile</li>
                <li className="highlight2">Powerful Search Filters</li>
                <li className="highlight3">Automatic Booking</li>
              </ul>
              <ul className="highlights__list">
                <li className="highlight4">Integrated Scheduling</li>
                <li className="highlight5">Automated Payments</li>
                <li className="highlight6">Multi-Platform Video Sessions</li>
              </ul>
              <button
                className="button button--primary"
                onClick={props.close}
              >
                CONTINUE
              </button>
              <span onClick={props.close} className="close__popup">
                <img src={Close} alt="close" />
              </span>
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};
export default PopupRegisterSuccess;