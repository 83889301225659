
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_POST_MEDIA_AUDIO_START,
    APP_POST_MEDIA_AUDIO,
    APP_POST_MEDIA_AUDIO_FAIL,
    APP_DELETE_MEDIA_AUDIO_START,
    APP_DELETE_MEDIA_AUDIO,
    APP_DELETE_MEDIA_AUDIO_FAIL,
    APP_LOGOUT,
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitMediaAudioStart = () => ({
    type: APP_POST_MEDIA_AUDIO_START
})

export const emitMediaAudio = data => {
    return {
        type: APP_POST_MEDIA_AUDIO,
        data
    }
}

export const emitMediaAudioFailed = (data) => ({
    type: APP_POST_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postMediaAudio = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/media/audios/audio'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPostMediaAudio = (data) => {
    return (dispatch) => {
        dispatch(emitMediaAudioStart())
        return postMediaAudio(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaAudioFailed(err))
            })
    }

}

//update media audio

const putMediaAudio = ({ jwt, data ,id}) => {
    let Authorization = composeAuth(jwt)
    let url =  process.env.REACT_APP_SERVER + `/api/v1/media/audios/audio/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutMediaAudio = (data) => {
    return (dispatch) => {
        dispatch(emitMediaAudioStart())
        return putMediaAudio(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaAudioFailed(err))
            })
    }
}

//delete Audio

export const emitMediaAudioDeleteStart = () => ({
    type: APP_DELETE_MEDIA_AUDIO_START
})

export const emitMediaAudioDelete = data => {
    return {
        type: APP_DELETE_MEDIA_AUDIO,
        data
    }
}

export const emitMediaAudioDeleteFailed = (data) => ({
    type: APP_DELETE_MEDIA_AUDIO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const deleteMediaAudio = ({ jwt, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/audio/${id}`
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startDeleteMediaAudio = (data) => {
    return (dispatch) => {
        dispatch(emitMediaAudioDeleteStart())
        return deleteMediaAudio(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMediaAudioDelete(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaAudioDeleteFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaAudioDeleteFailed(err))
            })
    }

}

//get media audio

const getMediaAudio = ({ jwt, ids }) => {
    let Authorization = composeAuth(jwt)
    let stringifyIds = encodeURI(JSON.stringify(ids))
    let url = process.env.REACT_APP_SERVER + `/api/v1/media/audios/audio/id/${stringifyIds}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMediaAudio = (data) => {
    return (dispatch) => {
        dispatch(emitMediaAudioStart())
        return getMediaAudio(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitMediaAudio(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMediaAudioFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitMediaAudioFailed(err))
            })
    }
}
