import React from 'react';
import { Scrollbars } from "react-custom-scrollbars";

const ExperianceDetail = (props) => {
    return (
        <div className="expDetail">
        <div className="expDetail__header">
        <div className="expDetail__headerWrap1">
            <h3>{props.skillHead}</h3>
            <h2>{props.name}</h2>
            <h4>{props.description}</h4>
            {/* <div className="expDetail__skill">{props.skills}</div> */}
            <div className="clear"></div>
        </div>
        <div className="expDetail__headerWrap2">
        <div className="expDetail__date">{props.date}</div>
            <div className="clear"></div>
        </div>
            <div className="clear"></div>
        </div>
            <Scrollbars autoHeight autoHeightMin={481} autoHeightMax={481}>
        <div className="expDetail__body">
            {props.children}
            <div className="clear"></div>
        </div>
            </Scrollbars>
            <div className="clear"></div>
        </div>
    );
};

export default ExperianceDetail;