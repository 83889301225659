import React, { useEffect, useState, useRef } from "react";
import { Tab, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { Scrollbars } from "react-custom-scrollbars";
import Gallery from "react-grid-gallery";
import _ from "underscore";
import moment from "moment";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Toast from 'react-bootstrap/Toast'
import Tooltip from "react-bootstrap/Tooltip";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//assets
import Header from "../assets/layouts/header/dashboardHeader";
import Press from "./press";
import SkillsTab from "./cards";
import Resume from "./resume";
import Details from "./details";
import HightlightCard from "../assets/layouts/cards/highlightCard";
import LinksCard from "../assets/layouts/cards/linksCard";
import AudioCard from "../assets/layouts/cards/audioCard";
import VideoCard from "../assets/layouts/cards/videoCard";
import ExperianceCard from "../assets/layouts/cards/experianceCard";
import ExperianceDetail from "../assets/layouts/cards/experianceDetail";
import Popup from "../assets/layouts/popup/popupPlain";
import PopupAskQus from "../assets/layouts/popup/popupCustom";
import ProfImg from "../../assets/images/profileIcon.svg";
import Share from "../../assets/images/shareIcon.svg";
import { linkImages } from "./assets";
import Spinner from "../assets/molecules/spinner";
import LeftArrow from "../../assets/images/arrowLeft.svg";
import RightArrow from "../../assets/images/arrowRight.svg";
import SendRequest from "../../assets/images/sendRequest.svg";
import PendingRequest from "../../assets/images/pendingRequest.svg";
import AcceptRequest from "../../assets/images/acceptRequest.svg";
import AcceptedRequest from "../../assets/images/acceptedRequest.svg";
import Cover from '../../assets/images/cover.png'
import LinesPattern from "../../assets/images/patternBg.png";
import ProfileBannerOval1 from "../../assets/images/profileBannerOval1.svg";
import ProfileBannerOval2 from "../../assets/images/profileBannerOval2.svg";
import Success from "../../assets/images/success.png"
import { ProfileEmpty } from '../assets/layouts/empty/profileEmpty'
import DownloadPopup from "../assets/layouts/popup/popupDownload";
import UserColleagues from "./colleagues";
import ViewAllUserColleagues from "../colleagues/userColleagues";

//actions
import {
  fetchUserProfileDataByUserNavName,
  startGetFeaturedProfileDataByUserId,
  startGetFeaturedProfileDataNoCardByUserId,
} from "../../redux/actions/profile";
import {
  startGetUserProfessionByUserId,
  startGetProfessionByIds,
} from "../../redux/actions/profession/profession";
import { startGetUserProfessionCardsByUserId } from "../../redux/actions/profession/cards";
import { startGetAllUserSkillsByUserId } from "../../redux/actions/profession/skills";
import { showLoginPopUp, loginPopUpTab } from "../../redux/actions/ui/profile";
import { startPostAskQuestion } from "../../redux/actions/askQuestion";
import { startGetRequestStatus, startPostSendRequest, startAcceptRequest, startGetUserColleagues } from "../../redux/actions/colleagues";
import { changeDropdownStatus, setViewAllColleaguesUserId } from '../../redux/actions/ui/profile'

//reducers
import {
  getProfileImagePublic,
  getProfileCoverImagePublic,
  getDisplayLocationPublic,
  getFirstNamePublic,
  getLastNamePublic,
  getIsLoadingFeaturedProfileData,
  getPhotosPublic,
  getAudiosPublic,
  getVideosPublic,
  getUpcomingPublic,
  getQuotesPublic,
  getDescriptionPublic,
  getLinksPublic,
  getResumePublic,
  getUseridPublic,
  getspotlight,
  getisLoading
} from "../../redux/reducers/profile";
import { getLoggedIn, getJwt, getUserId } from "../../redux/reducers/account/accounts";
import { getisLoadingAskQuestion } from "../../redux/reducers/askQuestion";
import {
  getUserCards,
  getCardsIsloading,
} from "../../redux/reducers/profession/cards";
import {
  getAllSpecializationSkillsByCardId,
  getAllSkillsByCardId,
} from "../../redux/reducers/profession/skills";
import {
  getProfessionsIsloading,
  getUserProfessions,
  getisLoading as getprofessionCategoryisLoading,
  getProfessionCategoriesById,
} from "../../redux/reducers/profession/profession";
import { getRequestStatus, getRequestId, getError, getisLoadingPostSendRequest, getisLoadingUpdateRequest, getMyColleagues } from '../../redux/reducers/colleagues'

//helpers
import { usePrevious } from "../../helpers/utils";

function myTileViewportStyleFn() {
  if (this && this.props) {
    if (this.props.index < 2) {
      return {
        width: '100%',
        height: '100%',
        borderRadius: 10
      }
    }
    else {
      return {
        width: '100%',
        height: '100%',
        borderRadius: 10
      }
    }
  }
}

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { navName } = useParams();
  const proCards = useRef();
  const SliderRef = useRef(null);
  const CardSliderRef = useRef(null);
  const LinkSliderRef = useRef(null);
  const aboutRef = useRef(null);
  const experianceRef = useRef(null);
  const mediaRef = useRef(null);
  const highRef = useRef(null);

  let location = useLocation();
  let card = location?.state?.card;

  const [
    userCardsByUserProfessionId,
    setUserCardsByUserProfessionID,
  ] = useState({});
  const [userProfessionsArr, setUserProfessionsArr] = useState([]);
  const [selectedUserProfession, setSelectedUserProfession] = useState({});
  const [selectedUserCard, setSelectedUserCard] = useState({});
  const [engagementsCount, setEngagementsCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedResume, setSelectedResume] = useState(null);
  const [popClassName, setPopClassName] = useState("hidePop");
  const [detailsType, setDetailsType] = useState("");
  const [popQusClassName, setPopQusClassName] = useState("hidePop");
  const [successpopQusClassName, setSuccessPopQusClassName] = useState("hidePop");
  const [downloadPopClassName, setDownloadPopClassName] = useState("hidePop");
  const [copyConfirm, setCopyConfirm] = useState(false);
  const [addColleagueStatusShow, setAddColleagueStatusShow] = useState(false);

  //askme states
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState("");
  const [title, settitle] = useState("");
  const [message, setmessage] = useState("");

  const isLoggedIn = useSelector(getLoggedIn);
  const jwt = useSelector(getJwt);
  const isLoading = useSelector(getisLoading);
  const isLoadingPrev = usePrevious(isLoading);
  const isLoadingAskQuestion = useSelector(getisLoadingAskQuestion);
  const isLoadingAskQuestionPrev = usePrevious(isLoadingAskQuestion);
  const displayLocation = useSelector(getDisplayLocationPublic);
  const profileCoverImage = useSelector(getProfileCoverImagePublic);
  const profileImage = useSelector(getProfileImagePublic);
  const firstNamePublic = useSelector(getFirstNamePublic);
  const lastNamePublic = useSelector(getLastNamePublic);
  const useridPublic = useSelector(getUseridPublic);
  const loggedInUserId = useSelector(getUserId);
  const spotlight = useSelector(getspotlight);
  const isLoadingFeaturedProfileData = useSelector(
    getIsLoadingFeaturedProfileData
  );
  const isLoadingFeaturedProfileDataPrev = usePrevious(
    isLoadingFeaturedProfileData
  );

  const userCardsIsloading = useSelector(getCardsIsloading);
  const userCardsIsloadingPrev = usePrevious(userCardsIsloading);
  const userCards = useSelector(getUserCards);

  const userProfessionsIsloading = useSelector(getProfessionsIsloading);
  const userProfessionsIsloadingPrev = usePrevious(userProfessionsIsloading);
  const userProfessions = useSelector(getUserProfessions);

  const professionCategoryisLoading = useSelector(
    getprofessionCategoryisLoading
  );
  const professionCategoryisLoadingPrev = usePrevious(
    professionCategoryisLoading
  );
  const professionCategoriesById = useSelector(getProfessionCategoriesById);

  const photosPublic = useSelector(getPhotosPublic);
  const audiosPublic = useSelector(getAudiosPublic);
  const videosPublic = useSelector(getVideosPublic);
  const upcomingPublic = useSelector(getUpcomingPublic);
  const quotesPublic = useSelector(getQuotesPublic);
  const descriptionPublic = useSelector(getDescriptionPublic);
  const linksPublic = useSelector(getLinksPublic);
  const resumePublic = useSelector(getResumePublic);

  const allSpecializationSkillsByCardId = useSelector(
    getAllSpecializationSkillsByCardId
  );
  const allSkillsByCardId = useSelector(getAllSkillsByCardId);

  const colleagueRequestStatus = useSelector(getRequestStatus);
  const colleagueRequestId = useSelector(getRequestId);
  const isLoadingPostSendRequest = useSelector(getisLoadingPostSendRequest);
  const isLoadingPostSendRequestPrev = usePrevious(isLoadingPostSendRequest);
  const isLoadingUpdateRequest = useSelector(getisLoadingUpdateRequest);
  const isLoadingUpdateRequestPrev = usePrevious(isLoadingUpdateRequest);
  const colleagueRequestError = useSelector(getError);
  const userColleagues = useSelector(getMyColleagues);

  // const [key, setKey] = useState(photosPublic && photosPublic?.length ? "photos" : videosPublic && videosPublic?.length ? "videos" : audiosPublic && audiosPublic?.length ? "audio" : "");

  useScrollPosition(
    ({ currPos }) => {
      if (aboutRef?.current) {
        var element = document.getElementById("blueWrap");
        if (element) {
          if (currPos.y < 93) {
            element?.classList?.add("blueWrapFixed");
          }
        }
      }
      else {
        return null
      }
    },
    [],
    aboutRef
  );

  useScrollPosition(
    ({ currPos }) => {
      if (aboutRef?.current) {
        let element = document.getElementById("blueWrap");
        let tabAbout = document.getElementById("tabAbout");
        let tabMedia = document.getElementById("tabMedia");
        let tabHighlights = document.getElementById("tabHighlights");
        let tabExp = document.getElementById("tabExp");
        if (currPos.y > 40 && element) {
          element?.classList?.remove("blueWrapFixed");
        } else if (
          currPos.y < 20 ||
          (currPos.y === 0)
        ) {
          tabAbout?.classList?.add("active");
          tabMedia?.classList?.remove("active");
          tabHighlights?.classList?.remove("active");
          tabExp?.classList?.remove("active");
        } else if (
          currPos.y > 0) {
          tabAbout?.classList?.remove("active");
          tabMedia?.classList?.remove("active");
          tabHighlights?.classList?.remove("active");
          tabExp?.classList?.remove("active");
        }
      }
      else {
        return null
      }
    },
    [],
    aboutRef
  );

  useScrollPosition(
    ({ currPos }) => {
      if (mediaRef?.current) {
        let element = document.getElementById("tabMedia");
        let tabAbout = document.getElementById("tabAbout");
        let tabExp = document.getElementById("tabExp");
        let tabHighlights = document.getElementById("tabHighlights");
        if (currPos.y < 20 || currPos.y === 0) {
          element?.classList?.add("active");
          tabAbout?.classList?.remove("active");
          tabExp?.classList?.remove("active");
          tabHighlights?.classList?.remove("active");
        } else if (currPos.y > 0) {
          element?.classList?.remove("active");
        }
      }
      else {
        return null
      }
    },
    [],
    mediaRef
  );

  useScrollPosition(
    ({ currPos }) => {
      if (highRef?.current) {
        let element = document.getElementById("tabHighlights");
        let tabMedia = document.getElementById("tabMedia");
        let tabAbout = document.getElementById("tabAbout");
        let tabExp = document.getElementById("tabExp");
        if (currPos.y < 20 || currPos.y === 0) {
          element?.classList?.add("active");
          tabAbout?.classList?.remove("active");
          tabExp?.classList?.remove("active");
          tabMedia?.classList?.remove("active");
        } else if (currPos.y > 0) {
          element?.classList?.remove("active");
        }
      }
      else {
        return null
      }
    },
    [],
    highRef
  );

  useScrollPosition(
    ({ currPos }) => {
      if (experianceRef?.current) {
        let element = document.getElementById("tabExp");
        let tabAbout = document.getElementById("tabAbout");
        let tabMedia = document.getElementById("tabMedia");
        let tabHighlights = document.getElementById("tabHighlights");
        if (currPos.y < 20 || currPos.y === 0) {
          element?.classList?.add("active");
          tabAbout?.classList?.remove("active");
          tabMedia?.classList?.remove("active");
          tabHighlights?.classList?.remove("active");
        } else if (currPos.y > 0) {
          element?.classList?.remove("active");
        }
      }
      else {
        return null
      }
    },
    [],
    experianceRef
  );

  const tabItem1Click = () => {
    let elmnt = document.getElementById("aboutRefer");
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  }

  const tabItem2Click = () => {
    let elmnt = document.getElementById("mediaRefer");
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  }

  const tabItem3Click = () => {
    let elmnt = document.getElementById("highlightsRef");
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  }

  const tabItem4Click = () => {
    let elmnt = document.getElementById("experianceRef");
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  }

  useScrollPosition(
    ({ currPos }) => {
      if (aboutRef?.current) {
        var element = document.getElementById("rightSidebar");
        if (
          element
        ) {
          if (currPos.y < 0 || currPos.y === 0) {
            element.classList.add("rightSidebarFixed");
          } else if (currPos.y > 0) {
            element.classList.remove("rightSidebarFixed");
          }
        } else if (element) {
          element.classList.remove("rightSidebarFixed");
          element.classList.remove("rightSidebarFixedAfter");
        }
      }
      else {
        return null
      }
    }, [resumePublic, photosPublic, audiosPublic, videosPublic, descriptionPublic, quotesPublic], aboutRef
  )

  useScrollPosition(
    ({ currPos }) => {
      if (experianceRef?.current) {
        var element = document.getElementById("rightSidebar");
        if (element) {
          if (currPos.y < 430) {
            element.classList.remove("rightSidebarFixed");
            element.classList.add("rightSidebarFixedAfter");
          } else {
            element.classList.remove("rightSidebarFixedAfter");
          }
        } else if (element) {
          element.classList.remove("rightSidebarFixed");
          element.classList.remove("rightSidebarFixedAfter");
        }
      }
      else {
        return null
      }
    },
    [
      resumePublic,
      photosPublic,
      audiosPublic,
      videosPublic,
      descriptionPublic,
      quotesPublic,
    ],
    experianceRef
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchUserProfileDataByUserNavName({ jwt: "", id: navName }));
  }, [navName, isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoading &&
      isLoading !== isLoadingPrev &&
      isLoadingPrev !== undefined && useridPublic
    ) {
      dispatch(startGetUserProfessionCardsByUserId({ jwt: "", userId: useridPublic }));
      dispatch(startGetUserProfessionByUserId({ jwt: "", userId: useridPublic }));
      dispatch(startGetAllUserSkillsByUserId({ jwt: "", userId: useridPublic }));
      dispatch(startGetUserColleagues({ jwt: "", userId: useridPublic }));
      if (isLoggedIn && jwt) {
        dispatch(startGetRequestStatus({ jwt, userId: useridPublic }))
      }
    }
  }, [isLoading, isLoadingPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !userProfessionsIsloading &&
      userProfessionsIsloading !== undefined &&
      userProfessionsIsloading !== userProfessionsIsloadingPrev &&
      userProfessionsIsloadingPrev !== undefined
    ) {
      const ids = userProfessions.map((up) => up.userprofessionids);
      if (ids && ids?.length === 0) {
        let id = navName.split("_");
        dispatch(
          startGetFeaturedProfileDataNoCardByUserId({
            jwt: "",
            userId: id[1],
            featured: true,
          })
        );
      } else {
        dispatch(startGetProfessionByIds({ jwt: "", ids }));
      }
    }
    else {
      return null
    }
  }, [userProfessionsIsloading, userProfessionsIsloadingPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !userCardsIsloading &&
      !professionCategoryisLoading &&
      professionCategoryisLoading !== professionCategoryisLoadingPrev
    ) {
      if (
        userCards &&
        userCards?.length &&
        userProfessions &&
        userProfessions?.length
      ) {
        let engagements = 0;
        userCards?.forEach((card) => {
          if (card?.contracttype === "contract") {
            engagements = engagements + card?.final_contracts;
          } else if (card?.contracttype === "slot") {
            engagements = engagements + card?.final_slots;
          }
        });
        let userProfessionsArr = userProfessions
          ?.sort((a, b) => a?.id - b?.id).map((item) => {
            return {
              ...item,
              name: professionCategoriesById[item?.userprofessionids]?.name,
            };
          })
        setUserProfessionsArr(userProfessionsArr);
        if (card) {
          let pos = userProfessionsArr.map((e) => { return e.id; }).indexOf(card?.userprofessionid);
          CardSliderRef?.current?.slickGoTo(pos)
          setSelectedUserProfession(userProfessionsArr?.filter(item => item?.id === card?.userprofessionid)?.[0]);
        }
        else {
          setSelectedUserProfession(userProfessionsArr[0]);
        }
        setEngagementsCount(engagements);
      }
    }
    else {
      return null
    }
  }, [userCardsIsloading, professionCategoryisLoading, userCardsIsloadingPrev, professionCategoryisLoadingPrev,]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedUserProfession?.id) {
      let userCardsByUserProfessionId = _.groupBy(
        userCards,
        (x) => x?.userprofessionid
      );
      setUserCardsByUserProfessionID(userCardsByUserProfessionId);
      if (card && (selectedUserProfession?.id === card?.userprofessionid)) {
        setSelectedUserCard(userCards?.filter((item) => item?.id === card?.cardid)?.[0]);
      }
      else {
        setSelectedUserCard(
          userCardsByUserProfessionId[selectedUserProfession?.id]
            ? userCardsByUserProfessionId[selectedUserProfession?.id][0]
            : {}
        );
      }
    }
    else {
      return null
    }
  }, [selectedUserProfession]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedUserCard?.id) {
      var element = document.getElementById("rightSidebar");
      if (element && element.classList.contains("rightSidebarFixed")) {
        element.classList.remove("rightSidebarFixed");
      } else if (
        element &&
        element.classList.contains("rightSidebarFixedAfter")
      ) {
        element.classList.remove("rightSidebarFixedAfter");
      }
      let id = navName.split("_");
      dispatch(
        startGetFeaturedProfileDataByUserId({
          jwt: "",
          userId: id[1],
          professionCardId: selectedUserCard?.id,
          featured: true,
        })
      );
    }
    else {
      return null
    }
  }, [selectedUserCard]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingFeaturedProfileData &&
      isLoadingFeaturedProfileData !== undefined &&
      isLoadingFeaturedProfileData !== isLoadingFeaturedProfileDataPrev &&
      isLoadingFeaturedProfileDataPrev !== undefined
    ) {
      setSelectedResume(resumePublic[0]);
      setLoading(false);
      // setKey(photosPublic && photosPublic?.length ? "photos" : videosPublic && videosPublic?.length ? "videos" : audiosPublic && audiosPublic?.length ? "audio" : "")
    }
    else {
      return null
    }
  }, [isLoadingFeaturedProfileData, isLoadingFeaturedProfileDataPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingAskQuestion &&
      isLoadingAskQuestion !== undefined &&
      isLoadingAskQuestion !== isLoadingAskQuestionPrev &&
      isLoadingAskQuestionPrev !== undefined
    ) {
      closeQusPopup();
      openSuccessQusPopup();
    }
    else {
      return null
    }
  }, [isLoadingAskQuestion, isLoadingFeaturedProfileDataPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingPostSendRequest &&
      isLoadingPostSendRequest !== isLoadingPostSendRequestPrev &&
      isLoadingPostSendRequestPrev !== undefined && !colleagueRequestError
    ) {
      setAddColleagueStatusShow("Request has been sent successfully")
      if (isLoggedIn && jwt) {
        dispatch(startGetRequestStatus({ jwt, userId: useridPublic }))
      }
    }
  }, [isLoadingPostSendRequest, isLoadingPostSendRequestPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingUpdateRequest &&
      isLoadingUpdateRequest !== isLoadingUpdateRequestPrev &&
      isLoadingUpdateRequestPrev !== undefined && !colleagueRequestError
    ) {
      if (colleagueRequestStatus === "accept") {
        setAddColleagueStatusShow("Colleague un-followed!")
      }
      else if (colleagueRequestStatus === "sent") {
        setAddColleagueStatusShow("Request Cancelled!")
      }
      else if (colleagueRequestStatus === "requested") {
        setAddColleagueStatusShow("Request Accepted!")
      }
      else {
        setAddColleagueStatusShow("Success!")
      }
      if (isLoggedIn && jwt) {
        dispatch(startGetRequestStatus({ jwt, userId: useridPublic }))
        dispatch(startGetUserColleagues({ jwt: "", userId: useridPublic }));
      }
    }
  }, [isLoadingUpdateRequest, isLoadingUpdateRequestPrev]); // eslint-disable-line react-hooks/exhaustive-deps

  const settings = {
    autoplay: false,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 3,
    variableWidth: true
  };
  const links = {
    autoplay: false,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: true
  };
  const bodyRef = document.getElementsByTagName("BODY")[0];
  const closePopup = () => {
    setPopClassName("hidePop");
    bodyRef.style.overflow = 'auto';
  };

  const openPopup = () => {
    setPopClassName("showPop");
    bodyRef.style.overflow = 'hidden';
  };
  const closeQusPopup = () => {
    setPopQusClassName("hidePop");
    bodyRef.style.overflow = 'auto';
  };

  const openQusPopup = () => {
    setPopQusClassName("showPop");
    bodyRef.style.overflow = 'hidden';
  };

  const closeSuccessQusPopup = () => {
    setSuccessPopQusClassName("hidePop");
    bodyRef.style.overflow = 'auto';
  };

  const openSuccessQusPopup = () => {
    setSuccessPopQusClassName("showPop");
    bodyRef.style.overflow = 'hidden';
  };

  //download popup
  const openDownloadPopup = () => {
    setDownloadPopClassName("showPop");
  };
  const closeDownloadPopup = () => {
    setDownloadPopClassName("hidePop");
  };

  const onSubmitAskMe = () => {
    if (isLoggedIn) {
      if (title && message) {
        const data = {
          asked_to: useridPublic,
          question: message,
          title: title,
        };
        dispatch(startPostAskQuestion({ jwt, data }, isLoggedIn));
      }
    } else {
      if (firstName && lastName && email && title && message) {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
        if (reg.test(email) === false) {
          setemailError("Invalid email address");
        } else {
          const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            asked_to: useridPublic,
            question: message,
            title: title,
          };
          dispatch(startPostAskQuestion({ data }, isLoggedIn));
        }
      }
    }
  };

  const next = () => {
    SliderRef.current.slickNext();
  };

  const previous = () => {
    SliderRef.current.slickPrev();
  };

  const nextLinkSliderRef = () => {
    LinkSliderRef.current.slickNext();
  };

  const previousLinkSliderRef = () => {
    LinkSliderRef.current.slickPrev();
  };

  const renderTopContent = () => {
    if ((photosPublic && photosPublic?.length) || (audiosPublic && audiosPublic?.length) || (videosPublic && videosPublic?.length) || (upcomingPublic && upcomingPublic?.length) || (quotesPublic && quotesPublic?.length) || descriptionPublic || (linksPublic && linksPublic?.length) || (resumePublic && resumePublic?.length)) {
      return (
        <div className="col-md-6 col-lg-7 col-xl-8" id="aboutRefer" ref={aboutRef}>
          {descriptionPublic ? <h3>About</h3> : null}
          <p
            className="read-more-content"
            onClick={() => {
              setDetailsType({
                type: "about",
                text: descriptionPublic,
              });
              openPopup();
            }}
          >
            {descriptionPublic?.length > 520
              ? descriptionPublic?.slice(0, 520) + "..."
              : descriptionPublic}
          </p>
          {descriptionPublic?.length > 520 ? (
            <p
              className="read-more-content--readmore"
              onClick={() => {
                setDetailsType({
                  type: "about",
                  text: descriptionPublic,
                });
                openPopup();
              }}
            >
              Read More
            </p>
          ) : null}
          {userColleagues && userColleagues?.length ? <UserColleagues data={userColleagues} onClickViewAll={() => {
            if (isLoggedIn){
              dispatch(setViewAllColleaguesUserId({ name: firstNamePublic, id: useridPublic }))
              history.push({
                pathname:"/user/colleagues"
              })
            }
            else{
              dispatch(showLoginPopUp())
              dispatch(loginPopUpTab("login"))
            }
          }}
            onClick={(navigation_name) => history.push({
              pathname: `/search/people/${navigation_name}`,
            })}
          /> : null}
          {quotesPublic && quotesPublic?.length ? (
            <div className="margin--tb50">
              <h3>Press</h3>
              <Press quotes={quotesPublic} onClickPress={(quote) => {
                setDetailsType({
                  type: "press",
                  quote: quote
                });
                openPopup();
              }} />
              <div className="clear"></div>
            </div>
          ) : null}
          {(photosPublic && photosPublic?.length) ||
            (videosPublic && videosPublic?.length) ||
            (audiosPublic && audiosPublic?.length) ? (
              <div ref={mediaRef} id="mediaRefer">
                <div className="medias margin--b50">
                  {photosPublic && photosPublic?.length ?
                    <div className="margin--tb80">
                      <h3>Photos</h3>
                      <Gallery
                        backdropClosesModal={true}
                        enableImageSelection={false}
                        images={photosPublic?.map((photo) => {
                          return {
                            src: photo?.url,
                            thumbnail: photo?.url,
                          };
                        })}
                        showLightboxThumbnails={true}
                        margin={5}
                        thumbnailImageComponent={({ tileViewportStyle, index, item }) => {
                          tileViewportStyle(index)
                          if (index < 2) {
                            return (
                              <img style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                              }} src={item?.src} alt="gallery" />
                            )
                          }
                          else {
                            return (
                              <img style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                              }} src={item?.src} alt="gallery" />
                            )
                          }
                        }}
                        tileViewportStyle={myTileViewportStyleFn}
                      />
                      <div className="clear"></div>
                    </div> : null}
                  {videosPublic && videosPublic?.length ?
                    <div className="margin--tb80 profileVideo__wrap">
                      <h3>Videos</h3>

                      <VideoCard
                        videos={videosPublic}
                        onClick={(selectedVideo) => { setDetailsType({ type: "video", id: selectedVideo?.id }); openPopup() }}
                      />
                      <div className="clear"></div>
                    </div> : null}
                  {audiosPublic && audiosPublic?.length ?
                    <div className="margin--t80">
                      <h3>Audio</h3>

                      <Scrollbars
                        autoHeight
                        autoHeightMin={100}
                        autoHeightMax={495}
                      >
                        {audiosPublic?.map((audio, index) => {
                          return (
                            <AudioCard
                              key={index}
                              onClick={() => {
                                setDetailsType({
                                  type: "audio",
                                  id: audio?.id,
                                });
                                openPopup();
                              }}
                              img={audio?.thumbnail}
                              name={audio?.title}
                              url={audio?.url}
                              creator={audio?.creators}
                              work={audio?.showname}
                            />
                          );
                        })}
                      </Scrollbars>
                      <div className="clear"></div>
                    </div> : null}
                  <div className="clear"></div>
                </div>
                <div className="clear"></div>
              </div>
            ) : null}
          {upcomingPublic && upcomingPublic?.length ? (
            <div ref={highRef} id="highlightsRef">
              <div>
                <h3>Highlights</h3>
                <div className="highlights">
                  <Slider
                    {...settings}
                    ref={SliderRef}
                    className={"highlights__slider"}
                  >
                    {upcomingPublic?.map((highlights, index) => {
                      return (
                        <HightlightCard
                          key={index}
                          img={highlights?.thumbnail}
                          title={highlights?.title}
                          text={highlights?.role}
                          date={highlights?.date}
                          onClick={() => {
                            setDetailsType({
                              type: "highlights",
                              id: highlights?.id,
                            });
                            openPopup();
                          }}
                        />
                      );
                    })}
                  </Slider>
                  {upcomingPublic?.length > 3 && (
                    <div onClick={previous} className="arrow__left">
                      <img alt="left" src={LeftArrow} />
                    </div>
                  )}
                  {upcomingPublic?.length > 3 && (
                    <div onClick={next} className="arrow__right">
                      <img alt="right" src={RightArrow} />
                    </div>
                  )}
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear"></div>
            </div>
          ) : null}
        </div>
      )
    }
    else {
      return <div className="col-md-8 profileSpinnerContainer">
        <ProfileEmpty />
      </div>
    }
  }

  const renderTooltip = props => (
    <Tooltip {...props} className="copyTooltip--colleague">
      <p>Copy profile link to clipboard</p>
    </Tooltip>
  );

  const renderAddPeopleTooltip = props => {
    let temp = ""
    if (colleagueRequestStatus === "accept") {
      temp = <p>You and {firstNamePublic} are colleagues</p>
    }
    else if (colleagueRequestStatus === "delete") {
      temp = <p>Send {firstNamePublic} a colleague request</p>
    }
    else if (colleagueRequestStatus === "unfriend") {
      temp = <p>Send {firstNamePublic} a colleague request</p>
    }
    else if (colleagueRequestStatus === "sent") {
      temp = <p>Your request is pending</p>
    }
    else if (colleagueRequestStatus === "requested") {
      temp = <p>Accept {firstNamePublic}’s colleague request</p>
    }
    else {
      temp = <p>Send {firstNamePublic} a colleague request</p>
    }
    return (
      <Tooltip {...props} className="copyTooltip--colleague">
        {temp}
      </Tooltip>
    );
  }

  const onSendRequest = () => {
    setAddColleagueStatusShow("")
    if (!colleagueRequestStatus || colleagueRequestStatus === "delete" || colleagueRequestStatus === "unfriend") {
      const data = {
        "user_id": useridPublic
      }
      dispatch(startPostSendRequest({ jwt, data }))
    }
    else if (colleagueRequestStatus === "requested") {
      dispatch(startAcceptRequest({ jwt, requestId: colleagueRequestId }))
    }
    else if (colleagueRequestStatus === "accept") {
      if (isLoggedIn) {
        if (useridPublic === loggedInUserId) {
          history.push({
            pathname: '/colleagues'
          })
        }
        else {
          dispatch(setViewAllColleaguesUserId({ name: firstNamePublic, id: useridPublic }))
          history.push({
            pathname: "/user/colleagues"
          })
        }
      }
      else {
        dispatch(setViewAllColleaguesUserId({ name: firstNamePublic, id: useridPublic }))
        history.push({
          pathname: "/user/colleagues"
        })
      }
    }
    else if (colleagueRequestStatus === "sent") {
      history.push({
        pathname: '/colleagues'
      })
    }
  }

  const renderColleagueImage = () => {
    if (!colleagueRequestStatus || colleagueRequestStatus === "delete" || colleagueRequestStatus === "unfriend") {
      return (<img
        className="addIcon"
        src={SendRequest}
        alt="Add People"
        onClick={() => onSendRequest()}
      />)
    }
    else if (colleagueRequestStatus === "requested") {
      return (<img
        className="addIcon"
        src={AcceptRequest}
        alt="Add People"
        onClick={() => onSendRequest()}
      />)
    }
    else if (colleagueRequestStatus === "accept") {
      return (<img
        className="addIcon"
        src={AcceptedRequest}
        alt="Add People"
        onClick={() => onSendRequest()}
      />)
    }
    else if (colleagueRequestStatus === "sent") {
      return (<img
        className="addIcon"
        src={PendingRequest}
        alt="Add People"
        onClick={() => onSendRequest()}
      />)
    }
  }

  return (
    <div id="dashboard" className="dashboard" onClick={() => dispatch(changeDropdownStatus(false))}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{firstNamePublic + " " + lastNamePublic}</title>
        <meta property="og:site_name" content={firstNamePublic + " " + lastNamePublic} />
        <meta property="og:title" content={firstNamePublic + " " + lastNamePublic} />
        <meta property="og:description" content={firstNamePublic + " " + lastNamePublic} />
        <meta property="og:image" itemprop="image" content={profileImage} />
        <meta property="og:type" content="website" />
        <meta property="og:updated_time" content="1440432932" />
      </Helmet>
      <Toast onClose={() => setCopyConfirm(false)} show={copyConfirm} delay={3000} className="copyToast" style={{ margin: 'auto' }} autohide>
        <Toast.Header closeButton={false} className="copyToastHeader">
          <p>Profile link copied to clipboard!</p>
        </Toast.Header>
      </Toast>
      <Toast contentStyle={{ backgroundColor: '#4A4A4A' }} onClose={() => setAddColleagueStatusShow("")} show={addColleagueStatusShow?.length ? true : false} delay={3000} className="copyToast" style={{ margin: 'auto' }} autohide>
        <Toast.Header closeButton={false} className="copyToastHeader--colleague">
          <p>{addColleagueStatusShow}</p>
        </Toast.Header>
      </Toast>
      <div className="main__dashboard profile">
        <Header heading="" category="" headerClassname="plainHeader" />
        {loading ? (
          <Spinner />
        ) : (
            <div className="container">
              <div>
                <div className="bannerImage" onClick={() => {
                  if (profileCoverImage) {
                    setDetailsType({ type: "picture", url: profileCoverImage }); openPopup()
                  }
                }}>
                  <img
                    src={profileCoverImage ? profileCoverImage : Cover}
                    alt="banner"
                  />
                </div>
                <div id="blueWrap" className="bannerBlueWrap">
                  <div className="container">
                    <div className={"banner__profile " + (spotlight ? "isFeatured" : null)} onClick={() => {
                      if (profileImage) {
                        setDetailsType({ type: "picture", url: profileImage }); openPopup()
                      }
                    }}>
                      <img
                        src={profileImage ? profileImage : ProfImg}
                        alt="Profile"
                      />
                      {/* {spotlight ? <div className="featuredProf">Featured Professional</div> : null}
                {spotlight ? <div className="featuredTag">
                  <img src={FeatTag} alt="Featured Professional" />
                </div>:null} */}
                      <div className="clear"></div>
                    </div>
                    <div className="banner__profileDetails">
                      <div className="banner__profileText d-flex h-100">
                        <div className="justify-content-center align-self-center">
                          <h2 className="name__verified">
                            {firstNamePublic + " " + lastNamePublic}
                          </h2>
                          {Array.isArray(displayLocation) ? (
                            <p className="locationWhite">
                              {displayLocation?.join(", ")}
                            </p>
                          ) : null}
                          <div className="fixedTop__dNone">
                            {userProfessionsArr?.slice(0, 3)?.map((pro, index) => {
                              return (
                                <div key={index} className="peopleSkills">
                                  {pro?.name}
                                </div>
                              );
                            })}
                            <div className="clear"></div>
                            {engagementsCount !== null && parseInt(engagementsCount) > 0 ? (
                              <div className="engagements">
                                {engagementsCount} Total Engagements
                              </div>
                            ) : null}
                            <div className="clear"></div>
                          </div>
                          <div className="clear"></div>
                        </div>
                        <div className="fixedTop__visible">
                          <ul className="fixedTop__tab">
                            {descriptionPublic ? <li
                              onClick={() => tabItem1Click()}
                              id="tabAbout"
                              className="tabAbout"
                            >
                              About
                      </li> : null}
                            {(photosPublic && photosPublic?.length) ||
                              (videosPublic && videosPublic?.length) ||
                              (audiosPublic && audiosPublic?.length) ? <li
                                onClick={() => tabItem2Click()}
                                id="tabMedia"
                                className="tabMedia"
                              >
                                Media
                      </li> : null}
                            {upcomingPublic && upcomingPublic?.length ? <li
                              onClick={() => tabItem3Click()}
                              id="tabHighlights"
                              className="tabHighlights"
                            >
                              Highlights
                      </li> : null}
                            {resumePublic &&
                              resumePublic?.length ? <li
                                onClick={() => tabItem4Click()}
                                id="tabExp"
                                className="tabExp"
                              >
                                Experience
                      </li> : null}
                          </ul>
                          <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                      </div>
                      <div className="banner__profileButtons">
                        <div className="fixedTop__dNone">
                          <div className="banner__iconsWrap">
                            {isLoggedIn && useridPublic !== loggedInUserId ? <OverlayTrigger placement="top" overlay={renderAddPeopleTooltip}>
                              {renderColleagueImage()}
                            </OverlayTrigger> : useridPublic === loggedInUserId ? null : <img
                              className="addIcon"
                              src={SendRequest}
                              alt="Add People"
                              onClick={() => { dispatch(showLoginPopUp()); dispatch(loginPopUpTab("login")) }}
                            />}
                            <OverlayTrigger placement="top" overlay={renderTooltip}>
                              <CopyToClipboard text={window.location.href}>
                                <img className="shareIcon" src={Share} alt="Share" onClick={() => { setCopyConfirm(true) }} />
                              </CopyToClipboard>
                            </OverlayTrigger>
                          </div>
                          <button
                            onClick={() => openQusPopup()}
                            className="button button--white"
                          >
                            ASK A QUESTION
                          </button>
                          <div className="clear"></div>
                        </div>
                        <div className="fixedTop__visible">
                          <h3>{selectedUserCard?.name}</h3>
                          <button
                            onClick={() => openQusPopup()}
                            className="button button--white"
                          >
                            {/* {selectedUserCard?.active ? "BOOK NOW" : "ASK A QUESTION"} */}
                            ASK A QUESTION
                          </button>
                          {!selectedUserCard?.active ? <div className="fixedTop__price">Not Available for Hire</div> : <div className="fixedTop__price">{
                            selectedUserCard?.contracttype === "contract"
                              ? selectedUserCard?.contract_amount
                                ? "$" + selectedUserCard?.contract_amount
                                : ""
                              : selectedUserCard?.contracttype === "slot"
                                ? selectedUserCard?.amount
                                  ? "$" + selectedUserCard?.amount
                                  : ""
                                : selectedUserCard?.contracttype === "service"
                                  ? selectedUserCard?.amount
                                    ? "$" + selectedUserCard?.amount
                                    : "Get a Quote"
                                  : ""
                          } | <span>{
                            selectedUserCard?.contracttype === "service"
                              ? `per ${selectedUserCard?.type}`
                              : selectedUserCard?.contracttype === "slot"
                                ? `for ${selectedUserCard?.slot_length} mins`
                                : selectedUserCard?.contracttype === "contract"
                                  ? "per Engagement"
                                  : ""
                          }</span></div>}
                          <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                      </div>
                      <img className="linePattern" src={LinesPattern} alt="pattern" />
                      <img className="profileBannerOval1" src={ProfileBannerOval1} alt="pattern" />
                      <img className="profileBannerOval2" src={ProfileBannerOval2} alt="pattern" />
                      <img className="profileBannerOval3" src={ProfileBannerOval2} alt="pattern" />
                      <div className="clear"></div>
                    </div>
                    <div className="clear"></div>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear"></div>
              </div>
              <div className="margin--tb50">
                <div className="row">
                  {isLoadingFeaturedProfileData ? (
                    <div className="col-md-8 profileSpinnerContainer">
                      <Spinner />
                    </div>
                  ) : renderTopContent()}
                  <div className="col-md-6 col-lg-5 col-xl-4 professionCards" ref={proCards}>
                    <div id="rightSidebar" className="bg--white padd--15">
                      {userProfessions &&
                        userProfessions.map((up) => up.userprofessionids)
                          ?.length ? (
                          <SkillsTab
                            cardSliderRef={CardSliderRef}
                            userProfessionsArr={userProfessionsArr}
                            selectedUserProfession={selectedUserProfession}
                            onChangeUserProfession={(pro) =>
                              setSelectedUserProfession(pro)
                            }
                            selectedUserCard={selectedUserCard}
                            userCards={
                              userCardsByUserProfessionId[
                              selectedUserProfession?.id
                              ]
                            }
                            onChangeCard={(card) => setSelectedUserCard(card)}
                            onClickAskMe={(card) => openQusPopup()}
                            onClickButtton={(e) => { e.preventDefault(); e.stopPropagation(); openDownloadPopup() }}
                            allSpecializationSkillsByCardId={
                              allSpecializationSkillsByCardId
                            }
                            allSkillsByCardId={allSkillsByCardId}
                          />
                        ) : (
                          <div className="connect__box">
                            <h2>Contact Today!</h2>
                            <h3>
                              Interested in working
                          <br />
                          with this person?
                        </h3>
                            <p>Contact them by sending a message</p>
                            <div className="askQus__box">
                              <button
                                className="button button--white"
                                onClick={() => openQusPopup()}
                              >
                                ASK A QUESTION
                          </button>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {isLoadingFeaturedProfileData ? null : resumePublic &&
                resumePublic?.length ? (
                  <div
                    ref={experianceRef}
                    id="experianceRef"
                    className="experiance margin--tb50"
                  >
                    <h3>Experience</h3>
                    <Tab.Container defaultActiveKey="0">
                      <Row>
                        <Col sm={4}>
                          <div className="experiance__cardWrp">
                            <Scrollbars style={{ height: 628 }}>
                              {_.uniq(resumePublic, (x) => x?.id)?.map(
                                (resume, index) => {
                                  return (
                                    <ExperianceCard
                                      key={index}
                                      onClick={() => setSelectedResume(resume)}
                                      className={
                                        selectedResume?.id === resume?.id
                                          ? "expCard expCard--active"
                                          : "expCard"
                                      }
                                      skill={resume?.title1 + " | " + resume?.title2}
                                      name={resume?.header}
                                      description={resume?.subheader}
                                      letter={resume?.header
                                        ?.slice(0, 1)
                                        .toUpperCase()}
                                      location={resume?.footer}
                                      date={
                                        resume?.sortdate
                                          ? moment(
                                            resume?.sortdate,
                                            "YYYY-MM-DD"
                                          ).format("MMM YY")
                                          : ""
                                      }
                                    />
                                  );
                                }
                              )}
                            </Scrollbars>
                          </div>
                        </Col>
                        <Col sm={8}>
                          <ExperianceDetail
                            skillHead={
                              selectedResume?.title1 +
                              " | " +
                              selectedResume?.title2
                            }
                            name={selectedResume?.header}
                            description={selectedResume?.subheader}
                            date={
                              selectedResume?.sortdate
                                ? moment(
                                  selectedResume?.sortdate,
                                  "YYYY-MM-DD"
                                ).format("MMM YYYY")
                                : ""
                            }
                          >
                            <Resume resume={selectedResume} />
                          </ExperianceDetail>
                        </Col>
                      </Row>
                    </Tab.Container>
                    <div className="clear"></div>
                  </div>
                ) : null}
              {isLoadingFeaturedProfileData ? null : linksPublic &&
                linksPublic?.length ? (
                  <div className="links">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2>Links</h2>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className="arrow__leftMar" onClick={previousLinkSliderRef}>
                          <img alt="left arrow" className="cursorPointer" src={LeftArrow} />
                        </div>
                        <div onClick={nextLinkSliderRef}>
                          <img alt="right arrow" className="cursorPointer" src={RightArrow} />
                        </div>
                      </div>
                    </div>
                    <Slider
                      {...links}
                      ref={LinkSliderRef}
                      slidesToShow={
                        linksPublic?.length >= 4 ? 4 : linksPublic?.length
                      }
                    >
                      {linksPublic?.map((link, index) => {
                        return (
                          <LinksCard
                            key={index}
                            img={linkImages[link?.type]}
                            heading={link?.type !== "Other Links" ? link?.type + " " + link?.title : link?.title}
                            description={link?.url}
                          />
                        );
                      })}
                    </Slider>
                    <div className="clear"></div>
                  </div>
                ) : null}
              <div className="clear"></div>
            </div>)}
        {popClassName === "showPop" ? <Popup className={popClassName} close={() => closePopup()}>
          <Details details={detailsType} />
        </Popup> : null}
        <PopupAskQus askQues="yes" className={popQusClassName} close={() => closeQusPopup()} onClickLogin={() => { closeQusPopup(); dispatch(loginPopUpTab("login")); dispatch(showLoginPopUp()) }} onClickRegister={() => { closeQusPopup(); dispatch(loginPopUpTab("register")); dispatch(showLoginPopUp()); }}>
          <div className="askpop">
            {isLoadingAskQuestion ? <Spinner /> : <div>
              <h3 className="askpop__head">Ask {firstNamePublic} a question</h3>
              <div>
                {!isLoggedIn ? <div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First name"
                        value={firstName}
                        onChange={(e) => setfirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Last name"
                        value={lastName}
                        onChange={(e) => setlastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control email inputIcon"
                      type="text"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={(e) => {
                        setemailError("");
                        setemail(e.target.value);
                      }}
                    />
                    {emailError && <p className="error">{emailError}</p>}
                  </div>
                </div> : null}
                <div className="form-group">
                  <label>Title</label>
                  <input
                    className="form-control email inputIcon"
                    type="text"
                    placeholder="Enter a title for your message"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    rows={isLoggedIn ? "5" : "1"}
                    className="form-control"
                    placeholder="Type your message"
                    value={message}
                    onChange={(e) => setmessage(e.target.value)}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className={
                    (
                      isLoggedIn
                        ? title && message
                        : firstName && lastName && email && title && message
                    )
                      ? "button button--primary width--100 mar--t25"
                      : "button button--disabled width--100 mar--t25"
                  }
                  onClick={() => onSubmitAskMe()}
                >
                  Submit
                    </button>
              </div>
            </div>}
          </div>
          <div className="clear"></div>
        </PopupAskQus>
        <PopupAskQus className={successpopQusClassName} close={() => closeSuccessQusPopup()} onClickLogin={() => { closeSuccessQusPopup(); dispatch(loginPopUpTab("login")); dispatch(showLoginPopUp()) }} onClickRegister={() => { closeSuccessQusPopup(); dispatch(loginPopUpTab("register")); dispatch(showLoginPopUp()); }}>
          <div className="askQus__success">
            <h2>Message sent successfully!</h2>
            <img src={Success} alt="Message sent successfully!" />
            <div className="clear"></div>
            <p>If you have any questions, you can always reach out directly to our support team by visiting our Contact page <span onClick={() => window.open("https://www.yourresonance.com/contact", "_blank")}>here</span>.</p>
            <button onClick={() => closeSuccessQusPopup()} className="button button--primary width--100 mar--t25">CLOSE</button>
            <div className="clear"></div>
          </div>
        </PopupAskQus>
        <DownloadPopup
          className={downloadPopClassName}
          close={() => closeDownloadPopup()}
        ></DownloadPopup>
        <div className="clear"></div>
      </div>
    </div>
  );
};

export default Profile;
