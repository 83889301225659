import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//assets
import Spinner from "../assets/molecules/spinner";
import VideoCard from "../assets/layouts/cards/activityVideoCard";

//actions
import { startGetRandomMediaVideos } from "../../redux/actions/media/profileEdit/video";

//reducers
import {
  getisLoadingAll,
  getAllvideos,
} from "../../redux/reducers/media/profileEdit/video";

const VideosSection = () => {
  const dispatch = useDispatch();

  let history = useHistory();

  const allvideos = useSelector(getAllvideos);
  const isLoadingAll = useSelector(getisLoadingAll);

  useEffect(() => {
    dispatch(startGetRandomMediaVideos());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoadingAll ? (
    <div className="container paddingContainer minHeightContainer">
      <Spinner />
    </div>
  ) : (
    <div className="container">
        <VideoCard videos={allvideos} onClickProfile={(navigation_name) => history.push({
          pathname: `/search/people/${navigation_name}`,
        })}/>
      <div className=""></div>
      <div className="clear"></div>
    </div>
  );
};

export default VideosSection;
