import React,{useRef} from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeftArrow from '../../assets/images/arrowLeft.svg'
import RightArrow from '../../assets/images/arrowRight.svg'

const Press = (props) => {
  let SliderRef = useRef(null)
  const settings = {
    dots: true,
    arrows:false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const next = () => {
    SliderRef.current.slickNext();
  }
  const previous = () => {
    SliderRef.current.slickPrev();
  }

  return (
    <div className="press">
      <Slider {...settings} ref={SliderRef} className="press__slider">
        {props?.quotes?.map((quote,index)=>{
          return (
            <div key={index}>
              <p
                className="read-more-content--press"
                onClick={() => props?.onClickPress(quote)}
              >
                {quote?.publicationExcerpt?.length > 200
                  ? quote?.publicationExcerpt?.slice(0, 200) + "..."
                  : quote?.publicationExcerpt}
              </p>
              {quote?.publicationExcerpt?.length > 200 ? (
                <p
                  className="read-more-content--press--readmore--press"
                  onClick={() => props?.onClickPress(quote)}
                >
                  View More Details
                </p>
              ) : null}
              <p className="testimonial__name">{quote?.publicationName}</p>
              <p className="testimonial__desig">{quote?.author}</p>
            </div>
          )
        })}
      </Slider>
      {props?.quotes?.length > 1 && <div className="pressArrows">
        <div onClick={previous} className="pressArrows__left addPointer">
          <img alt="left arrow" src={LeftArrow} />
        </div>
        <div className="pressArrows__right" onClick={next}>
          <img alt="right arrow" src={RightArrow} className="addPointer"/>
        </div>
      </div>}
    </div>
  );
};

export default Press;
