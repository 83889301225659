import React from "react";

// assets
import ProfileEmptyImg from '../../../../assets/images/emptyProfile.svg'

export const ProfileEmpty = (props) => {
    return (
        <div className="searchEmpty">
            <img src={ProfileEmptyImg} alt="empty profile" className="searchEmpty__image searchEmpty__image--profile" />
            <p className="searchEmpty__text searchEmpty__text--profile">This person is still building their profile.<br/>Check back later!</p>
            <div className="clear"></div>
        </div>
    )
}