import React,{ useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//action
import { fetchRegister } from '../../../redux/actions/account'

//reducers
import { getisLoading, getError } from '../../../redux/reducers/account/accounts'

//assets
import Spinner from "../../assets/molecules/spinner";
import ShowPass from "../../../assets/images/visibility.png"

//helpers
import { usePrevious } from '../../../helpers/utils'

const Register = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [agree, setAgree] = useState()
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [error, setError] = useState("") 
  const [showPassword, setShowPassword] = useState(false) 

  const dispatch = useDispatch()

  const isLoadingLogin = useSelector(getisLoading)
  const isLoadingLoginPrev = usePrevious(isLoadingLogin)
  const errorLogin = useSelector(getError)

  useEffect(() => {
    if (!isLoadingLogin && errorLogin && isLoadingLoginPrev !== isLoadingLogin && isLoadingLoginPrev !== undefined) {
      setError('User Already registered with email ID - "' + email + '"' )
    }
  }, [isLoadingLogin, errorLogin, isLoadingLoginPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRegister = (event) =>{
    event.preventDefault();
    if (firstName && lastName && email && password && agree){
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
      if (reg.test(email) === false) {
        setEmailError("Invalid email address")
      }
      else{
        const data = {
          "firstName": firstName,
          "lastName": lastName,
          "email": email,
          "password": password
        }
        dispatch(fetchRegister(data))
      }
    }
    else{
      if (!firstName) {
        setFirstNameError("Enter first name")
      }
      if (!lastName) {
        setLastNameError("Enter last name")
      }
      if (!email) {
        setEmailError("Enter an email address")
      }
      if (!password) {
        setPasswordError("Enter a password")
      }
      if (!agree) {
        setPasswordError("Please agree to continue.")
      }
    }
  }

  return (isLoadingLogin ? <Spinner/> :
    <form onSubmit={(e) => onRegister(e)}>
      <div className="form-group">
        <label>First Name</label>
        <input
          type="text"
          className="form-control  inputIcon name"
          placeholder="Enter First Name"
          value={firstName}
          onChange={(e) => {
            setError("")
            setFirstNameError("")
            setLastNameError("")
            setEmailError("")
            setPasswordError("")
            setFirstName(e.target.value)}
          }
        />
        {firstNameError && <p className="error">{firstNameError}</p>}
      </div>
      <div className="form-group">
        <label>Last Name</label>
        <input 
          type="text" 
          className="form-control  inputIcon name" 
          placeholder="Enter Last Name" 
          value={lastName}
          onChange={(e) => {
            setError("")
            setFirstNameError("")
            setLastNameError("")
            setEmailError("")
            setPasswordError("")
            setLastName(e.target.value)}
          }
          />
        {lastNameError && <p className="error">{lastNameError}</p>}
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control email inputIcon"
          placeholder="Enter email address"
          value={email}
          onChange={(e) => {
            setError("")
            setFirstNameError("")
            setLastNameError("")
            setEmailError("")
            setPasswordError("")
            setEmail(e.target.value)}
          }
        />
        {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="form-group password__wrap">
        <label>Password</label>
        <input
          type={showPassword ? "text" : "password"}
          className="form-control"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => {
            setError("")
            setFirstNameError("")
            setLastNameError("")
            setEmailError("")
            setPasswordError("")
            setPassword(e.target.value)}
          }
        />
        <button type="button" className={"inputIcon--password"} onClick={() => setShowPassword(!showPassword)}><img alt="show Password" src={ShowPass}/></button>
        {passwordError && <p className="error">{passwordError}</p>}
        {error && <p className="error">{error}</p>}
      </div>
      <div className="form-check">
        <input 
          type="checkbox" 
          className="form-check-input" 
          checked={agree}
          onChange={() => setAgree(!agree)}/>
        <label className="form-check-label">
          I certify that I’m over the age of 13 and abide by the <br /><a target="_blank" rel="noreferrer" href="https://www.yourresonance.com/terms">terms and
          services of Resonance</a>
        </label>
      </div>
      <button type="submit" className={agree ? "button button--primary width--100 mar--t25" : "button button--disabled width--100 mar--t25"} >
        REGISTER
      </button>
    </form>
  );
};

export default Register;
