import React,{useState} from "react";
import Autocomplete from 'react-google-autocomplete';
import _ from 'underscore'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

//assets
import Header from "../../assets/layouts/header/registrationHeader";
import ProfilePic from "../../../assets/images/profile.png";
import { ethinicity } from './assets'
import Spinner from "../../assets/molecules/spinner";
import Sidebar from "../../assets/layouts/sidebar";

//helpers 
import { phoneCodes } from '../../../helpers/phoneCodes'

//actions
import { startUserProfileData } from '../../../redux/actions/profile'
import { changeRegisterPopUp } from '../../../redux/actions/ui/profile'

//reducers
import { getisLoading } from '../../../redux/reducers/profile'
import { getJwt } from '../../../redux/reducers/account/accounts'

const UserDetails = (props) => {
  const dispatch = useDispatch()

  const isLoadingAddProfile = useSelector(getisLoading)
  const jwt = useSelector(getJwt)

  const [selectedProfilePicture, setSelectedProfilePicture] = useState("")
  const [uploadingImage, setUploadingImage] = useState(false)
  const [uploadingImageError, setUploadingImageError] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState("")
  const [countryCode, setCountryCode] = useState("+1")
  const [phoneError, setPhoneError] = useState("")
  const [allPhoneCodes] = useState(_.groupBy(phoneCodes, (x) => x.dial_code))

  const [selectedDob, setSelectedDob] = useState("")
  const [dobError, setDobError] = useState("")

  const [selectedGender, setSelectedGender] = useState("")
  const [genderError, setGenderError] = useState("")

  const [selectedEthinicity, setSelectedEthinicity] = useState("")
  const [ethinicityError, setEthinicityError] = useState("")

  const [selectedLocation, setSelectedLocation] = useState({})
  const [locationError, setLocationError] = useState("")

  const [error, setError] = useState("")


  const onChangePic = async (e) => {
    setUploadingImage(true)
    setUploadingImageError("")
    const imagedata = e.target.files[0]
    const imageSize = imagedata?.size / 1024 / 1024
    if (imageSize < 5) {
      const data = new FormData()
      data.append('file', imagedata)
      const url = `https://fileupload.yourresonance.com/api/s3/multiple/public`
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/*',
          },
          body: data,
        })
        if (res.status >= 200 && res.status <= 300) {
          const response = await res.json()
          setSelectedProfilePicture(response?.data?.publicUrl)
        }
        setUploadingImage(false)
      } catch (e) {
        setUploadingImage(false)
      }
    } else {
      setUploadingImage(false)
      setUploadingImageError('Please select an image less than 5MB.')
    }
  }

  const handleSave = () => {
    if (selectedDob === "" || selectedGender === "" || phoneNumber === "" || !selectedLocation?.desc || selectedEthinicity === "" || selectedProfilePicture === "" || countryCode === "") {
      if (countryCode === "") {
        setPhoneError("Please enter a country code.")
      }
      if (phoneNumber === "") {
        setPhoneError("Please enter a phone number")
      }
      if (selectedProfilePicture === "") {
        setUploadingImageError("Please upload a picture")
      }
      if (selectedDob === "") {
        setDobError("Please enter your date of birth")
      }
      if (selectedGender === "") {
        setGenderError("Please select an option")
      }
      if (!selectedLocation?.desc) {
        setLocationError("Please enter a location")
      }
      if (selectedEthinicity === "") {
        setEthinicityError("Please select one of the options")
      }
    }
    else if (countryCode === "+1" ? false : !allPhoneCodes[countryCode]) {
      setPhoneError("Please enter a valid country code. Make sure to add a + sign before the code.")
    }
    else if (phoneNumber.length < 8) {
      setPhoneError("Please enter a valid phone number")
    }
    else {
      const dob = moment(selectedDob).format("YYYY-MM-DD")
      const locationObj = {
        description: selectedLocation?.desc,
        placeid: selectedLocation?.placeId,
        latitude: selectedLocation?.lat,
        longitude: selectedLocation?.lng
      }
      dispatch(startUserProfileData({ jwt, dob, gender: selectedGender, phone: phoneNumber, location: locationObj, url: selectedProfilePicture, country_code: countryCode, ethnicity: selectedEthinicity }))
      dispatch(changeRegisterPopUp(true))
    }
  }

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="main__dashboard userDetailPage">
        <Header className="welcome">
          <h1>Great!</h1>
          <p>Just a few more questions</p>
          <div className="carousel__container">
            <span>.</span>
            <span className="active">.</span>
          </div>
        </Header>
        <div className="userDetails__container">
          <div>
            {uploadingImage ? <Spinner/> :<div className="uploadBtn__wrapper">
              <div>
              {selectedProfilePicture ? <div><img alt="uploaded" className="uploadedImg" src={selectedProfilePicture} /><div className="changeImg">Change</div></div> :<button className="uploadBtn">
                <span className="button__inner">
                  <img alt="ProfilePic" src={ProfilePic} />
                  <br />
                  <span>Upload Profile Picture</span>
                  {uploadingImageError && <p className="error">{uploadingImageError}</p>}
                </span>
              </button>}
              <input 
                type="file" 
                name="myfile" 
                onChange={(e) => {
                  setError("")
                  setPhoneError("")
                  setUploadingImageError("")
                  setDobError("")
                  setGenderError("")
                  setLocationError("")
                  setEthinicityError("")
                  onChangePic(e)}
                }
                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                />
                <div className="clear"></div>
            </div>
            </div>}
            <div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Date of Birth</label>
                  <div className="form-control">
                    <DatePicker
                      showYearDropdown={true}
                      selected={selectedDob}
                      onChange={(date) => {
                        setError("")
                        setPhoneError("")
                        setUploadingImageError("")
                        setDobError("")
                        setGenderError("")
                        setLocationError("")
                        setEthinicityError("")
                        setSelectedDob(date ? date : "")}}
                      placeholderText={"MM/DD/YYYY"}
                    />
                    {dobError && <p className="error">{dobError}</p>}
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Phone Number - {allPhoneCodes[countryCode] && countryCode ? countryCode === "+1" ? " Country : US" : "Country : " + allPhoneCodes[countryCode][0].code : ""}</label>
                  <div className="phoneNo__wrap">
                    <input
                      type="text"
                      className="form-control phoneNo__code"
                      placeholder="+1"
                      value={countryCode}
                      onChange={(e) => {
                        setError("")
                        setPhoneError("")
                        setUploadingImageError("")
                        setDobError("")
                        setGenderError("")
                        setLocationError("")
                        setEthinicityError("")
                        setCountryCode(e.target.value)
                      }
                      }
                    />
                    <input
                      type="text"
                      className="form-control phoneNo inputIcon"
                      placeholder="Phone number"
                      value={phoneNumber}
                      onChange={(e) => {
                        setError("")
                        setPhoneError("")
                        setUploadingImageError("")
                        setDobError("")
                        setGenderError("")
                        setLocationError("")
                        setEthinicityError("")
                        setPhoneNumber(e.target.value)
                      }
                      }
                    />
                  </div>
                  {phoneError && <p className="error">{phoneError}</p>}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Gender</label>
                  <div className="custom__choose mb-3" onChange={(e) => setSelectedGender(e.target.value)}>
                    <input
                      type="radio"
                      id="option-1"
                      value="male"
                      name="gender"
                    />
                    <label for="option-1">Male</label>
                    <input
                      type="radio"
                      id="option-2"
                      value="female"
                      name="gender"
                    />
                    <label for="option-2">Female</label>
                    <input
                      type="radio"
                      id="option-3"
                      value="other"
                      name="gender"
                    />
                    <label for="option-3">Other</label>
                  </div>
                  {genderError && <p className="error">{genderError}</p>}
                </div>
                <div className="form-group col-md-6">
                  <label>Race and Ethnicity</label>
                  <select className="form-control" value={selectedEthinicity} onChange={(e) => {
                    setError("")
                    setPhoneError("")
                    setUploadingImageError("")
                    setDobError("")
                    setGenderError("")
                    setLocationError("")
                    setEthinicityError("")
                    setSelectedEthinicity(e.target.value)}
                  }>
                    <option selected value={""}>Select</option>
                    {ethinicity?.map((item,index)=>{
                      return <option key={index} value={item}>{item}</option>
                    })}
                  </select>
                  {ethinicityError && <p className="error">{ethinicityError}</p>}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Work Location</label>
                  <Autocomplete
                    style={{ width: '100%' }}
                    onPlaceSelected={(place) => {
                      setError("")
                      setPhoneError("")
                      setUploadingImageError("")
                      setDobError("")
                      setGenderError("")
                      setLocationError("")
                      setEthinicityError("")
                      const locationObj = {
                        lat: place?.geometry?.location?.lat(),
                        lng: place?.geometry?.location?.lng(),
                        placeId: place?.place_id,
                        desc: place?.formatted_address
                      }
                      setSelectedLocation(locationObj)
                    }}
                    types={['establishment', 'geocode']}
                    className="form-control"
                  />
                  {locationError && <p className="error">{locationError}</p>}
                </div>
              </div>
              {error && <p className="error">{error}</p>}
             <div className="text-center"> {isLoadingAddProfile ? <Spinner/> : <button type="submit" className="button save button--primary" onClick={() => handleSave()}>
                SAVE
              </button>}</div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  );
};

export default UserDetails;
