import {
    APP_POST_LINKS_START,
    APP_POST_LINKS,
    APP_POST_LINKS_FAIL,
    APP_DELETE_LINKS_START,
    APP_DELETE_LINKS,
    APP_DELETE_LINKS_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    links: [],
    isLoading: false,
    isDeleteLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const links = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_LINKS_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_LINKS:
            return Object.assign({}, state, {
                links: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_LINKS_FAIL:
            return Object.assign({}, state, {
                links: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_LINKS_START:
            return Object.assign({}, state, {
                isDeleteLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_LINKS:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_LINKS_FAIL:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.links.error
export const getMsg = state => state.links.message
export const getisLoading = state => state.links.isLoading
export const getisDeleteLoading = state => state.links.isDeleteLoading
export const getLinks = state => state.links.links
export const getIsSuccess = state => state.links.isSuccess
export const getstatusCode = state => state.links.statusCode