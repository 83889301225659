import {
    APP_CREATE_TRAINING_INDIVIDUAL_START,
    APP_CREATE_TRAINING_INDIVIDUAL,
    APP_CREATE_TRAINING_INDIVIDUAL_FAIL,
    APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_START,
    APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO,
    APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_FAIL,
    APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_START,
    APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS,
    APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'

const initialState = {
    isLoading: false,
    isLoadingAdditionalInfo: false,
    isLoadingIndividualToProfessionCards: false,
    error: false,
    message: '',
    isSuccess: false,
    individual: {},
    statusCode: 201
}


export const trainingIndividual = (state = initialState, action) => {
    switch (action.type) {
        case APP_CREATE_TRAINING_INDIVIDUAL_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_TRAINING_INDIVIDUAL:
            return Object.assign({}, state, {
                individual: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_TRAINING_INDIVIDUAL_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_START:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                individual: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_TRAINING_INDIVIDUAL_ADDITIONAL_INFO_FAIL:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingIndividualToProfessionCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                isLoadingIndividualToProfessionCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_TRAINING_INDIVIDUAL_TO_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingIndividualToProfessionCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.trainingIndividual.error
export const getMsg = state => state.trainingIndividual.message
export const getisLoading = state => state.trainingIndividual.isLoading
export const getIsSuccess = state => state.trainingIndividual.isSuccess
export const getstatusCode = state => state.trainingIndividual.statusCode
export const getIndividual = state => state.trainingIndividual.individual
export const getisLoadingAdditionalInfo = state => state.trainingIndividual.isLoadingAdditionalInfo
export const getisLoadingIndividualToProfessionCards = state => state.trainingIndividual.isLoadingIndividualToProfessionCards

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
