import {
    APP_LOGIN_START,
    APP_LOGIN,
    APP_LOGIN_FAIL,
    APP_LOGOUT,
    APP_REGISTER_FAIL,
    APP_ACTIVITY_UPDATE,
    APP_ACTIVITY_UPDATE_FAIL,
    APP_REGISTER_VERIFY,
    APP_REGISTER_VERIFY_FAIL, 
    APP_RESENT_OTP,
    APP_RESENT_OTP_FAIL,
    APP_RESET_PASSWORD_OTP,
    APP_RESET_PASSWORD_OTP_FAIL,
    APP_RESET_PASSWORD,
    APP_UPDATE_PROFILE_CREATED,
    APP_GET_ALL_USER_BY_TEXT_SEARCH,
    APP_CLEAR_ALL_USER_BY_TEXT_SEARCH,
    APP_GET_ALL_USER_BY_TEXT_SEARCH_FAIL,
    APP_CHANGE_PASSWORD_START,
    APP_CHANGE_PASSWORD,
    APP_CHANGE_PASSWORD_FAIL,
    APP_POST_CALENDER_ID_START,
    APP_POST_CALENDER_ID,
    APP_POST_CALENDER_ID_FAIL,
    STRIPE_ACCOUNT_STATUS_CHANGE,
    APP_PUSH_NOTOFICATION,
    APP_PUSH_NOTOFICATION_START,
    APP_GET_SPOTLOGHT_USERS_START,
    APP_GET_SPOTLOGHT_USERS,
    APP_GET_SPOTLOGHT_USERS_FAIL
} from '../../actions'

import { createSelector } from 'reselect'
import { getById } from '../../../helpers/utils.js'

const initialState = {
    isLoggedIn: false,
    jwt: '',
    firstName: '',
    lastName: '',
    email: '',
    id: '',
    activity: [],
    selectedProfile: [],
    error: false,
    isLoading: false,
    message: '',
    isSuccess: false,
    profileCreated : false,
    verified:false,
    statusCode:201,
    users:[],
    isLoadingChangePassword: false,
    stripeClientId:"",
    stripePublicKey:"",
    expressAccountStatus:false,
    isLoadingPushnotfication: false,
    isLoadingPostCalenderId: false,
    callenderId:null,
    isLoadingGetSpolightUsers:false,
    spotlightUsers:[],
    navigationName:""
}


export const accounts = (state = initialState, action) => {
    switch (action.type) {
        case APP_LOGIN_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_LOGIN:
            return Object.assign({}, state, {
                isLoggedIn: true,
                isLoading: false,
                error: false,
                message: '',
                jwt: action.jwt,
                firstName: action.firstName,
                lastName: action.lastName,
                id: action.id,
                email: action.email,
                activity: action.activity,
                selectedProfile: action.selectedProfile,
                profileCreated : action.profileCreated,
                verified: action.verified,
                stripeClientId: action.stripe_client_id,
                stripePublicKey: action.stripe_public_key,
                expressAccountStatus: action.express_account,
                callenderId: action.calender_id,
                navigationName: action.navigation_name
            })
        case STRIPE_ACCOUNT_STATUS_CHANGE:
            return Object.assign({}, state, {
                expressAccountStatus: true
            })
        case APP_ACTIVITY_UPDATE:
            return Object.assign({}, state, {
                isSuccess: true,
                isLoading: false,
                error: false,
                message: '',
                activity: action.activity,
                selectedProfile: action.selectedProfile,
            })
        case APP_ACTIVITY_UPDATE_FAIL:
            return Object.assign({}, state, {
                isSuccess: false,
                isLoading: false,
                error: true,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGIN_FAIL:
            return Object.assign({}, state, {
                error: true,
                isLoading: false,
                email: '',
                id: '',
                activity: [],
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        case APP_REGISTER_FAIL:
            return Object.assign({}, state, {
                error: true,
                isLoading: false,
                message: action.message,
                statusCode: action.statusCode,
                isSuccess: false
            })
        case APP_REGISTER_VERIFY:
            return Object.assign({}, state, {
                error: false,
                isLoading: false,
                message: '',
                isSuccess: true,
                verified:action.verified
            })
        case APP_REGISTER_VERIFY_FAIL:
            return Object.assign({}, state, {
                error: true,
                isLoading: false,
                message: action.message,
                isSuccess: false,
                verified:false,
                statusCode:action.statusCode
            })
        case APP_UPDATE_PROFILE_CREATED:
            return Object.assign({}, state, {
                profileCreated: action.profileCreated
            })
        case APP_RESENT_OTP:
            return Object.assign({}, state, {
                error: false,
                isLoading: false,
                message: '',
                isSuccess: true,
            })
        case APP_RESENT_OTP_FAIL:
            return Object.assign({}, state, {
                error: true,
                isLoading: false,
                message: action.message,
                statusCode: action.statusCode,
                isSuccess: false,
            })
        case APP_RESET_PASSWORD_OTP:
            return Object.assign({}, state, {
                error: false,
                isLoading: false,
                message: '',
                isSuccess: true,
                email:action.email
            })
        case APP_RESET_PASSWORD_OTP_FAIL:
            return Object.assign({}, state, {
                error: true,
                isLoading: false,
                message: action.message,
                statusCode: action.statusCode,
                isSuccess: false,
            })
        case APP_RESET_PASSWORD:
            return Object.assign({}, state, {
                isLoggedIn: true,
                isLoading: false,
                error: false,
                message: '',
                firstName: action.firstName,
                lastName: action.lastName,
                id: action.id,
                email: action.email,
                activity: action.activity,
                selectedProfile: action.selectedProfile,
                profileCreated: action.profileCreated,
                verified: action.verified
            })
        case APP_GET_ALL_USER_BY_TEXT_SEARCH:
            return Object.assign({}, state, {
                error: false,
                message: '',
                isSuccess: true,
                users:action.data
            })
        case APP_GET_ALL_USER_BY_TEXT_SEARCH_FAIL:
            return Object.assign({}, state, {
                error: true,
                message: action.message,
                statusCode: action.statusCode,
                isSuccess: false,
                users: []
            })
        case APP_CLEAR_ALL_USER_BY_TEXT_SEARCH:
            return Object.assign({}, state, {
                users: []
            })
        case APP_CHANGE_PASSWORD_START:
            return Object.assign({}, state, {
                isLoadingChangePassword: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CHANGE_PASSWORD:
            return Object.assign({}, state, {
                isLoadingChangePassword: false,
                error: false,
                message: '',
                jwt: action.jwt,
                firstName: action.firstName,
                lastName: action.lastName,
                id: action.id,
                email: action.email,
                activity: action.activity,
                selectedProfile: action.selectedProfile,
                profileCreated: action.profileCreated,
                verified: action.verified,
                isSuccess: true
            })
        case APP_CHANGE_PASSWORD_FAIL:
            return Object.assign({}, state, {
                isLoadingChangePassword: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUSH_NOTOFICATION:
            return Object.assign({}, state, {
                isLoadingPushnotfication: false,
            })
        case APP_PUSH_NOTOFICATION_START:
            return Object.assign({}, state, {
                isLoadingPushnotfication: true,
            })
        case APP_POST_CALENDER_ID_START:
            return Object.assign({}, state, {
                isLoadingPostCalenderId: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_CALENDER_ID:
            return Object.assign({}, state, {
                isLoadingPostCalenderId: false,
                callenderId:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_CALENDER_ID_FAIL:
            return Object.assign({}, state, {
                isLoadingPostCalenderId: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_SPOTLOGHT_USERS_START:
            return Object.assign({}, state, {
                isLoadingGetSpolightUsers: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SPOTLOGHT_USERS:
            return Object.assign({}, state, {
                isLoadingGetSpolightUsers: false,
                spotlightUsers:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SPOTLOGHT_USERS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetSpolightUsers: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        default:
            return state
    }
}

export const getError = state => state.accounts.error
export const getMsg = state => state.accounts.message
export const getisLoading = state => state.accounts.isLoading
export const getLoggedIn = state => state.accounts.isLoggedIn
export const getUserId = state => state.accounts.id
export const getEmail = state => state.accounts.email
export const getFirstName = state => state.accounts.firstName
export const getLastName = state => state.accounts.lastName
export const getActivity = state => state.accounts.activity
export const getSelectedProfile = state => state.accounts.selectedProfile
export const getJwt = state => state.accounts.jwt
export const getisSuccess = state => state.accounts.isSuccess
export const getisProfileCreated = state => state.accounts.profileCreated
export const getverified = state => state.accounts.verified
export const getstatusCode = state => state.accounts.statusCode
export const getSerchedUsers = state => state.accounts.users
export const getisLoadingChangePassword = state => state.accounts.isLoadingChangePassword
export const getisLoadingPostCalenderId = state => state.accounts.isLoadingPostCalenderId
export const getisLoadingGetSpolightUsers = state => state.accounts.isLoadingGetSpolightUsers
export const getSpotlightUsers = state => state.accounts.spotlightUsers

export const getStripeClientId = state => state.accounts.stripeClientId
export const getStripePublicKey = state => state.accounts.stripePublicKey
export const getExpressAccountStatus = state => state.accounts.expressAccountStatus
export const getCallenderId = state => state.accounts.callenderId
export const getNavigationName = state => state.accounts.navigationName

export const getSerchedUsersById = state => {
    return getById(state.accounts.users)
}
export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))