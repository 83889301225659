import {
    SAVE_MEDIA_PHOTO_PROFESSION_CARDS,
    SAVE_MEDIA_PHOTOs,
    CLEAR_MEDIA_PHOTO,
    APP_LOGOUT
} from '../../../actions'

const initialState = {
    professionCardIds: [],
    photos: [],
}

export const mediaPhotoUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_MEDIA_PHOTO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                professionCardIds: action.data
            })
        case SAVE_MEDIA_PHOTOs:
            return Object.assign({}, state, {
                photos: action.data
            })
        case CLEAR_MEDIA_PHOTO:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getProfessionCardIds = state => state.mediaPhotoUI.professionCardIds
export const getPhotos = state => state.mediaPhotoUI.photos
