import { parseJSON, composeAuth } from '../../../helpers/utils'
import { getNotificationToken } from '../../reducers/ui/profile'
import { hideLoginPopUp, changeLoginStatus } from '../../actions/ui/profile'
import { fetchUserProfileData } from '../../actions/profile'
import {
    APP_LOGIN_START,
    APP_LOGIN,
    APP_LOGIN_FAIL,
    APP_LOGOUT,
    APP_REGISTER_FAIL,
    APP_ACTIVITY_UPDATE,
    APP_ACTIVITY_UPDATE_FAIL,
    APP_REGISTER_VERIFY,
    APP_REGISTER_VERIFY_FAIL,
    APP_RESENT_OTP,
    APP_RESENT_OTP_FAIL,
    APP_RESET_PASSWORD_OTP,
    APP_RESET_PASSWORD_OTP_FAIL,
    APP_RESET_PASSWORD,
    APP_UPDATE_PROFILE_CREATED,
    APP_GET_ALL_USER_BY_TEXT_SEARCH,
    APP_CLEAR_ALL_USER_BY_TEXT_SEARCH,
    APP_GET_ALL_USER_BY_TEXT_SEARCH_FAIL,
    APP_CHANGE_PASSWORD_START,
    APP_CHANGE_PASSWORD,
    APP_CHANGE_PASSWORD_FAIL,
    APP_POST_CALENDER_ID_START,
    APP_POST_CALENDER_ID,
    APP_POST_CALENDER_ID_FAIL,
    STRIPE_ACCOUNT_STATUS_CHANGE,
    APP_PUSH_NOTOFICATION,
    APP_PUSH_NOTOFICATION_START,
    APP_GET_SPOTLOGHT_USERS_START,
    APP_GET_SPOTLOGHT_USERS,
    APP_GET_SPOTLOGHT_USERS_FAIL
} from '../../actions'

export const emitFetchStart = () => ({
    type: APP_LOGIN_START
})
export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const stripeAccountStatusChange = () => ({
    type: STRIPE_ACCOUNT_STATUS_CHANGE
})

export const emitLoggedIn = data => {
    return {
        type: APP_LOGIN,
        ...data
    }
}

// register
export const emitRegistarFailed = (data) => ({
    type: APP_REGISTER_FAIL,
    message: data.message,
    statusCode: data.statusCode
})

const register = (data) => {
    let url = process.env.REACT_APP_SERVER +'/api/v1/user/register'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
}


export const fetchRegister = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return register(data)
            .then(parseJSON)
            .then(json => {
                if (json.statusCode === 201) {
                    dispatch(emitLoggedIn(json.data))
                    dispatch(hideLoginPopUp())
                    dispatch(changeLoginStatus(false))
                } else {
                    dispatch(emitRegistarFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitRegistarFailed(err))
            })
    }
}


//logout
export const emitLogOut = () => {
    return {
        type: APP_LOGOUT,
    }
}

const logout = ({ jwt }, notifyToken) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/logout'
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify({
            token_id: notifyToken ? notifyToken :""
        })
    })
}


export const startLogout = (data) =>{
    return (dispatch,getState) => {
        const notifyToken = getNotificationToken(getState())
        return logout(data, notifyToken)
            .then(parseJSON)
            .then(json =>{
                if (json.statusCode === 201) {
                    dispatch(emitLogOut())
                }
                else{
                    dispatch(emitLogOut())
                }
            })
            .catch(err => {
                dispatch(emitLogOut())
            })
    }
}

//activity update

export const updateProfileCreated = (data) =>{
    return {
        type: APP_UPDATE_PROFILE_CREATED,
        profileCreated:data.profileCreated
    }
}

export const emitActivityUpdate = data => {
    const { activity,selectedProfile } = data
    return {
        type: APP_ACTIVITY_UPDATE,
        activity,
        selectedProfile
    }
}

export const emitActivityUpdateFail = data => {
    const { statusCode,message } = data
    return {
        type: APP_ACTIVITY_UPDATE_FAIL,
        message,
        statusCode
    }
}

const activity = ({ jwt, activity, selectedProfile}) => {    
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/activity'
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify({ activity, selectedProfile})
    })
}


export const updateActivity = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return activity(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitActivityUpdate(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                }
                else{
                    dispatch(emitActivityUpdateFail(json))
                } 
            })
            .catch(err => {
                
                dispatch(emitActivityUpdateFail(err))
            })
    }
}

//login

export const emitLoginFailed = (data) => ({
    type: APP_LOGIN_FAIL,
    message: data.message,
    statusCode: data.statusCode
})


const login = (data) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/login'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
}

export const startLogin = (data) =>{
    return (dispatch) => {
        dispatch(emitFetchStart())
        return login(data)
            .then(parseJSON)
            .then(json => {
                if (json.statusCode === 201) {
                    dispatch(emitLoggedIn(json.data))
                    dispatch(fetchUserProfileData({jwt:json?.data?.jwt}))
                    dispatch(hideLoginPopUp())
                    dispatch(changeLoginStatus(true))
                } else {
                    dispatch(emitLoginFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitLoginFailed(err))
            })
    }
}

//account verification

export const emitVerifyAccount = (data) => {
    return {
        type: APP_REGISTER_VERIFY,
        ...data
    }
}

export const emitVerifyAccountFailed = (data) => {
    return {
        type: APP_REGISTER_VERIFY_FAIL,
        message:data.message,
        statusCode:data.statusCode
    }
}

const verify = ({ jwt, otp }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/verifyotp'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify({ otp })
    })
}


export const verifyAccount = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return verify(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 201) {
                    dispatch(emitVerifyAccount(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                }
                else{
                    dispatch(emitVerifyAccountFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitVerifyAccountFailed(err))
            })
    }
}
 //resend otp
export const emitResendOtp = (data) => {
    return {
        type: APP_RESENT_OTP,
        ...data
    }
}

export const emitResendOtpFail = (data) => {
    return {
        type: APP_RESENT_OTP_FAIL,
        message:data.message,
        statusCode:data.statusCode

    }
}

const resend = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/resendotp'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const resendOtp = data => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return resend(data)
            .then(parseJSON)
            .then(json => {
                

                if (json.statusCode === 201) {
                    dispatch(emitResendOtp(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                }
                else {
                    dispatch(emitResendOtpFail(json))
                }
            })
            .catch(err => {
                dispatch(emitResendOtpFail(err))
            })
    }
}

//reset password otp
export const emitResetPasswordOtp = (data) => {
    return {
        type: APP_RESET_PASSWORD_OTP,
        email:data
    }
}

export const emitResetPasswordOtpFail = (data) => {
    return {
        type: APP_RESET_PASSWORD_OTP_FAIL,
        message: data.message,
        statusCode:data.statusCode
    }
}

const resetPasswordOtp = ({ email }) => {
    
    
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/emailotp'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    })
}

export const startResetPasswordOtp = data => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return resetPasswordOtp(data)
        .then(parseJSON)
        .then(json => {
            
            
            if (json.statusCode === 201) {
                dispatch(emitResetPasswordOtp(data.email))
            }
            else {
                dispatch(emitResetPasswordOtpFail(json))
            }
        })
        .catch(err => {
            
            dispatch(emitResetPasswordOtpFail(err))
        })
    }
}

//reset pasword otp-email verification

const verifyEmailOtp = ({ email, otp }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/verifypasswordotp'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, otp })
    })
}


export const startVerifyEmailOtp = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return verifyEmailOtp(data)
        .then(parseJSON)
        .then(json => {
            
            
            if (json.statusCode === 201) {
                dispatch(emitLoggedIn(json.data))
            }
            else {
                dispatch(emitResetPasswordOtpFail(json))
            }
        })
        .catch(err => {
            
            dispatch(emitResetPasswordOtpFail(err))
        })
    }
}

//reset password

export const emitResetPassword = (data) => {
    return {
        type: APP_RESET_PASSWORD,
        ...data
    }
}

const restPassword = ({ jwt, password }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/changepassword'
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify({ password })
    })
}

export const startRestPassword = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return restPassword(data)
            .then(parseJSON)
            .then(json => {
                

                if (json.statusCode === 201) {
                    dispatch(emitResetPassword(json.data))
                }
                else {
                    dispatch(emitLoginFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitLoginFailed(err))
            })
    }
}


//clear all user searched
export const clearAllUsersSearched = data => {
    return {
        type: APP_CLEAR_ALL_USER_BY_TEXT_SEARCH,
        data
    }
}

// get all users

export const emitAllUsersByTextSearch = data => {
    return {
        type: APP_GET_ALL_USER_BY_TEXT_SEARCH,
        data
    }
}

export const emitAllUsersByTextSearchFail = data => {
    return {
        type: APP_GET_ALL_USER_BY_TEXT_SEARCH_FAIL,
        message: data.message,
        statusCode: data.statusCode
    }
}

const getAllUsersByTextSearch = ({ jwt, searchText }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/user/name/${searchText}`;
    
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
    })
}


export const fetchAllUsersByTextSearch = (data) => {
    return (dispatch) => {
        return getAllUsersByTextSearch(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAllUsersByTextSearch(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                }
                else {
                    dispatch(emitAllUsersByTextSearchFail(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAllUsersByTextSearchFail(err))
            })
    }
}

// get all users - dead and not dead

const getAllCreatorsByTextSearch = ({ jwt, searchText }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/creators/${searchText}`;
    
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
    })
}


export const fetchAllCreatorsByTextSearch = (data) => {
    return (dispatch) => {
        return getAllCreatorsByTextSearch(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAllUsersByTextSearch(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                }
                else {
                    dispatch(emitAllUsersByTextSearchFail(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAllUsersByTextSearchFail(err))
            })
    }
}

// edit password
export const emitChangePasswordStart = () => ({
    type: APP_CHANGE_PASSWORD_START
})


export const emitChangePassword = (data) => {
    return {
        type: APP_CHANGE_PASSWORD,
        ...data
    }
}

export const emitChangePasswordFailed = (data) => ({
    type: APP_CHANGE_PASSWORD_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const changePassword = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/user/change/password`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startChangePassword = (data) => {
    return (dispatch) => {
        dispatch(emitChangePasswordStart())
        return changePassword(data)
            .then(parseJSON)
            .then(json => {
                
                

                if (json.statusCode === 201) {
                    dispatch(emitChangePassword(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitChangePasswordFailed(json))
                }
            })
            .catch(err => {


                dispatch(emitChangePasswordFailed(err))
            })
    }

}


// update pushkey
export const emitPostTokentart = () => ({
    type: APP_PUSH_NOTOFICATION_START
})


export const emitPostToken = () => {
    return {
        type: APP_PUSH_NOTOFICATION,
        
    }
}

const pushnotification = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/expo/user/token`
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPushnotification = (data) => {
    return (dispatch) => {
        dispatch(emitPostTokentart())
        return pushnotification(data)
            .then(parseJSON)
            .then(json => {

                
                
                if (json.statusCode === 201) {
                    dispatch(emitPostToken(json.data))
                }
            })
            .catch(err => {
                dispatch(emitChangePasswordFailed(err))
            })
    }

}

//post calender id  
export const emitPostCallenderIdStart = () => ({
    type: APP_POST_CALENDER_ID_START
})

export const emitPostCallenderId = data => {
    return {
        type: APP_POST_CALENDER_ID,
        data
    }
}

export const emitPostCallenderIdFail = (data) => ({
    type: APP_POST_CALENDER_ID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postCallenderId = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/user/calender'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}


export const startPostCallenderId = (data) => {
    return (dispatch) => {
        dispatch(emitPostCallenderIdStart())
        return postCallenderId(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startPostCallenderId");
                
                if (json.statusCode === 201) {
                    dispatch(emitPostCallenderId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                }
                else {
                    dispatch(emitPostCallenderIdFail(json))
                }
            })
            .catch(err => {

                dispatch(emitPostCallenderIdFail(err))
            })
    }
}

//get spotlight users
export const emitGetSpotlightUsersStart = () => ({
    type: APP_GET_SPOTLOGHT_USERS_START
})

export const emitGetSpotlightUsers = data => {
    return {
        type: APP_GET_SPOTLOGHT_USERS,
        data
    }
}

export const emitGetSpotlightUsersFail = (data) => ({
    type: APP_GET_SPOTLOGHT_USERS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getSpotlightUsers = () => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/spotlight'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
}


export const startGetSpotlightUsers = () => {
    return (dispatch) => {
        dispatch(emitGetSpotlightUsersStart())
        return getSpotlightUsers()
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetSpotlightUsers");
                
                if (json.statusCode === 200) {
                    dispatch(emitGetSpotlightUsers(json.data))
                }
                else {
                    dispatch(emitGetSpotlightUsersFail(json))
                }
            })
            .catch(err => {

                dispatch(emitGetSpotlightUsersFail(err))
            })
    }
}

//random users
const getRandomUsers = () => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/user/random/users'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
}


export const startGetRandomUsers = () => {
    return (dispatch) => {
        dispatch(emitGetSpotlightUsersStart())
        return getRandomUsers()
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetRandomUsers");
                
                if (json.statusCode === 200) {
                    dispatch(emitGetSpotlightUsers(json.data))
                }
                else {
                    dispatch(emitGetSpotlightUsersFail(json))
                }
            })
            .catch(err => {

                dispatch(emitGetSpotlightUsersFail(err))
            })
    }
}
