import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { startGetTrainingIndividual } from '../../../redux/actions/resume/training/individual'

//reducers
import { getisLoading, getIndividual } from '../../../redux/reducers/resume/training/individual'
import { getJwt } from '../../../redux/reducers/account/accounts'

//helpers
import { monthToName } from '../../../helpers/utils'

const ResumeIndivuduals = (props) => {
    const dispatch = useDispatch()

    const individual = useSelector(getIndividual)
    const isLoading = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(startGetTrainingIndividual({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoading ? <Spinner /> : <div>
            <div className="expDetail__secHead">User Details</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">User Details</p>
                <p className="expDetail__desc">{individual.firstname + " " + individual.lastname}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Role</p>
                <p className="expDetail__desc">{individual.role}</p>
            </div>
            {individual.institutionid ? <div className="expDetail__wrap">
                <p className="expDetail__label">Associated Institute</p>
                <p className="expDetail__desc">{individual.institutionid}</p>
            </div> : null}
            {individual?.date?.from_month && individual?.date?.to_month ? <div className="expDetail__wrap">
                <p className="expDetail__label">Years Studied</p>
                <p className="expDetail__desc">{monthToName[individual.date.from_month] + " " + individual.date.from_year + " - " + monthToName[individual.date.to_month] + " " + individual.date.to_year}</p>
            </div> : null}
            {individual?.date?.stillStudying ? <div className="expDetail__wrap">
                <p className="expDetail__label">Still Studying</p>
                <p className="expDetail__desc"> {individual?.date?.stillStudying ? "Yes" : "No"}</p>
            </div> : null}

            {individual.information || (individual?.additional_info && individual?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {individual.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{individual.information}</p>
                </div> : null}
                {individual?.additional_info && individual?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {individual?.additional_info && individual?.additional_info.data[0] ? individual?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div> : null}
        </div>
    );
};

export default ResumeIndivuduals;
