import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//assets
import { NormalCarousel } from '../../assets/layouts/carousel/normalCarousel'
import People1 from "../../../assets/images/people/ashton.jpg";
import Spinner from "../../assets/molecules/spinner";

//actions
import { startGetTopProfessionSearchResult } from '../../../redux/actions/search/public'
import { startGetSpotlightUsers } from '../../../redux/actions/account'

//reducers
import { getToptemplates, getisLoadingTopSearchTemplate } from '../../../redux/reducers/search'
import { getisLoadingGetSpolightUsers, getSpotlightUsers } from '../../../redux/reducers/account/accounts'

//helpers
import { usePrevious } from '../../../helpers/utils'

const Explore = () => {
  const dispatch = useDispatch()

  let history = useHistory()

  const [topProfessions, setTopProfessions] = useState([])
  const [allSpotligthUsers, setAllSpotligthUsers] = useState([])

  const toptemplates = useSelector(getToptemplates)
  const spotlightUsers = useSelector(getSpotlightUsers)
  const isLoadingTopSearchTemplate = useSelector(getisLoadingTopSearchTemplate)
  const isLoadingTopSearchTemplatePrev = usePrevious(isLoadingTopSearchTemplate)
  const isLoadingGetSpolightUsers = useSelector(getisLoadingGetSpolightUsers)
  const isLoadingGetSpolightUsersPrev = usePrevious(isLoadingGetSpolightUsers)

  useEffect(()=>{
    dispatch(startGetTopProfessionSearchResult())
    dispatch(startGetSpotlightUsers())
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if (isLoadingTopSearchTemplatePrev !== isLoadingTopSearchTemplate && !isLoadingTopSearchTemplate && isLoadingTopSearchTemplate !== undefined){
      setTopProfessions(toptemplates?.sort((a, b) => a?.top_order - b?.top_order).map((item)=>{
        return {
          url: item?.image ? item?.image : People1,
          title: item?.name,
          description: "",
          id: item?.id,
          imp_skills: item?.imp_skills
        }
      }))
    }
  }, [isLoadingTopSearchTemplate, isLoadingTopSearchTemplatePrev ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if (isLoadingGetSpolightUsersPrev !== isLoadingGetSpolightUsers && !isLoadingGetSpolightUsers && isLoadingGetSpolightUsers !== undefined){
      setAllSpotligthUsers(spotlightUsers?.map((item) => {
        return {
          url: item?.profile_picture ? item?.profile_picture : People1,
          title: item?.firstName + " " + item?.lastName,
          description: "",
          id: item?.id,
          navigation_name: item?.navigation_name
        }
      }))
    }
  }, [isLoadingGetSpolightUsers, isLoadingGetSpolightUsersPrev ]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    isLoadingTopSearchTemplate || isLoadingGetSpolightUsers ? <Spinner/>:<div className="container paddingContainer minHeightContainer">
      
      {allSpotligthUsers && allSpotligthUsers?.length ? <NormalCarousel header="Spotlight Artists" data={allSpotligthUsers} onClick={(people) => history.push({
        pathname: `/search/people/${people?.navigation_name}`,
      })}/> : null }
      {topProfessions && topProfessions?.length ? <NormalCarousel header="Popular Services" data={topProfessions} onClick={(profession) => history.push({
        pathname: `/search/template/${profession?.id}`,
        state: { profession }
      })}/>:null}
      <div className="clear"></div>
    </div>
  );
};

export default Explore;
