import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import { Redirect } from 'react-router-dom'

//assets
import Header from "../../assets/layouts/header/registrationHeader";
import Spinner from "../../assets/molecules/spinner";
import Sidebar from "../../assets/layouts/sidebar";

//actions
import { startResetPasswordOtp, startVerifyEmailOtp } from '../../../redux/actions/account'

//reducers
import { getError, getisLoading } from '../../../redux/reducers/account/accounts'

//helper
import { usePrevious } from '../../../helpers/utils'

const VerifyCode = (props) => {
  const dispatch = useDispatch();

  let history = useHistory()
  let location = useLocation()
  let email = location?.state?.email;

  const [OTP, setOTP] = useState("");
  const [error, setError] = useState("");
  const [resendStatus, setResendStatus] = useState(false);

  const apiError = useSelector(getError)
  const isLoading = useSelector(getisLoading)
  const isLoadingPrev = usePrevious(isLoading)

  useEffect(() => {
    if (!isLoading && isLoading !== isLoadingPrev && isLoadingPrev !== undefined && !apiError) {
      if (!resendStatus){
        history.push({
          pathname: '/forgot-password/new-password',
        })
      }
    }
    else if (!isLoading && isLoading !== isLoadingPrev && isLoadingPrev !== undefined && apiError) {
      if (resendStatus) {
        setError("Something went wrong, please try again later")
      }
      else{
        setError("Invalid Code")
      }
    }
  }, [isLoading, isLoadingPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  const onResend = () => {
    dispatch(startResetPasswordOtp({ email }))
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setResendStatus(false)
    if (OTP) {
      dispatch(startVerifyEmailOtp({ email , otp: OTP }))
    }
    else {
      setError("Please enter a code.")
    }
  }


  return (
    !email ? <Redirect
      to={{
        pathname: '/',
      }}
    /> : 
    isLoading ? <Spinner/> : <div className="dashboard">
      <Sidebar />
      <div className="main__dashboard forgotPass__wrapper">
          <Header className="forgotPass" forgotPass></Header>
        <button class="button button--back forgotPass__back" onClick={() => history.goBack()}></button>
        <div className="forgotPass__container">
          <h2>
          Enter Security Code <div className="subHead__dot"></div>
          </h2>
          <p>We have sent a security code to <br/>
            <span>{email}</span></p>
            <form className="verifyPass__form" onSubmit={(e) => onSubmit(e)}>
            <div class="form-group">
              <label>Enter Code</label>
              <OtpInput
                value={OTP}
                onChange={(text) => { setError(""); setOTP(text) }}
                numInputs={6}
                containerStyle={{float:'left'}}
                inputStyle={{height:38, width:48}}
              />
              {error && <p className="error">{error}</p>}
            </div>

              <button type="submit" className="button save button--primary">
                VERIFY
            </button>
          </form>
          <div className="clear"></div>
        </div>
          {resendStatus && <p className="error--resend">Code Sent Successfully!</p>}
         <button type="button" className="button button--withoutBg" onClick={() => { setError(""); setResendStatus(true); onResend() }}>
          RESEND CODE
        </button>
        <div className="clear"></div>
      </div>
    </div>
  );
};

export default VerifyCode;
