import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_GET_EVENTS_BY_INSTUTUTIONID_START,
    APP_GET_EVENTS_BY_INSTUTUTIONID,
    APP_GET_EVENTS_BY_INSTUTUTIONID_FAIL,
    APP_GET_EVENT_TYPES_START,
    APP_GET_EVENT_TYPES,
    APP_GET_EVENT_TYPES_FAIL,
    APP_POST_EVENT_START,
    APP_POST_EVENT,
    APP_POST_EVENT_FAIL,
    APP_POST_NEW_EVENT_START,
    APP_POST_NEW_EVENT,
    APP_POST_NEW_EVENT_FAIL,
    APP_GET_EVENT_SUBTYPES_START,
    APP_GET_EVENT_SUBTYPES,
    APP_GET_EVENT_SUBTYPES_FAIL,
    APP_GET_EVENT_START,
    APP_GET_EVENT,
    APP_GET_EVENT_FAIL,
    APP_GET_PERFORMANCE_DETAILS_START,
    APP_GET_PERFORMANCE_DETAILS,
    APP_GET_PERFORMANCE_DETAILS_FAIL,
    APP_PUT_EVENT_START,
    APP_PUT_EVENT,
    APP_PUT_EVENT_FAIL,
    APP_EVENT_REASSIGN_PROFESSIONS_START,
    APP_EVENT_REASSIGN_PROFESSIONS,
    APP_EVENT_REASSIGN_PROFESSIONS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_GET_PENDING_HIDDEN_START,
    APP_GET_PENDING_HIDDEN,
    APP_GET_PENDING_HIDDEN_FAIL,
    APP_GET_PENDING_HIDDEN_STATUS_START,
    APP_GET_PENDING_HIDDEN_STATUS,
    APP_GET_PENDING_HIDDEN_STATUS_FAIL,
    APP_LOGOUT,
} from '../../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

//reassign event to preffessions
export const emitEventsReassignProfessionsStart = () => ({
    type: APP_EVENT_REASSIGN_PROFESSIONS_START
})

export const emitEventsReassignProfessions = data => {
    return {
        type: APP_EVENT_REASSIGN_PROFESSIONS,
        data
    }
}

export const emitEventsReassignProfessionsFailed = (data) => ({
    type: APP_EVENT_REASSIGN_PROFESSIONS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const eventsReassignProfessions = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/reassign/professioncard/event`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startEventsReassignProfessions = (data) => {
    return (dispatch) => {
        dispatch(emitEventsReassignProfessionsStart())
        return eventsReassignProfessions(data)
            .then(parseJSON)
            .then(json => {
                
                
                
                if (json.statusCode === 201) {
                    dispatch(emitEventsReassignProfessions(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEventsReassignProfessionsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEventsReassignProfessionsFailed(err))
            })
    }
}

//update event by id
export const emitPutEventsStart = () => ({
    type: APP_PUT_EVENT_START
})

export const emitPutEvents = data => {
    return {
        type: APP_PUT_EVENT,
        data
    }
}

export const emitPutEventsFailed = (data) => ({
    type: APP_PUT_EVENT_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const putEvents = ({ jwt, id, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/event/${id}`
    
    
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutEvents = (data) => {
    return (dispatch) => {
        dispatch(emitPutEventsStart())
        return putEvents(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitPutEvents(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPutEventsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitPutEventsFailed(err))
            })
    }
}

//get event all details
export const emitGetEventsStart = () => ({
    type: APP_GET_EVENT_START
})

export const emitGetEvents = data => {
    return {
        type: APP_GET_EVENT,
        data
    }
}

export const emitGetEventsFailed = (data) => ({
    type: APP_GET_EVENT_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getEvents = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/event/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetEvents = (data) => {
    return (dispatch) => {
        dispatch(emitGetEventsStart())
        return getEvents(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitGetEvents(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetEventsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetEventsFailed(err))
            })
    }
}


//Post new events - not found
export const emitPostNewEventsStart = () => ({
    type: APP_POST_NEW_EVENT_START
})

export const emitPostNewEvents = data => {
    return {
        type: APP_POST_NEW_EVENT,
        data
    }
}

export const emitPostNewEventsFailed = (data) => ({
    type: APP_POST_NEW_EVENT_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postNewEvents = ({ jwt, data}) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/event`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const StartPostNewEvents = (data) => {
    return (dispatch) => {
        dispatch(emitPostNewEventsStart())
        return postNewEvents(data)
            .then(parseJSON)
            .then(json => {
                if (json.statusCode === 201) {
                    dispatch(emitPostNewEvents(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPostNewEventsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitPostNewEventsFailed(err))
            })
    }
}

//Post events to performance
export const emitPostEventsStart = () => ({
    type: APP_POST_EVENT_START
})

export const emitPostEvents = data => {
    return {
        type: APP_POST_EVENT,
        data
    }
}

export const emitPostEventsFailed = (data) => ({
    type: APP_POST_EVENT_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postEvents = ({ jwt, data}) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/add/event`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const StartPostEvents = (data) => {
    return (dispatch) => {
        dispatch(emitPostEventsStart())
        return postEvents(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitPostEvents(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPostEventsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitPostEventsFailed(err))
            })
    }
}

//get events by institution id
export const emitGetEventsByInstitutionIdStart = () => ({
    type: APP_GET_EVENTS_BY_INSTUTUTIONID_START
})

export const emitGetEventsByInstitutionId = data => {
    return {
        type: APP_GET_EVENTS_BY_INSTUTUTIONID,
        data
    }
}

export const emitGetEventsByInstitutionIdFailed = (data) => ({
    type: APP_GET_EVENTS_BY_INSTUTUTIONID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getEventsByInstitutionId = ({ jwt, id, startYear, endYear }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/event/institution/${id}/start_year/${startYear}/end_year/${endYear}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const StartGetEventsByInstitutionId = (data) => {
    return (dispatch) => {
        dispatch(emitGetEventsByInstitutionIdStart())
        return getEventsByInstitutionId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitGetEventsByInstitutionId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetEventsByInstitutionIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetEventsByInstitutionIdFailed(err))
            })
    }

}

//get event types
export const emitFetchStart = () => ({
    type: APP_GET_EVENT_TYPES_START
})

export const emitEventTypes = data => {
    return {
        type: APP_GET_EVENT_TYPES,
        data
    }
}

export const emitEventTypesFailed = (data) => ({
    type: APP_GET_EVENT_TYPES_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getEventTypes = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/events/experiencetype'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchEventTypes = (data) => {
    return (dispatch) => {
        dispatch(emitFetchStart())
        return getEventTypes(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitEventTypes(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitEventTypesFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitEventTypesFailed(err))
            })
    }

}
//get event sub-types
export const emitGetEventSubTypesStart = () => ({
    type: APP_GET_EVENT_SUBTYPES_START
})

export const emitGetEventSubTypes = data => {
    return {
        type: APP_GET_EVENT_SUBTYPES,
        data
    }
}

export const emitGetEventSubTypesFailed = (data) => ({
    type: APP_GET_EVENT_SUBTYPES_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getEventSubType = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/events/experiencesubtype';
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetEventSubType = (data) => {
    return (dispatch) => {
        dispatch(emitGetEventSubTypesStart())
        return getEventSubType(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitGetEventSubTypes(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetEventSubTypesFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetEventSubTypesFailed(err))
            })
    }

}

//get performance details per user id
export const emitGetPerformanceDetailsStart = () => ({
    type: APP_GET_PERFORMANCE_DETAILS_START
})

export const emitGetPerformanceDetails = data => {
    return {
        type: APP_GET_PERFORMANCE_DETAILS,
        data
    }
}

export const emitGetPerformanceDetailsFailed = (data) => ({
    type: APP_GET_PERFORMANCE_DETAILS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getPerformanceDetails = ({ jwt, eventId, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/performance/event/${eventId}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetPerformanceDetails = (data) => {
    return (dispatch) => {
        dispatch(emitGetPerformanceDetailsStart())
        return getPerformanceDetails(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitGetPerformanceDetails(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetPerformanceDetailsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetPerformanceDetailsFailed(err))
            })
    }
}


//get performance/events by professionCardId
export const emitGetEventsByProfessionCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitGetEventsByProfessionCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitGetEventsByProfessionCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getEventsByProfessionCardId = ({ jwt, ids }) => {
    let stringifyIds = encodeURI(JSON.stringify(ids))
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/event/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetEventsByProfessionCardId = (data) => {
    return (dispatch) => {
        dispatch(emitGetEventsByProfessionCardIdStart())
        return getEventsByProfessionCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitGetEventsByProfessionCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetEventsByProfessionCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetEventsByProfessionCardIdFailed(err))
            })
    }
}

//get performance/events by professionCardId by user id
const getEventsByProfessionCardIdByUserId = ({ jwt, ids, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(ids))
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/event/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetEventsByProfessionCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitGetEventsByProfessionCardIdStart())
        return getEventsByProfessionCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitGetEventsByProfessionCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetEventsByProfessionCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetEventsByProfessionCardIdFailed(err))
            })
    }
}

//get user events
const getUserEvents = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/user/events`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserEvents = (data) => {
    
    return (dispatch) => {
        dispatch(emitGetEventsByProfessionCardIdStart())
        return getUserEvents(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 200) {
                    dispatch(emitGetEventsByProfessionCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetEventsByProfessionCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitGetEventsByProfessionCardIdFailed(err))
            })
    }
}

//get user events by user id
const getUserEventsByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/users/events/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserEventsByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitGetEventsByProfessionCardIdStart())
        return getUserEventsByUserId(data)
            .then(parseJSON)
            .then(json => {
                if (json.statusCode === 200) {
                    dispatch(emitGetEventsByProfessionCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetEventsByProfessionCardIdFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitGetEventsByProfessionCardIdFailed(err))
            })
    }
}

//get hidden / pending cards
export const emitHiddenPendingEventCardsStart = () => ({
    type: APP_GET_PENDING_HIDDEN_START
})

export const emitHiddenPendingEventCards = data => {
    return {
        type: APP_GET_PENDING_HIDDEN,
        data
    }
}

export const emitHiddenPendingEventCardsFailed = (data) => ({
    type: APP_GET_PENDING_HIDDEN_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

//get hidden

const getHiddenEventCards = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/hidden/event/roles`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetHiddenEventCards = (data) => {
    return (dispatch) => {
        dispatch(emitHiddenPendingEventCardsStart())
        return getHiddenEventCards(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 200) {
                    dispatch(emitHiddenPendingEventCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitHiddenPendingEventCardsFailed(json))
                }
            })
            .catch(err => {

                dispatch(emitHiddenPendingEventCardsFailed(err))
            })
    }
}

//get pending

const getPendingEventCards = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/pending/event/roles`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetPendingEventCards = (data) => {
    return (dispatch) => {
        dispatch(emitHiddenPendingEventCardsStart())
        return getPendingEventCards(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 200) {
                    dispatch(emitHiddenPendingEventCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitHiddenPendingEventCardsFailed(json))
                }
            })
            .catch(err => {

                dispatch(emitHiddenPendingEventCardsFailed(err))
            })
    }
}

//get hidden / pending  status
export const emitHiddenPendingEventCardsStatusStart = () => ({
    type: APP_GET_PENDING_HIDDEN_STATUS_START
})

export const emitHiddenPendingEventCardsStatus = data => {
    return {
        type: APP_GET_PENDING_HIDDEN_STATUS,
        data
    }
}

export const emitHiddenPendingEventCardsStatusFailed = (data) => ({
    type: APP_GET_PENDING_HIDDEN_STATUS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getHiddenEventCardsStatus = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/events/pending/event/rolelength`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetHiddenEventCardsStatus = (data) => {
    return (dispatch) => {
        dispatch(emitHiddenPendingEventCardsStatusStart())
        return getHiddenEventCardsStatus(data)
            .then(parseJSON)
            .then(json => {
                
                
                if (json.statusCode === 200) {
                    dispatch(emitHiddenPendingEventCardsStatus(json.status))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitHiddenPendingEventCardsStatusFailed(json))
                }
            })
            .catch(err => {

                dispatch(emitHiddenPendingEventCardsStatusFailed(err))
            })
    }
}






