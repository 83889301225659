import React from "react";
import HighlightThumb  from "../../../../assets/images/highlightsSmall.png"
import moment from 'moment'

const HightlightCard = (props) => {
  return (
    <div className={"peopleCard peopleCard--highlights"} onClick={props.onClick}>
      <img src={props.img ? props.img : HighlightThumb } className={"card-img-top card-img-top--highlights"} alt="HighlightThumb"/>
      <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end'}}>
        <span className="peaopleCard__title--highlights">
          {props.title}
        </span>
      </div>
      <p className="peaopleCard__desc">
        {props.text}
      </p>
      <div className="highlightDate">{moment(props?.date).format("MMM YYYY")}</div>
      <div className="clear"></div>
    </div>
  );
};

export default HightlightCard;

