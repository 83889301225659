import { parseJSON, composeAuth } from '../../../../../helpers/utils'

import {
    APP_CREATE_ACCOLADES_SCHOLARSHIP_START,
    APP_CREATE_ACCOLADES_SCHOLARSHIP,
    APP_CREATE_ACCOLADES_SCHOLARSHIP_FAIL,
    APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO_START,
    APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO,
    APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO_FAIL,
    APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS_START,
    APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS,
    APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../../actions'


//create scholarship

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitAccoladesScholarshipStart = () => ({
    type: APP_CREATE_ACCOLADES_SCHOLARSHIP_START
})

export const emitAccoladesScholarship = data => {
    return {
        type: APP_CREATE_ACCOLADES_SCHOLARSHIP,
        data
    }
}

export const emitAccoladesScholarshipFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_SCHOLARSHIP_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesScholarship = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/accolades/scholarship/scholarship'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createAccoladesScholarship = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipStart())
        return postAccoladesScholarship(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesScholarship(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipFailed(err))
            })
    }

}

// add additional info to scholarship

export const emitAccoladesScholarshipAdditionalInfoStart = () => ({
    type: APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO_START
})

export const emitAccoladesScholarshipAdditionalInfo = data => {
    return {
        type: APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO,
        data
    }
}

export const emitAccoladesScholarshipAdditionalInfoFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_SCHOLARSHIP_ADDITIONAL_INFO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesScholarshipAdditionalInfo = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/scholarship/information/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createAccoladesScholarshipAdditionalInfo = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipAdditionalInfoStart())
        return postAccoladesScholarshipAdditionalInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesScholarshipAdditionalInfo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipAdditionalInfoFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipAdditionalInfoFailed(err))
            })
    }
}

// get scholarship

const getAccoladesScholarship = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/scholarship/${id}`;
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchAccoladesScholarship = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipStart())
        return getAccoladesScholarship(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesScholarship(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipFailed(err))
            })
    }

}

// add scholarship to professions Cards


export const emitAccoladesScholarshipToProfessionCardsStart = () => ({
    type: APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS_START
})

export const emitAccoladesScholarshipToProfessionCards = data => {
    return {
        type: APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS,
        data
    }
}

export const emitAccoladesScholarshipToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_SCHOLARSHIP_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesScholarshipToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/accolades/scholarship/scholarship/professioncard'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignAccoladesScholarshipToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipToProfessionCardsStart())
        return postAccoladesScholarshipToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesScholarshipToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipToProfessionCardsFailed(err))
            })
    }
}

// update scholarship

const putAccoladesScholarship = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/scholarship/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateAccoladesScholarship = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipStart())
        return putAccoladesScholarship(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesScholarship(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipFailed(err))
            })
    }

}

// get accoladesScholarship resume by card id

export const emitAccoladesScholarshipByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitAccoladesScholarshipByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitAccoladesScholarshipByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getAccoladesScholarshipByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/scholarship/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetAccoladesScholarshipByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipByCardIdStart())
        return getAccoladesScholarshipByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesScholarshipByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipByCardIdFailed(err))
            })
    }
}

// get accoladesScholarship resume by card id by user id
const getAccoladesScholarshipByCardIdByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/scholarship/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetAccoladesScholarshipByCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipByCardIdStart())
        return getAccoladesScholarshipByCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesScholarshipByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipByCardIdFailed(err))
            })
    }
}

// get user accolades scholarship - no cards
const getUserAccoladesScholarship = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/user/scholarship`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserAccoladesScholarship = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipByCardIdStart())
        return getUserAccoladesScholarship(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesScholarshipByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipByCardIdFailed(err))
            })
    }
}

// get user accolades scholarship - no cards by user id
const getUserAccoladesScholarshipByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/users/scholarship/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserAccoladesScholarshipByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesScholarshipByCardIdStart())
        return getUserAccoladesScholarshipByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesScholarshipByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesScholarshipByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesScholarshipByCardIdFailed(err))
            })
    }
}

// delete accoladesScholarship by id

export const emitDeleteAccoladesScholarshipStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteAccoladesScholarship = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteAccoladesScholarshipFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteAccoladesScholarship = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/delete/scholarships`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteAccoladesScholarship = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteAccoladesScholarshipStart())
        return deleteAccoladesScholarship(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteAccoladesScholarship(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteAccoladesScholarshipFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteAccoladesScholarshipFailed(err))
            })
    }
}

// delete accoladesScholarship by id

export const emitAssignAccoladesScholarshipStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignAccoladesScholarship = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignAccoladesScholarshipFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignAccoladesScholarship = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/scholarship/reassign/professioncard/scholarships`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignAccoladesScholarship = (data) => {
    return (dispatch) => {
        dispatch(emitAssignAccoladesScholarshipStart())
        return assignAccoladesScholarship(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignAccoladesScholarship(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignAccoladesScholarshipFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignAccoladesScholarshipFailed(err))
            })
    }
}