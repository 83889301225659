import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_CREATE_COMMISSIONEDWORK_START,
    APP_CREATE_COMMISSIONEDWORK,
    APP_CREATE_COMMISSIONEDWORK_FAIL,
    APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_START,
    APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO,
    APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_FAIL,
    APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_START,
    APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS,
    APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../actions'


//create commissionedWork

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitCommissionedWorkStart = () => ({
    type: APP_CREATE_COMMISSIONEDWORK_START
})

export const emitCommissionedWork = data => {
    return {
        type: APP_CREATE_COMMISSIONEDWORK,
        data
    }
}

export const emitCommissionedWorkFailed = (data) => ({
    type: APP_CREATE_COMMISSIONEDWORK_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postCommissionedWork = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/commisionedworks/commisionedwork'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createCommissionedWork = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkStart())
        return postCommissionedWork(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitCommissionedWork(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkFailed(err))
            })
    }

}

// add additional info to commissionedWork

export const emitCommissionedWorkAdditionalInfoStart = () => ({
    type: APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_START
})

export const emitCommissionedWorkAdditionalInfo = data => {
    return {
        type: APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO,
        data
    }
}

export const emitCommissionedWorkAdditionalInfoFailed = (data) => ({
    type: APP_CREATE_COMMISSIONEDWORK_ADDITIONAL_INFO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postCommissionedWorkAdditionalInfo = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/commisionedwork/information/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createCommissionedWorkAdditionalInfo = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkAdditionalInfoStart())
        return postCommissionedWorkAdditionalInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitCommissionedWorkAdditionalInfo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkAdditionalInfoFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkAdditionalInfoFailed(err))
            })
    }
}

// get commissionedWork

const getCommissionedWork = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/commisionedwork/${id}`;
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchCommissionedWork = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkStart())
        return getCommissionedWork(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCommissionedWork(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkFailed(err))
            })
    }

}

// add commissionedWork to professions Cards


export const emitCommissionedWorkToProfessionCardsStart = () => ({
    type: APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_START
})

export const emitCommissionedWorkToProfessionCards = data => {
    return {
        type: APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS,
        data
    }
}

export const emitCommissionedWorkToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_COMMISSIONEDWORK_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postCommissionedWorkToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/commisionedworks/commisionedwork/professioncard'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignCommissionedWorkToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkToProfessionCardsStart())
        return postCommissionedWorkToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitCommissionedWorkToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkToProfessionCardsFailed(err))
            })
    }
}

// update commissionedWork

const putCommissionedWork = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/commisionedwork/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateCommissionedWork = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkStart())
        return putCommissionedWork(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitCommissionedWork(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkFailed(err))
            })
    }

}

// get commissionedWork resumes by card id

export const emitCommissionedWorkByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitCommissionedWorkByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitCommissionedWorkByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getCommissionedWorkByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/commisionedwork/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCommissionedWorkByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkByCardIdStart())
        return getCommissionedWorkByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCommissionedWorkByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkByCardIdFailed(err))
            })
    }
}

// get commissionedWork resumes by card id by user id
const getCommissionedWorkByCardIdByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/commisionedwork/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCommissionedWorkByCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkByCardIdStart())
        return getCommissionedWorkByCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCommissionedWorkByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkByCardIdFailed(err))
            })
    }
}

// get user commissioned work - no card
const getUserCommissionedWork = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/user/commisionedwork`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserCommissionedWork = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkByCardIdStart())
        return getUserCommissionedWork(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCommissionedWorkByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkByCardIdFailed(err))
            })
    }
}

// get user commissioned work - no card by user id
const getUserCommissionedWorkByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/users/commisionedwork/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserCommissionedWorkByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitCommissionedWorkByCardIdStart())
        return getUserCommissionedWorkByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitCommissionedWorkByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitCommissionedWorkByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitCommissionedWorkByCardIdFailed(err))
            })
    }
}

// delete commissionedWork by id

export const emitDeleteCommissionedWorkStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteCommissionedWork = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteCommissionedWorkFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteCommissionedWork = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/delete/commisionedworks`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteCommissionedWork = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteCommissionedWorkStart())
        return deleteCommissionedWork(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteCommissionedWork(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteCommissionedWorkFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteCommissionedWorkFailed(err))
            })
    }
}

// assign commissionedWork to card

export const emitAssignCommissionedWorkStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignCommissionedWork = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignCommissionedWorkFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignCommissionedWork = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/commisionedworks/reassign/professioncard/commisionedworks`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignCommissionedWork = (data) => {
    return (dispatch) => {
        dispatch(emitAssignCommissionedWorkStart())
        return assignCommissionedWork(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignCommissionedWork(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignCommissionedWorkFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignCommissionedWorkFailed(err))
            })
    }
}