import {
    APP_POST_PAYMENT_SLOTS_START,
    APP_POST_PAYMENT_SLOTS,
    APP_POST_PAYMENT_SLOTS_FAIL,
    APP_GET_PAYMENT_SLOTS_START,
    APP_GET_PAYMENT_SLOTS,
    APP_GET_PAYMENT_SLOTS_FAIL,
    APP_GET_ALL_CARD_PAYMENT_SLOTS_START,
    APP_GET_ALL_CARD_PAYMENT_SLOTS,
    APP_GET_ALL_CARD_PAYMENT_SLOTS_FAIL,
    APP_POST_PAYMENT_SLOTS_BOOKING_START,
    APP_POST_PAYMENT_SLOTS_BOOKING,
    APP_POST_PAYMENT_SLOTS_BOOKING_FAIL,
    APP_GET_PAYMENT_SLOTS_BOOKING_START,
    APP_GET_PAYMENT_SLOTS_BOOKING,
    APP_GET_PAYMENT_SLOTS_BOOKING_FAIL,
    APP_ACCEPT_PAYMENT_SLOTS_BOOKING_START,
    APP_ACCEPT_PAYMENT_SLOTS_BOOKING,
    APP_ACCEPT_PAYMENT_SLOTS_BOOKING_FAIL,
    APP_DECLINE_PAYMENT_SLOTS_BOOKING_START,
    APP_DECLINE_PAYMENT_SLOTS_BOOKING,
    APP_DECLINE_PAYMENT_SLOTS_BOOKING_FAIL,
    APP_CANCEL_PAYMENT_SLOTS_BOOKING_START,
    APP_CANCEL_PAYMENT_SLOTS_BOOKING,
    APP_CANCEL_PAYMENT_SLOTS_BOOKING_FAIL,
    APP_CHECKIN_PAYMENT_SLOTS_BOOKING_START,
    APP_CHECKIN_PAYMENT_SLOTS_BOOKING,
    APP_CHECKIN_PAYMENT_SLOTS_BOOKING_FAIL,
    APP_GET_FREE_SLOTS_BOOKING_START,
    APP_GET_FREE_SLOTS_BOOKING,
    APP_GET_FREE_SLOTS_BOOKING_FAIL,
    APP_GET_NO_EVENT_SLOTS_BOOKING_START,
    APP_GET_NO_EVENT_SLOTS_BOOKING,
    APP_GET_NO_EVENT_SLOTS_BOOKING_FAIL,
    APP_PUT_NO_EVENT_SLOTS_BOOKING_START,
    APP_PUT_NO_EVENT_SLOTS_BOOKING,
    APP_PUT_NO_EVENT_SLOTS_BOOKING_FAIL,
    APP_GET_FAILED_PAYMENT_SLOTS_BOOKING,
    APP_GET_RESONANCE_FEE_START,
    APP_GET_RESONANCE_FEE,
    APP_GET_RESONANCE_FEE_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    paymentSlots:{},
    isLoadingPaymentSlots:false,
    isLoadingGetPaymentSlots:false,
    isLoadingGetAllCardsPaymentSlots:false,
    allCardPaymentSlots:[],
    isLoadingPostPaymentSlotsBooking:false,
    isLoadingGetPaymentSlotsBooking:false,
    bookings:[],
    failedbookings:[],
    isLoadingAcceptPaymentSlotsBooking: false,
    isLoadingDeclinePaymentSlotsBooking: false,
    isLoadingCancelPaymentSlotsBooking: false,
    isLoadingCheckInPaymentSlotsBooking: false,
    isLoadingGetFreePaymentSlotsBooking: false,
    freeBookingSlots:[],
    isLoadingGetNoEventPaymentSlotsBooking: false,
    isLoadingPutNoEventPaymentSlotsBooking: false,
    noEventBookingSlots: [],
    bookingOnlineCode:"",
    resonanceFee:0
}


export const slots = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_RESONANCE_FEE_START:
            return Object.assign({}, state, {
                isLoadingResonanceFee: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_RESONANCE_FEE:
            return Object.assign({}, state, {
                isLoadingResonanceFee: false,
                error: false,
                message: '',
                isSuccess: true,
                resonanceFee: action?.data?.percentage ? (action?.data?.percentage/100) : 0
            })
        case APP_GET_RESONANCE_FEE_FAIL:
            return Object.assign({}, state, {
                isLoadingResonanceFee: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode,
                resonanceFee: 0
            })
        case APP_PUT_NO_EVENT_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingPutNoEventPaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_NO_EVENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingPutNoEventPaymentSlotsBooking: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_NO_EVENT_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingPutNoEventPaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_NO_EVENT_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingGetNoEventPaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_NO_EVENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingGetNoEventPaymentSlotsBooking: false,
                noEventBookingSlots:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_NO_EVENT_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingGetNoEventPaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_FREE_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingGetFreePaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_FREE_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingGetFreePaymentSlotsBooking: false,
                freeBookingSlots:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FREE_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingGetFreePaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CHECKIN_PAYMENT_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingCheckInPaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CHECKIN_PAYMENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingCheckInPaymentSlotsBooking: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CHECKIN_PAYMENT_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingCheckInPaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CANCEL_PAYMENT_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingCancelPaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CANCEL_PAYMENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingCancelPaymentSlotsBooking: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CANCEL_PAYMENT_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingCancelPaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DECLINE_PAYMENT_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingDeclinePaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DECLINE_PAYMENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingDeclinePaymentSlotsBooking: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DECLINE_PAYMENT_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingDeclinePaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_ACCEPT_PAYMENT_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingAcceptPaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_ACCEPT_PAYMENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingAcceptPaymentSlotsBooking: false,
                bookingOnlineCode:action.online_code,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_ACCEPT_PAYMENT_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingAcceptPaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_PAYMENT_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingGetPaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_PAYMENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingGetPaymentSlotsBooking: false,
                bookings: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FAILED_PAYMENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingGetPaymentSlotsBooking: false,
                failedbookings: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_PAYMENT_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingGetPaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_PAYMENT_SLOTS_BOOKING_START:
            return Object.assign({}, state, {
                isLoadingPostPaymentSlotsBooking: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_PAYMENT_SLOTS_BOOKING:
            return Object.assign({}, state, {
                isLoadingPostPaymentSlotsBooking: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_PAYMENT_SLOTS_BOOKING_FAIL:
            return Object.assign({}, state, {
                isLoadingPostPaymentSlotsBooking: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_ALL_CARD_PAYMENT_SLOTS_START:
            return Object.assign({}, state, {
                isLoadingGetAllCardsPaymentSlots: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ALL_CARD_PAYMENT_SLOTS:
            return Object.assign({}, state, {
                isLoadingGetAllCardsPaymentSlots: false,
                allCardPaymentSlots: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ALL_CARD_PAYMENT_SLOTS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetAllCardsPaymentSlots: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_PAYMENT_SLOTS_START:
            return Object.assign({}, state, {
                isLoadingGetPaymentSlots: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_PAYMENT_SLOTS:
            return Object.assign({}, state, {
                isLoadingGetPaymentSlots: false,
                paymentSlots: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_PAYMENT_SLOTS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetPaymentSlots: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_PAYMENT_SLOTS_START:
            return Object.assign({}, state, {
                isLoadingPaymentSlots: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_PAYMENT_SLOTS:
            return Object.assign({}, state, {
                isLoadingPaymentSlots: false,
                paymentSlots: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_PAYMENT_SLOTS_FAIL:
            return Object.assign({}, state, {
                isLoadingPaymentSlots: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.slots.error
export const getMsg = state => state.slots.message
export const getIsSuccess = state => state.slots.isSuccess
export const getstatusCode = state => state.slots.statusCode
export const getPaymentSlots = state => state.slots.paymentSlots
export const getisLoadingPaymentSlots = state => state.slots.isLoadingPaymentSlots
export const getisLoadingGetPaymentSlots = state => state.slots.isLoadingGetPaymentSlots
export const getisLoadingGetAllCardsPaymentSlots = state => state.slots.isLoadingGetAllCardsPaymentSlots
export const getAllCardPaymentSlots = state => state.slots.allCardPaymentSlots
export const getisLoadingPostPaymentSlotsBooking = state => state.slots.isLoadingPostPaymentSlotsBooking
export const getisLoadingGetPaymentSlotsBooking = state => state.slots.isLoadingGetPaymentSlotsBooking
export const getBookings = state => state.slots.bookings
export const getisLoadingAcceptPaymentSlotsBooking = state => state.slots.isLoadingAcceptPaymentSlotsBooking
export const getisLoadingDeclinePaymentSlotsBooking = state => state.slots.isLoadingDeclinePaymentSlotsBooking
export const getisLoadingCancelPaymentSlotsBooking = state => state.slots.isLoadingCancelPaymentSlotsBooking
export const getisLoadingCheckInPaymentSlotsBooking = state => state.slots.isLoadingCheckInPaymentSlotsBooking
export const getisLoadingGetFreePaymentSlotsBooking = state => state.slots.isLoadingGetFreePaymentSlotsBooking
export const getisLoadingGetNoEventPaymentSlotsBooking = state => state.slots.isLoadingGetNoEventPaymentSlotsBooking
export const getisLoadingPutNoEventPaymentSlotsBooking = state => state.slots.isLoadingPutNoEventPaymentSlotsBooking
export const getNoEventBookingSlots = state => state.slots.noEventBookingSlots
export const getFreeBookingSlots = state => state.slots.freeBookingSlots
export const getFailedbookings = state => state.slots.failedbookings
export const getBookingOnlineCode = state => state.slots.bookingOnlineCode
export const getResonanceFee = state => state.slots.resonanceFee





