import React from "react";
import Close from "../../../../assets/images/close.svg";
import Logout from "../../../../assets/images/logoutBig.png";

const PopupLogout = (props) => {
  return (
    <div className={"popup__logout popup__container " + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          <div className="popup__content">
            <p>
              You are still in the process of registering. Are you sure you want
              to log out? When you log back into your account, you’ll have to
              start the registration process over.
            </p>
            <img className="logout__img" src={Logout} alt="logout" />
            <div className="clear"></div>
            <div className="popup__buttonWrapper">
              <button className="button--shadow" onClick={props.close}>
                GO BACK
              </button>
              <button className="button--primary" onClick={props.onClick}>
                LOG OUT
              </button>
            </div>
            <span onClick={props.close} className="close__popup">
              <img src={Close} alt="close" />
            </span>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default PopupLogout;
