import {
    APP_POST_ASK_QUESTION_START,
    APP_POST_ASK_QUESTION,
    APP_POST_ASK_QUESTION_FAIL,
    APP_LOGOUT
} from '../../actions'

import { createSelector } from 'reselect'

const initialState = {
    data: {},
    isLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201
}


export const askQuestion = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_ASK_QUESTION_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_ASK_QUESTION:
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_ASK_QUESTION_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.askQuestion.error
export const getMsg = state => state.askQuestion.message
export const getisLoadingAskQuestion = state => state.askQuestion.isLoading
export const getIsSuccess = state => state.askQuestion.isSuccess
export const getstatusCode = state => state.askQuestion.statusCode

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))