import { parseJSON, composeAuth } from '../../../../helpers/utils'

import {
    APP_CREATE_MASTERCLASS_START,
    APP_CREATE_MASTERCLASS,
    APP_CREATE_MASTERCLASS_FAIL,
    APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_START,
    APP_CREATE_MASTERCLASS_ADDITIONAL_INFO,
    APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_FAIL,
    APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_START,
    APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS,
    APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../actions'


//create masterclass

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitMasterclassStart = () => ({
    type: APP_CREATE_MASTERCLASS_START
})

export const emitMasterclass = data => {
    return {
        type: APP_CREATE_MASTERCLASS,
        data
    }
}

export const emitMasterclassFailed = (data) => ({
    type: APP_CREATE_MASTERCLASS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postMasterclass = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/masterclasses/masterclass'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createMasterclass = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassStart())
        return postMasterclass(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMasterclass(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassFailed(err))
            })
    }

}

// add additional info to masterclass

export const emitMasterclassAdditionalInfoStart = () => ({
    type: APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_START
})

export const emitMasterclassAdditionalInfo = data => {
    return {
        type: APP_CREATE_MASTERCLASS_ADDITIONAL_INFO,
        data
    }
}

export const emitMasterclassAdditionalInfoFailed = (data) => ({
    type: APP_CREATE_MASTERCLASS_ADDITIONAL_INFO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postMasterclassAdditionalInfo = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/masterclass/information/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createMasterclassAdditionalInfo = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassAdditionalInfoStart())
        return postMasterclassAdditionalInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMasterclassAdditionalInfo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassAdditionalInfoFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassAdditionalInfoFailed(err))
            })
    }
}

// get masterclass

const getMasterclass = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/masterclass/${id}`;
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchMasterclass = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassStart())
        return getMasterclass(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitMasterclass(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassFailed(err))
            })
    }

}

// add masterclass to professions Cards


export const emitMasterclassToProfessionCardsStart = () => ({
    type: APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_START
})

export const emitMasterclassToProfessionCards = data => {
    return {
        type: APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS,
        data
    }
}

export const emitMasterclassToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_MASTERCLASS_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postMasterclassToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/masterclasses/masterclass/professioncard'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignMasterclassToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassToProfessionCardsStart())
        return postMasterclassToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMasterclassToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassToProfessionCardsFailed(err))
            })
    }
}

// update masterclass

const putMasterclass = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/masterclass/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateMasterclass = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassStart())
        return putMasterclass(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitMasterclass(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassFailed(err))
            })
    }

}

// get masterclass resume by card id

export const emitMasterclassByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitMasterclassByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitMasterclassByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getMasterclassByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/masterclass/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMasterclassByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassByCardIdStart())
        return getMasterclassByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitMasterclassByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassByCardIdFailed(err))
            })
    }
}

// get masterclass resume by card id by user id
const getMasterclassByCardIdByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/masterclass/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMasterclassByCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassByCardIdStart())
        return getMasterclassByCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitMasterclassByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassByCardIdFailed(err))
            })
    }
}

// get user masterclass - no card
const getUserMasterclass = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/user/masterclass`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserMasterclass = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassByCardIdStart())
        return getUserMasterclass(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitMasterclassByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassByCardIdFailed(err))
            })
    }
}


// get user masterclass - no card by user id
const getUserMasterclassByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/users/masterclass/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserMasterclassByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitMasterclassByCardIdStart())
        return getUserMasterclassByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitMasterclassByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitMasterclassByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitMasterclassByCardIdFailed(err))
            })
    }
}

// delete masterclass by id

export const emitDeleteMasterclassStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteMasterclass = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteMasterclassFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteMasterclass = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/delete/masterclass`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteMasterclass = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteMasterclassStart())
        return deleteMasterclass(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteMasterclass(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteMasterclassFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteMasterclassFailed(err))
            })
    }
}


// assign masterclass to card

export const emitAssignMasterclassStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignMasterclass = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignMasterclassFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignMasterclass = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/masterclasses/reassign/professioncard/masterclasss`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignMasterclass = (data) => {
    return (dispatch) => {
        dispatch(emitAssignMasterclassStart())
        return assignMasterclass(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignMasterclass(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignMasterclassFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignMasterclassFailed(err))
            })
    }
}