import {
    APP_GET_SENT_OFFER_PRESENT_STATUS_START,
    APP_GET_SENT_OFFER_PRESENT_STATUS,
    APP_GET_SENT_OFFER_PRESENT_STATUS_FAIL,
    APP_GET_RECEIVED_OFFER_PRESENT_STATUS_START,
    APP_GET_RECEIVED_OFFER_PRESENT_STATUS,
    APP_GET_RECEIVED_OFFER_PRESENT_STATUS_FAIL,
    APP_GET_SENT_CONTRACT_PRESENT_STATUS_START,
    APP_GET_SENT_CONTRACT_PRESENT_STATUS,
    APP_GET_SENT_CONTRACT_PRESENT_STATUS_FAIL,
    APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS_START,
    APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS,
    APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS_FAIL,
    APP_GET_SESSION_BOOKINGS_STATUS_START,
    APP_GET_SESSION_BOOKINGS_PRESENT_STATUS,
    APP_GET_SESSION_BOOKINGS_PRESENT_STATUS_FAIL,
    APP_GET_FAILED_PAYMENT_SLOTS_START,
    APP_GET_FAILED_PAYMENT_SLOTS,
    APP_GET_FAILED_PAYMENT_SLOTS_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    sentOfferStatus:false,
    receivedOfferStatus:false,
    sentContractStatus: false,
    receivedContractStatus: false,
    isLoadingSentOfferStatus:false,
    isLoadingReceivedOfferStatus:false,
    isLoadingSentContractStatus: false,
    isLoadingReceivedContractStatus: false,
    isLoadingSessionBookingsStatus: false,
    isLoadingFailedPaymentBookingsStatus: false,
    pendingSession: 0,
    confirmedSession: 0,
    failedPaymentLength:0

}


export const contractsLongPooling = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS_START:
            return Object.assign({}, state, {
                isLoadingReceivedContractStatus: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS:
            return Object.assign({}, state, {
                isLoadingReceivedContractStatus: false,
                receivedContractStatus:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_RECEIVED_CONTRACT_PRESENT_STATUS_FAIL:
            return Object.assign({}, state, {
                isLoadingReceivedContractStatus: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_SENT_CONTRACT_PRESENT_STATUS_START:
            return Object.assign({}, state, {
                isLoadingSentContractStatus: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SENT_CONTRACT_PRESENT_STATUS:
            return Object.assign({}, state, {
                isLoadingSentContractStatus: false,
                sentContractStatus:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SENT_CONTRACT_PRESENT_STATUS_FAIL:
            return Object.assign({}, state, {
                isLoadingSentContractStatus: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_RECEIVED_OFFER_PRESENT_STATUS_START:
            return Object.assign({}, state, {
                isLoadingReceivedOfferStatus: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_RECEIVED_OFFER_PRESENT_STATUS:
            return Object.assign({}, state, {
                isLoadingReceivedOfferStatus: false,
                receivedOfferStatus:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_RECEIVED_OFFER_PRESENT_STATUS_FAIL:
            return Object.assign({}, state, {
                isLoadingReceivedOfferStatus: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_SENT_OFFER_PRESENT_STATUS_START:
            return Object.assign({}, state, {
                isLoadingSentOfferStatus: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SENT_OFFER_PRESENT_STATUS:
            return Object.assign({}, state, {
                isLoadingSentOfferStatus: false,
                sentOfferStatus:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SENT_OFFER_PRESENT_STATUS_FAIL:
            return Object.assign({}, state, {
                isLoadingSentOfferStatus: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_SESSION_BOOKINGS_STATUS_START:
            return Object.assign({}, state, {
                isLoadingSessionBookingsStatus: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SESSION_BOOKINGS_PRESENT_STATUS:
            return Object.assign({}, state, {
                isLoadingSessionBookingsStatus: false,
                pendingSession: action.data.pending_length,
                confirmedSession: action.data.confirmed_length,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SESSION_BOOKINGS_PRESENT_STATUS_FAIL:
            return Object.assign({}, state, {
                isLoadingSessionBookingsStatus: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_FAILED_PAYMENT_SLOTS_START:
            return Object.assign({}, state, {
                isLoadingFailedPaymentBookingsStatus: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_FAILED_PAYMENT_SLOTS:
            return Object.assign({}, state, {
                isLoadingFailedPaymentBookingsStatus: false,
                failedPaymentLength: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FAILED_PAYMENT_SLOTS_FAIL:
            return Object.assign({}, state, {
                isLoadingFailedPaymentBookingsStatus: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.contractsLongPooling.error
export const getMsg = state => state.contractsLongPooling.message
export const getIsSuccess = state => state.contractsLongPooling.isSuccess
export const getstatusCode = state => state.contractsLongPooling.statusCode
export const getSentOfferStatus = state => state.contractsLongPooling.sentOfferStatus
export const getReceivedOfferStatus = state => state.contractsLongPooling.receivedOfferStatus
export const getSentContractStatus = state => state.contractsLongPooling.sentContractStatus
export const getReceivedContractStatus = state => state.contractsLongPooling.receivedContractStatus
export const getisLoadingSentOfferStatus = state => state.contractsLongPooling.isLoadingSentOfferStatus
export const getisLoadingReceivedOfferStatus = state => state.contractsLongPooling.isLoadingReceivedOfferStatus
export const getisLoadingSentContractStatus = state => state.contractsLongPooling.isLoadingSentContractStatus
export const getisLoadingReceivedContractStatus = state => state.contractsLongPooling.isLoadingReceivedContractStatus
export const getPendingSession = state => state.contractsLongPooling.pendingSession
export const getConfirmedSession = state => state.contractsLongPooling.confirmedSession
export const getisLoadingFailedPaymentBookingsStatus = state => state.contractsLongPooling.isLoadingFailedPaymentBookingsStatus
export const getFailedPaymentLength = state => state.contractsLongPooling.failedPaymentLength