
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_GET_REQUEST_STATUS_START,
    APP_GET_REQUEST_STATUS,
    APP_GET_REQUEST_STATUS_FAIL,
    APP_POST_SEND_REQUEST_START,
    APP_POST_SEND_REQUEST,
    APP_POST_SEND_REQUEST_FAIL,
    APP_GET_ALL_COLLEAGUES_START,
    APP_GET_ALL_COLLEAGUES,
    APP_GET_ALL_COLLEAGUES_FAIL,
    APP_GET_SENT_COLLEAGUES_REQUEST_START,
    APP_GET_SENT_COLLEAGUES_REQUEST,
    APP_GET_SENT_COLLEAGUES_REQUEST_FAIL,
    APP_GET_RECEIVED_COLLEAGUES_REQUEST_START,
    APP_GET_RECEIVED_COLLEAGUES_REQUEST,
    APP_GET_RECEIVED_COLLEAGUES_REQUEST_FAIL,
    APP_UPDATE_COLLEAGUES_REQUEST_START,
    APP_UPDATE_COLLEAGUES_REQUEST,
    APP_UPDATE_COLLEAGUES_REQUEST_FAIL,
    APP_LOGOUT,
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

//get request status between two users
export const emitRequestStatusStart = () => ({
    type: APP_GET_REQUEST_STATUS_START
})

export const emitRequestStatus = data => {
    return {
        type: APP_GET_REQUEST_STATUS,
        data
    }
}

export const emitRequestStatusFailed = (data) => ({
    type: APP_GET_REQUEST_STATUS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getRequestStatus = ({ jwt, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/status/user/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetRequestStatus = (data) => {
    return (dispatch) => {
        dispatch(emitRequestStatusStart())
        return getRequestStatus(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetRequestStatus");
                if (json.statusCode === 200) {
                    dispatch(emitRequestStatus(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitRequestStatusFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startGetRequestStatus err");
                dispatch(emitRequestStatusFailed(err))
            })
    }

}

//send a request
export const emitSendRequestStart = () => ({
    type: APP_POST_SEND_REQUEST_START
})

export const emitSendRequest = data => {
    return {
        type: APP_POST_SEND_REQUEST,
        data
    }
}

export const emitSendRequestFailed = (data) => ({
    type: APP_POST_SEND_REQUEST_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postSendRequest = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/request`
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body:JSON.stringify(data)
    })
}

export const startPostSendRequest = (data) => {
    return (dispatch) => {
        dispatch(emitSendRequestStart())
        return postSendRequest(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startPostSendRequest");
                if (json.statusCode === 201) {
                    dispatch(emitSendRequest(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitSendRequestFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startPostSendRequest err");
                dispatch(emitSendRequestFailed(err))
            })
    }

}

//get all colleagues
export const emitGetMyColleaguesStart = () => ({
    type: APP_GET_ALL_COLLEAGUES_START
})

export const emitGetMyColleagues = data => {
    return {
        type: APP_GET_ALL_COLLEAGUES,
        data
    }
}

export const emitGetMyColleaguesFailed = (data) => ({
    type: APP_GET_ALL_COLLEAGUES_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getMyColleagues = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/me/friends`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMyColleagues = (data) => {
    return (dispatch) => {
        dispatch(emitGetMyColleaguesStart())
        return getMyColleagues(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetMyColleagues");
                if (json.statusCode === 200) {
                    dispatch(emitGetMyColleagues(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetMyColleaguesFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startGetMyColleagues err");
                dispatch(emitGetMyColleaguesFailed(err))
            })
    }

}

//user colleagues
const getUserColleagues = ({ userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/user/colleagues/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
}

export const startGetUserColleagues = (data) => {
    return (dispatch) => {
        dispatch(emitGetMyColleaguesStart())
        return getUserColleagues(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetUserColleagues");
                if (json.statusCode === 200) {
                    dispatch(emitGetMyColleagues(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetMyColleaguesFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startGetMyColleagues err");
                dispatch(emitGetMyColleaguesFailed(err))
            })
    }

}

//get sent colleagues requests
export const emitGetMySentColleaguesRequestStart = () => ({
    type: APP_GET_SENT_COLLEAGUES_REQUEST_START
})

export const emitGetMySentColleaguesRequest = data => {
    return {
        type: APP_GET_SENT_COLLEAGUES_REQUEST,
        data
    }
}

export const emitGetMySentColleaguesRequestFailed = (data) => ({
    type: APP_GET_SENT_COLLEAGUES_REQUEST_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getMySentColleaguesRequest = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/me/sent/requests`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMySentColleaguesRequest = (data) => {
    return (dispatch) => {
        dispatch(emitGetMySentColleaguesRequestStart())
        return getMySentColleaguesRequest(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetMySentColleaguesRequest");
                if (json.statusCode === 200) {
                    dispatch(emitGetMySentColleaguesRequest(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetMySentColleaguesRequestFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startGetMySentColleaguesRequest err");
                dispatch(emitGetMySentColleaguesRequestFailed(err))
            })
    }

}

//get received colleagues requests
export const emitGetMyReceivedColleaguesRequestStart = () => ({
    type: APP_GET_RECEIVED_COLLEAGUES_REQUEST_START
})

export const emitGetMyReceivedColleaguesRequest = data => {
    return {
        type: APP_GET_RECEIVED_COLLEAGUES_REQUEST,
        data
    }
}

export const emitGetMyReceivedColleaguesRequestFailed = (data) => ({
    type: APP_GET_RECEIVED_COLLEAGUES_REQUEST_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getMyReceivedColleaguesRequest = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/me/requests`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetMyReceivedColleaguesRequest = (data) => {
    return (dispatch) => {
        dispatch(emitGetMyReceivedColleaguesRequestStart())
        return getMyReceivedColleaguesRequest(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startGetMyReceivedColleaguesRequest");
                if (json.statusCode === 200) {
                    dispatch(emitGetMyReceivedColleaguesRequest(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetMyReceivedColleaguesRequestFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startGetMyReceivedColleaguesRequest err");
                dispatch(emitGetMyReceivedColleaguesRequestFailed(err))
            })
    }

}

//update colleagues requests
export const emitUpdateRequestStart = () => ({
    type: APP_UPDATE_COLLEAGUES_REQUEST_START
})

export const emitUpdateRequest = data => {
    return {
        type: APP_UPDATE_COLLEAGUES_REQUEST,
        data
    }
}

export const emitUpdateRequestFailed = (data) => ({
    type: APP_UPDATE_COLLEAGUES_REQUEST_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

//accept request
const acceptRequest = ({ jwt, requestId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/accept/request/${requestId}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startAcceptRequest = (data) => {
    return (dispatch) => {
        dispatch(emitUpdateRequestStart())
        return acceptRequest(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startAcceptRequest");
                if (json.statusCode === 201) {
                    dispatch(emitUpdateRequest(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpdateRequestFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startAcceptRequest err");
                dispatch(emitUpdateRequestFailed(err))
            })
    }

}

//cancel/delete request
const cancelRequest = ({ jwt, requestId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/delete/request/${requestId}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startCancelRequest = (data) => {
    return (dispatch) => {
        dispatch(emitUpdateRequestStart())
        return cancelRequest(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startCancelRequest");
                if (json.statusCode === 201) {
                    dispatch(emitUpdateRequest(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpdateRequestFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startCancelRequest err");
                dispatch(emitUpdateRequestFailed(err))
            })
    }

}

//unfriend request
const unfriendRequest = ({ jwt, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/networks/unfriend/user/${userId}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startUnfriendRequest = (data) => {
    return (dispatch) => {
        dispatch(emitUpdateRequestStart())
        return unfriendRequest(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startUnfriendRequest");
                if (json.statusCode === 201) {
                    dispatch(emitUpdateRequest(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpdateRequestFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "startUnfriendRequest err");
                dispatch(emitUpdateRequestFailed(err))
            })
    }

}