import {
    SAVE_MEDIA_AUDIO_PROFESSION_CARDS,
    SAVE_MEDIA_AUDIO_MANDATE_FIELDS,
    SAVE_MEDIA_AUDIO_URL,
    CLEAR_MEDIA_AUDIO,
    APP_LOGOUT
} from '../../../actions'

const initialState = {
    professionCardIds: [],
    mandateFields:{},
    audioUrl:""
}

export const mediaAudioUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_MEDIA_AUDIO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                professionCardIds: action.data
            })
        case SAVE_MEDIA_AUDIO_MANDATE_FIELDS:
            return Object.assign({}, state, {
                mandateFields: action.data
            })
        case SAVE_MEDIA_AUDIO_URL:
            return Object.assign({}, state, {
                audioUrl: action.data
            })
        case CLEAR_MEDIA_AUDIO:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getProfessionCardIds = state => state.mediaAudioUI.professionCardIds
export const getMandateFields = state => state.mediaAudioUI.mandateFields
export const getAudioUrl = state => state.mediaAudioUI.audioUrl
