import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { fetchCommissionedWork } from '../../../redux/actions/resume/commissionedWork'

//reducers
import { getisLoading, getCommissionedWork } from '../../../redux/reducers/resume/commissionedWork'
import { getJwt } from '../../../redux/reducers/account/accounts'


const ResumeOriginalWork = (props) => {
    const dispatch = useDispatch()

    const commissionedWork = useSelector(getCommissionedWork)
    const isLoadingJob = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(fetchCommissionedWork({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingJob ? <Spinner /> : <div>
            <div className="expDetail__secHead">Creator Info</div>
            <div className="expDetail__wrap">
                {commissionedWork?.creator?.map((c, index) => {
                    return (
                        <p key={index} className="expDetail__desc">{c}</p>
                    )
                })}
                
            </div>

            <div className="expDetail__secHead">Work Info</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Type</p>
                <p className="expDetail__desc">{commissionedWork.type ? commissionedWork.type : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Title of Work</p>
                <p className="expDetail__desc">{commissionedWork.title ? commissionedWork.title : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Year</p>
                <p className="expDetail__desc">{commissionedWork.year ? commissionedWork.year : null}</p>
            </div>

            <div className="expDetail__secHead">Associated Users</div>
            <div className="expDetail__wrap">
                {(commissionedWork?.othercreator ? commissionedWork?.othercreator : []).map((item, index) => {
                    return (
                        <div key={index}>
                            <p className="expDetail__label">{item.name}</p>
                            {item.associations.map((association, index) => {
                                return  <p className="expDetail__desc">{association.firstName + " " + association.lastName}</p>
                            })}
                        </div>
                    )
                })}
            </div>


            {commissionedWork.information || (commissionedWork?.additional_info && commissionedWork?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {commissionedWork.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{commissionedWork.information}</p>
                </div> : null}
                {commissionedWork?.additional_info && commissionedWork?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {commissionedWork?.additional_info && commissionedWork?.additional_info.data[0] ? commissionedWork?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div> : null}
        </div>
    );
};

export default ResumeOriginalWork;
