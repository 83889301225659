import React from "react";
import Icon from "../../../assets/images/arrow.png";

const ViewAll = (props) => {
  return (
    <div onClick={props.onClick} className={props?.type === "People" ? "peopleViewAll" : "viewAll"}>
      <div className="viewAll__container">
        <h5>
          View All
          <br />
          {props.category}
        </h5>
        <img src={Icon} alt="icon" className="viewAll__arrow"/>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default ViewAll;
