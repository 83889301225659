import { useEffect, useRef } from 'react'

export const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const parseJSON = async (response) => {
    const statusCode = response.status;
    const json = await response.json();
    if (statusCode === 401 || statusCode === 422) {
        return Object.assign(json, { statusCode: statusCode })
    }
    else {
        return json
    }
}

export const composeAuth = jwt => 'Bearer ' + jwt

export const getById = (data, field = 'id') => {
    let byId = {}
    data.forEach(s => {
        byId[s[field]] = s
    })
    return byId
}

export const getByField = (collection, property) => {
    var i = 0, val, index,
        values = [], result = [];
    if (collection.length > 0) {
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
    }
    return result;
}

export const monthToName = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

export const OpenLink = (data) =>{
    const pattern = /^((http|https|ftp):\/\/)/;
    if (!pattern.test(data)) {
        let url = "https://" + data;
        window.open(url, "_blank")
    }
    else {
        window.open(data, "_blank")
    }
}