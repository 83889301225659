import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { OpenLink } from '../../../helpers/utils'


const PressDetails = (props) => {
    const quote = props?.quote
    return (
        <Scrollbars autoHeight autoHeightMin={300} autoHeightMax={500}>
            <div className="popup__details">
                {quote?.author ? <div className="popup__data">
                    <div className="popup__label">Author:</div>
                    <div className="popup__desc">{quote?.author}</div>
                    <div className="clear"></div>
                </div> : null}
                <div className="clear"></div>
                {quote?.publicationName ? <div className="popup__data">
                    <div className="popup__label">Publication Name:</div>
                    <div className="popup__desc">{quote?.publicationName}</div>
                    <div className="clear"></div>
                </div> : null}
                <div className="clear"></div>
                {quote?.publicationExcerpt ? <div className="popup__data">
                    <div className="popup__label">Publication Excerpt:</div>
                    <div className="popup__desc">{quote?.publicationExcerpt}</div>
                    <div className="clear"></div>
                </div> : null}
                {quote?.publicationLink ? <div className="popup__data">
                    <div className="popup__label">Web Link:</div>
                    <div className="popup__desc addPointer" onClick={() => OpenLink(quote?.publicationLink)}>{quote?.publicationLink}</div>
                    <div className="clear"></div>
                </div> : null}
                <div className="clear"></div>
            </div>
        </Scrollbars>
    )
};

export default PressDetails;
