import React from "react";

const CategoriesSort = (props) => {
  return (
    <div className="categoriesSort">
      <div className="categoriesSort__wrap">
        {props.children}
        <div className="clear"></div>
      </div>
      <div className="categoriesSort__buttonWrap d-flex h-100">
        <div className="justify-content-center align-self-center">
        <button onClick={props.onClick} className="button button--withoutBg">
          {props.buttonText}
        </button>
        <div className="clear"></div>
      </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default CategoriesSort;
