import {
    APP_GET_WORK_BY_TEXT_START,
    APP_GET_WORK_BY_TEXT,
    APP_GET_WORK_BY_TEXT_FAIL,
    APP_POST_NEW_WORK_START,
    APP_POST_NEW_WORK,
    APP_POST_NEW_WORK_FAIL,
    APP_GET_WORK_UNAMMED_ROLES_START,
    APP_GET_WORK_UNAMMED_ROLES,
    APP_GET_WORK_UNAMMED_ROLES_FAIL,
    APP_GET_WORK_BY_ID_START,
    APP_GET_WORK_BY_ID,
    APP_GET_WORK_BY_ID_FAIL,
    APP_GET_WORK_DETAILS_BY_EVENTID_START,
    APP_GET_WORK_DETAILS_BY_EVENTID,
    APP_GET_WORK_DETAILS_BY_EVENTID_FAIL,
    APP_PUT_NAMEDWORK_TO_EVENT_START,
    APP_PUT_NAMEDWORK_TO_EVENT,
    APP_PUT_NAMEDWORK_TO_EVENT_FAIL,
    APP_GET_WORK_DISCIPLINE_START,
    APP_GET_WORK_DISCIPLINE,
    APP_GET_WORK_DISCIPLINE_FAIL,
    APP_GET_WORK_TYPES_START,
    APP_GET_WORK_TYPES,
    APP_GET_WORK_TYPES_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'
import { getById } from '../../../../helpers/utils'

const initialState = {
    isLoadingGetByText: false,
    isLoadingGetByEventId: false,
    isLoadingGetById: false,
    isLoadingPostNew: false,
    isLoadingWorkRoles: false,
    isLoadingPutNamedWork: false,
    isLoadingWorkDiscipline: false,
    isLoadingWorkType: false,
    works: [],
    eventNamedWork: [],
    worksByEventId: [],
    workDiscipline:[],
    workTypes:[],
    postedNewWork: [],
    workRoles:[],
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const Works = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_WORK_TYPES_START:
            return Object.assign({}, state, {
                isLoadingWorkType: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_WORK_TYPES:
            return Object.assign({}, state, {
                workTypes:action.data,
                isLoadingWorkType: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_WORK_TYPES_FAIL:
            return Object.assign({}, state, {
                isLoadingWorkType: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_WORK_DISCIPLINE_START:
            return Object.assign({}, state, {
                isLoadingWorkDiscipline: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_WORK_DISCIPLINE:
            return Object.assign({}, state, {
                workDiscipline:action.data,
                isLoadingWorkDiscipline: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_WORK_DISCIPLINE_FAIL:
            return Object.assign({}, state, {
                isLoadingWorkDiscipline: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_NAMEDWORK_TO_EVENT_START:
            return Object.assign({}, state, {
                isLoadingPutNamedWork: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_NAMEDWORK_TO_EVENT:
            return Object.assign({}, state, {
                eventNamedWork:action.data,
                isLoadingPutNamedWork: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_NAMEDWORK_TO_EVENT_FAIL:
            return Object.assign({}, state, {
                isLoadingPutNamedWork: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_WORK_DETAILS_BY_EVENTID_START:
            return Object.assign({}, state, {
                isLoadingGetByEventId: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_WORK_DETAILS_BY_EVENTID:
            return Object.assign({}, state, {
                worksByEventId:action.data,
                isLoadingGetByEventId: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_WORK_DETAILS_BY_EVENTID_FAIL:
            return Object.assign({}, state, {
                isLoadingGetByEventId: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_WORK_BY_ID_START:
            return Object.assign({}, state, {
                isLoadingGetById: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_WORK_BY_ID:
            return Object.assign({}, state, {
                works:action.data,
                isLoadingGetById: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_WORK_BY_ID_FAIL:
            return Object.assign({}, state, {
                isLoadingGetById: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_WORK_UNAMMED_ROLES_START:
            return Object.assign({}, state, {
                isLoadingWorkRoles: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_WORK_UNAMMED_ROLES:
            return Object.assign({}, state, {
                workRoles:action.data,
                isLoadingWorkRoles: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_WORK_UNAMMED_ROLES_FAIL:
            return Object.assign({}, state, {
                isLoadingWorkRoles: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_NEW_WORK_START:
            return Object.assign({}, state, {
                isLoadingPostNew: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_NEW_WORK:
            return Object.assign({}, state, {
                postedNewWork: action.data,
                isLoadingPostNew: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_NEW_WORK_FAIL:
            return Object.assign({}, state, {
                isLoadingPostNew: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_WORK_BY_TEXT_START:
            return Object.assign({}, state, {
                isLoadingGetByText: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_WORK_BY_TEXT:
            return Object.assign({}, state, {
                works: action.data,
                isLoadingGetByText: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_WORK_BY_TEXT_FAIL:
            return Object.assign({}, state, {
                isLoadingGetByText: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.Works.error
export const getMsg = state => state.Works.message
export const getIsSuccess = state => state.Works.isSuccess
export const getisLoadingGetByText = state => state.Works.isLoadingGetByText
export const getisLoadingGetById = state => state.Works.isLoadingGetById
export const getisLoadingPostNew = state => state.Works.isLoadingPostNew
export const getisLoadingWorkGenericRoles = state => state.Works.isLoadingWorkRoles
export const getWorks = state => state.Works.works
export const getPostedNewWork = state => state.Works.postedNewWork
export const getGenericRoles = state => state.Works.workRoles
export const getisLoadingGetByEventId = state => state.Works.isLoadingGetByEventId
export const getworksByEventId = state => state.Works.worksByEventId
export const getisLoadingPutNamedWork = state => state.Works.isLoadingPutNamedWork
export const getEventNamedWork = state => state.Works.eventNamedWork
export const getWorkDiscipline = state => state.Works.workDiscipline
export const getisLoadingWorkDiscipline = state => state.Works.isLoadingWorkDiscipline
export const getWorkTypes = state => state.Works.workTypes
export const getisLoadingWorkType = state => state.Works.isLoadingWorkType

export const getWorksById = state => {
    return getById(state.Works.works)
}
export const getWorkDisciplineById = state => {
    return getById(state.Works.workDiscipline)
}
export const getWorkTypesById = state => {
    return getById(state.Works.workTypes)
}
export const getGenericRolesById = state => {
    return getById(state.Works.workRoles)
}

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
