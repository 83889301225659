import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

//assets
import Spinner from "../../assets/molecules/spinner";

//actions
import { fetchAccoladesCompetition } from '../../../redux/actions/resume/accolades/competition'

//reducers
import { getisLoading, getCompetition } from '../../../redux/reducers/resume/accolades/competition'
import { getJwt } from '../../../redux/reducers/account/accounts'

//helpers
import { monthToName } from '../../../helpers/utils'

const ResumeCompetition = (props) => {
    const dispatch = useDispatch()

    const competition = useSelector(getCompetition)
    const isLoading = useSelector(getisLoading)
    const jwt = useSelector(getJwt)

    useEffect(() => {
        dispatch(fetchAccoladesCompetition({ jwt: jwt ? jwt : "", id: props?.id }))
    }, [props?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoading ? <Spinner /> : <div>
            <div className="expDetail__secHead">Certifications</div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Title of Certification</p>
                <p className="expDetail__desc">{competition.title ? competition.title : null}</p>
            </div>
            <div className="expDetail__wrap">
                <p className="expDetail__label">Year</p>
                <p className="expDetail__desc">{(competition.month ? monthToName[competition.month] : null) + " - " + (competition.year ? competition.year : null)}</p>
            </div>

            {competition.institutionName || competition?.location?.description || competition.affiliateinstitutionName ? <div>
                <div className="expDetail__secHead">Institution Info</div>
                {competition.institutionName ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Location</p>
                    <p className="expDetail__desc">{competition.institutionName}</p>
                </div> : null}
                {competition.location ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Location</p>
                    <p className="expDetail__desc">{competition.location.description}</p>
                </div> : null}
                {competition.affiliateinstitutionName ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Affiliated Institute</p>
                    <p className="expDetail__desc">{competition.affiliateinstitutionName}</p>
                </div> : null}
            </div> : null}

            {competition.prize ? <div>
                <div className="expDetail__secHead">Prize Info</div>
                {competition.prize ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Prize Level</p>
                    <p className="expDetail__desc">{competition.prize === "Other" ? competition.prize + " - " + competition.otherposition : competition.prize + " - " + competition.securedposition}</p>
                </div> : null}
            </div> : null}

            {competition.information || (competition?.additional_info && competition?.additional_info?.data[0]) ? <div>
                <div className="expDetail__secHead">Additional Info</div>
                {competition.information ? <div className="expDetail__wrap">
                    <p className="expDetail__label">Additional Info</p>
                    <p className="expDetail__desc">{competition.information}</p>
                </div> : null}
                {competition?.additional_info && competition?.additional_info?.data[0] ? <div className="expDetail__wrap">
                    <p className="expDetail__label">URL Link</p>
                    {competition?.additional_info && competition?.additional_info.data[0] ? competition?.additional_info.data.map((l, index) =>
                        <p className="expDetail__desc">{index + 1}. {l.note} - {l.url}</p>) : null}
                </div> : null}
            </div> : null}
        </div>
    );
};

export default ResumeCompetition;
