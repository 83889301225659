import {
    APP_MAP_DATA,
    APP_LOGOUT,
    APP_MAP_DATA_FAIL,
    APP_START_MAP_DATA,
} from '../../actions'

const initialState = {
    isLoading:false,
    description: "",
    id: "",
    latLng: {},
    error:false,
    message:"",
    isSuccess:false
}

export const maps = (state = initialState, action) => {
    switch (action.type) {
        case APP_MAP_DATA:   
            return Object.assign({}, state, {
                description: action.description,
                id: action.id,
                latLng: action.latLng
            })
        case APP_START_MAP_DATA:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_MAP_DATA_FAIL:
            return Object.assign({}, state, {
                error: true,
                isLoading: false,
                message: action.message,
                description: "",
                id: "",
                latLng: {}
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getDescription = state => state.maps.description
export const getId = state => state.maps.id
export const getLatLng = state => state.maps.latLng
export const getisLoading = state => state.maps.isLoading
export const getisSuccess = state => state.maps.isSuccess
export const getError = state => state.maps.error
export const getMessage = state => state.maps.message

