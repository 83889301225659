import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";

import {
    getLoggedIn,
    getisProfileCreated,
    getverified
} from '../../../redux/reducers/account/accounts'
import Sidebar from "../../assets/layouts/sidebar";
import Footer from "../../assets/layouts/footer";

//components
import EmailVerification from '../../user/emailVerification'
import UserInfo from '../../user/userDetails'

export const CustomLayout = ({ children, loggedIn , ...rest }) => {
    const isLoggedIn = useSelector(getLoggedIn)
    const isProfileCreated = useSelector(getisProfileCreated)
    const isVerified = useSelector(getverified)
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (isLoggedIn){
                    if (!isVerified){
                        ReactGA.pageview("/register/email-verification");
                        return (<EmailVerification/>)
                    }
                    if (!isProfileCreated){
                        ReactGA.pageview("/register/user-info");
                        return (<UserInfo/>)
                    }
                    ReactGA.pageview(location?.pathname);
                    return (
                        <div className="dashboard">
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>Resonance</title>
                                <meta property="og:site_name" content="Resonance" />
                                <meta property="og:title" content="Resonance" />
                                <meta property="og:description" content="Resonance" />
                                <meta property="og:type" content="website" />
                                <meta property="og:updated_time" content="1440432932" />
                            </Helmet>
                            <Sidebar />
                            {children}
                            <div className="clear"></div>
                            <Footer />
                            <div className="clear"></div>
                        </div>

                    )
                }
                else{
                    if (loggedIn){
                        ReactGA.pageview("/");
                        return <Redirect
                            to={{
                                pathname: '/',
                            }}
                        />
                    }
                    ReactGA.pageview(location?.pathname);
                    return (<div className="dashboard">
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>Resonance</title>
                                <meta property="og:site_name" content="Resonance" />
                                <meta property="og:title" content="Resonance" />
                                <meta property="og:description" content="Resonance" />
                                <meta property="og:type" content="website" />
                                <meta property="og:updated_time" content="1440432932" />
                            </Helmet>
                            <Sidebar />
                            {children}
                            <div className="clear"></div>
                            <Footer />
                             <div className="clear"></div>
                        </div>
                    )
                }
            }}
        />
    )
}
