import {
    APP_GET_PROFESSION_CATEGORY_START,
    APP_GET_PROFESSION_CATEGORY,
    APP_GET_PROFESSION_CATEGORY_FAIL,
    APP_LOGOUT,
    SAVE_SELECTED_PROFESSIONS,
    CLEAR_SELECTED_PROFESSIONS,
    APP_POST_USER_PROFESSION_START,
    APP_POST_USER_PROFESSION,
    APP_POST_USER_PROFESSION_FAIL,
    APP_GET_USER_PROFESSION_START,
    APP_GET_USER_PROFESSION,
    APP_GET_USER_PROFESSION_404,
    APP_GET_USER_PROFESSION_FAIL,
    APP_GET_COMBINATION_USER_PROFESSION_START,
    APP_GET_COMBINATION_USER_PROFESSION,
    APP_GET_COMBINATION_USER_PROFESSION_FAIL
} from '../../actions'

import { createSelector } from 'reselect'
import { getById } from '../../../helpers/utils'

const initialState = {
    professionCategories: [],
    isLoading: false,
    postIsLoading:false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    selectedProfesion:[],
    getProfessionsIsloading:false,
    userProfessions:[],
    combinationUserProfessions:[]
}


export const profession = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_PROFESSION_CATEGORY_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_PROFESSION_CATEGORY:
            return Object.assign({}, state, {
                professionCategories: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_PROFESSION_CATEGORY_FAIL:
            return Object.assign({}, state, {
                professionCategories: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case SAVE_SELECTED_PROFESSIONS:
            return Object.assign({}, state, {
                selectedProfesion: action.data
            })
        case CLEAR_SELECTED_PROFESSIONS:
            return Object.assign({}, state, {
                selectedProfesion: []
            })
        case APP_POST_USER_PROFESSION_START:
            return Object.assign({}, state, {
                postIsLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_USER_PROFESSION:
            return Object.assign({}, state, {
                postIsLoading: false,
                error: false,
                message: '',
                isSuccess: true,
                userProfessions:action.data
            })
        case APP_POST_USER_PROFESSION_FAIL:
            return Object.assign({}, state, {
                postIsLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_USER_PROFESSION_START:
            return Object.assign({}, state, {
                getProfessionsIsloading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_USER_PROFESSION:
            return Object.assign({}, state, {
                userProfessions: action.data,
                getProfessionsIsloading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_USER_PROFESSION_404:
            return Object.assign({}, state, {
                userProfessions: [],
                getProfessionsIsloading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_USER_PROFESSION_FAIL:
            return Object.assign({}, state, {
                getProfessionsIsloading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
            
        case APP_GET_COMBINATION_USER_PROFESSION_START:
            return Object.assign({}, state, {
                getProfessionsIsloading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_COMBINATION_USER_PROFESSION:
            return Object.assign({}, state, {
                combinationUserProfessions: action.data,
                getProfessionsIsloading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_COMBINATION_USER_PROFESSION_FAIL:
            return Object.assign({}, state, {
                getProfessionsIsloading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.profession.error
export const getMsg = state => state.profession.message
export const getisLoading = state => state.profession.isLoading
export const getPostIsLoading = state => state.profession.postIsLoading
export const getProfessionsIsloading = state => state.profession.getProfessionsIsloading
export const getCombinationUserProfessions = state => state.profession.combinationUserProfessions
export const getUserProfessions = state => state.profession.userProfessions

export const getUserProfessionsAllProfessionCategoryIds = state => {
    return state.profession.userProfessions.map((up) => up.userprofessionids)
}

export const getProfessionCategories = state => {
    const professionCategories = state.profession.professionCategories
    professionCategories.sort((a, b) => {

      if ( a.name.toLowerCase().trim() > b.name.toLowerCase().trim()){
          return  1
      } else if (a.name.toLowerCase().trim() === b.name.toLowerCase().trim())
      {
          return 0
      }
      return -1
    })
    return professionCategories
}

export const getProfessionCategoriesById = state => {
    return getById(state.profession.professionCategories)
}

export const getCombinationUserProfessionsById = state => {
    return getById(state.profession.combinationUserProfessions)
}

export const getIsSuccess = state => state.profession.isSuccess
export const getstatusCode = state => state.profession.statusCode
export const getSelectedProfessions = state => state.profession.selectedProfesion

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))