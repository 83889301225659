import React from "react";
import { useSelector } from "react-redux";
import {
    getUseridPublic,
} from "../../redux/reducers/profile";

//assets
import ProfImg from "../../assets/images/profileIcon.svg";

const UserColleagues = (props) => {
    const useridPublic = useSelector(getUseridPublic);
    return (
        <div className="margin--tb50">
                <div className="userColleagues">
                <h3>
                    Colleagues <span onClick={props?.onClickViewAll}>View All</span>
                </h3>
                <ul>
                    {props?.data?.slice(0,6)?.map((col,index)=>{
                        const name = col?.firstname + " " + col?.lastname
                        const pic = col?.profile_pic ? col?.profile_pic : ProfImg
                        const navigation_name = col?.navigation_name 
                        return (
                            <li onClick={() => props?.onClick(navigation_name)} key={index}>
                                <img src={pic} alt={name} />
                                {/* <h3>{name}</h3> */}
                                <div className="clear"></div>
                            </li>
                        )
                        })}
                </ul>
                <div className="clear"></div>
                <p className="userColleagues__connections">{props?.data?.length} <span>Connections</span></p>
                <div className="clear"></div>
                </div>
            </div>
        
    );
};

export default UserColleagues;
