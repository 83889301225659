import {
    APP_GET_INSTITUTION_TYPES_START,
    APP_GET_INSTITUTION_TYPES,
    APP_GET_INSTITUTION_TYPES_FAIL,
    APP_GET_INSTITUTION_SUBTYPES_START,
    APP_GET_INSTITUTION_SUBTYPES,
    APP_GET_INSTITUTION_SUBTYPES_FAIL,
    APP_GET_INSTITUTIONS_BY_TYPE_ID_START,
    APP_GET_INSTITUTIONS_BY_TYPE_ID,
    APP_GET_INSTITUTIONS_BY_TYPE_ID_FAIL,
    APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID_START,
    APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID,
    APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID_FAIL,
    APP_GET_INSTITUTIONS_START,
    APP_GET_INSTITUTIONS,
    APP_GET_INSTITUTIONS_FAIL,
    APP_POST_INSTITUTIONS_START,
    APP_POST_INSTITUTIONS,
    APP_POST_INSTITUTIONS_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'
import { getById } from '../../../../helpers/utils.js'

const initialState = {
    isLoading: false,
    isLoadingPost: false,
    institutionIsLoading: false,
    institutionSubtypesIsLoading: false,
    isLoadingSubType:false,
    institutionSubTypes:[],
    error: false,
    message: '',
    isSuccess: false,
    institutions:[],
    postInstitution:[],
    institutionTypes: [],
    institutionsByTypeId: [],
    institutionsSubtypesByTypeId:[],
    statusCode: 201
}


export const institution = (state = initialState, action) => {
    switch (action.type) {
        case APP_POST_INSTITUTIONS_START:
            return Object.assign({}, state, {
                isLoadingPost: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_INSTITUTIONS:
            return Object.assign({}, state, {
                postInstitution: action.data,
                isLoadingPost: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_INSTITUTIONS_FAIL:
            return Object.assign({}, state, {
                isLoadingPost: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_INSTITUTIONS_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_INSTITUTIONS:
            return Object.assign({}, state, {
                institutions: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_INSTITUTIONS_FAIL:
            return Object.assign({}, state, {
                institutions: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_INSTITUTION_TYPES_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_INSTITUTION_TYPES:
            return Object.assign({}, state, {
                institutionTypes: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_INSTITUTION_TYPES_FAIL:
            return Object.assign({}, state, {
                institutionTypes: [],
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_INSTITUTION_SUBTYPES_START:
            return Object.assign({}, state, {
                isLoadingSubType: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_INSTITUTION_SUBTYPES:
            return Object.assign({}, state, {
                institutionSubTypes: action.data,
                isLoadingSubType: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_INSTITUTION_SUBTYPES_FAIL:
            return Object.assign({}, state, {
                isLoadingSubType: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_INSTITUTIONS_BY_TYPE_ID_START:
            return Object.assign({}, state, {
                institutionIsLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_INSTITUTIONS_BY_TYPE_ID:
            return Object.assign({}, state, {
                institutionsByTypeId: action.data,
                institutionIsLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_INSTITUTIONS_BY_TYPE_ID_FAIL:
            return Object.assign({}, state, {
                institutionsByTypeId: [],
                institutionIsLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID_START:
            return Object.assign({}, state, {
                institutionSubtypesIsLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID:
            return Object.assign({}, state, {
                institutionsSubtypesByTypeId: action.data,
                institutionSubtypesIsLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_INSTITUTIONS_SUBTYPE_BY_TYPE_ID_FAIL:
            return Object.assign({}, state, {
                institutionsSubtypesByTypeId: [],
                institutionSubtypesIsLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.institution.error
export const getMsg = state => state.institution.message
export const getisLoading = state => state.institution.isLoading
export const getisLoadingPost = state => state.institution.isLoadingPost
export const getPostedInstitution = state => state.institution.postInstitution
export const getIsSuccess = state => state.institution.isSuccess
export const getstatusCode = state => state.institution.statusCode
export const getInstitutionTypes = state => state.institution.institutionTypes
export const getInstitutions = state => state.institution.institutions
export const getisLoadingSubType = state => state.institution.isLoadingSubType
export const getInstitutionSubTypes = state => state.institution.institutionSubTypes
export const getInstitutionsById = state => {
    return getById(state.institution.institutions)
}

export const getInstitutionsSubtypesByTypeIdIsLoading = state => state.institution.institutionSubtypesIsLoading
export const getInstitutionsSubtypesByTypeId = state => state.institution.institutionsSubtypesByTypeId

export const getInstitutionsSubtypesByTypeIdById = state => {
    return getById(state.institution.institutionsSubtypesByTypeId)
}

export const getInstitutionsByTypeIdIsLoading = state => state.institution.institutionIsLoading
export const getInstitutionsByTypeId = state => state.institution.institutionsByTypeId

export const getInstitutionsByTypeIdById = state => {
    return getById(state.institution.institutionsByTypeId)
}

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))