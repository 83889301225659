import {
    APP_SAVE_NOTIFICATION_TOKEN_ID,
    APP_SAVE_SELCTED_PROFESSION_CARD_ID,
    APP_SAVE_SELCTED_PROFESSION_CATEGORY_ID,
    APP_SAVE_SELCTED_USER_PROFESSION_CATEGORY_ID,
    APP_SAVE_PUBLIC_USER_ID,
    CLEAR_PUBLIC,
    CLEAR_USER_PROFESSION_DATA,
    LOGIN_POPUP_TAB,
    SHOW_LOGIN_POPUP,
    HIDE_LOGIN_POPUP,
    REGISTER_SUCCESS_POPUP,
    LOGIN_STATUS,
    DROPDOWN_STATUS,
    VIEW_ALL_COLLEAGUES_USERID,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    professionCardId: null,
    professionCategoryId: null,
    userProfessionCategoryId: null,
    publicUserId: null,
    publicStatus:false,
    notificationToken:"",
    loginPopUpStatus:false,
    loginStatus:false,
    loginPopUpTab:"login",
    dropdownStatus:false,
    registerSuceessPopUpStatus:false,
    navBarState:"explore",
    viewAllColleaguesUserId:null,
    viewAllColleaguesUserName:null
}

export const profileUi = (state = initialState, action) => {
    switch (action.type) {
        case APP_SAVE_NOTIFICATION_TOKEN_ID:
            return Object.assign({}, state, {
                notificationToken: action.data,
            })
        case APP_SAVE_SELCTED_PROFESSION_CARD_ID:
            return Object.assign({}, state, {
                professionCardId: action.data,
            })
        case APP_SAVE_SELCTED_PROFESSION_CATEGORY_ID:
            return Object.assign({}, state, {
                professionCategoryId: action.data,
            })
        case APP_SAVE_SELCTED_USER_PROFESSION_CATEGORY_ID:
            return Object.assign({}, state, {
                userProfessionCategoryId: action.data,
            })
        case APP_SAVE_PUBLIC_USER_ID:
            return Object.assign({}, state, {
                publicUserId: action.data,
                publicStatus:true
            })
        case CLEAR_USER_PROFESSION_DATA:
            return Object.assign({}, state, {
                professionCardId: null,
                professionCategoryId: null,
                userProfessionCategoryId: null,
            })
        case CLEAR_PUBLIC:
            return Object.assign({}, state, {
                publicUserId:null,
                publicStatus:false
            })
        case SHOW_LOGIN_POPUP:
            return Object.assign({}, state, {
                loginPopUpStatus:true
            })
        case LOGIN_POPUP_TAB:
            return Object.assign({}, state, {
                loginPopUpTab:action.data
            })
        case HIDE_LOGIN_POPUP:
            return Object.assign({}, state, {
                loginPopUpStatus: false
            })
        case LOGIN_STATUS:
            return Object.assign({}, state, {
                loginStatus: action.data
            })
        case REGISTER_SUCCESS_POPUP:
            return Object.assign({}, state, {
                registerSuceessPopUpStatus: action.data
            })
        case VIEW_ALL_COLLEAGUES_USERID:
            return Object.assign({}, state, {
                viewAllColleaguesUserId: action?.data?.id,
                viewAllColleaguesUserName: action.data?.name
            })
        case APP_LOGOUT:
            return Object.assign({}, state, {
                professionCardId: null,
                professionCategoryId: null,
                userProfessionCategoryId: null,
                publicUserId: null,
                publicStatus: false,
                notificationToken: "",
                loginStatus: false
            })
        case DROPDOWN_STATUS:
            return Object.assign({}, state, {
                dropdownStatus: action.data
            })
        default:
            return state
    }
}

export const getProfessionCardId = state => state.profileUi.professionCardId
export const getProfessionCategoryId = state => state.profileUi.professionCategoryId
export const getUserProfessionCategoryId = state => state.profileUi.userProfessionCategoryId
export const getPublicUserId = state => state.profileUi.publicUserId
export const getPublicStatus = state => state.profileUi.publicStatus
export const getNotificationToken = state => state.profileUi.notificationToken
export const getLoginPopUpStatus = state => state.profileUi.loginPopUpStatus
export const getLoginStatus = state => state.profileUi.loginStatus
export const getLoginPopUpTab = state => state.profileUi.loginPopUpTab
export const getDropdownStatus = state => state.profileUi.dropdownStatus
export const getRegisterSuceessPopUpStatus = state => state.profileUi.registerSuceessPopUpStatus
export const getViewAllColleaguesUserId = state => state.profileUi.viewAllColleaguesUserId
export const getViewAllColleaguesUserName = state => state.profileUi.viewAllColleaguesUserName

