import {
    SAVE_COMMISSIONEDWORK_CREATORS,
    SAVE_COMMISSIONEDWORK_OTHER_CREATORS,
    SAVE_COMMISSIONEDWORK_TITLE,
    SAVE_COMMISSIONEDWORK_TYPE,
    SAVE_COMMISSIONEDWORK_DATE,
    SAVE_COMMISSIONEDWORK_ASSOCIATION_USER,
    CLEAR_COMMISSIONEDWORK_ASSOCIATION_USER,
    CLEAR_COMMISSIONEDWORK,
    APP_LOGOUT
} from '../../../../actions'

const initialState = {
    CommissionedWorkCreators:[],
    CommissionedWorkOtherCreators:[],
    CommissionedWorkTitle:"",
    CommissionedWorkType:"",
    CommissionedWorkDate:"",
    associationUser:[]
}

export const CommissionedWorkUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_COMMISSIONEDWORK_CREATORS:
            return Object.assign({}, state, {
                CommissionedWorkCreators: action.data
            })
        case SAVE_COMMISSIONEDWORK_OTHER_CREATORS:
            return Object.assign({}, state, {
                CommissionedWorkOtherCreators: action.data
            })
        case SAVE_COMMISSIONEDWORK_TITLE:
            return Object.assign({}, state, {
                CommissionedWorkTitle: action.data
            })
        case SAVE_COMMISSIONEDWORK_TYPE:
            return Object.assign({}, state, {
                CommissionedWorkType: action.data
            })
        case SAVE_COMMISSIONEDWORK_DATE:
            return Object.assign({}, state, {
                CommissionedWorkDate: action.data
            })
        case SAVE_COMMISSIONEDWORK_ASSOCIATION_USER:
            return Object.assign({}, state, {
                associationUser: action.data
            })
        case CLEAR_COMMISSIONEDWORK_ASSOCIATION_USER:
            return Object.assign({}, state, {
                associationUser: []
            })
        case CLEAR_COMMISSIONEDWORK:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getCommissionedWorkCreators = state => state.CommissionedWorkUI.CommissionedWorkCreators
export const getCommissionedWorkOtherCreators = state => state.CommissionedWorkUI.CommissionedWorkOtherCreators
export const getCommissionedWorkTitle = state => state.CommissionedWorkUI.CommissionedWorkTitle
export const getCommissionedWorkType = state => state.CommissionedWorkUI.CommissionedWorkType
export const getCommissionedWorkDate = state => state.CommissionedWorkUI.CommissionedWorkDate
export const getAssociationUser = state => state.CommissionedWorkUI.associationUser