
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_GET_SEARCH_RESULTS_START,
    APP_GET_SEARCH_RESULTS,
    APP_GET_SEARCH_RESULTS_FAIL,
    APP_GET_SEARCH_TEMPLATE_START,
    APP_GET_SEARCH_TEMPLATE,
    APP_GET_SEARCH_TEMPLATE_FAIL,
    APP_GET_SEARCH_TOP_TEMPLATE_START,
    APP_GET_SEARCH_TOP_TEMPLATE,
    APP_GET_SEARCH_TOP_TEMPLATE_FAIL,
    APP_LOGOUT,
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

// general search
export const emitGetSearchResultStart = () => ({
    type: APP_GET_SEARCH_RESULTS_START
})

export const emitGetSearchResult = data => {
    return {
        type: APP_GET_SEARCH_RESULTS,
        data
    }
}

export const emitGetSearchResultFailed = (data) => ({
    type: APP_GET_SEARCH_RESULTS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getSearchResult = (searchString) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/cards/search/web/all/${searchString}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
}

export const startGetSearchResult = (data) => {
    return (dispatch) => {
        dispatch(emitGetSearchResultStart())
        return getSearchResult(data)
            .then(parseJSON)
            .then(json => {
                if (json.statusCode === 200) {
                    dispatch(emitGetSearchResult(json.data))
                } else {
                    dispatch(emitGetSearchResultFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitGetSearchResultFailed(err))
            })
    }

}

// get top profession searches
export const emitGetTopProfessionSearchResultStart = () => ({
    type: APP_GET_SEARCH_TOP_TEMPLATE_START
})

export const emitGetTopProfessionSearchResult = data => {
    return {
        type: APP_GET_SEARCH_TOP_TEMPLATE,
        data
    }
}

export const emitGetTopProfessionSearchResultFailed = (data) => ({
    type: APP_GET_SEARCH_TOP_TEMPLATE_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getTopProfessionSearchResult = () => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/cards/search/template/top`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
}

export const startGetTopProfessionSearchResult = () => {
    return (dispatch) => {
        dispatch(emitGetTopProfessionSearchResultStart())
        return getTopProfessionSearchResult()
            .then(parseJSON)
            .then(json => {
                if (json.statusCode === 201) {
                    dispatch(emitGetTopProfessionSearchResult(json.data))
                } else {
                    dispatch(emitGetTopProfessionSearchResultFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitGetTopProfessionSearchResultFailed(err))
            })
    }

}

//get profession cards by profession id
export const emitGetSearchTemplateStart = () => ({
    type: APP_GET_SEARCH_TEMPLATE_START
})

export const emitGetSearchTemplate = data => {
    return {
        type: APP_GET_SEARCH_TEMPLATE,
        data
    }
}

export const emitGetSearchTemplateFailed = (data) => ({
    type: APP_GET_SEARCH_TEMPLATE_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getSearchTemplate = ({ jwt, id }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/cards/search/template/id/${id}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetSearchTemplate = (data) => {
    return (dispatch) => {
        dispatch(emitGetSearchTemplateStart())
        return getSearchTemplate(data)
            .then(parseJSON)
            .then(json => {

                if (json.statusCode === 200) {
                    dispatch(emitGetSearchTemplate(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetSearchTemplateFailed(json))
                }
            })
            .catch(err => {


                dispatch(emitGetSearchTemplateFailed(err))
            })
    }

}

// get filtered profession cards by skill id
const getSearchTemplateBySkillIdFilters = ({ jwt, id, skillIds, availability, leastprice, highestprice, onlinestatus, travelstatus, lat, lng, gender }) => {
    let Authorization = composeAuth(jwt)

    let stringifySkillIds = skillIds ? encodeURI(JSON.stringify(skillIds)) : []
    let stringifyavailability = encodeURI(JSON.stringify(availability ? availability : []))
    let leastPrice = leastprice ? leastprice : 0
    let highestPrice = highestprice ? (highestprice === 500 ? 1000000 : highestprice) : 1000000
    let onlineStatus = onlinestatus ? onlinestatus : null
    let stringifyTravelStatus = encodeURI(JSON.stringify(travelstatus ? travelstatus : []))
    let latFinal = lat ? lat : 0
    let lngFinal = lng ? lng : 0
    let genderFinal = encodeURI(JSON.stringify(gender))
    let url = process.env.REACT_APP_SERVER + `/api/v1/cards/search/template/${id}/skills/${stringifySkillIds}/availability/${stringifyavailability}/leastprice/${leastPrice}/highestprice/${highestPrice}/online/${onlineStatus}/travel/${stringifyTravelStatus}/lat/${latFinal}/lng/${lngFinal}/gender/${genderFinal}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetSearchTemplateBySkillIdFilters = (data) => {
    return (dispatch) => {
        dispatch(emitGetSearchTemplateStart())
        return getSearchTemplateBySkillIdFilters(data)
            .then(parseJSON)
            .then(json => {
                // console.log(json,"startGetSearchTemplateBySkillIdFilters");

                if (json.statusCode === 200) {
                    dispatch(emitGetSearchTemplate(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetSearchTemplateFailed(json))
                }
            })
            .catch(err => {
                console.log(err, "error");

                dispatch(emitGetSearchTemplateFailed(err))
            })
    }

}