import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//assets
import Spinner from "../assets/molecules/spinner";
import { ProfessionCardCarousel } from "../assets/layouts/carousel/professionCardCarousel";

//actions
import { startGetRandomUserProfessionCards } from '../../redux/actions/profession/cards'

//reducers
import { getRandomUserCards, getRandomCardsIsloading } from '../../redux/reducers/profession/cards'

const UserCardsSection = () => {
    const dispatch = useDispatch()

    let history = useHistory()

    const userCards = useSelector(getRandomUserCards)
    const isLoadingAll = useSelector(getRandomCardsIsloading)

    useEffect(() => {
        dispatch(startGetRandomUserProfessionCards())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingAll ? <div className="container paddingContainer minHeightContainer">
            <Spinner />
        </div> : <div className="container">
                <ProfessionCardCarousel className="professional__group--extraTopMargin" header="Browse Services" data={userCards}/>
                <div className="clear"></div>
            </div>
    );
};

export default UserCardsSection;
