import {
    APP_POST_CONTRACT_START,
    APP_POST_CONTRACT,
    APP_POST_CONTRACT_FAIL,
    APP_POST_CONTRACT_REQUIRED_EVENTS_START,
    APP_POST_CONTRACT_REQUIRED_EVENTS,
    APP_POST_CONTRACT_REQUIRED_EVENTS_FAIL,
    APP_POST_CONTRACT_FLEXIBLE_EVENTS_START,
    APP_POST_CONTRACT_FLEXIBLE_EVENTS,
    APP_POST_CONTRACT_FLEXIBLE_EVENTS_FAIL,
    APP_POST_CONTRACT_PAYMENT_INSTALLMENTS_START,
    APP_POST_CONTRACT_PAYMENT_INSTALLMENTS,
    APP_POST_CONTRACT_PAYMENT_INSTALLMENTS_FAIL,
    APP_POST_CONTRACT_RIDERS_START,
    APP_POST_CONTRACT_RIDERS,
    APP_POST_CONTRACT_RIDERS_FAIL,
    APP_GET_CONTRACT_RIDER_TYPES_START,
    APP_GET_CONTRACT_RIDER_TYPES,
    APP_GET_CONTRACT_RIDER_TYPES_FAIL,
    APP_GET_CONTRACT_RIDER_SUBTYPES_START,
    APP_GET_CONTRACT_RIDER_SUBTYPES,
    APP_GET_CONTRACT_RIDER_SUBTYPES_FAIL,
    APP_SEND_OFFER_START,
    APP_SEND_OFFER,
    APP_SEND_OFFER_FAIL,
    APP_GET_SENT_OFFERS_START,
    APP_GET_SENT_RECEIVED_OFFERS,
    APP_GET_SENT_OFFERS_FAIL,
    APP_GET_RECEIVED_OFFERS_START,
    APP_GET_RECEIVED_OFFERS_FAIL,
    APP_GET_DRAFT_OFFERS_START,
    APP_GET_DRAFT_OFFERS_FAIL,
    APP_PUT_NEGOTIATE_OFFER_START,
    APP_PUT_NEGOTIATE_OFFER,
    APP_PUT_NEGOTIATE_OFFER_FAIL,
    APP_ACCEPT_OFFER_START,
    APP_ACCEPT_OFFER,
    APP_ACCEPT_OFFER_FAIL,
    APP_REJECT_OFFER_START,
    APP_REJECT_OFFER,
    APP_REJECT_OFFER_FAIL,
    APP_FINALIZE_OFFER_START,
    APP_FINALIZE_OFFER,
    APP_FINALIZE_OFFER_FAIL,
    APP_RESCIND_OFFER_START,
    APP_RESCIND_OFFER,
    APP_RESCIND_OFFER_FAIL,
    APP_GET_SENT_CONTRACTS_START,
    APP_GET_SENT_RECEIVED_CONTRACTS,
    APP_GET_SENT_CONTRACTS_FAIL,
    APP_GET_RECEIVED_CONTRACTS_START,
    APP_GET_RECEIVED_CONTRACTS_FAIL,
    APP_SEND_CONTRACT_SEEN_START,
    APP_SEND_CONTRACT_SEEN,
    APP_SEND_CONTRACT_SEEN_FAIL,
    APP_RECEIVED_CONTRACT_SEEN_START,
    APP_RECEIVED_CONTRACT_SEEN,
    APP_RECEIVED_CONTRACT_SEEN_FAIL,
    CLEAR_PAYMENT_MAIN,
    APP_LOGOUT,
} from '../../actions'

import { getById } from '../../../helpers/utils'

const initialState = {
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    contract:{},
    isLoadingPost:false,
    riderTypes:[],
    riderSubtypes:[],
    isLoadingRiderTypes:false,
    isLoadingRiderSubTypes:false,
    isLoadingPostFlexibleEvents:false,
    isLoadingPostPaymentInstallments:false,
    isLoadingPostRiders:false,
    isLoadingPostRequiredEvents:false,
    isLoadingSendContractOffer:false,
    isLoadingGetSentOffers:false,
    isLoadingGetReceivedOffers:false,
    isLoadingGetDraftOffers:false,
    sentReceivedOffers:[],
    isLoadingNegotiateOffer:false,
    isLoadingAcceptOffer:false,
    isLoadingRejectOffer:false,
    isLoadingFinalizeOffer:false,
    isLoadingRescindOffer:false,
    isLoadingGetSentContracts: false,
    isLoadingGetReceivedContracts: false,
    isLoadingPutSentContractsSeen: false,
    isLoadingPutReceivedContractsSeen: false,
    sentReceivedContracts: [],
}


export const contracts = (state = initialState, action) => {
    switch (action.type) {
        case APP_RECEIVED_CONTRACT_SEEN_START:
            return Object.assign({}, state, {
                isLoadingPutReceivedContractsSeen: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_RECEIVED_CONTRACT_SEEN:
            return Object.assign({}, state, {
                isLoadingPutReceivedContractsSeen: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_RECEIVED_CONTRACT_SEEN_FAIL:
            return Object.assign({}, state, {
                isLoadingPutReceivedContractsSeen: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_SEND_CONTRACT_SEEN_START:
            return Object.assign({}, state, {
                isLoadingPutSentContractsSeen: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_SEND_CONTRACT_SEEN:
            return Object.assign({}, state, {
                isLoadingPutSentContractsSeen: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_SEND_CONTRACT_SEEN_FAIL:
            return Object.assign({}, state, {
                isLoadingPutSentContractsSeen: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_RESCIND_OFFER_START:
            return Object.assign({}, state, {
                isLoadingRescindOffer: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_RESCIND_OFFER:
            return Object.assign({}, state, {
                isLoadingRescindOffer: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_RESCIND_OFFER_FAIL:
            return Object.assign({}, state, {
                isLoadingRescindOffer: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_FINALIZE_OFFER_START:
            return Object.assign({}, state, {
                isLoadingFinalizeOffer: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_FINALIZE_OFFER:
            return Object.assign({}, state, {
                isLoadingFinalizeOffer: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_FINALIZE_OFFER_FAIL:
            return Object.assign({}, state, {
                isLoadingFinalizeOffer: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })

        case APP_GET_SENT_CONTRACTS_START:
            return Object.assign({}, state, {
                isLoadingGetSentContracts: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SENT_RECEIVED_CONTRACTS:
            return Object.assign({}, state, {
                sentReceivedContracts:action.data,
                isLoadingGetSentContracts: false,
                isLoadingGetReceivedContracts: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SENT_CONTRACTS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetSentContracts: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_RECEIVED_CONTRACTS_START:
            return Object.assign({}, state, {
                isLoadingGetReceivedContracts: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_RECEIVED_CONTRACTS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetReceivedContracts: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })

        case APP_GET_SENT_OFFERS_START:
            return Object.assign({}, state, {
                isLoadingGetSentOffers: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SENT_RECEIVED_OFFERS:
            return Object.assign({}, state, {
                sentReceivedOffers:action.data,
                isLoadingGetSentOffers: false,
                isLoadingGetReceivedOffers: false,
                isLoadingGetDraftOffers: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SENT_OFFERS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetSentOffers: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_RECEIVED_OFFERS_START:
            return Object.assign({}, state, {
                isLoadingGetReceivedOffers: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_RECEIVED_OFFERS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetReceivedOffers: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_DRAFT_OFFERS_START:
            return Object.assign({}, state, {
                isLoadingGetDraftOffers: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_DRAFT_OFFERS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetDraftOffers: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_SEND_OFFER_START:
            return Object.assign({}, state, {
                isLoadingSendContractOffer: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_SEND_OFFER:
            return Object.assign({}, state, {
                isLoadingSendContractOffer: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_SEND_OFFER_FAIL:
            return Object.assign({}, state, {
                isLoadingSendContractOffer: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_CONTRACT_RIDER_TYPES_START:
            return Object.assign({}, state, {
                isLoadingRiderTypes: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_CONTRACT_RIDER_TYPES:
            return Object.assign({}, state, {
                riderTypes: action.data,
                isLoadingRiderTypes: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_CONTRACT_RIDER_TYPES_FAIL:
            return Object.assign({}, state, {
                isLoadingRiderTypes: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_CONTRACT_RIDER_SUBTYPES_START:
            return Object.assign({}, state, {
                isLoadingRiderSubTypes: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_CONTRACT_RIDER_SUBTYPES:
            return Object.assign({}, state, {
                riderSubtypes: action.data,
                isLoadingRiderSubTypes: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_CONTRACT_RIDER_SUBTYPES_FAIL:
            return Object.assign({}, state, {
                isLoadingRiderSubTypes: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_CONTRACT_START:
            return Object.assign({}, state, {
                isLoadingPost: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_CONTRACT:
            return Object.assign({}, state, {
                contract: Array.isArray(action.data) ? action.data[0] : action.data,
                isLoadingPost: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_CONTRACT_FAIL:
            return Object.assign({}, state, {
                isLoadingPost: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_CONTRACT_REQUIRED_EVENTS_START:
            return Object.assign({}, state, {
                isLoadingPostRequiredEvents: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_CONTRACT_REQUIRED_EVENTS:
            return Object.assign({}, state, {
                contract: Array.isArray(action.data) ? action.data[0] : action.data,
                isLoadingPostRequiredEvents: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_CONTRACT_REQUIRED_EVENTS_FAIL:
            return Object.assign({}, state, {
                isLoadingPostRequiredEvents: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_CONTRACT_FLEXIBLE_EVENTS_START:
            return Object.assign({}, state, {
                isLoadingPostFlexibleEvents: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_CONTRACT_FLEXIBLE_EVENTS:
            return Object.assign({}, state, {
                contract: Array.isArray(action.data) ? action.data[0] : action.data,
                isLoadingPostFlexibleEvents: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_CONTRACT_FLEXIBLE_EVENTS_FAIL:
            return Object.assign({}, state, {
                isLoadingPostFlexibleEvents: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_CONTRACT_PAYMENT_INSTALLMENTS_START:
            return Object.assign({}, state, {
                isLoadingPostPaymentInstallments: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_CONTRACT_PAYMENT_INSTALLMENTS:
            return Object.assign({}, state, {
                contract: Array.isArray(action.data) ? action.data[0] : action.data,
                isLoadingPostPaymentInstallments: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_CONTRACT_PAYMENT_INSTALLMENTS_FAIL:
            return Object.assign({}, state, {
                isLoadingPostPaymentInstallments: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_CONTRACT_RIDERS_START:
            return Object.assign({}, state, {
                isLoadingPostRiders: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_CONTRACT_RIDERS:
            return Object.assign({}, state, {
                contract: Array.isArray(action.data) ? action.data[0] : action.data,
                isLoadingPostRiders: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_CONTRACT_RIDERS_FAIL:
            return Object.assign({}, state, {
                isLoadingPostRiders: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })   
        case APP_PUT_NEGOTIATE_OFFER_START:
            return Object.assign({}, state, {
                isLoadingNegotiateOffer: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_NEGOTIATE_OFFER:
            return Object.assign({}, state, {
                isLoadingNegotiateOffer: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_NEGOTIATE_OFFER_FAIL:
            return Object.assign({}, state, {
                isLoadingNegotiateOffer: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_ACCEPT_OFFER_START:
            return Object.assign({}, state, {
                isLoadingAcceptOffer: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_ACCEPT_OFFER:
            return Object.assign({}, state, {
                isLoadingAcceptOffer: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_ACCEPT_OFFER_FAIL:
            return Object.assign({}, state, {
                isLoadingAcceptOffer: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_REJECT_OFFER_START:
            return Object.assign({}, state, {
                isLoadingRejectOffer: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_REJECT_OFFER:
            return Object.assign({}, state, {
                isLoadingRejectOffer: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_REJECT_OFFER_FAIL:
            return Object.assign({}, state, {
                isLoadingRejectOffer: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case CLEAR_PAYMENT_MAIN:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.contracts.error
export const getMsg = state => state.contracts.message
export const getIsSuccess = state => state.contracts.isSuccess
export const getstatusCode = state => state.contracts.statusCode
export const getContract = state => state.contracts.contract
export const getisLoadingPost = state => state.contracts.isLoadingPost
export const getRiderTypes = state => state.contracts.riderTypes
export const getRiderSubtypes = state => state.contracts.riderSubtypes
export const getisLoadingRiderTypes = state => state.contracts.isLoadingRiderTypes
export const getisLoadingRiderSubTypes = state => state.contracts.isLoadingRiderSubTypes
export const getisLoadingPostFlexibleEvents = state => state.contracts.isLoadingPostFlexibleEvents
export const getisLoadingPostPaymentInstallments = state => state.contracts.isLoadingPostPaymentInstallments
export const getisLoadingPostRiders = state => state.contracts.isLoadingPostRiders
export const getisLoadingPostRequiredEvents = state => state.contracts.isLoadingPostRequiredEvents
export const getisLoadingSendContractOffer = state => state.contracts.isLoadingSendContractOffer
export const getisLoadingGetSentOffers = state => state.contracts.isLoadingGetSentOffers
export const getisLoadingGetReceivedOffers = state => state.contracts.isLoadingGetReceivedOffers
export const getsentReceivedOffers = state => state.contracts.sentReceivedOffers
export const getisLoadingNegotiateOffer = state => state.contracts.isLoadingNegotiateOffer
export const getisLoadingAcceptOffer = state => state.contracts.isLoadingAcceptOffer
export const getisLoadingRejectOffer = state => state.contracts.isLoadingRejectOffer
export const getisLoadingFinalizeOffer = state => state.contracts.isLoadingFinalizeOffer
export const getisLoadingRescindOffer = state => state.contracts.isLoadingRescindOffer
export const getisLoadingGetSentContracts = state => state.contracts.isLoadingGetSentContracts
export const getisLoadingGetReceivedContracts = state => state.contracts.isLoadingGetReceivedContracts
export const getSentReceivedContracts = state => state.contracts.sentReceivedContracts
export const getisLoadingGetDraftOffers = state => state.contracts.isLoadingGetDraftOffers

export const getisLoadingPutSentContractsSeen = state => state.contracts.isLoadingPutSentContractsSeen
export const getisLoadingPutReceivedContractsSeen = state => state.contracts.isLoadingPutReceivedContractsSeen

export const getRiderTypesById = state => {
    return getById(state.contracts.riderTypes)
}

export const getRiderSubtypesById = state => {
    return getById(state.contracts.riderSubtypes)
}