import {
    APP_GET_EVENTS_BY_INSTUTUTIONID_START,
    APP_GET_EVENTS_BY_INSTUTUTIONID,
    APP_GET_EVENTS_BY_INSTUTUTIONID_FAIL,
    APP_GET_EVENT_TYPES_START,
    APP_GET_EVENT_TYPES,
    APP_GET_EVENT_TYPES_FAIL,
    APP_GET_EVENT_SUBTYPES_START,
    APP_GET_EVENT_SUBTYPES,
    APP_GET_EVENT_SUBTYPES_FAIL,
    APP_POST_EVENT_START,
    APP_POST_EVENT,
    APP_POST_EVENT_FAIL,
    APP_POST_NEW_EVENT_START,
    APP_POST_NEW_EVENT,
    APP_POST_NEW_EVENT_FAIL,
    APP_GET_EVENT_START,
    APP_GET_EVENT,
    APP_GET_EVENT_FAIL,
    APP_GET_PERFORMANCE_DETAILS_START,
    APP_GET_PERFORMANCE_DETAILS,
    APP_GET_PERFORMANCE_DETAILS_FAIL,
    APP_PUT_EVENT_START,
    APP_PUT_EVENT,
    APP_PUT_EVENT_FAIL,
    APP_EVENT_REASSIGN_PROFESSIONS_START,
    APP_EVENT_REASSIGN_PROFESSIONS,
    APP_EVENT_REASSIGN_PROFESSIONS_FAIL,
    APP_GET_PENDING_HIDDEN_START,
    APP_GET_PENDING_HIDDEN,
    APP_GET_PENDING_HIDDEN_FAIL,
    APP_GET_PENDING_HIDDEN_STATUS_START,
    APP_GET_PENDING_HIDDEN_STATUS,
    APP_GET_PENDING_HIDDEN_STATUS_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'
import { getById } from '../../../../helpers/utils'

const initialState = {
    isLoadingGetEventsByInstitutionId: false,
    isLoadingGet: false,
    isLoadingPut: false,
    isLoadingTypes: false,
    isLoadingSubTypes: false,
    isLoadingPost: false,
    isLoadingPostNew: false,
    isLoadingGetPerformanceDetails: false,
    isLoadingProfessionReassign: false,
    events: [],
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
    eventTypes:[],
    eventSubTypes:[],
    postedEvent:[],
    eventDetails:{},
    performanceDetails:{},
    isLoadingHiddenPendingEventCards:false,
    hiddenPendingEventCards:[],
    hiddenPendingEventCardsStatus:false,

}


export const Events = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_PENDING_HIDDEN_STATUS_START:
            return Object.assign({}, state, {
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_PENDING_HIDDEN_STATUS:
            return Object.assign({}, state, {
                hiddenPendingEventCardsStatus:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_PENDING_HIDDEN_STATUS_FAIL:
            return Object.assign({}, state, {
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_PENDING_HIDDEN_START:
            return Object.assign({}, state, {
                isLoadingHiddenPendingEventCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_PENDING_HIDDEN:
            return Object.assign({}, state, {
                isLoadingHiddenPendingEventCards: false,
                hiddenPendingEventCards:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_PENDING_HIDDEN_FAIL:
            return Object.assign({}, state, {
                isLoadingHiddenPendingEventCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_EVENT_REASSIGN_PROFESSIONS_START:
            return Object.assign({}, state, {
                isLoadingProfessionReassign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_EVENT_REASSIGN_PROFESSIONS:
            return Object.assign({}, state, {
                isLoadingProfessionReassign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_EVENT_REASSIGN_PROFESSIONS_FAIL:
            return Object.assign({}, state, {
                isLoadingProfessionReassign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_PERFORMANCE_DETAILS_START:
            return Object.assign({}, state, {
                isLoadingGetPerformanceDetails: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_PERFORMANCE_DETAILS:
            return Object.assign({}, state, {
                isLoadingGetPerformanceDetails: false,
                performanceDetails:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_PERFORMANCE_DETAILS_FAIL:
            return Object.assign({}, state, {
                isLoadingGetPerformanceDetails: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_EVENT_START:
            return Object.assign({}, state, {
                isLoadingPut: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_EVENT:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_EVENT_FAIL:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_EVENT_START:
            return Object.assign({}, state, {
                isLoadingGet: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_EVENT:
            return Object.assign({}, state, {
                eventDetails: action.data,
                isLoadingGet: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_EVENT_FAIL:
            return Object.assign({}, state, {
                isLoadingGet: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_EVENT_START:
            return Object.assign({}, state, {
                isLoadingPost: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_EVENT:
            return Object.assign({}, state, {
                postedEvent: action.data,
                isLoadingPost: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_EVENT_FAIL:
            return Object.assign({}, state, {
                isLoadingPost: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_NEW_EVENT_START:
            return Object.assign({}, state, {
                isLoadingPostNew: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_NEW_EVENT:
            return Object.assign({}, state, {
                postedEvent: action.data,
                isLoadingPostNew: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_NEW_EVENT_FAIL:
            return Object.assign({}, state, {
                isLoadingPostNew: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_EVENTS_BY_INSTUTUTIONID_START:
            return Object.assign({}, state, {
                isLoadingGetEventsByInstitutionId: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_EVENTS_BY_INSTUTUTIONID:
            return Object.assign({}, state, {
                events: action.data,
                isLoadingGetEventsByInstitutionId: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_EVENTS_BY_INSTUTUTIONID_FAIL:
            return Object.assign({}, state, {
                isLoadingGetEventsByInstitutionId: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_EVENT_TYPES_START:
            return Object.assign({}, state, {
                isLoadingTypes: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_EVENT_TYPES:
            return Object.assign({}, state, {
                eventTypes: action.data,
                isLoadingTypes: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_EVENT_TYPES_FAIL:
            return Object.assign({}, state, {
                isLoadingTypes: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_EVENT_SUBTYPES_START:
            return Object.assign({}, state, {
                isLoadingSubTypes: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_EVENT_SUBTYPES:
            return Object.assign({}, state, {
                eventSubTypes: action.data,
                isLoadingSubTypes: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_EVENT_SUBTYPES_FAIL:
            return Object.assign({}, state, {
                isLoadingSubTypes: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.Events.error
export const getMsg = state => state.Events.message
export const getisLoadingGetEventsByInstitutionId = state => state.Events.isLoadingGetEventsByInstitutionId
export const getIsSuccess = state => state.Events.isSuccess
export const getstatusCode = state => state.Events.statusCode
export const getEvents = state => state.Events.events
export const getisLoadingTypes = state => state.Events.isLoadingTypes
export const getisLoadingSubTypes = state => state.Events.isLoadingSubTypes
export const getEventTypes = state => {
    state.Events.eventTypes.sort((a,b) => {
        if(a.name > b.name){
            return 1
        }else{
            return -1
        }
    })
    return (state.Events.eventTypes)    
}
export const getEventSubTypes = state => {
    state.Events.eventSubTypes.sort((a, b) => {
        if (a.name > b.name) {
            return 1
        } else {
            return -1
        }
    })
    return (state.Events.eventSubTypes)
}
export const getisLoadingPost = state => state.Events.isLoadingPost
export const getisLoadingPostNew = state => state.Events.isLoadingPostNew
export const getPostedEvent = state => state.Events.postedEvent
export const getisLoadingGet = state => state.Events.isLoadingGet
export const getisLoadingPut = state => state.Events.isLoadingPut
export const getEventDetails = state => state.Events.eventDetails
export const getisLoadingGetPerformanceDetails = state => state.Events.isLoadingGetPerformanceDetails
export const getPerformanceDetails = state => state.Events.performanceDetails
export const getisLoadingProfessionReassign = state => state.Events.isLoadingProfessionReassign
export const getisLoadingHiddenPendingEventCards = state => state.Events.isLoadingHiddenPendingEventCards
export const getHiddenPendingEventCards = state => state.Events.hiddenPendingEventCards
export const getHiddenPendingEventCardsStatus = state => state.Events.hiddenPendingEventCardsStatus

export const getEventsById = state => {
    return getById(state.Events.events)
}
export const getEventTypessById = state => {
    return getById(state.Events.eventTypes)
}
export const getEventSubTypesById = state => {
    return getById(state.Events.eventSubTypes)
}

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
