import {
    APP_CREATE_TRAINING_PROGRAM_START,
    APP_CREATE_TRAINING_PROGRAM,
    APP_CREATE_TRAINING_PROGRAM_FAIL,
    APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_START,
    APP_CREATE_TRAINING_PROGRAM_COLLEAGUES,
    APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_FAIL,
    APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_START,
    APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO,
    APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_FAIL,
    APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_START,
    APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS,
    APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_FAIL,
    APP_LOGOUT,
} from '../../../actions'

import { createSelector } from 'reselect'

const initialState = {
    isLoading: false,
    isLoadingColleagues:false,
    isLoadingAdditionalInfo:false,
    isLoadingProgramToProfessionCards:false,
    error: false,
    message: '',
    isSuccess: false,
    program:{},
    statusCode: 201
}


export const trainingProgram = (state = initialState, action) => {
    switch (action.type) {
        case APP_CREATE_TRAINING_PROGRAM_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_TRAINING_PROGRAM:
            return Object.assign({}, state, {
                program: action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_TRAINING_PROGRAM_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_START:
            return Object.assign({}, state, {
                isLoadingColleagues: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_TRAINING_PROGRAM_COLLEAGUES:
            console.log(action.data,"action.data");
            return Object.assign({}, state, {
                isLoadingColleagues: false,
                program: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_TRAINING_PROGRAM_COLLEAGUES_FAIL:
            return Object.assign({}, state, {
                isLoadingColleagues: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_START:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                program: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_TRAINING_PROGRAM_ADDITIONAL_INFO_FAIL:
            return Object.assign({}, state, {
                isLoadingAdditionalInfo: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                isLoadingProgramToProfessionCards: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS:
            return Object.assign({}, state, {
                isLoadingProgramToProfessionCards: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_CREATE_TRAINING_PROGRAM_TO_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                isLoadingProgramToProfessionCards: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.trainingProgram.error
export const getMsg = state => state.trainingProgram.message
export const getisLoading = state => state.trainingProgram.isLoading
export const getIsSuccess = state => state.trainingProgram.isSuccess
export const getstatusCode = state => state.trainingProgram.statusCode
export const getProgram = state => state.trainingProgram.program
export const getisLoadingColleagues = state => state.trainingProgram.isLoadingColleagues
export const getisLoadingAdditionalInfo = state => state.trainingProgram.isLoadingAdditionalInfo
export const getisLoadingProgramToProfessionCards = state => state.trainingProgram.isLoadingProgramToProfessionCards

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))
