import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//assets
import Spinner from "../assets/molecules/spinner";
import { AnnouncementsCarousel } from "../assets/layouts/carousel/announcementsCarousel";

//actions
import { startGetAnnouncements } from '../../redux/actions/announcements'

//reducers
import { getisLoadingGetAnnouncements, getAnnouncements } from '../../redux/reducers/announcements'

//helpers
import { OpenLink } from '../../helpers/utils'

const AnnouncementsSection = () => {
    const dispatch = useDispatch()

    let history = useHistory()

    const announcements = useSelector(getAnnouncements)
    const isLoadingAll = useSelector(getisLoadingGetAnnouncements)

    useEffect(() => {
        dispatch(startGetAnnouncements())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        isLoadingAll ? <div className="container paddingContainer minHeightContainer">
            <Spinner />
        </div> : <div className="container">
                {announcements && announcements?.length ? <AnnouncementsCarousel className="professional__group--extraTopMargin" header="Announcements" data={announcements} onClick={(url) => { OpenLink(url) }} /> : null}
                <div className="clear"></div>
            </div>
    );
};

export default AnnouncementsSection;
