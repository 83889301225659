import {
    SAVE_QUOTES_PROFESSION_CARDS,
    SAVE_QUOTES_EXCERPT,
    SAVE_QUOTES_AUTHOR,
    SAVE_QUOTES_PUBLICATION_NAME,
    SAVE_QUOTES_PUBLICATION_LINK,
    CLEAR_QUOTES,
    APP_LOGOUT
} from '../../../actions'

const initialState = {
    professionCardIds: [],
    excerpt:"",
    author:"",
    publicationName:"",
    publicationLink:""
}

export const quotesUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_QUOTES_PROFESSION_CARDS:
            return Object.assign({}, state, {
                professionCardIds: action.data
            })
        case SAVE_QUOTES_EXCERPT:
            return Object.assign({}, state, {
                excerpt: action.data
            })
        case SAVE_QUOTES_AUTHOR:
            return Object.assign({}, state, {
                author: action.data
            })
        case SAVE_QUOTES_PUBLICATION_NAME:
            return Object.assign({}, state, {
                publicationName: action.data
            })
        case SAVE_QUOTES_PUBLICATION_LINK:
            return Object.assign({}, state, {
                publicationLink: action.data
            })
        case CLEAR_QUOTES:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getProfessionCardIds = state => state.quotesUI.professionCardIds
export const getExcerpt = state => state.quotesUI.excerpt
export const getAuthor = state => state.quotesUI.author
export const getPublicationName = state => state.quotesUI.publicationName
export const getPublicationLink = state => state.quotesUI.publicationLink
