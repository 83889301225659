import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";

//assets
import Header from "../assets/layouts/header/dashboardHeader";
import SearchTab from "./searchResultTab";

//reducers
import { getSearchText } from '../../redux/reducers/ui/search'
import { changeDropdownStatus } from '../../redux/actions/ui/profile'

const SearchResult = () => {
  const dispatch = useDispatch()
  const text = useSelector(getSearchText)

  let location = useLocation();
  let tab = location?.state?.tab;

  const [finalTab, setFinalTab] = useState(tab)

    return (
      <div className="dashboard" onClick={() => dispatch(changeDropdownStatus(false))}>
        <div className="main__dashboard bgLines searchResult__wrapper">
          <Header
          heading='Explore > Your Search Results for '
          category={'"' + text + '"'}
          results
          onSearchViewAll={(data) => setFinalTab(data)}
          />
          <SearchTab searchText tab={finalTab}/>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    );
};

export default SearchResult;