import React,{useRef} from "react";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom'

// assets
import LeftArrow from '../../../../assets/images/arrowLeft.svg'
import RightArrow from '../../../../assets/images/arrowRight.svg'

export const AnnouncementsCarousel = (props) => {
    let SliderRef = useRef(null)
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const next = () => {
        SliderRef.current.slickNext();
    }
    const previous = () => {
        SliderRef.current.slickPrev();
    }

    const sortedData = props?.data?.sort((a,b) => b.id - a.id)

    return (
        <div className={"professional__group " + props?.className}>
            {sortedData && sortedData?.length ? <h2>{props?.header}</h2> : null}
            <div className="press" >
                <div style={{cursor: "pointer"}}>
                <Slider {...settings} ref={SliderRef} className="announcemnts__slider">
                    {sortedData?.map((announcement, index) => {
                        return (
                            
                                <img key={index} src={announcement?.image} onClick={() => props?.onClick(announcement?.redirect_url)}  />
                           
                        )
                    })}
                </Slider>
                </div>
                {props?.data?.length > 1 && <div className="pressArrows">
                    <div onClick={previous} className="pressArrows__left addPointer">
                        <img alt="left arrow" src={LeftArrow} />
                    </div>
                    <div className="pressArrows__right" onClick={next}>
                        <img alt="right arrow" src={RightArrow} className="pressArrows--right addPointer" />
                    </div>
                </div>}
            </div>
        </div>
    )
}