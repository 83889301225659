import React from "react";

// assets
import SearchEmptyImg from '../../../../assets/images/searchEmpty.svg'

export const SearchEmpty = (props) => {
    return (
        <div className="searchEmpty">
            <p className="searchEmpty__text">{props?.text ? props?.text : "No results. Please try searching for something else."}</p>
            <img alt="search empty" src={props?.image ? props?.image : SearchEmptyImg} className="searchEmpty__image"/>
            <div className="clear"></div>
        </div>
    )
}