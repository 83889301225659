import {
    APP_UNASSIGN_UPCOMING_START,
    APP_UNASSIGN_UPCOMING,
    APP_UNASSIGN_UPCOMING_FAIL,
    APP_GET_CARD_UPCOMING_START,
    APP_GET_CARD_UPCOMING,
    APP_GET_CARD_UPCOMING_FAIL,
    APP_GET_UPCOMING_START,
    APP_GET_UPCOMING,
    APP_GET_UPCOMING_FAIL,
    APP_PUT_UPCOMING_START,
    APP_PUT_UPCOMING,
    APP_PUT_UPCOMING_FAIL,
    APP_ASSIGN_UPCOMING_START,
    APP_ASSIGN_UPCOMING,
    APP_ASSIGN_UPCOMING_FAIL,
    APP_LOGOUT,
} from '../../../actions'


const initialState = {
    allupcomings: [],
    cardupcomings: [],
    isLoading: false,
    isLoadingAssign: false,
    isLoadingPut: false,
    isLoadingAll: false,
    isDeleteLoading: false,
    isLoadingUnassign: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const upcomingEdit = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_CARD_UPCOMING_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_CARD_UPCOMING:
            return Object.assign({}, state, {
                cardupcomings: action.data ? action.data:[],
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_CARD_UPCOMING_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_UPCOMING_START:
            return Object.assign({}, state, {
                isLoadingAll: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_UPCOMING:
            return Object.assign({}, state, {
                allupcomings: action.data ? action.data:[],
                isLoadingAll: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_UPCOMING_FAIL:
            return Object.assign({}, state, {
                isLoadingAll: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_UNASSIGN_UPCOMING_START:
            return Object.assign({}, state, {
                isLoadingUnassign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_UNASSIGN_UPCOMING:
            return Object.assign({}, state, {
                isLoadingUnassign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_UNASSIGN_UPCOMING_FAIL:
            return Object.assign({}, state, {
                isLoadingUnassign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_UPCOMING_START:
            return Object.assign({}, state, {
                isLoadingPut: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_UPCOMING:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_UPCOMING_FAIL:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_ASSIGN_UPCOMING_START:
            return Object.assign({}, state, {
                isLoadingAssign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_ASSIGN_UPCOMING:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_ASSIGN_UPCOMING_FAIL:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.upcomingEdit.error
export const getMsg = state => state.upcomingEdit.message
export const getisLoading = state => state.upcomingEdit.isLoading
export const getisLoadingAll = state => state.upcomingEdit.isLoadingAll
export const getisLoadingUnassign = state => state.upcomingEdit.isLoadingUnassign
export const getisLoadingAssign = state => state.upcomingEdit.isLoadingAssign
export const getisDeleteLoading = state => state.upcomingEdit.isDeleteLoading
export const getisLoadingPut = state => state.upcomingEdit.isLoadingPut
export const getCardUpcomings = state => state.upcomingEdit.cardupcomings
export const getAllupcomings = state => state.upcomingEdit.allupcomings
export const getIsSuccess = state => state.upcomingEdit.isSuccess
export const getstatusCode = state => state.upcomingEdit.statusCode

