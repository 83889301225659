import {
    APP_GET_SEARCH_RESULTS_START,
    APP_GET_SEARCH_RESULTS,
    APP_GET_SEARCH_RESULTS_FAIL,
    APP_GET_SEARCH_TEMPLATE_START,
    APP_GET_SEARCH_TEMPLATE,
    APP_GET_SEARCH_TEMPLATE_FAIL,
    APP_GET_SEARCH_TOP_TEMPLATE_START,
    APP_GET_SEARCH_TOP_TEMPLATE,
    APP_GET_SEARCH_TOP_TEMPLATE_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    searchResults: [],
    templateProfessionCards: [],
    toptemplates:[],
    isLoadingSearchResults: false,
    isLoadingTopSearchTemplate: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const search = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_SEARCH_TOP_TEMPLATE_START:
            return Object.assign({}, state, {
                isLoadingTopSearchTemplate: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SEARCH_TOP_TEMPLATE:
            return Object.assign({}, state, {
                toptemplates: action.data,
                isLoadingTopSearchTemplate: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SEARCH_TOP_TEMPLATE_FAIL:
            return Object.assign({}, state, {
                isLoadingTopSearchTemplate: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_SEARCH_TEMPLATE_START:
            return Object.assign({}, state, {
                isLoadingSearchTemplate: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SEARCH_TEMPLATE:
            return Object.assign({}, state, {
                templateProfessionCards: action.data,
                isLoadingSearchTemplate: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SEARCH_TEMPLATE_FAIL:
            return Object.assign({}, state, {
                isLoadingSearchTemplate: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_SEARCH_RESULTS_START:
            return Object.assign({}, state, {
                isLoadingSearchResults: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SEARCH_RESULTS:
            return Object.assign({}, state, {
                searchResults: action.data,
                isLoadingSearchResults: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SEARCH_RESULTS_FAIL:
            return Object.assign({}, state, {
                isLoadingSearchResults: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return Object.assign({}, state, {
                isLoadingSearchResults: false,
                isLoadingTopSearchTemplate: false,
                error: false,
                message: '',
                isSuccess: false,
                statusCode: 201,
            })
        default:
            return state
    }
}

export const getError = state => state.search.error
export const getMsg = state => state.search.message
export const getisLoadingSearchResults = state => state.search.isLoadingSearchResults
export const getSearchResults = state => state.search.searchResults
export const getIsSuccess = state => state.search.isSuccess
export const getstatusCode = state => state.search.statusCode
export const getisLoadingSearchTemplate = state => state.search.isLoadingSearchTemplate
export const getTemplateProfessionCards = state => state.search.templateProfessionCards
export const getToptemplates = state => state.search.toptemplates
export const getisLoadingTopSearchTemplate = state => state.search.isLoadingTopSearchTemplate



export const getProfessionSearchResults = state => state.search.searchResults.filter((result) => result.type === "profession")
export const getEventsSearchResults = state => state.search.searchResults.filter((result) => result.type === "experience")
export const getUserSearchResults = state => state.search.searchResults.filter((result) => result.type === "name")