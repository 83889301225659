import {
    SAVE_TRAINING_INDIVIDUAL_TRAINER,
    SAVE_TRAINING_INDIVIDUAL_INSTITUTION,
    CLEAR_TRAINING_INDIVIDUAL,
    CLEAR_TRAINING_INDIVIDUAL_TRAINER,
    APP_LOGOUT
} from '../../../../actions'

const initialState = {
    institution:[],
    trainer:[]
}

export const trainingIndividualUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_TRAINING_INDIVIDUAL_TRAINER:
            return Object.assign({}, state, {
                trainer: action.data
            })
        case SAVE_TRAINING_INDIVIDUAL_INSTITUTION:
            return Object.assign({}, state, {
                institution: action.data
            })
        case CLEAR_TRAINING_INDIVIDUAL:
            return initialState
        case CLEAR_TRAINING_INDIVIDUAL_TRAINER:
            return Object.assign({}, state, {
                trainer: [],
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getIndividualInstitution = state => state.trainingIndividualUI.institution
export const getIndividualTrainer = state => state.trainingIndividualUI.trainer
