import { parseJSON, composeAuth } from '../../../../../helpers/utils'

import {
    APP_CREATE_ACCOLADES_AWARD_START,
    APP_CREATE_ACCOLADES_AWARD,
    APP_CREATE_ACCOLADES_AWARD_FAIL,
    APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_START,
    APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO,
    APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_FAIL,
    APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_START,
    APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS,
    APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_FAIL,
    APP_GET_RESUME_BY_CARDID_START,
    APP_GET_RESUME_BY_CARDID,
    APP_GET_RESUME_BY_CARDID_FAIL,
    APP_DELETE_RESUME_START,
    APP_DELETE_RESUME,
    APP_DELETE_RESUME_FAIL,
    APP_ASSIGN_RESUME_START,
    APP_ASSIGN_RESUME,
    APP_ASSIGN_RESUME_FAIL,
    APP_LOGOUT,
} from '../../../../actions'


//create awards

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitAccoladesAwardStart = () => ({
    type: APP_CREATE_ACCOLADES_AWARD_START
})

export const emitAccoladesAward = data => {
    return {
        type: APP_CREATE_ACCOLADES_AWARD,
        data
    }
}

export const emitAccoladesAwardFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_AWARD_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesAward = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/accolades/award/award'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createAccoladesAward = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardStart())
        return postAccoladesAward(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesAward(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardFailed(err))
            })
    }

}

// add additional info to awards

export const emitAccoladesAwardAdditionalInfoStart = () => ({
    type: APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_START
})

export const emitAccoladesAwardAdditionalInfo = data => {
    return {
        type: APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO,
        data
    }
}

export const emitAccoladesAwardAdditionalInfoFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_AWARD_ADDITIONAL_INFO_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesAwardAdditionalInfo = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/award/information/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const createAccoladesAwardAdditionalInfo = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardAdditionalInfoStart())
        return postAccoladesAwardAdditionalInfo(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesAwardAdditionalInfo(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardAdditionalInfoFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardAdditionalInfoFailed(err))
            })
    }
}

// get awards

const getAccoladesAward = ({ jwt, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/award/${id}`;
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const fetchAccoladesAward = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardStart())
        return getAccoladesAward(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesAward(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardFailed(err))
            })
    }

}

// add awards to professions Cards


export const emitAccoladesAwardToProfessionCardsStart = () => ({
    type: APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_START
})

export const emitAccoladesAwardToProfessionCards = data => {
    return {
        type: APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS,
        data
    }
}

export const emitAccoladesAwardToProfessionCardsFailed = (data) => ({
    type: APP_CREATE_ACCOLADES_AWARD_TO_PROFESSION_CARDS_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postAccoladesAwardToProfessionCards = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + '/api/v1/accolades/award/award/professioncard'
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const assignAccoladesAwardToProfessionCards = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardToProfessionCardsStart())
        return postAccoladesAwardToProfessionCards(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesAwardToProfessionCards(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardToProfessionCardsFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardToProfessionCardsFailed(err))
            })
    }
}

// update awards

const putAccoladesAward = ({ jwt, data, id }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/award/${id}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const updateAccoladesAward = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardStart())
        return putAccoladesAward(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAccoladesAward(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardFailed(err))
            })
    }

}

// get accoladesAward resume by card id

export const emitAccoladesAwardByCardIdStart = () => ({
    type: APP_GET_RESUME_BY_CARDID_START
})

export const emitAccoladesAwardByCardId = data => {
    return {
        type: APP_GET_RESUME_BY_CARDID,
        data
    }
}

export const emitAccoladesAwardByCardIdFailed = (data) => ({
    type: APP_GET_RESUME_BY_CARDID_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getAccoladesAwardByCardId = ({ jwt, id }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/award/professioncard/${stringifyIds}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetAccoladesAwardByCardId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardByCardIdStart())
        return getAccoladesAwardByCardId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesAwardByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardByCardIdFailed(err))
            })
    }
}

// get accoladesAward resume by card id by user Id
const getAccoladesAwardByCardIdByUserId = ({ jwt, id, userId }) => {
    let stringifyIds = encodeURI(JSON.stringify(id))
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/award/professioncard/${stringifyIds}/user/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetAccoladesAwardByCardIdByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardByCardIdStart())
        return getAccoladesAwardByCardIdByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesAwardByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardByCardIdFailed(err))
            })
    }
}

// get all user accolades awards - no profession card/s
const getUserAccoladesAward = ({ jwt }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/user/award`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserAccoladesAward = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardByCardIdStart())
        return getUserAccoladesAward(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesAwardByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardByCardIdFailed(err))
            })
    }
}

// get all user accolades awards - no profession card/s by user id
const getUserAccoladesAwardByUserId = ({ jwt, userId }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/users/award/${userId}`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserAccoladesAwardByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitAccoladesAwardByCardIdStart())
        return getUserAccoladesAwardByUserId(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitAccoladesAwardByCardId(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAccoladesAwardByCardIdFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAccoladesAwardByCardIdFailed(err))
            })
    }
}

// delete accoladesAward by id

export const emitDeleteAccoladesAwardStart = () => ({
    type: APP_DELETE_RESUME_START
})

export const emitDeleteAccoladesAward = data => {
    return {
        type: APP_DELETE_RESUME,
        data
    }
}

export const emitDeleteAccoladesAwardFailed = (data) => ({
    type: APP_DELETE_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const deleteAccoladesAward = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/delete/awards`
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteAccoladesAward = (data) => {
    return (dispatch) => {
        dispatch(emitDeleteAccoladesAwardStart())
        return deleteAccoladesAward(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitDeleteAccoladesAward(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitDeleteAccoladesAwardFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitDeleteAccoladesAwardFailed(err))
            })
    }
}

//assign accolades awards
export const emitAssignAccoladesAwardStart = () => ({
    type: APP_ASSIGN_RESUME_START
})

export const emitAssignAccoladesAward = data => {
    return {
        type: APP_ASSIGN_RESUME,
        data
    }
}

export const emitAssignAccoladesAwardFailed = (data) => ({
    type: APP_ASSIGN_RESUME_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const assignAccoladesAward = ({ jwt, data }) => {
    let url = process.env.REACT_APP_SERVER + `/api/v1/accolades/award/reassign/professioncard/awards`
    
    
    let Authorization = composeAuth(jwt)
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startAssignAccoladesAward = (data) => {
    return (dispatch) => {
        dispatch(emitAssignAccoladesAwardStart())
        return assignAccoladesAward(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitAssignAccoladesAward(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitAssignAccoladesAwardFailed(json))
                }
            })
            .catch(err => {
                
                dispatch(emitAssignAccoladesAwardFailed(err))
            })
    }
}