import {
    APP_GET_FEATURED_QUOTE_START,
    APP_GET_FEATURED_QUOTE,
    APP_GET_FEATURED_QUOTE_FAIL,
    APP_PUT_FEATURED_QUOTE_START,
    APP_PUT_FEATURED_QUOTE,
    APP_PUT_FEATURED_QUOTE_FAIL,
    APP_UNASSIGN_QUOTE_START,
    APP_UNASSIGN_QUOTE,
    APP_UNASSIGN_QUOTE_FAIL,
    APP_GET_CARD_QUOTE_START,
    APP_GET_CARD_QUOTE,
    APP_GET_CARD_QUOTE_FAIL,
    APP_GET_QUOTE_START,
    APP_GET_QUOTE,
    APP_GET_QUOTE_FAIL,
    APP_PUT_QUOTE_START,
    APP_PUT_QUOTE,
    APP_PUT_QUOTE_FAIL,
    APP_DELETE_QUOTES_START,
    APP_DELETE_QUOTES,
    APP_DELETE_QUOTES_FAIL,
    APP_ASSIGN_QUOTE_START,
    APP_ASSIGN_QUOTE,
    APP_ASSIGN_QUOTE_FAIL,
    APP_LOGOUT,
} from '../../../actions'

const initialState = {
    allquotes: [],
    cardquotes: [],
    featuredQuotes: [],
    isLoading: false,
    isLoadingAssign: false,
    isLoadingPut: false,
    isLoadingAll: false,
    isLoadingFeatured: false,
    isLoadingPutFeatured: false,
    isDeleteLoading: false,
    isLoadingUnassign: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const mediaQuoteEdit = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_FEATURED_QUOTE_START:
            return Object.assign({}, state, {
                isLoadingFeatured: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_FEATURED_QUOTE:
            return Object.assign({}, state, {
                featuredQuotes: action.data ? action.data:[],
                isLoadingFeatured: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FEATURED_QUOTE_FAIL:
            return Object.assign({}, state, {
                isLoadingFeatured: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_FEATURED_QUOTE_START:
            return Object.assign({}, state, {
                isLoadingPutFeatured: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_FEATURED_QUOTE:
            return Object.assign({}, state, {
                isLoadingPutFeatured: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_FEATURED_QUOTE_FAIL:
            return Object.assign({}, state, {
                isLoadingPutFeatured: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_CARD_QUOTE_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_CARD_QUOTE:
            return Object.assign({}, state, {
                cardquotes: action.data ? action.data:[],
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_CARD_QUOTE_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_QUOTE_START:
            return Object.assign({}, state, {
                isLoadingAll: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_QUOTE:
            console.log(action, "action");

            return Object.assign({}, state, {
                allquotes: action.data ? action.data:[],
                isLoadingAll: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_QUOTE_FAIL:
            return Object.assign({}, state, {
                isLoadingAll: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_UNASSIGN_QUOTE_START:
            return Object.assign({}, state, {
                isLoadingUnassign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_UNASSIGN_QUOTE:
            return Object.assign({}, state, {
                isLoadingUnassign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_UNASSIGN_QUOTE_FAIL:
            return Object.assign({}, state, {
                isLoadingUnassign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_PUT_QUOTE_START:
            return Object.assign({}, state, {
                isLoadingPut: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_PUT_QUOTE:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUT_QUOTE_FAIL:
            return Object.assign({}, state, {
                isLoadingPut: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_ASSIGN_QUOTE_START:
            return Object.assign({}, state, {
                isLoadingAssign: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_ASSIGN_QUOTE:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_ASSIGN_QUOTE_FAIL:
            return Object.assign({}, state, {
                isLoadingAssign: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DELETE_QUOTES_START:
            return Object.assign({}, state, {
                isDeleteLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_QUOTES:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_DELETE_QUOTES_FAIL:
            return Object.assign({}, state, {
                isDeleteLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.mediaQuoteEdit.error
export const getMsg = state => state.mediaQuoteEdit.message
export const getisLoading = state => state.mediaQuoteEdit.isLoading
export const getisLoadingFeatured = state => state.mediaQuoteEdit.isLoadingFeatured
export const getisLoadingPutFeatured = state => state.mediaQuoteEdit.isLoadingPutFeatured
export const getisLoadingAll = state => state.mediaQuoteEdit.isLoadingAll
export const getisLoadingUnassign = state => state.mediaQuoteEdit.isLoadingUnassign
export const getisLoadingAssign = state => state.mediaQuoteEdit.isLoadingAssign
export const getisDeleteLoading = state => state.mediaQuoteEdit.isDeleteLoading
export const getisLoadingPut = state => state.mediaQuoteEdit.isLoadingPut
export const getCardQuotes = state => state.mediaQuoteEdit.cardquotes
export const getFeaturedQuotes = state => state.mediaQuoteEdit.featuredQuotes
export const getAllquotes = state => state.mediaQuoteEdit.allquotes
export const getIsSuccess = state => state.mediaQuoteEdit.isSuccess
export const getstatusCode = state => state.mediaQuoteEdit.statusCode

