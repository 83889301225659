import {
    APP_USER_PROFILE_START,
    APP_USER_PROFILE_ADD,
    APP_USER_PROFILE_FAIL,
    APP_USER_PROFILE_LOCATION_ADD,
    APP_GET_FEATURED_USER_PROFILE_DATA_START,
    APP_GET_FEATURED_USER_PROFILE_DATA,
    APP_GET_FEATURED_USER_PROFILE_DATA_NO_CARD,
    APP_GET_FEATURED_USER_PROFILE_DATA_FAIL,
    APP_LOGOUT,
    APP_DELETE_PROFILE_CARD_START,
    APP_DELETE_PROFILE_CARD,
    APP_DELETE_PROFILE_CARD_FAILED,
    APP_DESCRIPTION_USER_PROFESSION_CARDS_START,
    APP_DESCRIPTION_USER_PROFESSION_CARDS,
    APP_DESCRIPTION_USER_PROFESSION_CARDS_FAIL,
    APP_PUBLIC_USER_PROFILE_ADD,
    APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA,
    APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA_NO_CARD
} from '../../actions'

import { createSelector } from 'reselect'

const initialState = {
    imageUrl:null,
    coverPic:null,
    firstName:"",
    lastName:"",
    dob: '',
    gender: '',
    phone: '',
    countryCode: '',
    ethnicity: '',
    countryCodePublic: '',
    ethnicityPublic: '',
    location: {
        description: "",
        id: "",
        latLng: {},
    },
    firstNamePublic:"",
    lastNamePublic:"",
    dobPublic: '',
    genderPublic: '',
    phonePublic: '',
    locationPublic: {
        description: "",
        id: "",
        latLng: {},
    },
    isdeleting:false,
    isLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    location_display:[],
    location_displayPublic:[],
    profilecards:[],
    profilecardsPublic:[],
    isLoadingFeaturedProfileData:false,
    photos:[],
    audios:[],
    videos:[],
    upcoming:[],
    quotes:[],
    description:"",
    links:[],
    resume:[],
    photosPublic:[],
    audiosPublic:[],
    videosPublic:[],
    upcomingPublic:[],
    quotesPublic:[],
    descriptionPublic:"",
    linksPublic:[],
    resumePublic:[],
    statusCode:201,
    descriptionIsLoading: false,
    eventWarning:false,
    resumeWarning:false,
    useridPublic:"",
    navigationName:"",
    navigationNamePublic:"",
    spotlight:false
}


export const profile = (state = initialState, action) => {
    switch (action.type) {
        case APP_USER_PROFILE_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_USER_PROFILE_ADD:
            return Object.assign({}, state, {
                dob: action.dob,
                gender: action.gender,
                phone: action.phone,
                location: action.location,
                imageUrl: action.url,
                coverPic: action.coverpic,
                location_display: action.location_display ? action.location_display:[],
                profilecards: action.profilecards ? action.profilecards:[],
                eventWarning: action.eventwarning,
                resumeWarning: action.resumewarning,
                firstName: action.firstName,
                lastName: action.lastName,
                countryCode: action.country_code,
                ethnicity: action.ethnicity,
                navigationName: action.navigationName,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_PUBLIC_USER_PROFILE_ADD:
            return Object.assign({}, state, {
                dobPublic: action.dob,
                genderPublic: action.gender,
                phonePublic: action.phone,
                locationPublic: action.location,
                imageUrlPublic: action.url,
                coverPicPublic: action.coverpic,
                location_displayPublic: action.location_display ? action.location_display:[],
                profilecardsPublic: action.profilecards ? action.profilecards:[],
                eventWarningPublic: action.eventwarning,
                resumeWarningPublic: action.resumewarning,
                firstNamePublic: action.firstName,
                lastNamePublic: action.lastName,
                countryCodePublic: action.country_code,
                ethnicityPublic: action.ethnicity,
                navigationNamePublic: action.navigationName,
                useridPublic: action.userid,
                spotlight: action.spotlight,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_USER_PROFILE_LOCATION_ADD:
            return Object.assign({}, state, {
                location: action.data,
               
            })
        case APP_USER_PROFILE_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode:action.statusCode
            })
        case APP_DELETE_PROFILE_CARD_START:
            return Object.assign({}, state, {
                isdeleting: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DELETE_PROFILE_CARD:
            return Object.assign({}, state, {
                isdeleting: false,
                error: false,
                isSuccess: true,
                message: action.message,
                statusCode:action.statusCode,
                profilecards: action.profilecards ? action.profilecards : [],
            })
        case APP_DELETE_PROFILE_CARD_FAILED:
            return Object.assign({}, state, {
                isdeleting: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode:action.statusCode
            })
        case APP_GET_FEATURED_USER_PROFILE_DATA_START:
            return Object.assign({}, state, {
                isLoadingFeaturedProfileData: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_FEATURED_USER_PROFILE_DATA:
            return Object.assign({}, state, {
                photos: action.data.photo ? action.data.photo.length > 0 ? action.data.photo:[]:[],
                audios: action.data.audio ? action.data.audio.length > 0 ? action.data.audio : [] : [],
                videos: action.data.video ? action.data.video.length > 0 ? action.data.video : [] : [],
                upcoming: action.data.upcoming ? action.data.upcoming.length > 0 ? action.data.upcoming : [] : [],
                quotes: action.data.quote ? action.data.quote.length > 0 ? action.data.quote : [] : [],
                description: action.data.card[0].description ? action.data.card[0].description.length > 0 && action.data.card[0].description !== "None"? action.data.card[0].description : "" : "",
                links: action.data.link ? action.data.link.length > 0 ? action.data.link : [] : [],
                resume: action.data.resume ? action.data.resume.length > 0 ? action.data.resume : [] : [],
                isLoadingFeaturedProfileData: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA:
            return Object.assign({}, state, {
                photosPublic: action.data.photo ? action.data.photo.length > 0 ? action.data.photo:[]:[],
                audiosPublic: action.data.audio ? action.data.audio.length > 0 ? action.data.audio : [] : [],
                videosPublic: action.data.video ? action.data.video.length > 0 ? action.data.video : [] : [],
                upcomingPublic: action.data.upcoming ? action.data.upcoming.length > 0 ? action.data.upcoming : [] : [],
                quotesPublic: action.data.quote ? action.data.quote.length > 0 ? action.data.quote : [] : [],
                descriptionPublic: action.data.card[0].description ? action.data.card[0].description.length > 0 && action.data.card[0].description !== "None"? action.data.card[0].description : "" : "",
                linksPublic: action.data.link ? action.data.link.length > 0 ? action.data.link : [] : [],
                resumePublic: action.data.resume ? action.data.resume.length > 0 ? action.data.resume : [] : [],
                isLoadingFeaturedProfileData: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FEATURED_USER_PROFILE_DATA_NO_CARD:
            return Object.assign({}, state, {
                photos: action.data.photo ? action.data.photo.length > 0 ? action.data.photo:[]:[],
                audios: action.data.audio ? action.data.audio.length > 0 ? action.data.audio : [] : [],
                videos: action.data.video ? action.data.video.length > 0 ? action.data.video : [] : [],
                upcoming: action.data.upcoming ? action.data.upcoming.length > 0 ? action.data.upcoming : [] : [],
                quotes: action.data.quote ? action.data.quote.length > 0 ? action.data.quote : [] : [],
                description: action.data.description ? action.data.description.length > 0 && action.data.description !== "None"? action.data.description : "" : "",
                links: action.data.link ? action.data.link.length > 0 ? action.data.link : [] : [],
                resume: action.data.resume ? action.data.resume.length > 0 ? action.data.resume : [] : [],
                isLoadingFeaturedProfileData: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FEATURED_PUBLIC_USER_PROFILE_DATA_NO_CARD:
            return Object.assign({}, state, {
                photosPublic: action.data.photo ? action.data.photo.length > 0 ? action.data.photo:[]:[],
                audiosPublic: action.data.audio ? action.data.audio.length > 0 ? action.data.audio : [] : [],
                videosPublic: action.data.video ? action.data.video.length > 0 ? action.data.video : [] : [],
                upcomingPublic: action.data.upcoming ? action.data.upcoming.length > 0 ? action.data.upcoming : [] : [],
                quotesPublic: action.data.quote ? action.data.quote.length > 0 ? action.data.quote : [] : [],
                descriptionPublic: action.data.description ? action.data.description.length > 0 && action.data.description !== "None"? action.data.description : "" : "",
                linksPublic: action.data.link ? action.data.link.length > 0 ? action.data.link : [] : [],
                resumePublic: action.data.resume ? action.data.resume.length > 0 ? action.data.resume : [] : [],
                isLoadingFeaturedProfileData: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_FEATURED_USER_PROFILE_DATA_FAIL:
            return Object.assign({}, state, {
                photosPublic: [],
                audiosPublic: [],
                videosPublic: [],
                upcomingPublic: [],
                quotesPublic: [],
                descriptionPublic: "",
                linksPublic: [],
                resumePublic: [],
                isLoadingFeaturedProfileData: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_DESCRIPTION_USER_PROFESSION_CARDS_START:
            return Object.assign({}, state, {
                descriptionIsLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_DESCRIPTION_USER_PROFESSION_CARDS:
            return Object.assign({}, state, {
                descriptionIsLoading: false,
                error: false,
                message: '',
                isSuccess: true,
                description: action.data.description ? action.data.description:""
            })
        case APP_DESCRIPTION_USER_PROFESSION_CARDS_FAIL:
            return Object.assign({}, state, {
                descriptionIsLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.profile.error
export const getMsg = state => state.profile.message
export const getisLoading = state => state.profile.isLoading
export const getisDeleting = state => state.profile.isdeleting
export const getDob = state => state.profile.dob
export const getGender = state => state.profile.gender
export const getPhone = state => state.profile.phone
export const getLocation = state => state.profile.location
export const getIsSuccess = state => state.profile.isSuccess
export const getstatusCode = state => state.profile.statusCode
export const getProfileImage = state => state.profile.imageUrl
export const getProfileCoverImage = state => state.profile.coverPic
export const getDisplayLocation = state => state.profile.location_display
export const getIsLoadingFeaturedProfileData= state => state.profile.isLoadingFeaturedProfileData
export const getPhotos = state => state.profile.photos
export const getAudios = state => state.profile.audios
export const getVideos= state => state.profile.videos
export const getUpcoming = state => state.profile.upcoming
export const getQuotes = state => state.profile.quotes
export const getDescription = state => state.profile.description
export const getDescriptionIsLoading = state => state.profile.descriptionIsLoading
export const getLinks= state => state.profile.links
export const getResume = state => state.profile.resume
export const getEventWarning = state => state.profile.eventWarning
export const getResumeWarning = state => state.profile.resumeWarning
export const getFirstName = state => state.profile.firstName
export const getLastName = state => state.profile.lastName
export const getCountryCode = state => state.profile.countryCode
export const getEthnicity = state => state.profile.ethnicity

//public
export const getDobPublic = state => state.profile.dobPublic
export const getGenderPublic = state => state.profile.genderPublic
export const getPhonePublic = state => state.profile.phonePublic
export const getLocationPublic = state => state.profile.locationPublic
export const getProfileImagePublic = state => state.profile.imageUrlPublic
export const getProfileCoverImagePublic = state => state.profile.coverPicPublic
export const getDisplayLocationPublic = state => state.profile.location_displayPublic
export const getEventWarningPublic = state => state.profile.eventWarningPublic
export const getResumeWarningPublic = state => state.profile.resumeWarningPublic
export const getFirstNamePublic = state => state.profile.firstNamePublic
export const getLastNamePublic = state => state.profile.lastNamePublic
export const getCountryCodePublic = state => state.profile.countryCodePublic
export const getEthnicityPublic = state => state.profile.ethnicityPublic
export const getUseridPublic = state => state.profile.useridPublic
export const getProfileCardsPublic = state => {
    if (state.profile.profilecardsPublic) {

        return state.profile.profilecards.sort((a, b) => {
            return a.order - b.order
        })
    } else {
        return []
    }
}
export const getPhotosPublic = state => state.profile.photosPublic
export const getAudiosPublic = state => state.profile.audiosPublic
export const getVideosPublic = state => state.profile.videosPublic
export const getUpcomingPublic = state => state.profile.upcomingPublic
export const getQuotesPublic = state => state.profile.quotesPublic
export const getDescriptionPublic = state => state.profile.descriptionPublic
export const getLinksPublic = state => state.profile.linksPublic
export const getResumePublic = state => state.profile.resumePublic
export const getNavigationName = state => state.profile.navigationName
export const getNavigationNamePublic = state => state.profile.navigationNamePublic
export const getspotlight = state => state.profile.spotlight


export const getProfileCards = state => {
    if (state.profile.profilecards){

        return state.profile.profilecards.sort( (a, b)=> {
           return a.order - b.order
       })
    }else{
        return []
    }
}

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))