import React, { useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import moment from 'moment'

import VideoThubDefault from "../../../../assets/images/videoSmall.png";
import RightArrow from "../../../../assets/images/arrowRight.svg";
import Slider from "react-slick";
import LeftArrow from "../../../../assets/images/arrowLeft.svg";
import Play from "../../../../assets/images/play.svg";
import VideoThumb from "../../../../assets/images/videoDetail.png";
import { OpenLink } from '../../../../helpers/utils'
import VideoDescription from "../../../assets/layouts/cards/browseVideoDescription";
import PersonDetailsCard from "../../../assets/molecules/personDetailsCard";
import ProfileIcon from "../../../../assets/images/profileIcon.svg";

const VideoCard = (props) => {
  const VideoRef = useRef(null);

  const [selectedVideo, setSelectedVideo] = useState(props?.videos[0]);

  const videoGallery = {
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    draggable: true,
  };

  const nextVideo = () => {
    VideoRef?.current.slickNext();
  };

  const previousVideo = () => {
    VideoRef?.current.slickPrev();
  };

  return (
    <div className="videoSlider__activity">
      <h2>Browse Video</h2>
      <div className="preview" onClick={() => props?.onClick(selectedVideo)}>
        {selectedVideo?.type === "Youtube" ? <div className="videoWithIframe">
          <iframe
            className="youtubeIframe"
            title="video"
            allowFullScreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            width="100%"
            height="379"
            src={"https://www.youtube.com/embed/" + selectedVideo?.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe></div> : selectedVideo?.thumbnail ? <div onClick={() => OpenLink(selectedVideo?.url)}>
            <img alt="video" src={selectedVideo?.thumbnail} />
            <img alt="video icon" className="videoSlider__play" src={Play} />
          </div> : <div className="videoPop__wrap" onClick={() => OpenLink(selectedVideo?.url)}>
              <img alt="video thumb" src={VideoThumb} />
              <img alt="video icon" className="videoSlider__play" src={Play} />
            </div>}
        <div className="clear"></div>
      </div>
      <div className="browseVideo__desc">
        <VideoDescription
          title={selectedVideo?.title}
          date={moment(selectedVideo?.timestamp).format("YYYY")}
        >
          <Scrollbars autoHeight autoHeightMin={20} autoHeightMax={285}>
            {selectedVideo?.artists ? <h5>Artists/Collaborators</h5> : null}
            <p>{selectedVideo?.artists}</p>

            {selectedVideo?.creators ? <h5>Creator/s</h5> : null}
            <p>{selectedVideo?.creators}</p>

            {selectedVideo?.showname ? <h5>Show/Work</h5> : null}
            <p>{selectedVideo?.showname}</p>

            {selectedVideo?.institutionName ? <h5>Institution</h5> : null } 
            <p>{selectedVideo?.institutionName}</p>

            {selectedVideo?.description ? <h5>Description</h5> : null}
            <p>{selectedVideo?.description}</p>

            <h5>URL</h5>
            <p>{selectedVideo?.type === "Youtube" ? ("https://www.youtube.com/embed/" + selectedVideo?.url) : selectedVideo?.url}</p>
          </Scrollbars>
        </VideoDescription>
      <div className="clear"></div>
      </div>
      <div className="clear"></div>
      <Slider ref={VideoRef} className="videoGallery--activity" {...videoGallery}>
        {props?.videos?.map((video, index) => {
          return (
            <div key={index}>
              <div
                className="videoSlider__items"
                onClick={() => setSelectedVideo(video)}
              >
                <img
                  src={video?.thumbnail ? video?.thumbnail : VideoThubDefault}
                  alt="video thumb"
                />
                <p
                  className={
                    "videoSlider__items__text " +
                    (selectedVideo?.id === video?.id
                      ? "videoSlider__items__text--active"
                      : "")
                  }
                >
                  {video?.title}
                </p>
              </div>
              <PersonDetailsCard
                onClick={() => props?.onClickProfile(video?.navigation_name)}
                img={video?.profile_pic ? video?.profile_pic : ProfileIcon}
                name={video?.firstName + " " + video?.lastName}
                designation={video?.location}
                date={"Added " + moment(video?.timestamp).format("MMM DD, YYYY")}
              />
            </div>
          );
        })}
      </Slider>
      <div onClick={previousVideo} className="arrow__left">
        <img src={LeftArrow} alt="left arrow" />
      </div>
      <div onClick={nextVideo} className="arrow__right">
        <img src={RightArrow} alt="Right arrow" />
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default VideoCard;
