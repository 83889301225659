import React from "react";

//components
import AudioDetails from './audio'
import VideoDetails from './video'
import HighlightsDetails from './highlights'
import AboutDetails from './about'
import PictureDetails from './picture'
import PressDetails from './press'

const PopUpDetails = (props) => {

    const renderPopUpDetails = () => {
        if (props?.details?.type === "video") {
            return <VideoDetails id={props?.details?.id}/>
        }
        else if (props?.details?.type === "audio") {
            return <AudioDetails id={props?.details?.id}/>
        }
        else if (props?.details?.type === "highlights") {
            return <HighlightsDetails id={props?.details?.id}/>
        }
        else if (props?.details?.type === "about") {
            return <AboutDetails text={props?.details?.text}/>
        }
        else if (props?.details?.type === "picture") {
            return <PictureDetails url={props?.details?.url}/>
        }
        else if (props?.details?.type === "press") {
            return <PressDetails quote={props?.details?.quote}/>
        }
    }

    return (
        <div>
            {renderPopUpDetails()}
        </div>
    );
};

export default PopUpDetails;
