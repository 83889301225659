import {
    APP_GET_REQUEST_STATUS_START,
    APP_GET_REQUEST_STATUS,
    APP_GET_REQUEST_STATUS_FAIL,
    APP_POST_SEND_REQUEST_START,
    APP_POST_SEND_REQUEST,
    APP_POST_SEND_REQUEST_FAIL,
    APP_GET_ALL_COLLEAGUES_START,
    APP_GET_ALL_COLLEAGUES,
    APP_GET_ALL_COLLEAGUES_FAIL,
    APP_GET_SENT_COLLEAGUES_REQUEST_START,
    APP_GET_SENT_COLLEAGUES_REQUEST,
    APP_GET_SENT_COLLEAGUES_REQUEST_FAIL,
    APP_GET_RECEIVED_COLLEAGUES_REQUEST_START,
    APP_GET_RECEIVED_COLLEAGUES_REQUEST,
    APP_GET_RECEIVED_COLLEAGUES_REQUEST_FAIL,
    APP_UPDATE_COLLEAGUES_REQUEST_START,
    APP_UPDATE_COLLEAGUES_REQUEST,
    APP_UPDATE_COLLEAGUES_REQUEST_FAIL,
    APP_LOGOUT,
} from '../../actions'

const initialState = {
    requestStatus: "",
    requestId: "",
    isLoadingGetRequestStatus: false,
    isLoadingPostSendRequest: false,
    isLoadingAllColleagues: false,
    isLoadingSentColleaguesRequest: false,
    isLoadingReceivedColleaguesRequest: false,
    isLoadingUpdateRequest: false,
    myColleagues: [],
    sentColleaguesRequest: [],
    receivedColleaguesRequest: [],
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201,
}


export const colleagues = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_REQUEST_STATUS_START:
            return Object.assign({}, state, {
                isLoadingGetRequestStatus: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_REQUEST_STATUS:
            return Object.assign({}, state, {
                requestStatus: action?.data?.status,
                requestId: action?.data?.id,
                isLoadingGetRequestStatus: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_REQUEST_STATUS_FAIL:
            return Object.assign({}, state, {
                requestStatus: "",
                isLoadingGetRequestStatus: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_POST_SEND_REQUEST_START:
            return Object.assign({}, state, {
                isLoadingPostSendRequest: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_POST_SEND_REQUEST:
            return Object.assign({}, state, {
                isLoadingPostSendRequest: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_POST_SEND_REQUEST_FAIL:
            return Object.assign({}, state, {
                isLoadingPostSendRequest: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_ALL_COLLEAGUES_START:
            return Object.assign({}, state, {
                isLoadingAllColleagues: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_ALL_COLLEAGUES:
            return Object.assign({}, state, {
                isLoadingAllColleagues: false,
                myColleagues:action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_ALL_COLLEAGUES_FAIL:
            return Object.assign({}, state, {
                isLoadingAllColleagues: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_SENT_COLLEAGUES_REQUEST_START:
            return Object.assign({}, state, {
                isLoadingSentColleaguesRequest: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_SENT_COLLEAGUES_REQUEST:
            return Object.assign({}, state, {
                isLoadingSentColleaguesRequest: false,
                sentColleaguesRequest: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_SENT_COLLEAGUES_REQUEST_FAIL:
            return Object.assign({}, state, {
                isLoadingSentColleaguesRequest: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_GET_RECEIVED_COLLEAGUES_REQUEST_START:
            return Object.assign({}, state, {
                isLoadingReceivedColleaguesRequest: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_RECEIVED_COLLEAGUES_REQUEST:
            return Object.assign({}, state, {
                isLoadingReceivedColleaguesRequest: false,
                receivedColleaguesRequest: action.data,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_RECEIVED_COLLEAGUES_REQUEST_FAIL:
            return Object.assign({}, state, {
                isLoadingReceivedColleaguesRequest: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_UPDATE_COLLEAGUES_REQUEST_START:
            return Object.assign({}, state, {
                isLoadingUpdateRequest: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_UPDATE_COLLEAGUES_REQUEST:
            return Object.assign({}, state, {
                isLoadingUpdateRequest: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_UPDATE_COLLEAGUES_REQUEST_FAIL:
            return Object.assign({}, state, {
                isLoadingUpdateRequest: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.colleagues.error
export const getMsg = state => state.colleagues.message
export const getisLoadingGetRequestStatus = state => state.colleagues.isLoadingGetRequestStatus
export const getisLoadingPostSendRequest = state => state.colleagues.isLoadingPostSendRequest
export const getisLoadingAllColleagues = state => state.colleagues.isLoadingAllColleagues
export const getMyColleagues = state => state.colleagues.myColleagues
export const getisLoadingSentColleaguesRequest = state => state.colleagues.isLoadingSentColleaguesRequest
export const getSentColleaguesRequest = state => state.colleagues.sentColleaguesRequest
export const getisLoadingReceivedColleaguesRequest = state => state.colleagues.isLoadingReceivedColleaguesRequest
export const getisLoadingUpdateRequest = state => state.colleagues.isLoadingUpdateRequest
export const getReceivedColleaguesRequest = state => state.colleagues.receivedColleaguesRequest
export const getRequestStatus = state => state.colleagues.requestStatus
export const getRequestId = state => state.colleagues.requestId
export const getIsSuccess = state => state.colleagues.isSuccess
export const getstatusCode = state => state.colleagues.statusCode