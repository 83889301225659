import {
    APP_FILES_UPLOAD_START,
    APP_FILES_UPLOAD,
    APP_FILES_UPLOAD_FAIL,
    APP_LOGOUT
} from '../../actions'

import { createSelector } from 'reselect'

const initialState = {
    publicFiles:[],
    isLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201
}


export const fileUpload = (state = initialState, action) => {
    switch (action.type) {
        case APP_FILES_UPLOAD_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_FILES_UPLOAD:
            return Object.assign({}, state, {
                publicFiles:action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_FILES_UPLOAD_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.fileUpload.error
export const getMsg = state => state.fileUpload.message
export const getisLoading = state => state.fileUpload.isLoading
export const getPublicFiles = state => state.fileUpload.publicFiles
export const getIsSuccess = state => state.fileUpload.isSuccess
export const getstatusCode = state => state.fileUpload.statusCode

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))