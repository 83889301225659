import React from 'react';

const SkillsWithClose = (props) => {
    return (
        <div className="skillsWithClose">
            {props.name} <span onClick={props.onClick}>x</span>
        </div>
    );
};

export default SkillsWithClose;