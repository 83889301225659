import {
    SAVE_ACCOLADES_COMPETITION_TITLE,
    SAVE_ACCOLADES_COMPETITION_DATE,
    SAVE_ACCOLADES_COMPETITION_INSTITUTION_ID,
    SAVE_ACCOLADES_COMPETITION_INSTITUTION_LOCATION,
    SAVE_ACCOLADES_COMPETITION_AFFILIATE_INSTITUTION_ID,
    CLEAR_ACCOLADES_COMPETITION,
    APP_LOGOUT
} from '../../../../../actions'

const initialState = {
    accoladesCompetitionTitle:"",
    accoladesCompetitionDate:{},
    accoladesCompetitionInstitutionId:[],
    accoladesCompetitionInstitutionLocation:{},
    accoladesCompetitionAffiliateInstitutionId:null,
}

export const accoladesCompetitionUI = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ACCOLADES_COMPETITION_TITLE:
            return Object.assign({}, state, {
                accoladesCompetitionTitle: action.data
            })
        case SAVE_ACCOLADES_COMPETITION_DATE:
            return Object.assign({}, state, {
                accoladesCompetitionDate: action.data
            })
        case SAVE_ACCOLADES_COMPETITION_INSTITUTION_ID:
            return Object.assign({}, state, {
                accoladesCompetitionInstitutionId: action.data
            })
        case SAVE_ACCOLADES_COMPETITION_INSTITUTION_LOCATION:
            return Object.assign({}, state, {
                accoladesCompetitionInstitutionLocation: action.data
            })
        case SAVE_ACCOLADES_COMPETITION_AFFILIATE_INSTITUTION_ID:
            return Object.assign({}, state, {
                accoladesCompetitionAffiliateInstitutionId: action.data
            })
        case CLEAR_ACCOLADES_COMPETITION:
            return initialState
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getAccoladesCompetitionTitle = state => state.accoladesCompetitionUI.accoladesCompetitionTitle
export const getAccoladesCompetitionDate = state => state.accoladesCompetitionUI.accoladesCompetitionDate
export const getAccoladesCompetitionInstitutionId = state => state.accoladesCompetitionUI.accoladesCompetitionInstitutionId
export const getAccoladesCompetitionInstitutionLocation = state => state.accoladesCompetitionUI.accoladesCompetitionInstitutionLocation
export const getAccoladesCompetitionAffiliateInstitutionId = state => state.accoladesCompetitionUI.accoladesCompetitionAffiliateInstitutionId