import React from "react";
import { useSelector } from "react-redux";
import popLeftBg from "../../../../assets/images/popLeftBg.png";
import LogoWhite from "../../../../assets/images/logoWhite.png";
import AppStore from "../../../../assets/images/appStore.png";
import PlayStore from "../../../../assets/images/playStore.png";
import Close from "../../../../assets/images/close.png";

//reducers
import { getLoggedIn } from "../../../../redux/reducers/account/accounts";

const PopupConfirm = (props) => {
  const isLoggedIn = useSelector(getLoggedIn);

  return (
    <div className={"popup__custom popup__container " + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          <div className="popup__left">
            <img className="popLeftBgHt" src={popLeftBg} alt="background" />
                <div className={"askQuestion__left " + props.askQues}>
                  <div>
                    <img src={LogoWhite} alt="logo" />
                    {props?.type !== "login" ? (
              isLoggedIn ? null : <div><button
                      onClick={() => props?.onClickLogin()}
                      className="button button--white"
                    >
                      LOGIN
                    </button>
                    <p className="donHaveAcc">Don't have an account? <span onClick={() => props?.onClickRegister()}>Sign up </span> today!</p>
                    </div>
            ) : (null)}
                    <div className="askQuestion__leftBottom">
                    <p>
                      Download the Resonance app to enjoy amazing functionality
                      and book pros directly today!
                    </p>
                    <div className="store__wrap">
                    <img className="appstore" src={AppStore} alt="logo" onClick={() => window.open('https://apps.apple.com/in/app/resonance-hire-professionals/id1508162127', '_blank')}/>
                    <img className="playstore" src={PlayStore} alt="logo" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.resonancenetwork.resonance', '_blank')}/>
                      <div className="clear"></div>
                    </div>
                    <div className="clear"></div>
                  </div>
                    <div className="clear"></div>
                  </div>
                  <div className="clear"></div>
                </div>
            <div className="clear"></div>
          </div>
          <div className="popup__content">
            {props.children}
            <span onClick={props.close} className="close__popup">
              <img src={Close} alt="close" />
            </span>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default PopupConfirm;
