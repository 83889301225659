import {
    APP_GET_YOUTUBE_VIDEO_START,
    APP_GET_YOUTUBE_VIDEO,
    APP_GET_YOUTUBE_VIDEO_FAIL,
    APP_LOGOUT
} from '../../actions'

import { createSelector } from 'reselect'

const initialState = {
    videoDetails:{},
    isLoading: false,
    error: false,
    message: '',
    isSuccess: false,
    statusCode: 201
}


export const youtube = (state = initialState, action) => {
    switch (action.type) {
        case APP_GET_YOUTUBE_VIDEO_START:
            return Object.assign({}, state, {
                isLoading: true,
                error: false,
                message: '',
                isSuccess: false
            })
        case APP_GET_YOUTUBE_VIDEO:
            return Object.assign({}, state, {
                videoDetails:action.data,
                isLoading: false,
                error: false,
                message: '',
                isSuccess: true
            })
        case APP_GET_YOUTUBE_VIDEO_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: true,
                isSuccess: false,
                message: action.message,
                statusCode: action.statusCode
            })
        case APP_LOGOUT:
            return initialState
        default:
            return state
    }
}

export const getError = state => state.youtube.error
export const getMsg = state => state.youtube.message
export const getisLoading = state => state.youtube.isLoading
export const getVideoDetails = state => state.youtube.videoDetails
export const getIsSuccess = state => state.youtube.isSuccess
export const getstatusCode = state => state.youtube.statusCode

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
    isTrue: err,
    msg
}))