import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { CustomLayout } from "./layouts/customLayout";

//components
import ScrollToTop from './scrollToTop'
import Dashboard from "../dashboard";
import SearchResult from "../dashboard/searchResult";
import SearchDetail from "../dashboard/searchDetail";
import Profile from "../profile";
import ForgotPasswordEmail from "../user/forgotPassword";
import ForgotPasswordVerify from "../user/forgotPassword/verfifyCode";
import ForgotPasswordReset from "../user/forgotPassword/resetPassword";

//private components
import MyColleagues from '../colleagues'
import UserColleagues from '../colleagues/userColleagues'
import MyActivity from '../myActivity'

export default function routing() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <CustomLayout exact path="/forgot-password/email" name="user">
            <ForgotPasswordEmail />
          </CustomLayout>
          <CustomLayout exact path="/forgot-password/verify" name="user">
            <ForgotPasswordVerify />
          </CustomLayout>
          <CustomLayout exact path="/forgot-password/new-password" name="user">
            <ForgotPasswordReset />
          </CustomLayout>
          <CustomLayout exact path="/search/template/:id" name="searchDetail">
            <SearchDetail />
          </CustomLayout>
          <CustomLayout exact path="/search/people/:navName" name="searchDetail">
            <Profile />
          </CustomLayout>
          <CustomLayout exact path="/search-result" name="searchResult">
            <SearchResult />
          </CustomLayout>
          <CustomLayout exact path="/" name="dashboard">
            <Dashboard />
          </CustomLayout>
          <CustomLayout exact path="/colleagues" name="dashboard" loggedIn={true}>
            <MyColleagues />
          </CustomLayout>
          <CustomLayout exact path="/user/colleagues" name="dashboard">
            <UserColleagues />
          </CustomLayout>
          <CustomLayout exact path="/dashboard" name="dashboard" loggedIn={true}>
            <MyActivity />
          </CustomLayout>
          <CustomLayout>
            <Dashboard/>
          </CustomLayout>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}
