
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_GET_PROFESSION_CATEGORY_START,
    APP_GET_PROFESSION_CATEGORY,
    APP_GET_PROFESSION_CATEGORY_FAIL,
    APP_LOGOUT,
    SAVE_SELECTED_PROFESSIONS,
    CLEAR_SELECTED_PROFESSIONS,
    APP_POST_USER_PROFESSION_START,
    APP_POST_USER_PROFESSION,
    APP_POST_USER_PROFESSION_FAIL,
    APP_GET_USER_PROFESSION_START,
    APP_GET_USER_PROFESSION,
    APP_GET_USER_PROFESSION_404,
    APP_GET_USER_PROFESSION_FAIL,
    APP_GET_COMBINATION_USER_PROFESSION_START,
    APP_GET_COMBINATION_USER_PROFESSION,
    APP_GET_COMBINATION_USER_PROFESSION_FAIL
} from '../../actions'

export const clearSelectedProfession = () => ({
    type: CLEAR_SELECTED_PROFESSIONS
})

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitStart = () => ({
    type: APP_GET_PROFESSION_CATEGORY_START
})


//get profession by discipline
export const emitProfession = data => {
    return {
        type: APP_GET_PROFESSION_CATEGORY,
        data
    }
}

export const emitProfessionFailed = (data) => ({
    type: APP_GET_PROFESSION_CATEGORY_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const professionByDiscipline = ({ jwt, ids }) => {

    let Authorization = composeAuth(jwt)
    
    let stringifyIds = encodeURI(JSON.stringify(ids))
    
    
    let url = process.env.REACT_APP_SERVER + `/api/v1/profession/category/${stringifyIds}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetProfessionByDiscipline = (data) => {
    return (dispatch) => {
        dispatch(emitStart())
        return professionByDiscipline(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitProfession(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitProfessionFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitProfessionFailed(err))
            })
    }

}

//get user professions with id

const professionByIds = ({ jwt, ids }) => {

    let Authorization = composeAuth(jwt)

    let stringifyIds = encodeURI(JSON.stringify(ids))
    let url = process.env.REACT_APP_SERVER + `/api/v1/profession/userprofession/category/${stringifyIds}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetProfessionByIds = (data) => {
    return (dispatch) => {
        dispatch(emitStart())
        return professionByIds(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitProfession(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitProfessionFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitProfessionFailed(err))
            })
    }

}

export const saveSelectedProfession = (data) => ({
    type: SAVE_SELECTED_PROFESSIONS,
    data

})

//post user profession categories

export const emitStartPostUserProfessions = () => ({
    type: APP_POST_USER_PROFESSION_START
})


export const emitUserProfession = (data) => {
    return {
        type: APP_POST_USER_PROFESSION,
        data
    }
}

export const emitPostProfessionFailed = (data) => ({
    type: APP_POST_USER_PROFESSION_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})


const postProfession = ({ jwt, professioncategory }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/profession/add/userprofession'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify({professioncategory})
    })
}

export const startPostUserProfession = (data) => {
    return (dispatch) => {
        dispatch(emitStartPostUserProfessions())
        return postProfession(data)
            .then(parseJSON)
            .then(json => {
                console.log(json,"startPostUserProfession");
                if (json.statusCode === 201) {
                    dispatch(emitUserProfession(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitPostProfessionFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitPostProfessionFailed(err))
            })
    }

}

//get user professions

export const emitStartGetUserProfessions = () => ({
    type: APP_GET_USER_PROFESSION_START
})


export const emitGetUserProfession = (data) => {
    return {
        type: APP_GET_USER_PROFESSION,
        data
    }
}

export const emitGetProfessionFailed = (data) => ({
    type: APP_GET_USER_PROFESSION_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

export const emitGetUserProfession404 = () => ({
    type: APP_GET_USER_PROFESSION_404,
})


const getProfession = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/profession/userprofession'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserProfession = (data) => {
    return (dispatch) => {
        dispatch(emitStartGetUserProfessions())
        return getProfession(data)
            .then(parseJSON)
            .then(json => {
                // console.log(json,"startGetUserProfession");
                if (json.statusCode === 200) {
                    dispatch(emitGetUserProfession(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else if (json.statusCode === 404) {
                    dispatch(emitGetUserProfession404())
                }else {
                    dispatch(emitGetProfessionFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitGetProfessionFailed(err))
            })
    }

}

//get combination user professions

export const emitStartGetCombinationUserProfessions = () => ({
    type: APP_GET_COMBINATION_USER_PROFESSION_START
})


export const emitGetCombinationUserProfession = (data) => {
    return {
        type: APP_GET_COMBINATION_USER_PROFESSION,
        data
    }
}

export const emitGetCombinationProfessionFailed = (data) => ({
    type: APP_GET_COMBINATION_USER_PROFESSION_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const getCombinationProfession = ({ jwt, searchText }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/card/combinations/${searchText}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetCombinationUserProfession = (data) => {
    return (dispatch) => {
        dispatch(emitStartGetCombinationUserProfessions())
        return getCombinationProfession(data)
            .then(parseJSON)
            .then(json => {
                
                
                
                if (json.statusCode === 200) {
                    dispatch(emitGetCombinationUserProfession(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else if (json.statusCode === 404) {
                    dispatch(emitGetUserProfession404())
                }else {
                    dispatch(emitGetCombinationProfessionFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitGetCombinationProfessionFailed(err))
            })
    }

}

// get user professions by user id
const getProfessionByUserId = ({ jwt, userId }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/profession/users/userprofession/${userId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUserProfessionByUserId = (data) => {
    return (dispatch) => {
        dispatch(emitStartGetUserProfessions())
        return getProfessionByUserId(data)
            .then(parseJSON)
            .then(json => {
                if (json.statusCode === 200) {
                    dispatch(emitGetUserProfession(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else if (json.statusCode === 404) {
                    dispatch(emitGetUserProfession404())
                }else {
                    dispatch(emitGetProfessionFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitGetProfessionFailed(err))
            })
    }

}

// delete profession categories

const deleteProfession = ({ jwt, userprofessionid }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/profession/delete/userprofession'
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify({ userprofessionid })
    })
}

export const startDeleteUserProfession = (data) => {
    return (dispatch) => {
        dispatch(emitStartGetUserProfessions())
        return deleteProfession(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitGetUserProfession(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitGetProfessionFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitGetProfessionFailed(err))
            })
    }

}


