import React from 'react';
import { useDispatch } from 'react-redux'

//assets
import Header from "../assets/layouts/header/dashboardHeader";

//actions
import { changeDropdownStatus } from '../../redux/actions/ui/profile'

//components
import AnnouncementsSection from './anouncements'
import UserSection from './users'
import HighLightsSection from './upcoming'
import VideosSection from './videos'
import AudiosSection from './audio'
import UserCardsSection from './cards'

const MyActivity = () => {
    const dispatch = useDispatch()

    return (
        <div className="main__dashboard bgLines" onClick={() => dispatch(changeDropdownStatus(false))}>
            <Header heading="Dashboard" showText="Welcome to the Resonance Dashboard! From here, you can find the latest news and announcements, learn about upcoming events, deals, and discounts, and view featured media and posts from other people on the platform."/>
            <div className="minHeightContainer">
                <AnnouncementsSection/>
                <UserSection/>
                <VideosSection/>
                <AudiosSection/>
                <HighLightsSection/>
                <UserCardsSection/>
            </div>
            <div className="clear"></div>
        </div>
    );
};

export default MyActivity;