
import { parseJSON, composeAuth } from '../../../helpers/utils'

import {
    APP_POST_UPCOMING_START,
    APP_POST_UPCOMING,
    APP_POST_UPCOMING_FAIL,
    APP_DELETE_UPCOMING_START,
    APP_DELETE_UPCOMING,
    APP_DELETE_UPCOMING_FAIL,
    APP_LOGOUT,
} from '../../actions'

export const emitAuthFail = () => ({
    type: APP_LOGOUT
})

export const emitUpcomingStart = () => ({
    type: APP_POST_UPCOMING_START
})

export const emitUpcoming = data => {
    return {
        type: APP_POST_UPCOMING,
        data
    }
}

export const emitUpcomingFailed = (data) => ({
    type: APP_POST_UPCOMING_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const postUpcoming = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/upcomings/upcoming'
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPostUpcoming = (data) => {
    return (dispatch) => {
        dispatch(emitUpcomingStart())
        return postUpcoming(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpcomingFailed(json))
                }
            })
            .catch(err => {
                dispatch(emitUpcomingFailed(err))
            })
    }

}

//edit Upcoming

const putUpcoming = ({ jwt, data, id}) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + `/api/v1/upcomings/edit/${id}`
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startPutUpcoming = (data) => {
    return (dispatch) => {
        dispatch(emitUpcomingStart())
        return putUpcoming(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUpcomingFailed(err))
            })
    }
}

//get Upcoming

const getUpcoming = ({ jwt }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/upcomings/userupcomings'
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUpcoming = (data) => {
    return (dispatch) => {
        dispatch(emitUpcomingStart())
        return getUpcoming(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUpcomingFailed(err))
            })
    }
}

//delete Upcoming

export const emitUpcomingDeleteStart = () => ({
    type: APP_DELETE_UPCOMING_START
})

export const emitUpcomingDelete = data => {
    return {
        type: APP_DELETE_UPCOMING,
        data
    }
}

export const emitUpcomingDeleteFailed = (data) => ({
    type: APP_DELETE_UPCOMING_FAIL,
    message: data.message,
    statusCode: data.statusCode,

})

const deleteUpcoming = ({ jwt, data }) => {
    let Authorization = composeAuth(jwt)
    let url = process.env.REACT_APP_SERVER + '/api/v1/upcomings/delete/upcoming'
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        },
        body: JSON.stringify(data)
    })
}

export const startDeleteUpcoming = (data) => {
    return (dispatch) => {
        dispatch(emitUpcomingDeleteStart())
        return deleteUpcoming(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 201) {
                    dispatch(emitUpcomingDelete(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpcomingDeleteFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUpcomingDeleteFailed(err))
            })
    }

}

//get upcoming by id

const getUpcomingById = ({ jwt, ids }) => {
    let Authorization = composeAuth(jwt)
    let stringifyIds = encodeURI(JSON.stringify(ids))
    let url = process.env.REACT_APP_SERVER + `/api/v1/upcomings/upcoming/${stringifyIds}`
    
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
        }
    })
}

export const startGetUpcomingById = (data) => {
    return (dispatch) => {
        dispatch(emitUpcomingStart())
        return getUpcomingById(data)
            .then(parseJSON)
            .then(json => {
                
                if (json.statusCode === 200) {
                    dispatch(emitUpcoming(json.data))
                } else if (json.statusCode === 401 || json.statusCode === 422) {
                    dispatch(emitAuthFail())
                } else {
                    dispatch(emitUpcomingFailed(json))
                }
            })
            .catch(err => {
                

                dispatch(emitUpcomingFailed(err))
            })
    }
}
